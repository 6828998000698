import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  mailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  urlPattern: RegExp = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/,=]*)$/;


  constructor(
    private translate: TranslateService
  ) { }


  public hasTranslation(key: string): boolean {
    let isTranslated: boolean = true;
    if (this.translate.instant(key) == key) {
      isTranslated = false;
    }
    return isTranslated;
  }

  validateEmailPattern(email: string) {
    return this.mailPattern.test(email)
  }

  validateUrlPattern(url: string) {
    return this.urlPattern.test(url)
  }
}
