import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, toArray } from 'rxjs';
import { User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-country-operator-constraint-dialog',
  templateUrl: './country-operator-constraint-dialog.component.html',
  styleUrls: ['./country-operator-constraint-dialog.component.scss']
})
export class CountryOperatorConstraintDialogComponent {
  user?: User;
  constraints: string[] = [];
  territories: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user?: User },
    private aclService: AclService,
    private dialogRef: MatDialogRef<CountryOperatorConstraintDialogComponent>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    if (this.data && this.data.user) {
      this.user = this.data.user;
      if (this.user.acls) {
        this.constraints = this.aclService.getCountriesConstraints(this.user.acls);
        this.territories = [...this.constraints];
      }

    }
  }

  ngOnDestroy(): void {
  }


  onCancel(): void {
    this.dialogRef.close({
      confirmed: false
    });
  }

  async onConfirm() {
    try {
      this.toastr.success(this.translate.instant("COUNTRY_OPERATOR_CONSTRAINT_DIALOG.CONSTRAINT_MODIFIED", this.user));
      if (this.user && this.user._id) {
        const observables: Observable<User>[] = [];
        this.constraints.forEach(async constraint => {
          if (!this.territories.includes(constraint)) {
            observables.push(this.userService.deleteOperatorConstraint(this.user?._id!, `${constraint}:COUNTRY`));
          }
        });
        this.territories.forEach(territory => {
          if (!this.constraints.includes(territory)) {
            observables.push(this.userService.addOperatorConstraint(this.user?._id!, `${territory}:COUNTRY`));
          }
        });
        concat(...observables).pipe(toArray()).subscribe((res) => {
          this.dialogRef.close({
            confirmed: true
          });
          this.toastr.success(this.translate.instant("COUNTRY_OPERATOR_CONSTRAINT_DIALOG.CONSTRAINT_MODIFIED", this.user));
        }, err => {
          this.toastr.error(`ERRORS.GENERIC`)
        })

      }
    } catch (err: any) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }
}
