import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AddCaseDocumentsDialogComponent } from 'src/app/dialogs/add-case-documents-dialog/add-case-documents-dialog.component';
import { Case, CaseDocument } from 'src/app/models/case';
import { Paginator } from 'src/app/models/paginator';
import { CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { DocumentsCaseComponent } from '../../documents-display/documents-case/documents-case.component';
import { Document } from 'src/app/models/document';

@Component({
  selector: 'app-case-proofs',
  templateUrl: './case-proofs.component.html',
  styleUrls: ['./case-proofs.component.scss']
})
export class CaseProofsComponent {

  @Input() case?: Case;
  @Input() readonly: boolean = false;

  phase?: { name: string };

  public documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: CaseDocument[] = [];

  selection = new SelectionModel<CaseDocument>(true, []);

  @ViewChild('caseDocuments') caseDocuments?: DocumentsCaseComponent;

  @Output() refresh = new EventEmitter<any>();

  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  init() {
    this.resetPage();
    this.retrieveDocuments();
  }

  async retrieveDocuments() {
    try {
      this._message.emitChange("LOADING", "START");
      if (this.case) {
        this.documents = { ...this.documents, ...await this.caseService.retrieveDocuments(this.case!._id!, this.documents.page, this.documents.limit, this.documents.offset, this.documents.sort) };
        this.filteredDocuments = this.documents.docs;
        if (this.case.phase) {
          this.phase = {
            name: this.translate.instant(`CASE.PHASE_${this.case.phase.name}`).toUpperCase()
          }
        }
      }
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  resetPage() {
    this.documents.limit = 10;
    this.documents.page = 1;
    this.documents.offset = 0;
  }

  async handlePage(event: any) {
    this.documents.limit = event.pageSize;
    this.documents.page = event.pageIndex + 1;
    this.documents.offset = event.pageIndex * this.documents.limit;
    this.retrieveDocuments();
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.documents.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveDocuments();
    }
  }

  async openUploadDialog() {
    this.documentService.openUploadDialog([], this.case);
  }

  async openLinkDialog() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      height: "calc(100% - 30px)",
      width: "calc(100% - 30px)",
      maxWidth: "100%",
      maxHeight: "100%",
      autoFocus: false,
      data: {
        case: this.case
      }
    }
    const dialog = this.dialog.open(AddCaseDocumentsDialogComponent, config);
    const result: { linked: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.linked && this.case && this.case._id) {
      this.init();
      this.refresh.emit();
    }
  }

  async unlink() {
    try {
      if (this.case && this.case._id) {
        this._message.emitChange("LOADING", "START");
        const documentsToUnlink = this.selection.selected.map((doc: Document) => doc._id!);
        await this.caseService.unlink(this.case._id, documentsToUnlink);
        this.init();
        this.refresh.emit();
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant(documentsToUnlink.length > 1 ? 'CASE.UNLINK_PROOFS_SUCCESS' : 'CASE.UNLINK_PROOF_SUCCESS', { n: documentsToUnlink.length }));
        this.selection.clear();
        if (this.caseDocuments) {
          this.caseDocuments.flushSelection();
        }
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }


}
