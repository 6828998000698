import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';
import { TrademarkService } from 'src/app/services/trademark.service';

type TrademarkOption = Trademark & { selected: boolean, disabled?: boolean, display?: boolean }


@Component({
  selector: 'app-trademark-select',
  templateUrl: './trademark-select.component.html',
  styleUrls: ['./trademark-select.component.scss']
})
export class TrademarkSelectComponent {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;

  @Input() initial?: string = 'all';
  @Input() disabled: boolean = false;
  @Input() disabledValues: string[] = [];
  @Input() emitOnInit: boolean = true;

  loading: boolean = false;
  trademarks: Paginator<TrademarkOption> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0
  };

  selected: TrademarkOption[] = [];

  query?: string;
  queryUpdate: Subject<string | undefined> = new Subject<string | undefined>();

  @Output() change = new EventEmitter<any>();
  @Input() value?: Trademark | Trademark[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private trademarkService: TrademarkService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
    this.queryUpdate
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(async () => {
        this.resetPage();
        await this.retrieveTrademarks(true);
      });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  _isDisabled(option: Trademark) {
    return (option._id && this.disabledValues.includes(option._id)) ? true : false;
  }

  _isSelected(option: Trademark) {
    return (option._id && this.selected.map((t) => t._id).includes(option._id)) ? true : false;
  }

  populate() {
    if (this.multiple && this.value && (this.value as Trademark[]).length > 0) {
      const values = (this.value as Trademark[]);
      this.selected = values.map((t) => ({ ...t, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as Trademark);
      this.selected = [{ ...value, selected: true, disabled: false, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  async setInitialState() {
    await this.retrieveTrademarks(true);
    if (this.multiple && this.value && (this.value as Trademark[]).length > 0) {
      const values = (this.value as Trademark[]);
      this.selected = values.map((t) => ({ ...t, selected: true, disabled: false }));
      this.trademarks.docs.forEach(trademark => {
        const found = values.find(t => t.countryOfDesignation === trademark.countryOfDesignation && t.identifierNumber === trademark.identifierNumber);
        if (found) {
          trademark.selected = true;
        } else {
          trademark.selected = false;
        }
      });
      const value = _.unionWith([...this.trademarks.docs.filter(c => c.selected), ...this.selected], this._trademarkComparator);
      if (value.length > 0) {
        this.selection = value.length === 1 ? (value[0].name || value[0].identifierNumber) : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as Trademark);
      this.selected = [{ ...value, selected: true, disabled: false }];
      const option = this.trademarks.docs.find(t => t.countryOfDesignation === value.countryOfDesignation && t.identifierNumber === value.identifierNumber);
      if (option) {
        option.selected = true;
      }
      this.selection = value ? (value.name || value.identifierNumber) : undefined;
    }
    if (this.emitOnInit) {
      this.onChange();
    }

  }

  removeFromPreselection(option: any) {
    this.selected.splice(this.selected.findIndex((o) => option._id === o._id), 1);
    const doc = this.trademarks.docs.find((t) => t._id === option._id);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: TrademarkOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option._id === o._id), 1);
    } else if (!this._isSelected(option)) {
      this.selected.push({ ...option, display: false });
    }
    this.selected.sort((a, b) => (a.name || 'a').localeCompare(b.name || 'a'))
  }

  resetPage() {
    this.trademarks.limit = 10;
    this.trademarks.page = 1;
    this.trademarks.offset = 0;
  }

  _trademarkComparator(trademarkA: Trademark, trademarkB: Trademark) {
    return trademarkA.identifierNumber === trademarkB.identifierNumber && trademarkA.countryOfDesignation === trademarkB.countryOfDesignation
  }

  async retrieveTrademarks(replace: boolean = false) {
    this.loading = true;
    const result = await this.trademarkService.retrieveAll(this.trademarks.page, this.trademarks.limit, this.trademarks.offset, 'name', [], [], this.query);
    this.trademarks = {
      ...result,
      docs: replace ? [...result.docs.map(t => ({ ...t, disabled: this._isDisabled(t), selected: this._isSelected(t) }))] : _.unionWith([...this.trademarks.docs, ...result.docs.map(t => ({ ...t, disabled: this._isDisabled(t), selected: this._isSelected(t) }))], this._trademarkComparator)
    };
    this.loading = false;
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.trademarks.docs.forEach(t => {
        if (t._id !== option._id) {
          t.selected = false;
        }
      })
    }
    this.onChange();
  }

  // TODO: handle trademarks having countrycode which is a wipo code (ex: EM)
  onChange() {
    if (!this.multiple) {
      const value = this.trademarks.docs.find(c => c.selected) !== undefined ? this.trademarks.docs.find(c => c.selected) : (this.selected.length === 1 ? this.selected[0] : undefined);
      this.selection = value ? (value.name || value.identifierNumber) : undefined;
      this.valueChange.emit(value);
      this.change.emit({ value });
      this.trigger?.close();
    } else {
      const values = _.unionWith([...this.trademarks.docs.filter(c => c.selected), ...this.selected], this._trademarkComparator);
      if (values.length > 0) {
        this.selection = values.length === 1 ? (values[0].name || values[0].identifierNumber) : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values);
      this.change.emit({ value: values });
    }
  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return this.trademarks.docs;
  }

  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.trademarks.docs.forEach(t => {
      t.selected = false;
    });
    this.selected = [];
    this.selection = undefined;
    this.query = undefined;
    this.onChange();
    this.trigger?.close();
  }

  onQueryChange(event: any) {
    this.queryUpdate.next(this.query);
  }


  onScroll() {

    this.trademarks.offset = this.trademarks.page * this.trademarks.limit;
    this.trademarks.page = this.trademarks.page + 1;
    this.retrieveTrademarks();
  }
}
