import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  selector: '[toast-component]',
  styleUrls: ['./toast.component.scss'],
  templateUrl: './toast.component.html',
  animations: [],
  preserveWhitespaces: false
})
export class ToastComponent extends Toast {

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    this.toastPackage.triggerAction();
    return false;
  }
}
