import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent {
  @Input() buttons: any[] = [];

  @Output() change = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit() {
  }

  onClick(index: number) {
    this.buttons.forEach((button, i) => {
      if (i === index) {
        button.selected = true;
      } else {
        button.selected = false;
      }
    })
    this.change.emit(index)
  }
}
