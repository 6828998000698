<div class="p-20 fx-row fx-wrap align-items-center justify-content-center text-center">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'OTP_DIALOG.TITLE' | translate}}</span>
  </div>
  <div class="text fx-flex-100 text-center" [innerHTML]="'OTP_DIALOG.INSTRUCTION' | translate:user"></div>
  <div class="fx-flex-100 fx-row align-items-center justify-content-center mv-20">
    <app-otp-input placeholder="_" [(value)]="otp"></app-otp-input>
  </div>
  <span class="text fx-flex-100 text-center">{{'OTP_DIALOG.NOT_RECEIVED' | translate}}
    <a [class.disabled]="requestDisabled" (click)="requestDisabled ? null : requestOTP()">{{(requestDisabled ?
      'OTP_DIALOG.SEND_AGAIN_IN':
      'OTP_DIALOG.SEND_AGAIN') | translate:requestInterval}}</a></span>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onValidate()" [class.disabled]="isDisabled()"
      [disabled]="isDisabled()">{{'ACTIONS.VALIDATE'
      | translate}}</button>
  </div>
</div>