<div class="fx-column">
  <div class="graph-period-container" [style.height.px]="height">
    <div id="graphperioddiv" #graphperioddiv class="graph-period" [style.height.px]="height" *ngIf=" !error && !noData">
    </div>
    <!-- <mat-progress-spinner class="main-spinner" mode="indeterminate" diameter="25"
      *ngIf="loading   && !error && !noData">
    </mat-progress-spinner> -->
    <div class="fx-column align-items-center justify-content-center" *ngIf="error">
      <mat-icon class="red mb-5" fontSet="material-icons-outlined">error</mat-icon>
      <span class="text light">{{'DOCUMENTS_REPORT.ERROR' | translate}}</span>
    </div>
    <div class="fx-column align-items-center justify-content-center" *ngIf="!loading && noData">
      <span class="text light">{{'DOCUMENTS_LIST.SEARCH_NO_DOCUMENTS' | translate}}</span>
    </div>
  </div>
</div>