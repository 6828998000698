import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, catchError, debounceTime, distinctUntilChanged, last, map, of } from 'rxjs';
import { TerritorySelectComponent } from 'src/app/components/territory-select/territory-select.component';
import { TrademarkSelectComponent } from 'src/app/components/trademark-select/trademark-select.component';
import { Batch, BatchMode } from 'src/app/models/batch';
import { Case } from 'src/app/models/case';
import { DocumentClass } from 'src/app/models/documentClass';
import { Trademark } from 'src/app/models/trademark';
import { AnalysisMode, UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { Role } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { BatchService } from 'src/app/services/batch.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  styleUrls: ['./upload-documents-dialog.component.scss']
})
export class UploadDocumentsDialogComponent {

  origin: string = 'DOCUMENTS';
  backTo: string[] = ['documents'];

  public active: boolean = false;
  private accepted: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/tiff'];

  files: UploadFile[] = [];
  filtered: UploadFile[] = [];

  progress: number = 0;
  uploading: boolean = false;
  done: boolean = false;
  confidentialities: any[] = [
    {
      value: true,
      label: "CONFIDENTIALITY.DOCUMENT_CONFIDENTIAL"
    },
    {
      value: false,
      label: "CONFIDENTIALITY.DOCUMENT_CONFIDENTIAL"
    },
  ];
  classes: { value: string, label: string, selected: boolean }[] = [];
  modes: { value: string, label: string, selected: boolean, disabled: boolean }[] = [
    {
      value: BatchMode.GENERIC,
      label: "UPLOAD_DOCUMENTS.MODE_GENERIC",
      selected: false,
      disabled: false
    },
    {
      value: BatchMode.TRADEMARKS,
      label: "UPLOAD_DOCUMENTS.MODE_TRADEMARKS",
      selected: false,
      disabled: false
    },
    {
      value: BatchMode.COUNTRIES,
      label: "UPLOAD_DOCUMENTS.MODE_COUNTRIES",
      selected: false,
      disabled: false
    },
    {
      value: BatchMode.NON_USE,
      label: "UPLOAD_DOCUMENTS.NON_USE",
      selected: false,
      disabled: false
    }
  ];
  mode?: string;
  trademark?: Trademark;
  trademarks: Trademark[] = [];
  niceClassification: number[] = [];
  countries: any[] = [];

  analysisMode: 'light' | 'full' = 'light';
  analysisModes: { value: AnalysisMode, label: string, selected: boolean, disabled: boolean }[] = [
    {
      value: AnalysisMode.LIGHT,
      label: "UPLOAD_DOCUMENT.ANALYSIS_MODE_LIGHT",
      selected: true,
      disabled: false
    },
    {
      value: AnalysisMode.FULL,
      label: "UPLOAD_DOCUMENT.ANALYSIS_MODE_FULL",
      selected: false,
      disabled: false
    }
  ];

  query?: string;
  queryUpdate: Subject<string | undefined> = new Subject<string | undefined>();

  caseObject?: Case;

  public role = Role;

  langs: { selected: boolean, value: string, label: string }[] = [
    { value: "ar", selected: false, label: "LANGUAGES.ar" },
    { value: "bg", selected: false, label: "LANGUAGES.bg" },
    { value: "bs", selected: false, label: "LANGUAGES.bs" },
    { value: "cs", selected: false, label: "LANGUAGES.cs" },
    { value: "da", selected: false, label: "LANGUAGES.da" },
    { value: "de", selected: false, label: "LANGUAGES.de" },
    { value: "el", selected: false, label: "LANGUAGES.el" },
    { value: "en", selected: false, label: "LANGUAGES.en" },
    { value: "es", selected: false, label: "LANGUAGES.es" },
    { value: "et", selected: false, label: "LANGUAGES.et" },
    { value: "fi", selected: false, label: "LANGUAGES.fi" },
    { value: "fr", selected: false, label: "LANGUAGES.fr" },
    { value: "hr", selected: false, label: "LANGUAGES.hr" },
    { value: "hu", selected: false, label: "LANGUAGES.hu" },
    { value: "is", selected: false, label: "LANGUAGES.is" },
    { value: "it", selected: false, label: "LANGUAGES.it" },
    { value: "ja", selected: false, label: "LANGUAGES.ja" },
    { value: "ka", selected: false, label: "LANGUAGES.ka" },
    { value: "ko", selected: false, label: "LANGUAGES.ko" },
    { value: "lt", selected: false, label: "LANGUAGES.lt" },
    { value: "lv", selected: false, label: "LANGUAGES.lv" },
    { value: "me", selected: false, label: "LANGUAGES.me" },
    { value: "mk", selected: false, label: "LANGUAGES.mk" },
    { value: "mt", selected: false, label: "LANGUAGES.mt" },
    { value: "nl", selected: false, label: "LANGUAGES.nl" },
    { value: "no", selected: false, label: "LANGUAGES.no" },
    { value: "pl", selected: false, label: "LANGUAGES.pl" },
    { value: "pt", selected: false, label: "LANGUAGES.pt" },
    { value: "ro", selected: false, label: "LANGUAGES.ro" },
    { value: "ru", selected: false, label: "LANGUAGES.ru" },
    { value: "sk", selected: false, label: "LANGUAGES.sk" },
    { value: "sl", selected: false, label: "LANGUAGES.sl" },
    { value: "sq", selected: false, label: "LANGUAGES.sq" },
    { value: "sr", selected: false, label: "LANGUAGES.sr" },
    { value: "sv", selected: false, label: "LANGUAGES.sv" },
    { value: "tr", selected: false, label: "LANGUAGES.tr" },
    { value: "zh", selected: false, label: "LANGUAGES.zh" }
  ];

  @ViewChild('trademarkSelect') trademarkSelect?: TrademarkSelectComponent;
  @ViewChild('territorySelect') territorySelect?: TerritorySelectComponent;

  constructor(
    private _message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private aclService: AclService,
    private batchService: BatchService,
    private dialogRef: MatDialogRef<UploadDocumentsDialogComponent>,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.queryUpdate
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(async () => {
        this.filter()
      });
  }

  ngOnInit(): void {
    if (this.data && this.data.files && this.data.files.length > 0) {
      this.handleFiles([...this.data.files]);
    }
    if (this.data && this.data.case) {
      this.caseObject = this.data.case;
      this.mode = 'TRADEMARKS';
      this.modes.forEach(mode => {
        if (mode.value !== BatchMode.TRADEMARKS) {
          mode.disabled = true;
        }
      })
      this.trademarks = [{
        _id: this.caseObject?.trademark.ref,
        name: this.caseObject?.trademark.name,
        identifierNumber: this.caseObject?.trademark.identifierNumber!,
        countryOfDesignation: this.caseObject?.trademark.countryOfDesignation!
      }];
    } else if (this.aclService.role()?.toLowerCase() === Role.COUNTRY_OPERATOR.toLowerCase()) {
      this.mode = BatchMode.COUNTRIES;
    } else if (this.aclService.role()?.toLowerCase() === this.role.CASE_OPERATOR.toLowerCase()) {
      this.mode = BatchMode.TRADEMARKS;
    } else {
      this.mode = BatchMode.GENERIC;
    }
  }


  back() {
    this.router.navigate(this.backTo)
  }

  setMode(mode: string) {
    if (mode === BatchMode.GENERIC) {
      this.trademark = undefined;
      this.trademarks = [];
      if (this.trademarkSelect)
        this.trademarkSelect?.onClear()
      this.countries = [];
      if (this.territorySelect)
        this.territorySelect?.onClear()
    } else if (mode === BatchMode.TRADEMARKS) {
      this.trademark = undefined;
      this.countries = [];
      if (this.territorySelect)
        this.territorySelect?.onClear()
    } else if (mode === BatchMode.COUNTRIES) {
      this.trademark = undefined;
      this.trademarks = [];
      if (this.trademarkSelect)
        this.trademarkSelect?.onClear()
    } else if (mode === BatchMode.NON_USE) {
      this.trademark = undefined;
      this.trademarks = [];
      if (this.trademarkSelect)
        this.trademarkSelect?.onClear()
      this.countries = [];
      if (this.territorySelect)
        this.territorySelect?.onClear()
    }
    this.mode = mode;
  }

  onTrademarkChange(event: any) {
    if (event && event.value)
      this.niceClassification = event.value.niceClassification?.map((niceClass: any) => niceClass.classNumber) ?? [];
  }

  isModeComplete() {
    return this.mode === BatchMode.GENERIC
      || (this.mode === BatchMode.TRADEMARKS && this.trademarks.length > 0)
      || (this.mode === BatchMode.COUNTRIES && this.countries.length > 0)
      || (this.mode === BatchMode.NON_USE && this.trademark && this.isNonUseComplete());
  }

  isNonUseComplete() {
    if (!this.files || this.files.length === 0) {
      return false;
    }
    const uploadFile = this.files[0];
    return uploadFile && uploadFile.name && uploadFile.class
      && (uploadFile.territories && uploadFile.territories.length > 0)
      && (uploadFile.niceClassification && uploadFile.niceClassification.length > 0)
      && (uploadFile.langs && uploadFile.langs.length > 0);
  }

  isDisabled() {
    return !this.isModeComplete() || this.files.length === 0;
  }

  onQueryChange(event: any) {
    this.queryUpdate.next(this.query);
  }


  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
    const files = event.dataTransfer.files;
    this.handleFiles(this.mode === 'NON_USE' ? [files[0]] : [...files]);
  }

  onFilesSelected(event: any) {
    const files: File[] = event.target.files;
    this.handleFiles(this.mode === 'NON_USE' ? [files[0]] : [...files]);
  }

  handleFiles(files: File[]) {
    const unauthorized = {
      format: files.filter(file => !this.accepted.includes(file.type)),
      size: files.filter(file => file.size >= 20 * 1024 * 1024)
    }
    const authorized = files.filter(file => this.accepted.includes(file.type) && file.size < 20 * 1024 * 1024);
    if (unauthorized.format.length > 0) {
      this.toastr.warning("UPLOAD_DOCUMENTS.UNAUTHORIZED_FORMAT_MESSAGE")
    }
    if (unauthorized.size.length > 0) {
      this.toastr.warning("UPLOAD_DOCUMENTS.UNAUTHORIZED_SIZE_MESSAGE")
    }
    this.files = [...this.files, ...authorized.map(file => file as UploadFile)];
    this.files.forEach((file) => {
      file.confidential = false;
      file.filename = file.name;
      file.status = UploadFileStatus.PENDING;
      file.nonUse = this.mode === BatchMode.NON_USE;
      if (this.mode !== BatchMode.NON_USE) {
        file.class = DocumentClass.AUTOMATIC;
      }
      file.analysis = AnalysisMode.LIGHT;
    });
    this.filter();
  }


  onFilesHover(event: any) {
    if (event.dataTransfer.types && event.dataTransfer.types.some((t: string) => t === "Files")) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  remove(file: UploadFile) {
    this.files.splice(this.files.indexOf(file), 1)
  }

  filter() {
    if (this.query) {
      this.filtered = this.files.filter((file: UploadFile) => this.query && file.name.toLowerCase().includes(this.query?.toLowerCase()))
    } else {
      this.filtered = this.files;
    }
  }

  onPeriodChange(file: UploadFile, event: any) {
    if (!file.period) {
      file.period = {
        start: event.start,
        end: event.end
      }
    } else {
      file.period.start = event.start;
      file.period.end = event.end;
    }
  }

  onLangsChange(file: UploadFile, event: any) {
    if (event && event.value)
      file.langs = event.value;
  }

  onDocumentClassChange(file: UploadFile, event: any) {
    if (event && event.value && event.value.value) {
      file.class = event.value.value;
    }
  }

  onAnalysisModeChange(file: UploadFile, event: any) {
    if (event && event.value) {
      file.analysis = event.value.value;
    } else {
      file.analysis = AnalysisMode.LIGHT;
    }
  }


  icon(file: File) {
    return this.documentService.icon(file);
  }
  size(file: File) {
    return this.documentService.size(file);
  }

  async upload() {
    try {
      this.dialogRef.close();
      const batch: Batch = await this.batchService.create({
        mode: this.mode,
        trademarks: this.mode === BatchMode.NON_USE ? [{ ...this.trademark, ref: this.trademark?._id }] : this.trademarks.map((trademark) => ({ ...trademark, ref: trademark._id })),
        countries: this.countries
      });
      await this.documentService.upload(batch, this.files, this.caseObject);

    } catch (err) {
      console.log(err)
      this.toastr.error('ERRORS.GENERIC')
    }
  }

  onCancel() {
    this.dialogRef.close();
  }


}
