import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-countries',
  templateUrl: './document-countries.component.html',
  styleUrls: ['./document-countries.component.scss']
})
export class DocumentCountriesComponent {
  @Input() territories: string[] = []
  @Input() mode: string = 'list';
  @Input() metadata?: DocumentMetadata[] = [];
  @Input() shown: boolean = false;
  @Input() title: boolean = true;
  limit: number = 4;


  constructor(
    private translate: TranslateService
  ) {

  }

  getConfidence(country_code: string) {
    return (this.metadata?.find(datum => datum.type === MetadataType.LOCATION && datum.value === `${country_code}`)?.confidence ?? 0) * 100
  }

  toggle() {
    if (this.limit === 4) {
      this.limit = this.territories.length;
    } else {
      this.limit = 4;
    }
  }

  moreTooltip() {
    return this.translate.instant('UTILS.X_MORE', { x: this.territories.length - this.limit })
  }
}
