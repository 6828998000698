<div class="fx-row fx-wrap align-items-center justify-content-start  fx-flex-100">
  <div class="fx-row fx-wrap align-items-start justify-content-between fx-flex-100" *ngIf="user">
    <div class="fx-column align-items-start justify-content-start">
      <span class="title bold">{{'SETTINGS.SECURITY.TAB' | translate}}</span>
      <span class="text light">{{'SETTINGS.SECURITY.HINT' | translate}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button class="button" (click)="cancel()">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" (click)="update()" [disabled]="isUpdateDisabled()"
        [class.disabled]="isUpdateDisabled()">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.SECURITY.CURRENT' | translate}}</span>
      <span class="text light">{{'SETTINGS.SECURITY.CURRENT_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.SECURITY.CURRENT' | translate" type="password" class="fx-flex-100"
        [(value)]="current" [error]="errors.current"></app-input>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.SECURITY.NEW' | translate}}</span>
      <span class="text light">{{'SETTINGS.SECURITY.NEW_HINT' | translate}}</span>
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex mt-10">
        <div class="fx-row align-items-center justify-content-start fx-flex-100"
          *ngFor="let attr of _passwordStrength()">
          <mat-icon [class]="attr.valid ? 'green mr-5' : 'red mr-5'" fontSet="material-icons-outlined" inset
            inline>{{attr.valid ?
            'done' : 'close'}}</mat-icon>
          <span [class]="attr.valid ? 'small green mr-5' : 'small red mr-5'">{{'RESET_PASSWORD.'+attr.label |
            translate}}</span>
        </div>
      </div>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.SECURITY.NEW' | translate" type="password" class="fx-flex-100" [(value)]="new"
        [error]="errors.new"></app-input>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.SECURITY.CONFIRMATION' | translate}}</span>
      <span class="text light">{{'SETTINGS.SECURITY.CONFIRMATION_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.SECURITY.CONFIRMATION' | translate" type="password" class="fx-flex-100"
        [(value)]="confirmation" [error]="errors.confirmation"></app-input>
    </div>
  </div>
</div>