import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoComponent } from './pages/demo/demo.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

// Angular Material
import { CdkMenuModule } from '@angular/cdk/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatRadioModule } from '@angular/material/radio'
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';

// Custom components
import { AvatarComponent } from './components/avatar/avatar.component';
import { InputComponent } from './components/input/input.component';
import { LoginComponent } from './pages/login/login.component';
import { NavComponent } from './pages/nav/nav.component';
import { HomeComponent } from './pages/home/home.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { DocumentComponent } from './pages/documents/document/document.component'
import { DocumentsListComponent } from './pages/documents/documents-list/documents-list.component';
import { CasesComponent } from './pages/cases/cases.component';
import { CasesListComponent } from './pages/cases/cases-list/cases-list.component';
import { CaseComponent } from './pages/cases/case/case.component';
import { TrademarksComponent } from './pages/trademarks/trademarks.component';
import { TrademarksListComponent } from './pages/trademarks/trademarks-list/trademarks-list.component';
import { TrademarkComponent } from './pages/trademarks/trademark/trademark.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';

// Externals
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { SelectComponent } from './components/select/select.component';
import { ToastrModule } from 'ngx-toastr';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { OtpDialogComponent } from './dialogs/otp-dialog/otp-dialog.component';
import { DocumentTimelineComponent } from './components/document-timeline/document-timeline.component';
import { DocumentMapComponent } from './components/document-map/document-map.component';
import { TrademarkDeclareComponent } from './pages/trademarks/trademark-declare/trademark-declare.component';
import { CaseDeclareComponent } from './pages/cases/case-declare/case-declare.component';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { NiceSelectComponent } from './components/nice-select/nice-select.component';
import { UploadPictureComponent } from './components/upload-picture/upload-picture.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { ToastComponent } from './components/toast/toast.component';
import { TrademarkContentComponent } from './components/trademark-content/trademark-content.component';
import { DocumentsTableComponent } from './components/documents-display/documents-table/documents-table.component';
import { DocumentsReportComponent } from './components/documents-display/documents-report/documents-report.component';
import { DocumentCountriesComponent } from './components/document-countries/document-countries.component';
import { PaginatorIntlService } from './services/paginator-intl.service';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { TrademarkSelectComponent } from './components/trademark-select/trademark-select.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OrganizationComponent } from './pages/organization/organization/organization.component';
import { OrganizationInformationComponent } from './pages/organization/organization-information/organization-information.component';
import { OrganizationIamComponent } from './pages/organization/organization-iam/organization-iam.component';
import { TrademarkDeclareSingleComponent } from './pages/trademarks/trademark-declare/trademark-declare-single/trademark-declare-single.component';
import { TrademarkDeclareImportComponent } from './pages/trademarks/trademark-declare/trademark-declare-import/trademark-declare-import.component';
import { TrademarkNiceClassificationComponent } from './components/trademark-nice-classification/trademark-nice-classification.component';
import { TrademarkCountryComponent } from './components/trademark-country/trademark-country.component';
import { TrademarksImportOverviewListDialogComponent } from './dialogs/trademarks-import-overview-list-dialog/trademarks-import-overview-list-dialog.component';
import { UploadDocumentsDialogComponent } from './dialogs/upload-documents-dialog/upload-documents-dialog.component';
import { UploadPanelComponent } from './components/upload-panel/upload-panel.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { BatchesComponent } from './pages/documents/batches/batches.component';
import { BatchesListComponent } from './pages/documents/batches/batches-list/batches-list.component';
import { BatchComponent } from './pages/documents/batches/batch/batch.component';
import { BatchSCoreComponent } from './components/batch-score/batch-score.component';
import { BatchDocumentsComponent } from './components/batch-documents/batch-documents.component';
import { EmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { GraphDocumentsNiceComponent } from './components/documents-display/documents-report/graph-documents-nice/graph-documents-nice.component';
import { GraphDocumentsTrademarkComponent } from './components/documents-display/documents-report/graph-documents-trademark/graph-documents-trademark.component';
import { GraphDocumentsClassComponent } from './components/documents-display/documents-report/graph-documents-class/graph-documents-class.component';
import { GraphDocumentsStatusComponent } from './components/documents-display/documents-report/graph-documents-status/graph-documents-status.component';
import { GraphDocumentsTimelineComponent } from './components/documents-display/documents-report/graph-documents-timeline/graph-documents-timeline.component';
import { GraphDocumentsMapComponent } from './components/documents-display/documents-report/graph-documents-map/graph-documents-map.component';
import { GraphDocumentsTypeComponent } from './components/documents-display/documents-report/graph-documents-type/graph-documents-type.component';
import { UnauthenticatedComponent } from './pages/unauthenticated/unauthenticated.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { DocumentsHierarchyComponent } from './components/documents-display/documents-hierarchy/documents-hierarchy.component';
import { DaterangepickerComponent } from './components/daterangepicker/daterangepicker.component';
import { MetadataConfidenceComponent } from './components/metadata-confidence/metadata-confidence.component';
import { PeriodSelectComponent } from './components/period-select/period-select.component';
import { DocumentTrademarksComponent } from './components/document-trademarks/document-trademarks.component';
import { DocumentNiceClassesComponent } from './components/document-nice-classes/document-nice-classes.component';
import { DocumentsCaseComponent } from './components/documents-display/documents-case/documents-case.component';
import { PropertyOfficeSelectComponent } from './components/property-office-select/property-office-select.component';
import { TerritorySelectComponent } from './components/territory-select/territory-select.component';
import { CaseStatusComponent } from './components/case-status/case-status.component';
import { TrademarkStatusComponent } from './components/trademark-status/trademark-status.component';
import { CaseContentComponent } from './components/case-content/case-content.component';
import { AddCaseDocumentsDialogComponent } from './dialogs/add-case-documents-dialog/add-case-documents-dialog.component';
import { TrademarkCoverageComponent } from './components/trademark-coverage/trademark-coverage.component';
import { DocumentCaseScoreComponent } from './components/document-case-score/document-case-score.component';
import { CaseScoreComponent } from './components/case-score/case-score.component';
import { AnimatedNumberComponent } from './components/animated-number/animated-number.component';
import { CaseTimelineComponent } from './components/case-timeline/case-timeline.component';
import { CounterCardComponent } from './components/dashboard-widgets/counter-card/counter-card.component';
import { DocumentsGraphComponent } from './components/dashboard-widgets/documents-graph/documents-graph.component';
import { CaseCardComponent } from './components/case-card/case-card.component';
import { BatchProgressComponent } from './components/batch-progress/batch-progress.component';
import { SettingsInformationComponent } from './pages/settings/settings-information/settings-information.component';
import { SettingsSecurityComponent } from './pages/settings/settings-security/settings-security.component';
import { SignupComponent } from './pages/signup/signup.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { InviteUserDialogComponent } from './dialogs/invite-user-dialog/invite-user-dialog.component';
import { EditDocumentMetadataComponent } from './components/edit-document-metadata/edit-document-metadata.component';
import { EditDocumentClassDialogComponent } from './dialogs/edit-document-class-dialog/edit-document-class-dialog.component';
import { EditDocumentLangDialogComponent } from './dialogs/edit-document-lang-dialog/edit-document-lang-dialog.component';
import { DocumentClassComponent } from './components/document-class/document-class.component';
import { DocumentLangsComponent } from './components/document-langs/document-langs.component';
import { FilterRolePipe } from './pipes/filter-role.pipe';
import { RoleBasedElementDirective } from './directives/role-based-element.directive';
import { Error403Component } from './pages/errors/error403/error403.component';
import { Error404Component } from './pages/errors/error404/error404.component';
import { AclBasedElementDirective } from './directives/acl-based-element.directive';
import { CaseInformationComponent } from './components/case-structure/case-information/case-information.component';
import { CaseProofsComponent } from './components/case-structure/case-proofs/case-proofs.component';
import { CaseAttachmentsComponent } from './components/case-structure/case-attachments/case-attachments.component';
import { CaseEvidenceRecordsComponent } from './components/case-structure/case-evidence-records/case-evidence-records.component';
import { CaseNotesComponent } from './components/case-structure/case-notes/case-notes.component';
import { CaseUsersComponent } from './components/case-structure/case-users/case-users.component';
import { UploadCaseAttachmentDialogComponent } from './dialogs/upload-case-attachment-dialog/upload-case-attachment-dialog.component';
import { CaseCoverageComponent } from './components/case-structure/case-coverage/case-coverage.component';
import { DashboardCountryOperatorComponent } from './components/dashboards/dashboard-country-operator/dashboard-country-operator.component';
import { DashboardCaseOperatorComponent } from './components/dashboards/dashboard-case-operator/dashboard-case-operator.component';
import { DashboardComponent } from './components/dashboards/dashboard/dashboard.component';
import { CountryOperatorConstraintDialogComponent } from './dialogs/country-operator-constraint-dialog/country-operator-constraint-dialog.component';
import { CaseOperatorConstraintDialogComponent } from './dialogs/case-operator-constraint-dialog/case-operator-constraint-dialog.component';
import { CaseSelectComponent } from './components/case-select/case-select.component';
import { InviteCaseOperatorDialogComponent } from './dialogs/invite-case-operator-dialog/invite-case-operator-dialog.component';
import { UploadDocumentsBoxComponent } from './components/upload-documents-box/upload-documents-box.component';
import { UploadingDocumentsBoxComponent } from './components/uploading-documents-box/uploading-documents-box.component';
import { MetadataMissingComponent } from './components/metadata-missing/metadata-missing.component';
import { DocumentClassSelectComponent } from './components/document-class-select/document-class-select.component';
import { BackComponent } from './components/back/back.component';
import { CaseTimelineActionDialogComponent } from './dialogs/case-timeline-action-dialog/case-timeline-action-dialog.component';
import { CasePhaseComponent } from './components/case-phase/case-phase.component';
import { CasesChartComponent } from './components/dashboard-widgets/cases-chart/cases-chart.component';
import { TrademarksPieComponent } from './components/dashboard-widgets/trademarks-pie/trademarks-pie.component';
import { LoadingContainerComponent } from './components/loading-container/loading-container.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { IntegrationsListComponent } from './pages/integrations/integrations-list/integrations-list.component';
import { IntegrationComponent } from './pages/integrations/integration/integration.component';
import { IntegrationDialogComponent } from './dialogs/integration-dialog/integration-dialog.component';
import { ShortUrlPipe } from './pipes/short-url.pipe';
import { SupportComponent } from './pages/support/support.component';
import { FilterPipe } from './pipes/filter.pipe';
import { TrademarkExternalCreateDialogComponent } from './dialogs/trademark-external-create-dialog/trademark-external-create-dialog.component';
import { TrademarkObligationComponent } from './components/trademark-obligation/trademark-obligation.component';
import { DocumentUsersComponent } from './components/document-users/document-users.component';
import { NonUseAttachmentDialogComponent } from './dialogs/non-use-attachment-dialog/non-use-attachment-dialog.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DemoComponent,
    AvatarComponent,
    InputComponent,
    LoginComponent,
    NavComponent,
    HomeComponent,
    DocumentsComponent,
    DocumentsListComponent,
    DocumentComponent,
    CasesComponent,
    CasesListComponent,
    CaseComponent,
    TrademarksComponent,
    TrademarksListComponent,
    TrademarkComponent,
    ButtonToggleComponent,
    SelectComponent,
    ProgressBarComponent,
    CheckboxComponent,
    OtpDialogComponent,
    OtpInputComponent,
    DocumentTimelineComponent,
    DocumentMapComponent,
    TrademarkDeclareComponent,
    CaseDeclareComponent,
    NiceSelectComponent,
    UploadPictureComponent,
    DatepickerComponent,
    ToastComponent,
    TrademarkContentComponent,
    DocumentsTableComponent,
    DocumentsReportComponent,
    DocumentCountriesComponent,
    ConfirmationDialogComponent,
    TrademarkSelectComponent,
    OrganizationComponent,
    OrganizationInformationComponent,
    OrganizationIamComponent,
    TrademarkDeclareSingleComponent,
    TrademarkDeclareImportComponent,
    TrademarkNiceClassificationComponent,
    TrademarkCountryComponent,
    TrademarksImportOverviewListDialogComponent,
    UploadDocumentsDialogComponent,
    UploadPanelComponent,
    ProgressSpinnerComponent,
    BatchesComponent,
    BatchesListComponent,
    BatchComponent,
    BatchSCoreComponent,
    BatchDocumentsComponent,
    EmptyPlaceholderComponent,
    SettingsComponent,
    GraphDocumentsNiceComponent,
    GraphDocumentsTrademarkComponent,
    GraphDocumentsClassComponent,
    GraphDocumentsStatusComponent,
    GraphDocumentsTimelineComponent,
    GraphDocumentsMapComponent,
    GraphDocumentsTypeComponent,
    UnauthenticatedComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DocumentsHierarchyComponent,
    DaterangepickerComponent,
    MetadataConfidenceComponent,
    PeriodSelectComponent,
    DocumentTrademarksComponent,
    DocumentNiceClassesComponent,
    DocumentsCaseComponent,
    PropertyOfficeSelectComponent,
    TerritorySelectComponent,
    CaseStatusComponent,
    TrademarkStatusComponent,
    CaseContentComponent,
    AddCaseDocumentsDialogComponent,
    TrademarkCoverageComponent,
    DocumentCaseScoreComponent,
    CaseScoreComponent,
    AnimatedNumberComponent,
    CaseTimelineComponent,
    CounterCardComponent,
    DocumentsGraphComponent,
    CaseCardComponent,
    BatchProgressComponent,
    SettingsInformationComponent,
    SettingsSecurityComponent,
    SignupComponent,
    UserProfileComponent,
    InviteUserDialogComponent,
    EditDocumentMetadataComponent,
    EditDocumentClassDialogComponent,
    EditDocumentLangDialogComponent,
    DocumentClassComponent,
    DocumentLangsComponent,
    FilterRolePipe,
    RoleBasedElementDirective,
    Error403Component,
    Error404Component,
    AclBasedElementDirective,
    CaseInformationComponent,
    CaseProofsComponent,
    CaseAttachmentsComponent,
    CaseEvidenceRecordsComponent,
    CaseNotesComponent,
    CaseUsersComponent,
    UploadCaseAttachmentDialogComponent,
    CaseCoverageComponent,
    DashboardCountryOperatorComponent,
    DashboardCaseOperatorComponent,
    DashboardComponent,
    CountryOperatorConstraintDialogComponent,
    CaseOperatorConstraintDialogComponent,
    CaseSelectComponent,
    InviteCaseOperatorDialogComponent,
    UploadDocumentsBoxComponent,
    UploadingDocumentsBoxComponent,
    MetadataMissingComponent,
    DocumentClassSelectComponent,
    BackComponent,
    CaseTimelineActionDialogComponent,
    CasePhaseComponent,
    CasesChartComponent,
    TrademarksPieComponent,
    LoadingContainerComponent,
    IntegrationsComponent,
    IntegrationsListComponent,
    IntegrationComponent,
    IntegrationDialogComponent,
    ShortUrlPipe,
    SupportComponent,
    FilterPipe,
    TrademarkExternalCreateDialogComponent,
    TrademarkObligationComponent,
    DocumentUsersComponent,
    NonUseAttachmentDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatDividerModule,
    MatTableModule,
    MatSortModule,
    MatCheckboxModule,
    MatButtonModule,
    MatExpansionModule,
    MatMenuModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTabsModule,
    MatTooltipModule,
    CdkMenuModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      toastComponent: ToastComponent,
      preventDuplicates: true
    }),
    PdfViewerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatRadioModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
