import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-trademarks-import-overview-list-dialog',
  templateUrl: './trademarks-import-overview-list-dialog.component.html',
  styleUrls: ['./trademarks-import-overview-list-dialog.component.scss']
})
export class TrademarksImportOverviewListDialogComponent {
  title: string = '';
  text: string = '';
  trademarks: { line: number, identifierNumber?: string, country?: string }[] = [];
  displayedColumns: string[] = ['line', 'identifier', 'country'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TrademarksImportOverviewListDialogComponent>
  ) {

  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.text = this.data.text;
    this.trademarks = this.data.trademarks;
  }

  onCancel() {
    this.dialogRef.close()
  }
}
