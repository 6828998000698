import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Case } from 'src/app/models/case';
import { EvidenceRecord } from 'src/app/models/evidenceRecord';
import { Paginator } from 'src/app/models/paginator';
import { CaseService } from 'src/app/services/case.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-case-evidence-records',
  templateUrl: './case-evidence-records.component.html',
  styleUrls: ['./case-evidence-records.component.scss']
})
export class CaseEvidenceRecordsComponent {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  evidenceRecords: Paginator<EvidenceRecord> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  displayedColumns: string[] = ['id', 'status', 'documents', 'creator', 'createdAt', 'actions'];

  @Input() case?: Case;

  name?: string;

  noEvidenceRecordButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'EVIDENCE_RECORDS.GENERATE',
      icon: 'folder_special',
      class: 'main-button',
      action: () => { this.generate() }
    }


  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {

  }

  async init() {
    this.loading = true;
    this.done = false;
    await this.retrieveEvidenceRecords(true);
    this.loading = false;
    this.done = true;
  }

  async retrieveEvidenceRecords(init: boolean) {
    try {
      if (this.case && this.case._id) {

        this._message.emitChange("LOADING", "START");
        this.evidenceRecords = { ...this.evidenceRecords, ...await this.caseService.retrieveEvidenceRecords(this.case._id, this.evidenceRecords.page, this.evidenceRecords.limit, this.evidenceRecords.offset, this.evidenceRecords.sort || '-createdAt') };

        if (init && this.evidenceRecords.totalDocs === 0) {
          this.noDocs = true;
        } else if (init && this.evidenceRecords.totalDocs > 0) {
          this.noDocs = false;
        } else {
          this.noDocs = false;
        }

        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  resetPage() {
    this.evidenceRecords.limit = 10;
    this.evidenceRecords.page = 1;
    this.evidenceRecords.offset = 0;
  }

  async handlePage(event: any) {
    this.evidenceRecords.limit = event.pageSize;
    this.evidenceRecords.page = event.pageIndex + 1;
    this.evidenceRecords.offset = event.pageIndex * this.evidenceRecords.limit;
    this.retrieveEvidenceRecords(false);
  }

  async generate() {
    try {
      if (this.case && this.case._id) {
        this._message.emitChange("LOADING", "START");
        await this.caseService.generateEvidenceRecord(this.case?._id);
        this.toastr.success('EVIDENCE_RECORDS.GENERATION_SUCCESS_MESSAGE', 'EVIDENCE_RECORDS.GENERATION_SUCCESS_TITLE');
        this.resetPage();
        await this.retrieveEvidenceRecords(false);
        this._message.emitChange("LOADING", "END");
      }

    } catch (err: any) {
      this._message.emitChange("LOADING", "END");
      if (err && err.error && err.error.message && this.utils.hasTranslation(`ERRORS.${err.error.message}`)) {
        this.toastr.error(`ERRORS.${err.error.message}`)
      } else {
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  async download(evidenceRecord: EvidenceRecord) {
    let toast;
    try {
      if (this.case && this.case._id && evidenceRecord._id) {
        const base_name = `${this.case.identifier || this.case.reference}_evidence_record_${evidenceRecord.name}`;
        toast = this.toastr.info(this.translate.instant('EVIDENCE_RECORDS.DOWNLOAD_MESSAGE', evidenceRecord), "EVIDENCE_RECORDS.DOWNLOAD_TITLE", {
          progressBar: true,
          disableTimeOut: true
        })
        const blob = await this.caseService.downloadEvidenceRecord(this.case._id, evidenceRecord._id);

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = `${base_name}.zip`;
        a.click();
        this.toastr.clear(toast.toastId);
        URL.revokeObjectURL(objectUrl);
      }
    } catch (err) {
      if (toast) {
        this.toastr.clear(toast.toastId);
      }
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async delete(evidenceRecord: EvidenceRecord) {
    try {
      if (this.case && this.case._id) {
        this._message.emitChange("LOADING", "START");
        await this.caseService.deleteEvidenceRecord(this.case?._id, evidenceRecord._id!);
        this.toastr.success('EVIDENCE_RECORDS.DELETE_SUCCESS_MESSAGE');
        this.resetPage();
        await this.retrieveEvidenceRecords(false);
        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  formatDate(date: string) {
    return moment(date).format('LLL')
  }
}
