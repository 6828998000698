<div class="mb-8 fx-row align-items-center justify-content-between">
  <span class="text light mb-8 fx-flex-100">{{'DOCUMENT.USERS' | translate}}</span>
  <mat-icon [class.shown]="shown" class="visibility-icon" fontSet="material-icons-outlined">{{shown ? 'visibility' :
    'visibility_off'}}</mat-icon>
</div>
<div class="fx-row fx-wrap mb-10 w-100 align-items-center justify-content-between mt-8"
  *ngFor="let user of users.slice(0,limit)">
  <div class="fx-column fx-flex ellipsis-container">
    <span class="fx-flex-100 ellipsis text">{{user}}</span>
  </div>
  <app-metadata-confidence *ngIf="user" class="ml-20" [value]="getConfidence(user) || 0"></app-metadata-confidence>
</div>
<span class="text" *ngIf="!users || users.length === 0">-</span>
<div class="fx-row w-100 align-items-center justify-content-end mt-10 clickable" *ngIf="users.length > 3">
  <span class="text main" (click)="toggle()">{{(limit === 3 ? 'UTILS.SEE_MORE' : 'UTILS.SEE_LESS') |
    translate}}</span>
  <mat-icon class="main ml-5" fontSet="material-icons-outlined">{{limit === 3 ? 'keyboard_arrow_down' :
    'keyboard_arrow_up'}}</mat-icon>
</div>