<div class="fx-column">
  <span class="label" *ngIf="label">{{label}}</span>
  <div class="fx-row justify-content-start align-items-center input-wrapper"
    [class]="{'focus':focus,'error':error.value}">
    <mat-icon class="prefix light" [class.focus]="focus" *ngIf="prefix"
      fontSet="material-icons-outlined">{{prefix}}</mat-icon>
    <input [type]="type === 'password' ? (shown ? 'text' : 'password') : type" [placeholder]="placeholder"
      [(ngModel)]="value" (ngModelChange)="valueUpdate.next($event)" (focus)="focus = true" (blur)="focus = false"
      [disabled]="disabled" #input />
    <mat-icon class="suffix light" [class.focus]="focus" *ngIf="suffix && type !== 'password'"
      fontSet="material-icons-outlined">{{suffix}}</mat-icon>
    <mat-icon class="suffix dark show-icon clickable"
      [style.visibility]="type === 'password' && value && value.length > 0 ? 'visible' : 'hidden'"
      fontSet="material-icons-outlined" (click)="shown = !shown">{{shown ? 'visibility_off' : 'visibility'}}</mat-icon>
  </div>
  <div class="fx-row justify-content-start align-items-center bottom-wrapper"
    *ngIf="bottomWrapper || (hint || (error.value && error.message))">
    <span class="small light" *ngIf="hint && !(error.value && error.message)">
      {{hint}}
    </span>
    <span class="small red" *ngIf="error.value && error.message">
      {{error.message}}
    </span>
  </div>
</div>