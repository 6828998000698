import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Batch, BatchDocumentStatus, BatchPopulatedDocument } from 'src/app/models/batch';
import { BatchService } from 'src/app/services/batch.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.scss']
})
export class BatchComponent {

  from: string = "BATCHES";
  loading: boolean = false;
  batch?: Batch;
  batchDocuments: BatchPopulatedDocument[] = [];
  score?: {
    ok: number,
    warning: number,
    error: number,
    total: number
  };

  documentStatuses: { value: string, label: string, selected: boolean }[] = [
    {
      value: BatchDocumentStatus.OK,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.OK}`,
      selected: false
    },
    {
      value: BatchDocumentStatus.ERROR,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.ERROR}`,
      selected: false
    },
    {
      value: BatchDocumentStatus.NO_TRADEMARK,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.NO_TRADEMARK}`,
      selected: false
    },
    {
      value: BatchDocumentStatus.NO_NICE_CLASSIFICATION,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.NO_NICE_CLASSIFICATION}`,
      selected: false
    },
    {
      value: BatchDocumentStatus.NO_TERRITORY,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.NO_TERRITORY}`,
      selected: false
    },
    {
      value: BatchDocumentStatus.NO_DATE,
      label: `BATCH.DOCUMENT_${BatchDocumentStatus.NO_DATE}`,
      selected: false
    }
  ];
  statuses: BatchDocumentStatus[] = [];

  private readonly statusesSubject = new Subject<BatchDocumentStatus[]>();
  private statusesSubscription?: Subscription;

  selection = new SelectionModel<BatchPopulatedDocument>(true, []);

  constructor(
    private _message: MessageService,
    private batchService: BatchService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.from = window.history.state.from || "BATCHES";
    this.statusesSubscription = this.statusesSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        await this.getBatchDocuments();
      });
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.retrieveBatch(params['id']);
  }

  async retrieveBatch(id: string) {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      this.batch = await this.batchService.retrieve(id);
      this.computeScore();
      await this.getBatchDocuments();
      this.loading = false;
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async getBatchDocuments() {
    try {
      if (this.batch && this.batch._id) {
        this._message.emitChange("LOADING", "START");
        this.loading = true;
        this.batchDocuments = await this.batchService.retrieveDocuments(this.batch._id, this.statuses);
        this.batchService.pager.totalDocs = this.batchDocuments.length;

        this.loading = false;
        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async refresh() {
    if (this.batch && this.batch._id) {
      await this.retrieveBatch(this.batch?._id)
    }
  }

  async reanalyze() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'BATCH.REANALYZE_TITLE',
        text: this.translate.instant('BATCH.REANALYZE_TEXT', this.batch),
        button: {
          text: 'ACTIONS.REANALYZE',
          class: 'main-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.batch && this.batch._id) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.batchService.analyze(this.batch._id);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('BATCH.REANALYZE_SUCCESS', this.batch));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async deleteDocuments() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'BATCH.DELETE_DOCUMENTS_TITLE',
        text: this.translate.instant(this.selection.selected.length === this.batch?.documents ? 'BATCH.DELETE_DOCUMENTS_AND_BATCH_TEXT' : 'BATCH.DELETE_DOCUMENTS_TEXT', { length: this.selection.selected.length }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.batch && this.batch._id) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.documentService.deleteMany(this.selection.selected.map((doc) => doc._id));
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant(this.selection.selected.length === this.batch?.documents ? 'BATCH.BATCH_AND_DOCUMENTS_DELETED' : 'BATCH.DOCUMENTS_DELETED', { length: this.selection.selected.length }));

        if (this.selection.selected.length === this.batch?.documents) {
          this.location.back();
        } else {
          await this.getBatchDocuments();
        }
        this.selection.clear();

      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  onStatusChange(event: any) {
    if (event && event.value) {
      this.statuses = event.value;
      this.statusesSubject.next(this.statuses);
    }
  }


  computeScore() {
    if (this.batch && this.batch.report) {
      this.score = {
        total: this.batch?.documents,
        ...this.batch.report
      };
    } else {
      this.score = {
        total: 1,
        ok: 0,
        warning: 0,
        error: 0
      };
    }
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  toTrademark(trademark: any) {
    this.router.navigate(['trademarks', trademark.ref], {
      state: {
        from: "BATCH"
      }
    })
  }

}
