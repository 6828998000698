import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, debounceTime, filter, firstValueFrom, of, switchMap, take, tap, throwError } from 'rxjs';
import { AuthenticationService, TokenDecoded } from '../services/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import jwt_decode from "jwt-decode";
import * as moment from 'moment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private refreshing = false;
  private extending = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );


  private readonly extendSubject = new Subject<void>();
  private extendSubscription?: Subscription;

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {

    this.extendSubscription = this.extendSubject
      .pipe(
        debounceTime(2000)
      ).subscribe(async () => {
        if (this.authService.isLoggedIn())
          await this.extendAccessToken();
      });

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.getAccessToken() && !request.headers.has('sca')) {
      request = this.addAccessToken(request, this.authService.getAccessToken());
    }
    return next.handle(request).pipe(
      tap(async () => {
        if (!request.url.includes("auth/refreshToken")) {
          this.extendSubject.next();
        }
        await this.extendAccessTokenIfNeeded();
      }),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !request.headers.has('sca') && !["auth/login", "auth/refreshToken", "auth/logout", "auth/invitation"].some(path => request.url.includes(path))) {
          return this.handle401Error(request, next);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addAccessToken(request: HttpRequest<any>, token: string | null) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private async extendAccessTokenIfNeeded() {
    if (!this.extending) {

      const isAboutToExpire = this.authService.isAccessTokenAboutToExpire();
      if (isAboutToExpire) {
        this.extending = true;
        await firstValueFrom(this.authService.refreshToken());
        this.extending = false;
      }
    }
  }

  private async extendAccessToken() {
    if (!this.extending) {
      this.extending = true;
      await firstValueFrom(this.authService.refreshToken());
      this.extending = false;
    }
  }



  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.refreshing) {
      this.refreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((token) => {
          this.refreshing = false;
          this.refreshTokenSubject.next(token);
          return next.handle(this.addAccessToken(request, token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.addAccessToken(request, token));
        })
      );
    }
  }
}
