import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

  title: string = '';
  text: string = '';
  button: {
    text: string,
    class: string
  } = {
      text: '',
      class: 'main-button'
    };
  confirmation?: {
    text: string,
    value?: any
  } = undefined;

  value: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, text: string, button: { text: string, class: string }, confirmation?: { text: string, value: any } },
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {
    this.title = this.data.title;
    this.text = this.data.text;
    this.button = this.data.button;
    this.confirmation = this.data.confirmation;
  }

  isDisabled() {
    return this.confirmation && this.confirmation.value !== this.value;
  }

  onConfirm() {
    this.dialogRef.close({
      confirmed: true
    })
  }

  onCancel() {
    this.dialogRef.close({
      confirmed: false
    })
  }
}
