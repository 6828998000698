<div class="mb-8 fx-row align-items-center justify-content-between">
  <span class="text light">{{'DOCUMENT.NICE_CLASSIFICATION' | translate}}</span>
  <mat-icon [class.shown]="shown" class="visibility-icon" fontSet="material-icons-outlined">{{shown ? 'visibility' :
    'visibility_off'}}</mat-icon>
</div>
<div class="fx-row w-100 align-items-center justify-content-between mb-10"
  *ngFor="let niceClass of niceClasses.slice(0,limit)">
  <div class="chip main" [matTooltipClass]="{'uzai-tooltip':true}"
    [matTooltip]="'NICE_CLASSES.'+niceClass.value | translate">
    <span><b>{{'NICE_CLASSES.CLASS_NUMBER' |
        translate}}{{niceClass.value}}</b></span>
  </div>
  <app-metadata-confidence class="ml-20" [value]="niceClass.confidence"></app-metadata-confidence>
</div>
<span class="text" *ngIf="!niceClasses || niceClasses?.length === 0">-</span>
<div class="fx-row w-100 align-items-center justify-content-end mt-10 clickable" *ngIf="niceClasses.length > 3">
  <span class="text main " (click)="toggle()">{{(limit === 3 ? 'UTILS.SEE_MORE' : 'UTILS.SEE_LESS') |
    translate}}</span>
  <mat-icon class="main ml-5" fontSet="material-icons-outlined">{{limit === 3 ? 'keyboard_arrow_down' :
    'keyboard_arrow_up'}}</mat-icon>
</div>