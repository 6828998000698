import { AfterViewInit, Component, Input } from '@angular/core';

const EUROPE = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE"
];


@Component({
  selector: 'app-document-map',
  templateUrl: './document-map.component.html',
  styleUrls: ['./document-map.component.scss']
})
export class DocumentMapComponent implements AfterViewInit {
  @Input("territories") territories: string[] = [];
  @Input("height") height: number = 300;
  @Input("tooltip") tooltip: boolean = false;

  private chart?: any;

  constructor() {

  }

  ngAfterViewInit() {
    Promise.all([
      import(/* webpackChunkName: "am4core" */ '@amcharts/amcharts4/core'),
      import(/* webpackChunkName: "am4maps" */ '@amcharts/amcharts4/maps'),
      import(/* webpackChunkName: "am4geodata_worldLow" */ '@amcharts/amcharts4-geodata/worldHigh'),
      import(/* webpackChunkName: "am4themes_animated" */ '@amcharts/amcharts4/themes/animated'),
    ]
    ).then((modules) => {
      const am4core = modules[0];
      const am4maps = modules[1];
      const am4geodata_worldLow = modules[2];
      const am4themes_animated = modules[3];
      am4core.useTheme(am4themes_animated.default);

      let chart = am4core.create("chartmapdiv", am4maps.MapChart);
      chart.marginTop = 0;
      chart.marginBottom = 0;
      chart.marginLeft = 0;
      chart.marginRight = 0;
      chart.responsive.enabled = true;
      chart.logo.setVisibility(false);
      // Set map definition
      chart.geodata = am4geodata_worldLow.default;

      // Set projection
      chart.projection = new am4maps.projections.Miller();

      // Create map polygon series
      var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Exclude Antartica
      polygonSeries.exclude = ["AQ"];

      // Make map load polygon (like country names) data from GeoJSON
      polygonSeries.useGeodata = true;

      // Configure series
      var polygonTemplate = polygonSeries.mapPolygons.template;
      if (this.tooltip)
        polygonTemplate.tooltipText = "{name}";
      polygonTemplate.fill = am4core.color("#e7e5e9");

      // Create hover state and set alternative fill color
      let as = polygonTemplate.states.create("active");
      as.properties.fill = am4core.color("#8847e3");

      const self = this;
      chart.events.on("ready", function (ev) {
        var north: number | undefined, south: number | undefined, west: number | undefined, east: number | undefined;
        if (self.territories && self.territories.length > 0) {
          for (let i = 0; i < self.territories.length; i++) {
            if (!["EU", "WO"].includes(self.territories[i])) {
              var country = polygonSeries.getPolygonById(self.territories[i]);
              if (country) {
                if (north == undefined || (country.north > north)) {
                  north = country.north;
                }
                if (south == undefined || (country.south < south)) {
                  south = country.south;
                }
                if (west == undefined || (country.west < west)) {
                  west = country.west;
                }
                if (east == undefined || (country.east > east)) {
                  east = country.east;
                }
                country.isActive = true;
              }

            } else if (self.territories[i] === "EU") {
              EUROPE.forEach(code => {
                var country = polygonSeries.getPolygonById(code);
                if (country) {
                  if (north == undefined || (country.north > north)) {
                    north = country.north;
                  }
                  if (south == undefined || (country.south < south)) {
                    south = country.south;
                  }
                  if (west == undefined || (country.west < west)) {
                    west = country.west;
                  }
                  if (east == undefined || (country.east > east)) {
                    east = country.east;
                  }
                  country.isActive = true;
                }
              })
            }
            chart.zoomToRectangle(north as number, east as number, south as number, west as number, 1, true);
          }
        }
      });
      this.chart = chart;
    })

  }
}
