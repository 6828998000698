<div class="fx-row align-items-center justify-content-between">
  <span class="text light">{{'DOCUMENT.LANGS' | translate}}</span>
</div>
<div class="fx-row fx-wrap mb-10 w-100 align-items-center justify-content-between mt-8" *ngFor="let lang of langs">
  <span class="text">{{lang ? ('LANGUAGES.'+lang | translate) : '-'}}</span>
  <app-metadata-confidence *ngIf="lang" class="ml-20" [value]="confidences[lang]"></app-metadata-confidence>
</div>
<div class="fx-row fx-wrap mb-10 w-100 align-items-center justify-content-between mt-8"
  *ngIf="!langs || langs.length == 0">
  <span class="text">-</span>
</div>