<mat-card *ngIf="!loading && done && !noDocs">
  <table mat-table [dataSource]="evidenceRecords.docs" style="z-index: 0;">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'EVIDENCE_RECORDS.ID' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <span class="text main bold">{{evidenceRecord.name}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'EVIDENCE_RECORDS.STATUS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <div class="fx-row align-items-center justify-content-start" [matTooltipClass]="{'uzai-tooltip':true}"
          [matTooltip]="'EVIDENCE_RECORDS.STATUS_'+evidenceRecord.status+'_TOOLTIP' | translate">
          <mat-icon class="green" fontSet="material-icons-outlined"
            *ngIf="evidenceRecord.status === 'DONE'">check_circle</mat-icon>
          <mat-icon class="main spin" fontSet="material-icons-outlined"
            *ngIf="evidenceRecord.status === 'PROCESSING'">sync</mat-icon>
          <mat-icon class="red" fontSet="material-icons-outlined"
            *ngIf="evidenceRecord.status === 'ERROR'">error_outline</mat-icon>
          <mat-icon class="light" fontSet="material-icons-outlined"
            *ngIf="evidenceRecord.status === 'CREATED'">pending</mat-icon>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="documents">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'EVIDENCE_RECORDS.DOCUMENTS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <span class="text">{{evidenceRecord.documents.length}} {{((evidenceRecord.documents.length > 1 ?
          'ENTITIES.DOCUMENTS' : 'ENTITIES.DOCUMENT' )| translate).toLowerCase()}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="creator">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'EVIDENCE_RECORDS.CREATOR' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <div class="fx-row justify-content-start align-items-center fx-flex-100">
          <app-avatar [user]="evidenceRecord.createdBy" [size]="25"></app-avatar>
          <span class="small ml-10">{{evidenceRecord.createdBy.firstName}} {{evidenceRecord.createdBy.lastName}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        <span class="small light bold">
          {{'EVIDENCE_RECORDS.GENERATED_AT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <span class="text">{{formatDate(evidenceRecord.createdAt)}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'ACTIONS.ACTIONS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let evidenceRecord">
        <button mat-icon-button [cdkMenuTriggerFor]="menu">
          <mat-icon class="dark mr-5" fontSet="material-icons-outlined">more_vert</mat-icon>
        </button>
        <ng-template #menu>
          <mat-card class="menu-container p-0 elevation" cdkMenu>
            <div class="fx-column">
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="evidenceRecord.status !== 'DONE' ? null : download(evidenceRecord)" cdkMenuItem
                [class.disabled]="evidenceRecord.status !== 'DONE'">
                <mat-icon class="dark mr-5" [class.light]="evidenceRecord.status !== 'DONE'"
                  fontSet="material-icons-outlined">cloud_download</mat-icon>
                <span class="text" [class.light]="evidenceRecord.status !== 'DONE'">{{'ACTIONS.DOWNLOAD' |
                  translate}}</span>
              </div>
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="delete(evidenceRecord)" cdkMenuItem>
                <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
                <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
              </div>
            </div>
          </mat-card>
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <div class="p-16" *ngIf="evidenceRecords.totalDocs === 0">
    <span class="text light">{{'EVIDENCE_RECORDS.SEARCH_NO_EVIDENCE_RECORDS' | translate}}</span>
  </div>
  <mat-paginator [length]="evidenceRecords.totalDocs || 0" [pageSize]="evidenceRecords.limit || 0"
    [pageIndex]="evidenceRecords && evidenceRecords.page ? (evidenceRecords.page-1) : 0"
    [pageSizeOptions]="[10, 25, 50, 100]" (page)="handlePage($event)">
  </mat-paginator>
</mat-card>
<mat-card *ngIf="!loading && done && noDocs" class="placeholder">
  <div class="fx-flex-100 fx-row align-items-center justify-content-start">
    <div class="watermark-container">
      <img src="/assets/img/icon_lighter.svg" class="watermark" />
    </div>
    <div class="fx-row fx-wrap instructions-container">
      <span class="title dark fx-flex-100">{{'EVIDENCE_RECORDS.NO_EVIDENCE_RECORD' | translate}}</span>
      <span class="text dark fx-flex-100">{{'EVIDENCE_RECORDS.NO_EVIDENCE_RECORD_MESSAGE' |
        translate}}</span>
      <div class="fx-row fx-flex-100 mt-10">
        <button class="main-button" (click)="generate()">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">folder_special</mat-icon>
            {{ 'EVIDENCE_RECORDS.GENERATE' | translate}}
          </div>
        </button>
      </div>
    </div>
  </div>
</mat-card>