import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss']
})
export class EmptyPlaceholderComponent implements OnInit {

  @Input() offset: number = 80;
  @Input() title: string = '';
  @Input() message: string = '';
  @Input() button?: {
    label: string;
    icon: string;
    class: string;
    action: Function
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  height() {
    return window.innerHeight;
  }
}
