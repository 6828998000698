<div class="panel fx-column">
  <div class="fx-row align-items-center justify-content-start">
    <img src="/assets/img/logo.svg" height="60">
  </div>
  <div class="fx-column fx-flex-100 align-items-center justify-content-center">
    <div class="fx-row fx-wrap justify-content-center align-items-center fx-flex-50" style="max-width:50% !important;"
      *ngIf="nonce">
      <div class="fx-column fx-flex-100 align-items-center justify-content-center">
        <span class="title main bold center">{{'SIGNUP.TITLE' | translate}}</span>
        <span class="text center">{{'SIGNUP.INSTRUCTIONS' | translate}}</span>
      </div>
      <div class="fx-column mv-30 fx-flex-100">
        <app-input [placeholder]="('SIGNUP.PASSWORD' | translate) + '...'" type="password" prefix="lock"
          [(value)]="password" [error]="errors.password" [bottomWrapper]="true"></app-input>
        <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex mb-20">
          <div class="fx-row align-items-center justify-content-start fx-flex-100"
            *ngFor="let attr of _passwordStrength()">
            <mat-icon [class]="attr.valid ? 'green mr-5' : 'red mr-5'" fontSet="material-icons-outlined" inset
              inline>{{attr.valid ?
              'done' : 'close'}}</mat-icon>
            <span [class]="attr.valid ? 'small green mr-5' : 'small red mr-5'">{{'SIGNUP.'+attr.label |
              translate}}</span>
          </div>
        </div>
        <app-input [placeholder]="('SIGNUP.CONFIRMATION' | translate) + '...'" type="password" prefix="lock"
          [(value)]="confirmation" [error]="errors.confirmation" [bottomWrapper]="true"></app-input>
        <span class="small light mt-10">{{'SIGNUP.EDIT_INFO_LATER' | translate}}</span>
        <div class="fx-row align-items-center justify-content-center mt-10 fx-flex">
          <button class="main-button fx-flex" (click)="confirmInvitation()">
            {{'ACTIONS.SIGNUP' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="fx-row fx-wrap justify-content-center align-items-center" *ngIf="!nonce"
      style="max-width:50% !important;">
      <div class="error-icon-container ">
        <mat-icon class="red" fontSet="material-icons-outlined">error_outline</mat-icon>
      </div>
      <div class="mt-10 fx-column fx-flex-100 ">
        <span class="text text-center">{{"SIGNUP.INVALID_INVITATION" | translate}}</span>
      </div>
      <span class="text mt-20 center fx-flex-100 ">
        {{"SIGNUP.ASK_FOR_RESEND" | translate}}
      </span>
      <app-input [placeholder]="('SIGNUP.EMAIL' | translate) + '...'" type="text" prefix="mail"
        class="mb-10 mt-20 fx-flex-100 " [(value)]="email" [error]="errors.email" [bottomWrapper]="true"></app-input>
      <div class="fx-row align-items-center justify-content-center fx-flex-100">
        <button class="main-button fx-flex" [class.disabled]="resent" (click)="resendInvitation()" [disabled]="resent">
          {{'SIGNUP.RESEND_INVITATION' | translate}}
        </button>
      </div>
      <div class="fx-column mt-10 align-items-center justify-content-center fx-flex-100 " (click)="toLogin()">
        <a>{{'SIGNUP.BACK_TO_LOGIN' | translate}}</a>
      </div>
    </div>
  </div>
  <div class="fx-column align-items-center justify-content-center">
    <img src="/assets/icons/icon.svg" height="50" class="mb-10">
    <a class="main">uzai.tech</a>
  </div>
</div>