import { ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-unauthenticated',
  templateUrl: './unauthenticated.component.html',
  styleUrls: ['./unauthenticated.component.scss']
})
export class UnauthenticatedComponent {

  loading: boolean = false;
  subscription: Subscription;

  constructor(
    private _cd: ChangeDetectorRef,
    private _message: MessageService
  ) {
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LOADING.START") {
        this.loading = true;
        this._cd.detectChanges();
      }
      if (change && change.key == "LOADING.END") {
        this.loading = false;
        this._cd.detectChanges();
      }
    });
  }
}
