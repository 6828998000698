<div class="fx-row fx-wrap align-items-center justify-content-start  fx-flex-100">
  <div class="fx-row fx-wrap align-items-start justify-content-between fx-flex-100" *ngIf="user">
    <div class="fx-column align-items-start justify-content-start">
      <span class="title bold">{{'SETTINGS.INFORMATION.TAB' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.HINT' | translate}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button class="button" (click)="cancel()">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" (click)="update()" [disabled]="isUpdateDisabled()"
        [class.disabled]="isUpdateDisabled()">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.ROLE' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.ROLE_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-center justify-content-start">
      <span class="text ml-10  main" *ngIf="user.role && user.role.name">{{'USER_ROLES.'+user.role.name.replace("
        ","_").toUpperCase() |
        translate}}</span>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.EMAIL' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.EMAIL_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.INFORMATION.EMAIL' | translate" type="text" class="fx-flex-100"
        [(value)]="user.email" [disabled]="true"></app-input>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.FIRSTNAME' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.FIRSTNAME_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.INFORMATION.FIRSTNAME' | translate" type="text" class="fx-flex-100"
        [(value)]="user.firstName"></app-input>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.LASTNAME' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.LASTNAME_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'SETTINGS.INFORMATION.LASTNAME' | translate" type="text" class="fx-flex-100"
        [(value)]="user.lastName"></app-input>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.AVATAR' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.AVATAR_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-upload-picture [src]="user.avatar" [height]="150" class="fx-flex"
        (pictureChange)="onAvatarChange($event)"></app-upload-picture>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="user">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'SETTINGS.INFORMATION.LANGUAGE' | translate}}</span>
      <span class="text light">{{'SETTINGS.INFORMATION.LANGUAGE_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-select initial="none" label="{{'SETTINGS.INFORMATION.LANGUAGE' | translate}}" [options]="languages"
        [multiple]="false" class="fx-flex" [(value)]="user.lang" [emitOnInit]="false"></app-select>
    </div>
  </div>
</div>