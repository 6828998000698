<div class="panel fx-column">
  <div class="fx-row align-items-center justify-content-start">
    <img src="/assets/img/logo.svg" height="60">
  </div>
  <div class="fx-row fx-wrap fx-flex-100 align-items-center justify-content-center">
    <div class="fx-row fx-wrap justify-content-center align-items-center fx-flex-50">
      <div class="fx-column fx-flex-100 align-items-center justify-content-center">
        <span class="title main bold center">{{'FORGOT_PASSWORD.TITLE' | translate}}</span>
        <span class="text center">{{'FORGOT_PASSWORD.INSTRUCTIONS' | translate}}</span>
      </div>
      <div class="fx-column mv-30 fx-flex-100">
        <app-input [placeholder]="('LOGIN.EMAIL' | translate) + '...'" type="text" prefix="mail" class="mb-10"
          [(value)]="email" [error]="errors.email" [bottomWrapper]="true"></app-input>
        <div class="fx-row align-items-center justify-content-center mt-10 fx-flex">
          <button class="main-button fx-flex" (click)="send()">
            {{'ACTIONS.SEND_EMAIL' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="fx-column align-items-center justify-content-center">
    <img src="/assets/icons/icon.svg" height="50" class="mb-10">
    <a class="main">uzai.tech</a>
  </div>
</div>