import { Component } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss']
})
export class CasesComponent {
  constructor(
    private _message: MessageService
  ) {

  }

  ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: "cases"
    });
  }
}
