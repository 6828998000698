<div class="p-40">
  <div class="fx-column">
    <div class="fx-row align-items-center justify-content-between">
      <div class="fx-column">
        <span class="page-title">{{'CASE.ADD_PROOFS_TITLE' | translate}}</span>
        <span class="page-subtitle">{{'CASE.ADD_PROOFS_SUBTITLE' | translate}}</span>
      </div>
      <div class="fx-row align-items-center justify-content-end ml-20">
        <button class="button ml-10" (click)="onCancel()" [disabled]="loading" [class.disabled]="loading">
          {{'ACTIONS.CANCEL' | translate}}
        </button>
        <button class="main-button ml-10" (click)="link()" [class.disabled]="isDisabled()" [disabled]="isDisabled()">
          {{'CASE.LINK_PROOFS' | translate}}
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="mv-20"></mat-divider>
  <mat-drawer-container class="example-container">
    <mat-drawer mode="over" #drawer class="p-16" autoFocus="false">
      <div class="fx-column">
        <span class="text bold">{{'DOCUMENTS_LIST.FILTERS.LABEL' | translate}}</span>
        <span class="text">{{'DOCUMENTS_LIST.FILTERS.HINT' | translate}}</span>
        <app-document-class-select label="{{'ENTITIES.DOCUMENT_TYPES' | translate}}" [multiple]="true" [search]="true"
          [emitOnInit]="false" class="mt-20 fx-flex" (change)="onDocumentClassChange($event)"
          [(value)]="filters.documentClass" [noAutomatic]="true"></app-document-class-select>
        <app-trademark-family-select label="{{'ENTITIES.TRADEMARK_FAMILIES' | translate}}" [multiple]="true"
          [search]="true" class="mt-10 fx-flex" (change)="onTrademarkFamilyChange($event)"
          [(value)]="filters.trademarkFamily" [emitOnInit]="false"></app-trademark-family-select>
        <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" (change)="onTrademarkChange($event)" [(value)]="filters.trademark"
          [emitOnInit]="false"></app-trademark-select>
        <app-nice-select label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" [(value)]="filters.classNumber" (change)="onNiceClassificationChange($event)"
          [emitOnInit]="false"></app-nice-select>
        <app-territory-select label="{{'ENTITIES.TERRITORIES' | translate}}" [multiple]="true" [search]="true"
          class="mt-10 fx-flex" [(value)]="filters.territories" (change)="onTerritoryChange($event)"
          [emitOnInit]="false"></app-territory-select>
        <app-period-select label="{{'ENTITIES.PERIOD' | translate}}" class="mt-10 fx-flex" [range]="true"
          (change)="onPeriodChange($event)" [(valueRange)]="filters.period"></app-period-select>
        <button class="mt-20" [class.main-button]="filtersCount() > 0" [class.button]="filtersCount() === 0"
          (click)="drawer.close()">
          {{(filtersCount() > 0 ? 'ACTIONS.SEE_RESULTS' : 'ACTIONS.CLOSE') | translate}}
        </button>
      </div>
    </mat-drawer>
    <mat-drawer-content class="p-16">
      <div class="fx-row fw-wrap align-items-center justify-content-between fx-flex-100 mb-16">
        <div class="fx-row fw-wrap align-items-center justify-content-start">
          <button class="button" (click)="drawer.toggle()">
            <div class="fx-row align-items-center justify-content-start">
              {{'DOCUMENTS_LIST.FILTERS.LABEL' | translate}}
              <div class="badge ml-5 white" *ngIf="filtersCount() > 0">
                {{filtersCount()}}
              </div>
            </div>
          </button>
          <div class="ml-10 chip main">
            {{this.selection.selected.length}} {{((this.selection.selected.length > 1 ?
            'CASE_DECLARE.SELECTED_DOCUMENTS' :
            'CASE_DECLARE.SELECTED_DOCUMENT') | translate).toLowerCase()}}
          </div>
        </div>
        <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search"
          [(value)]="filters.query" (change)="onQueryChange($event)" class="ml-10"></app-input>
      </div>
      <app-documents-table [filteredDocuments]="filteredDocuments" (sort)="onSortChange($event)"
        (page)="handlePage($event)" [select]="true" [clickable]="false" [restrictions]="restrictions"
        [(selection)]="selection"></app-documents-table>
    </mat-drawer-content>
  </mat-drawer-container>
  <!-- <div>
    <div class="fx-row fw-wrap align-items-center justify-content-between fx-flex-100">
    </div>
    <div class="fx-row align-items-center justify-content-start mt-20">
      <div class="chip main">
        {{this.selection.selected.length}} {{((this.selection.selected.length > 1 ? 'CASE_DECLARE.SELECTED_DOCUMENTS' :
        'CASE_DECLARE.SELECTED_DOCUMENT') | translate).toLowerCase()}}
      </div>
    </div>
  </div> -->
</div>