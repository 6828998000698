import { Component, DoCheck, EventEmitter, Input, IterableDiffer, IterableDiffers, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { EditDocumentClassDialogComponent } from 'src/app/dialogs/edit-document-class-dialog/edit-document-class-dialog.component';
import { EditDocumentLangDialogComponent } from 'src/app/dialogs/edit-document-lang-dialog/edit-document-lang-dialog.component';
import { Document } from 'src/app/models/document';
import { DocumentClass } from 'src/app/models/documentClass';
import { DocumentEvidence } from 'src/app/models/documentEvidence';
import { DocumentMetadata, DocumentMetadataAndEvidence, MetadataType } from 'src/app/models/documentMetadata';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

interface Metadata {
  type: MetadataType;
  title: string;
  hint: string;
  placeholder: string;
  multiple: boolean;
  entries: any[]
}


@Component({
  selector: 'app-edit-document-metadata',
  templateUrl: './edit-document-metadata.component.html',
  styleUrls: ['./edit-document-metadata.component.scss']
})
export class EditDocumentMetadataComponent implements OnInit, OnChanges {
  @Input() document?: Document;

  @Input() evidences: DocumentEvidence[] = []

  @Input() metadata: DocumentMetadata[] = []

  @Output() add = new EventEmitter<DocumentMetadataAndEvidence[]>();
  @Output() update = new EventEmitter<DocumentMetadataAndEvidence>();
  @Output() delete = new EventEmitter<DocumentMetadata>();
  @Output() close = new EventEmitter<any>();
  @Output() toggleDraw = new EventEmitter<any>();
  @Output() confirmAnnotation = new EventEmitter<{ type: MetadataType, metadata: any }[]>();
  @Output() cancelAnnotation = new EventEmitter<any>();
  @Output() mouseenter = new EventEmitter<{
    type: MetadataType,
    value: any
  }>();
  @Output() mouseleave = new EventEmitter<{
    type: MetadataType,
    value: any
  }>();

  limits: { [type: string]: number } = {
    'TRADEMARK': 3,
    'LOCATION': 3,
    'LANG': 3,
    'CLASS': 1,
    'NICE_CLASS': 3,
    'DATE': 3,
    'USER': 3
  };


  metadataEvidences: Metadata[] = [
    {
      type: MetadataType.CLASS,
      title: 'DOCUMENT.CLASS',
      hint: 'DOCUMENT.CLASS_HINT',
      placeholder: 'DOCUMENT.NO_CLASS',
      multiple: false,
      entries: []
    },
    {
      type: MetadataType.LANG,
      title: 'DOCUMENT.LANGS',
      hint: 'DOCUMENT.LANGS_HINT',
      placeholder: 'DOCUMENT.NO_LANG',
      multiple: true,
      entries: []
    },
    {
      type: MetadataType.TRADEMARK,
      title: 'ENTITIES.TRADEMARKS',
      hint: 'DOCUMENT.TRADEMARKS_HINT',
      placeholder: 'DOCUMENT.NO_TRADEMARKS',
      multiple: true,
      entries: []
    },
    {
      type: MetadataType.NICE_CLASS,
      title: 'DOCUMENT.NICE_CLASSIFICATION',
      hint: 'DOCUMENT.NICE_CLASSIFICATION_HINT',
      placeholder: 'DOCUMENT.NO_NICE_CLASSIFICATION',
      multiple: true,
      entries: []
    },
    {
      type: MetadataType.LOCATION,
      title: 'DOCUMENT.TERRITORIES',
      hint: 'DOCUMENT.TERRITORIES_HINT',
      placeholder: 'DOCUMENT.NO_TERRITORIES',
      multiple: true,
      entries: []
    },
    {
      type: MetadataType.DATE,
      title: 'DOCUMENT.PERIOD',
      hint: 'DOCUMENT.PERIOD_HINT',
      placeholder: 'DOCUMENT.NO_PERIOD',
      multiple: true,
      entries: []
    },
    {
      type: MetadataType.USER,
      title: 'DOCUMENT.USERS',
      hint: 'DOCUMENT.USERS_HINT',
      placeholder: 'DOCUMENT.NO_USERS',
      multiple: true,
      entries: []
    }
  ]

  public metadataType = MetadataType;

  @Input('isDraw') isDraw: boolean = false;
  @Input('drawing') drawing?: {
    x: number,
    y: number,
    width: number,
    height: number
  };
  periodPattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}_[0-9]{4}-[0-9]{2}-[0-9]{2}$/
  evidenceType: {
    type?: MetadataType,
    metadata?: any,
    period?: {
      start: moment.Moment,
      end: moment.Moment
    }
  } = {
      type: undefined,
      metadata: undefined
    };
  evidenceTypes: {
    type: MetadataType,
    metadata: any,
    period?: {
      start: moment.Moment,
      end: moment.Moment
    }
  }[] = []
  evidenceTypesOptions: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "TRADEMARK",
        label: "DOCUMENT.EVIDENCE_TYPE_TRADEMARK",
        selected: false
      },
      {
        value: "LOCATION",
        label: "DOCUMENT.EVIDENCE_TYPE_LOCATION",
        selected: false
      },
      {
        value: "DATE",
        label: "DOCUMENT.EVIDENCE_TYPE_DATE",
        selected: false
      },
      {
        value: "PERIOD",
        label: "DOCUMENT.EVIDENCE_TYPE_PERIOD",
        selected: false
      },
      {
        value: "NICE_CLASS",
        label: "DOCUMENT.EVIDENCE_TYPE_NICE_CLASS",
        selected: false
      },
      {
        value: "USER",
        label: "DOCUMENT.EVIDENCE_TYPE_USER",
        selected: false
      }
    ];

  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    moment.locale(this.translate.currentLang);
    this.populateMetadata();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.populateMetadata();
  }

  populateMetadata() {
    this.metadataEvidences.forEach(metadatum => {
      metadatum.entries = this.metadata.filter(datum => (![MetadataType.DATE, MetadataType.PERIOD].includes(datum.type) && datum.type === metadatum.type) || ([MetadataType.DATE, MetadataType.PERIOD].includes(datum.type) && metadatum.type === MetadataType.DATE)).sort((a, b) => {
        if (metadatum.type === MetadataType.TRADEMARK) {
          return a.value.split('_')[1].localeCompare(b.value.split('_')[1]);
        } else if (metadatum.type === MetadataType.NICE_CLASS) {
          return parseInt(a.value) - parseInt(b.value);
        }
        return a.value.localeCompare(b.value);
      })
    })
  }


  humanFriendlyValue(type: MetadataType, entity: any) {
    switch (type) {
      case MetadataType.LANG:
        return this.translate.instant(`LANGUAGES.${entity.value}`)
      case MetadataType.LOCATION:
        return this.translate.instant(`TERRITORIES.${entity.value.toUpperCase()}`)
      case MetadataType.CLASS:
        return `${this.translate.instant(`DOCUMENT_CLASSES.${entity.value}`)}`;
      case MetadataType.TRADEMARK:
        return `${entity.value.split('_')[1]}`;
      case MetadataType.NICE_CLASS:
        return `${entity.value} - ${this.translate.instant(`NICE_CLASSES.${entity.value}`)}`;
      case MetadataType.DATE:
        moment.locale(this.translate.currentLang)
        return moment(entity.value).format("ll");
      case MetadataType.PERIOD:
        moment.locale(this.translate.currentLang)
        return `${moment(entity.value.split('_')[0]).format("ll")}_${moment(entity.value.split('_')[1]).format("ll")}`;
      case MetadataType.USER:
        return entity.value.split('_').join(' ');
    }
  }

  async removeMetadata(type: MetadataType, entity: DocumentMetadata) {

    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: `DOCUMENT.DELETE_METADATA_TITLE`,
        text: this.translate.instant(`DOCUMENT.DELETE_METADATA_TEXT_${type.toUpperCase()}`, { entity: this.humanFriendlyValue(type, entity) }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed) {
      this.delete.emit(entity);
    }
  }

  async editDocumentClass() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        document: this.document
      }
    }
    const dialog = this.dialog.open(EditDocumentClassDialogComponent, config);
    const result: { edited: boolean, class?: DocumentClass } = await firstValueFrom(dialog.afterClosed());
    if (result && result.edited && result.class && this.document) {
      const metadata = this.metadata.find(datum => datum.type === MetadataType.CLASS);
      if (metadata) {
        const metadataAndEvidence: DocumentMetadataAndEvidence = {
          _id: metadata?._id,
          type: metadata.type,
          value: DocumentClass[result.class]
        }
        this.update.emit(metadataAndEvidence)
      }
    }
  }

  async addDocumentLang() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        document: this.document
      }
    }
    const dialog = this.dialog.open(EditDocumentLangDialogComponent, config);
    const result: { edited: boolean, lang?: string } = await firstValueFrom(dialog.afterClosed());
    if (result && result.edited && result.lang && this.document) {
      const metadataAndEvidence: DocumentMetadataAndEvidence = {
        type: MetadataType.LANG,
        value: result.lang
      }
      this.add.emit([metadataAndEvidence])
    }
  }

  async editDocumentLang(lang: string) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        document: this.document,
        lang
      }
    }
    const dialog = this.dialog.open(EditDocumentLangDialogComponent, config);
    const result: { edited: boolean, lang?: string } = await firstValueFrom(dialog.afterClosed());
    if (result && result.edited && result.lang && this.document) {
      const metadata = this.metadata.find(datum => datum.type === MetadataType.LANG);
      if (metadata) {
        const metadataAndEvidence: DocumentMetadataAndEvidence = {
          _id: metadata?._id,
          type: metadata.type,
          value: result.lang
        }
        this.update.emit(metadataAndEvidence)
      }
    }
  }

  formatDate(date: string) {
    return moment(date).format('ll');
  }
  formatDateOrPeriod(entry: DocumentMetadata) {
    if (entry.type === MetadataType.PERIOD) {
      return `${this.formatDate(entry.value.split("_")[0])} - ${this.formatDate(entry.value.split("_")[1])}`
    }
    return moment(entry.value).format('ll');
  }
  formatDatetime(date: string) {
    return moment(date).format('lll');
  }

  onEvidenceTypePeriodChange(event: any) {
    if (!this.evidenceType.period) {
      this.evidenceType.period = {
        start: event.start,
        end: event.end
      }
    } else {
      this.evidenceType.period.start = event.start;
      this.evidenceType.period.end = event.end;
    }
    this.evidenceType.metadata = `${this.evidenceType.period.start ? this.evidenceType.period.start.format('YYYY-MM-DD') : ''}_${this.evidenceType.period.end ? this.evidenceType.period.end.format('YYYY-MM-DD') : ''}`
  }

  onEvidenceTypeTrademarksChange(event: any) {
    this.evidenceTypes = event.value.map((value: any) => ({
      type: MetadataType.TRADEMARK,
      metadata: value
    }))
    console.log(this.evidenceTypes)
  }

  onMouseEnter(type: MetadataType, value: any) {
    this.mouseenter.emit({ type, value });
  }
  onMouseLeave(type: MetadataType, value: any) {
    this.mouseleave.emit({ type, value });
  }


  onClose() {
    this.close.emit();
  }

  onToggleDraw() {
    this.isDraw = !this.isDraw;
    this.toggleDraw.emit(this.isDraw)
  }

  isAnnotationDisabled() {
    return (this.evidenceType.type && ![MetadataType.TRADEMARK, MetadataType.PERIOD].includes(this.evidenceType.type) && !this.evidenceType.metadata)
      || (this.evidenceType.type === 'TRADEMARK' && this.evidenceTypes.some(evidenceType => !evidenceType.metadata._id))
      || (this.evidenceType.type === 'PERIOD' && (!this.evidenceType.period || !this.evidenceType.period?.start || !this.evidenceType.period.end || !this.periodPattern.test(this.evidenceType.metadata)));
  }

  async onConfirmAnnotation() {
    if (this.isDraw && this.drawing && this.evidenceType.type) {
      if (this.evidenceType.type === MetadataType.TRADEMARK) {
        this.confirmAnnotation.emit(this.evidenceTypes.map(evidenceType => ({
          type: evidenceType.type,
          metadata: evidenceType.metadata
        })));
      } else {
        this.confirmAnnotation.emit([{
          type: this.evidenceType.type,
          metadata: this.evidenceType.metadata
        }]);
      }

      this.onCancelAnnotation();
    }
  }

  onCancelAnnotation() {
    this.evidenceType = {
      type: undefined,
      metadata: undefined
    };
    this.evidenceTypes = [];
    this.evidenceTypesOptions.forEach((option) => option.selected = false);
    this.cancelAnnotation.emit();
  }
}
