<div class="fx-row fx-wrap align-items-stretch justify-content-start border-bottom mt-8 mb-8  fx-flex-100">
  <div class="fx-flex-100 border-box pb-16">
    <mat-card>
      <div class="fx-row fw-wrap align-items-center justify-content-between">
        <div class="fx-column">
          <div class="fx-row fw-wrap align-items-baseline justify-content-start">
            <app-animated-number [value]="count" [class]="'text bold main'"></app-animated-number>
            &nbsp;
            <span class="text bold"><span class="bold main">{{((count > 1 ? 'ENTITIES.PROOFS' :
                'ENTITIES.PROOF') |
                translate).toLowerCase()}}</span> {{('TRADEMARK.PROOFS_NUMBER_ON_PLATFORM' |
              translate).toLowerCase()}}</span>
          </div>
          <span class="text">Coverage report is displayed below</span>
        </div>
        <button class="main-button ml-10" (click)="toTrademarkDocuments()">
          {{'TRADEMARK.VIEW_PROOFS' | translate}}
        </button>
      </div>
    </mat-card>
  </div>
  <div class="fx-flex-50 border-box pr-8">
    <mat-card class="p-0 h-100 fx-column">
      <div class="p-16">
        <span class="text bold">{{'TRADEMARK.COVERAGE_CLASS' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <app-graph-documents-class [data]="documentClasses.data" [loading]="documentClasses.loading"
          [error]="documentClasses.error" [noData]="documentClasses.started && documentClasses.data.length === 0"
          [title]="false" [height]="200"
          *ngIf="!documentClasses.loading && !documentClasses.error && documentClasses.data.length > 0"></app-graph-documents-class>
      </div>
    </mat-card>
  </div>
  <div class="fx-flex-50 border-box pl-8">
    <mat-card class="p-0 h-100">
      <div class="p-16">
        <span class="text bold">{{'TRADEMARK.COVERAGE_NICE' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <app-graph-documents-nice [data]="documentNice.data" [loading]="documentNice.loading"
          [error]="documentNice.error" [noData]="documentNice.started && documentNice.data.length === 0" [title]="false"
          [height]="200"
          *ngIf="!documentNice.loading && !documentNice.error && documentNice.data.length > 0"></app-graph-documents-nice>
      </div>
    </mat-card>
  </div>
</div>
<div class="fx-row fx-wrap align-items-stretch justify-content-start border-bottom mt-16 mb-16 fx-flex-100">
  <div class="fx-flex-50 border-box pr-8">
    <mat-card class="p-0 h-100">
      <div class="p-16">
        <span class="text bold">{{'TRADEMARK.COVERAGE_GEO' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <app-graph-documents-map [data]="documentTerritories.data" [loading]="documentTerritories.loading"
          [error]="documentTerritories.started && documentTerritories.error"
          [noData]="documentTerritories.data.length === 0" [title]="false" [height]="300"
          *ngIf="!documentTerritories.loading && !documentTerritories.error && documentTerritories.data.length > 0"
          [count]="count"></app-graph-documents-map>
      </div>
    </mat-card>
  </div>
  <div class="fx-flex-50 border-box pl-8">
    <mat-card class="p-0 h-100 fx-column">
      <div class="p-16">
        <span class="text bold">{{'TRADEMARK.COVERAGE_TIME' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <app-graph-documents-timeline [data]="documentPeriods.data" [loading]="documentPeriods.loading"
          [error]="documentPeriods.started && documentPeriods.error" [noData]="documentPeriods.data.length === 0"
          [title]="false" [height]="300"
          *ngIf="!documentPeriods.loading && !documentPeriods.error && documentPeriods.data.length > 0"></app-graph-documents-timeline>
      </div>
    </mat-card>
  </div>
</div>