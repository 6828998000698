import { MapPolygonSeries } from '@amcharts/amcharts4/maps';
import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'lodash';
const EUROPE = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE"
];
@Component({
  selector: 'app-trademarks-territories',
  templateUrl: './trademarks-territories.component.html',
  styleUrls: ['./trademarks-territories.component.scss']
})
export class TrademarksTerritoriesComponent {
  @Input() data: {
    _id: string,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 400;
  @Input() count: number = 0;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('graphterritorydiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: any;

  top5: {
    id: string,
    self: number,
    percent: number
  }[] = []

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }



  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      setTimeout(() => {
        this.makeChart();
      }, 100)
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  _territoryMapper() {
    const results: { id: string, value: number, self: number, parent?: { name: string, value?: number } }[] = [];
    this.data.forEach(datum => {
      if (["EU", "EM"].includes(datum._id)) {
        const found = results.find(r => r.id === "EU");
        if (found) {
          found.value += datum.count;
          found.self += datum.count;
        } else {
          results.push({ id: "EU", value: datum.count, self: datum.count })
        }
      } else if (!["AFR", "ASI", "WO"].includes(datum._id)) {
        results.push({ id: datum._id, value: datum.count, self: datum.count })
      }
    })
    const europe = results.find(r => r.id === "EU");
    EUROPE.forEach(country => {
      const found = results.find(r => r.id === country);
      if (found) {
        found.value = found.self + (europe?.value || 0)
        found.parent = {
          name: this.translate.instant('TERRITORIES.EU'),
          value: europe?.value
        }
      } else {
        results.push({
          id: country,
          self: 0,
          value: europe?.value || 0,
          parent: {
            name: this.translate.instant('TERRITORIES.EU'),
            value: europe?.value
          }
        })
      }
    })
    return results;
  }



  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {


        Promise.all([
          import(/* webpackChunkName: "am4core" */ '@amcharts/amcharts4/core'),
          import(/* webpackChunkName: "am4maps" */ '@amcharts/amcharts4/maps'),
          import(/* webpackChunkName: "am4geodata_worldLow" */ '@amcharts/amcharts4-geodata/worldHigh'),
        ]
        ).then((modules) => {
          const am4core = modules[0];
          const am4maps = modules[1];
          const am4geodata_worldLow = modules[2];


          let chart = am4core.create("graphterritorydiv", am4maps.MapChart);
          chart.logo.setVisibility(false);
          // Set map definition
          chart.geodata = am4geodata_worldLow.default;
          chart.preloader!.hiddenState.transitionDuration = 0;

          // Set projection
          chart.projection = new am4maps.projections.Miller();

          // Create map polygon series
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

          // Exclude Antartica
          polygonSeries.exclude = ["AQ"];

          // Make map load polygon (like country names) data from GeoJSON
          polygonSeries.useGeodata = true;

          const filteredData = this._territoryMapper();


          polygonSeries.data = filteredData;
          this.top5 = filteredData.sort((a, b) => b.self - a.self).slice(0, 5).map((item) => ({ ...item, percent: Math.round(item.self * 100 / this.count) }));
          this.changeDetector.detectChanges();

          polygonSeries.heatRules.push({
            property: "fill",
            target: polygonSeries.mapPolygons.template,
            min: am4core.color("#f3ecfc"),
            max: am4core.color("#8847E3"),
          });

          // Configure series
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.fill = am4core.color("#f5f5f7");

          // Create hover state and set alternative fill color
          let as = polygonTemplate.states.create("active");
          as.properties.fill = am4core.color("#8847e3");

          let heatLegend = chart.createChild(am4maps.HeatLegend);
          heatLegend.series = polygonSeries;
          heatLegend.align = "right";
          heatLegend.valign = "bottom";
          heatLegend.width = am4core.percent(20);
          heatLegend.marginRight = am4core.percent(4);
          heatLegend.minValue = Math.min(...filteredData.map(d => d.value));
          heatLegend.maxValue = Math.max(...filteredData.map(d => d.value));
          heatLegend.valueAxis.renderer.labels.template.fontSize = 10;
          heatLegend.valueAxis.renderer.labels.template.fill = am4core.color("#747479");
          heatLegend.valueAxis.renderer.labels.template.fontWeight = 'bold';
          heatLegend.valueAxis.renderer.labels.template.fontFamily = 'Source Sans 3, sans-serif';

          // Set up custom heat map legend labels using axis ranges
          var minRange = heatLegend.valueAxis.axisRanges.create();
          minRange.value = heatLegend.minValue;
          minRange.label.text = Math.min(...filteredData.map(d => d.value)).toString();
          var maxRange = heatLegend.valueAxis.axisRanges.create();
          maxRange.value = heatLegend.maxValue;
          maxRange.label.text = Math.max(...filteredData.map(d => d.value)).toString();

          // Blank out internal heat legend value axis labels
          heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function (labelText) {
            return "";
          });

          polygonSeries.mapPolygons.template.adapter.add("tooltipText", function (text, target) {
            const dataContext = (target.dataItem.dataContext as any);
            if (!dataContext.value || dataContext.value === 0) {
              return "";
            }

            if (dataContext.parent && dataContext.value === 0) {
              return `${dataContext.parent.name}: ${dataContext.parent.value} ${self.translate.instant('ENTITIES.TRADEMARKS').toLowerCase()}`
            } else if (dataContext.parent && dataContext.value > 0) {
              return `${self.translate.instant(`TERRITORIES.${dataContext.id}`)}: ${dataContext.self} ${self.translate.instant('ENTITIES.TRADEMARKS').toLowerCase()}\n${dataContext.parent.name}: ${dataContext.parent.value} ${self.translate.instant('ENTITIES.TRADEMARKS').toLowerCase()}`
            } else {
              return `${self.translate.instant(`TERRITORIES.${dataContext.id}`)}: ${dataContext.self} ${self.translate.instant('ENTITIES.TRADEMARKS').toLowerCase()}`;
            }

          });

          // Configure series tooltip
          var polygonTemplate = polygonSeries.mapPolygons.template;
          polygonTemplate.tooltipText = "{name}: {value}";
          polygonTemplate.nonScalingStroke = true;
          polygonTemplate.strokeWidth = 0.5;

          this.chart = chart;
          this.chart.events.on('ready', () => {
            this.ready = true;
            console.log("chart is ready")
            this.zoomToCountries(filteredData, polygonSeries);
          });
        })
      }, 100);

    })
  }
  zoomToCountries(data: {
    id: string,
    value: number
  }[], series: MapPolygonSeries) {
    var north, south, west, east;
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].value > 0) {
          var country = series.getPolygonById(data[i].id);
          if (north == undefined || (country.north > north)) {
            north = country.north;
          }
          if (south == undefined || (country.south < south)) {
            south = country.south;
          }
          if (west == undefined || (country.west < west)) {
            west = country.west;
          }
          if (east == undefined || (country.east > east)) {
            east = country.east;
          }
        }
      }
      this.chart.zoomToRectangle(north as number, east as number, south as number, west as number, 1, true);
    }
  }
}
