<div class="p-20 fx-row fx-wrap align-items-center justify-content-center text-center">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'INVITE_CASE_OPERATOR_DIALOG.TITLE' | translate}}</span>
  </div>
  <span class="text mt-20">{{'INVITE_CASE_OPERATOR_DIALOG.INVITE_INSTRUCTIONS' | translate}}</span>
  <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center mt-20">
    <app-input [placeholder]="('USER.FIRSTNAME' | translate) + '...'" type="text" class="mb-10 fx-flex-100"
      [(value)]="userToInvite.firstName" [error]="errors['firstName']" [bottomWrapper]="true"
      [disabled]="existing !== undefined"></app-input>
    <app-input [placeholder]="('USER.LASTNAME' | translate) + '...'" type="text" class="mb-10 fx-flex-100"
      [(value)]="userToInvite.lastName" [error]="errors['lastName']" [bottomWrapper]="true"
      [disabled]="existing !== undefined"></app-input>
    <app-input [placeholder]="('USER.EMAIL' | translate) + '...'" type="text" class="fx-flex-100"
      [(value)]="userToInvite.email" [error]="errors['email']" [bottomWrapper]="true"
      [disabled]="existing !== undefined"></app-input>
  </div>
  <div *ngIf="userOptions && userOptions.length > 0"
    class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center">
    <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center mv-10">
      <mat-divider class="fx-flex"></mat-divider>
      <span class="text bold mh-10 text-center">{{'UTILS.OR' | translate}}</span>
      <mat-divider class="fx-flex"></mat-divider>
    </div>
    <span class="text mt-20">{{'INVITE_CASE_OPERATOR_DIALOG.EXISTING_INSTRUCTIONS' | translate}}</span>
    <app-select label="{{'ENTITIES.USER' | translate}}" [options]="userOptions" [multiple]="false"
      (change)="onExistingUserChange($event)" [emitOnInit]="false" class="fx-flex-100 mt-20"
      [disabled]="isExistingDisabled()"></app-select>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onInvite()" [class.disabled]="isDisabled()"
      [disabled]="isDisabled()">{{'ACTIONS.INVITE'
      | translate}}</button>
  </div>
</div>