<div class="error-container fx-column align-items-center justify-content-center">
  <mat-card class="p-40">
    <div class="fx-column align-items-center justify-content-center">
      <div class="logo-container">
        <img src="/assets/icons/icon.svg" />
      </div>
      <span class="huge main bold mt-20">{{'ERRORS.403_TITLE' | translate}}</span>
      <span class="title">{{'ERRORS.403_TEXT' | translate}}</span>
      <button class="main-button mt-10" routerLink="/">
        {{'ERRORS.BACK_TO_HOME' | translate}}
      </button>
    </div>
  </mat-card>
</div>