import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-document-case-score',
  templateUrl: './document-case-score.component.html',
  styleUrls: ['./document-case-score.component.scss']
})
export class DocumentCaseScoreComponent {
  @Input() score: number = 0;

  tooltip() {
    return `${Math.round(this.score * 100)}%`
  }
}
