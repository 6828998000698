import { Component, HostListener, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from './services/authentication.service';
import { User } from './models/user';
import { DocumentService } from './services/document.service';
import { Subscription } from 'rxjs';
import { MessageService } from './services/message.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'uzai';

  uploading: boolean = false;

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private documentService: DocumentService,
    private translate: TranslateService
  ) {

    this.authService.user.subscribe((user: User | undefined) => {
      if (user) {
        this.translate.setDefaultLang(user.lang || "en");
      } else {
        this.translate.setDefaultLang(this.translate.getBrowserLang() || "en");
      }
    });
  }


}
