import { Component, OnInit } from '@angular/core';
import { Data, IntegrationService } from 'src/app/services/integration.service';

@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit {

  types: {
    key: string,
    icon?: string,
    logo?: string,
    title: string,
    available: boolean,
    description: string,
    count?: number
  }[] = [];

  aggregation: Data[] = [];

  constructor(
    private integrationService: IntegrationService
  ) {

  }

  async ngOnInit() {
    await this.aggregateTypes();
    this.types = this.integrationService.types().sort((a, b) => { return (a.available === b.available) ? 0 : a.available ? -1 : 1; });
    this.types.forEach((type) => {
      const found = this.aggregation.find((agg) => agg._id === type.key);
      type.count = found ? found.count : undefined;
    })
  }

  async aggregateTypes() {
    this.aggregation = await this.integrationService.aggregate(undefined, undefined, undefined, undefined);
  }


}
