import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Organization } from 'src/app/models/organization';
import { Paginator } from 'src/app/models/paginator';
import { AdminService } from 'src/app/services/admin.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  organizations: Paginator<Organization> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  filteredOrganizations: Organization[] = [];
  displayedColumns: string[] = ['name', 'headquartersAddress', 'createdAt', 'actions'];
  dataSource: MatTableDataSource<Organization> = new MatTableDataSource<Organization>([])

  query?: string;

  constructor(
    private _message: MessageService,
    private adminService: AdminService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.done = false;
    this.retrieveOrganizations(true);
    this.loading = false;
    this.done = true;
    // this.filtersSubscription = this.filtersSubject
    //   .pipe(
    //     debounceTime(300),
    //     distinctUntilChanged()
    //   ).subscribe(async () => {
    //     this.resetPage();
    //     await this.retrieveTrademarks(false);
    //   });
  }

  async retrieveOrganizations(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.organizations = { ...this.organizations, ...await this.adminService.retrieveOrganizations(this.organizations.page, this.organizations.limit, this.organizations.offset, this.organizations.sort, this.query) };
      this.filteredOrganizations = this.organizations.docs;

      this.dataSource = new MatTableDataSource<Organization>(this.filteredOrganizations);

      if (init && this.organizations.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.organizations.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.organizations.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveOrganizations(false);
    }
  }

  async handlePage(event: PageEvent) {
    this.organizations.limit = event.pageSize;
    this.organizations.page = event.pageIndex + 1;
    this.organizations.offset = event.pageIndex * this.organizations.limit;
    this.retrieveOrganizations(false);
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }
}
