<div class="fx-row fx-wrap" *ngIf="case && attachments.length > 0">
  <!-- <div class="fx-row fx-flex-100 mb-16">
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
    <div class="fx-flex-100 file-upload fx-row justify-content-center align-items-center" (click)="fileUpload.click()"
      [class.active]="active">
      <div class="upload-icon-container">
        <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
      </div>
      <div class="ml-10 fx-column">
        <span class="text main text-center">{{"CASE.UPLOAD_ATTACHMENT_TITLE" | translate}}</span>
        <span class="small main text-center">{{"CASE.UPLOAD_ATTACHMENT_SUBTITLE" | translate}}</span>
      </div>
    </div>
  </div> -->
  <div class="fx-row fx-flex-100 align-items-center justify-content-end mb-16">
    <button class="main-button" (click)="openLinkNonUseDialog()">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_link</mat-icon>
        <span class="white fx-flex">{{ 'CASE.LINK_NON_USE_PROOF' | translate}}</span>
      </div>
    </button>
    <button class="main-button ml-10" (click)="fileUpload.click()">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
        <span class="white fx-flex">{{ 'CASE.UPLOAD_ATTACHMENT' | translate}}</span>
      </div>
    </button>
    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
  </div>
  <mat-card *ngFor="let attachment of attachments; let last = last" [class.mb-16]="!last" class="fx-flex-100">
    <div class="fx-row align-items-center justify-content-between  fx-flex-100">
      <div class="fx-row align-items-center justify-content-start fx-flex">
        <img [src]="icon(attachment)" [height]="35" />
        <div class="fx-column align-items-start justify-content-start ml-10">
          <div class="fx-row align-items-center justify-content-start">
            <span class="text bold dark">{{attachment.name}}</span>
            <mat-icon [class]="attachment.appendix ? 'green ml-5' : 'light ml-5'" fontSet="material-icons-outlined"
              [matTooltip]="(attachment.appendix ? 'CASE.INCLUDED_APPENDIX' : 'CASE.NOT_INCLUDED_APPENDIX') | translate"
              [matTooltipClass]="{'uzai-tooltip':true}" inset inline>{{attachment.appendix
              ? 'file_download' : 'file_download_off'}}</mat-icon>
            <mat-icon class="ml-5 main" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'DOCUMENT.NON_USE' | translate" inset inline
              *ngIf="attachment.nonUse">assignment_late</mat-icon>
          </div>
          <span class="text dark" *ngIf="attachment.description">{{attachment.description}}</span>
          <span class="small light">{{size(attachment)}}</span>
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-end  ml-20">
        <button mat-icon-button [cdkMenuTriggerFor]="menu">
          <mat-icon class="dark" fontSet="material-icons-outlined">more_vert</mat-icon>
        </button>
        <ng-template #menu>
          <mat-card class="menu-container p-0 elevation" cdkMenu>
            <div class="fx-column">
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="viewNonUseDocument(attachment)" cdkMenuItem *ngIf="attachment.nonUse">
                <mat-icon class="main mr-5" fontSet="material-icons-outlined">launch</mat-icon>
                <span class="text">{{'CASE.VIEW_LINKED_DOCUMENT' | translate}}</span>
              </div>
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="download(attachment)" cdkMenuItem>
                <mat-icon class="main mr-5" fontSet="material-icons-outlined">file_download</mat-icon>
                <span class="text">{{'ACTIONS.DOWNLOAD' | translate}}</span>
              </div>
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="edit(attachment)" cdkMenuItem>
                <mat-icon class="main mr-5" fontSet="material-icons-outlined">edit</mat-icon>
                <span class="text">{{'ACTIONS.EDIT' | translate}}</span>
              </div>
              <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                (click)="delete(attachment)" cdkMenuItem>
                <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
                <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
              </div>
            </div>
          </mat-card>
        </ng-template>
      </div>
    </div>
  </mat-card>
</div>
<mat-card *ngIf="case && attachments.length == 0" class="placeholder">
  <div class="fx-flex-100 fx-row align-items-end justify-content-start">
    <div class="watermark-container">
      <img src="/assets/img/icon_lighter.svg" class="watermark" />
    </div>
    <div class="fx-row fx-wrap instructions-container">
      <span class="title dark fx-flex-100">{{'CASE.NO_ATTACHMENT' | translate}}</span>
      <span class="text dark fx-flex-100">{{'CASE.NO_ATTACHMENT_TEXT' | translate:case.trademark}}</span>
      <div class="fx-row fx-flex-100 mt-10">
        <button class="main-button fx-flex" (click)="openLinkNonUseDialog()">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_link</mat-icon>
            <span class="white fx-flex">{{ 'CASE.LINK_NON_USE_PROOF' | translate}}</span>
          </div>
        </button>
        <button class="main-button ml-10 fx-flex" (click)="fileUpload.click()">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
            <span class="white fx-flex">{{ 'CASE.UPLOAD_ATTACHMENT' | translate}}</span>
          </div>
        </button>
        <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
      </div>
    </div>
  </div>
</mat-card>