import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Role } from '../models/user';
import { AclService } from '../services/acl.service';

@Directive({
  selector: '[roleBasedElement]'
})
export class RoleBasedElementDirective {

  @Input() roles: string[] = [];
  @Input() minLevel?: number;

  constructor(
    private aclService: AclService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    const role = this.aclService.role();
    const level = this.aclService.level();
    if (!role || (this.roles.length > 0 && !this.roles.map(r => Role[r as keyof typeof Role].toLowerCase()).includes(role.toLowerCase()))) {
      if (this.el.nativeElement.parentNode)
        this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    } else if (!level || (this.minLevel !== undefined && level < this.minLevel)) {
      if (this.el.nativeElement.parentNode)
        this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
    }
  }

}
