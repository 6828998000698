<div class="p-20 fx-row fx-wrap align-items-center justify-content-center">
  <div class="fx-flex-100 align-items-center justify-content-start text-center">
    <span class="title text-center">{{(mode === 'edit' ? 'INTEGRATIONS.EDIT_TITLE' : 'INTEGRATIONS.CREATE_TITLE')
      | translate}}</span>
  </div>
  <ng-container *ngIf="type" [ngSwitch]="type.key">
    <div *ngSwitchCase="'WEBSITE'" class="mt-20 fx-row fx-wrap fx-flex-100">
      <div class="fx-flex-100 fx-row align-items-center justify-content-start">
        <div class="fx-flex-50 pr-8">
          <app-input [placeholder]="('INTEGRATIONS.WEBSITE.NAME' | translate) + '...'" type="text" [(value)]="name"
            [error]="errors['name']" [bottomWrapper]="true"></app-input>
        </div>
        <div class="fx-flex-50 pl-8">
          <app-select initial="none" label="{{'INTEGRATIONS.WEBSITE.RECURRENCE' | translate}}" [options]="recurrences"
            [multiple]="false" [emitOnInit]="false" [(value)]="recurrence" [error]="errors['recurrence']"
            [bottomWrapper]="true"></app-select>
        </div>
      </div>
      <div class="fx-flex-100 fx-row align-items-center justify-content-start pb-16" *ngIf="recurrence">
        <span class="small light">{{('INTEGRATIONS.RECURRENCE_TEXT_'+recurrence.toUpperCase())
          | translate}}</span>
      </div>
      <div class="fx-flex-100 fx-row align-items-center justify-content-start pt-8 pb-8">
        <span class="text">
          {{'INTEGRATIONS.WEBSITE.URL_TEXT' | translate}}
        </span>
      </div>
      <div class="fx-flex-100">
        <app-input [placeholder]="('INTEGRATIONS.WEBSITE.URL' | translate) + '...'" type="url" [(value)]="url"
          [error]="errors['url']" [bottomWrapper]="true"></app-input>
      </div>
      <div class="fx-flex-100 fx-row align-items-center justify-content-start pt-8 pb-8">
        <span class="text">
          {{'INTEGRATIONS.WEBSITE.TRADEMARKS_TEXT' | translate}}
        </span>
      </div>
      <app-trademark-select label="{{'ENTITIES.TRADEMARK_S' | translate}}" [multiple]="true" [search]="true"
        class="fx-flex" [(value)]="trademarks" [emitOnInit]="false"></app-trademark-select>
    </div>
  </ng-container>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="mode === 'edit' ? onEdit() : onCreate()">{{(mode === 'edit' ?
      'ACTIONS.UPDATE' :
      'ACTIONS.CREATE') | translate}}</button>
  </div>
</div>