import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { DocumentData, DocumentsGraphComponent } from 'src/app/components/dashboard-widgets/documents-graph/documents-graph.component';
import { Organization } from 'src/app/models/organization';
import { Paginator } from 'src/app/models/paginator';
import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-organization-dashboard',
  templateUrl: './organization-dashboard.component.html',
  styleUrls: ['./organization-dashboard.component.scss']
})
export class OrganizationDashboardComponent implements OnInit {

  loading: boolean = false;
  organization?: Organization;
  documentsStats: {
    documents: number;
    pages: number;
    size: number;
    cost: number;
    types: { _id: string, count: number }[],
    classes: { _id: string, count: number }[]
  } = {
      documents: 0,
      pages: 0,
      size: 0,
      cost: 0,
      types: [],
      classes: []
    };
  documentsGraph: DocumentData[] = [];
  period: "hour" | "day" | "week" | "month" | "year" = "day";
  periods: any[] = [
    {
      selected: false,
      label: "DATES.HOURLY",
      value: "hour"
    },
    {
      selected: true,
      label: "DATES.DAILY",
      value: "day"
    },
    {
      selected: false,
      label: "DATES.WEEKLY",
      value: "week"
    },
    {
      selected: false,
      label: "DATES.MONTHLY",
      value: "month"
    },
    {
      selected: false,
      label: "DATES.YEARLY",
      value: "year"
    }
  ];
  metric: "documents" | "size" | "pages" | "cost" = "documents";
  metrics: any[] = [
    {
      selected: true,
      label: "ADMIN.METRICS.DOCUMENTS",
      value: "documents"
    },
    {
      selected: false,
      label: "ADMIN.METRICS.PAGES",
      value: "pages"
    },
    {
      selected: false,
      label: "ADMIN.METRICS.SIZE",
      value: "size"
    },
    {
      selected: false,
      label: "ADMIN.METRICS.COST",
      value: "cost"
    },
  ]
  @ViewChild("documentsGraph") documentsGraphComponent?: DocumentsGraphComponent;

  users: Paginator<User> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 5,
    totalDocs: 0,
    sort: 'lastName'
  };
  filteredUsers: User[] = [];
  displayedColumns: string[] = ['avatar', 'lastName', 'firstName', 'email', 'profile', 'createdAt', 'actions'];
  usersDataSource: MatTableDataSource<User> = new MatTableDataSource<User>([])
  query?: string;
  usersStats: any[] = [];

  trademarksStats: {
    total: number,
    geographies: { _id: string, count: number }[],
    classes: { _id: string, count: number }[],
    statuses: { _id: string, count: number }[]
  } = {
      total: 0,
      geographies: [],
      classes: [],
      statuses: []
    };

  constructor(
    private _message: MessageService,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.retrieveOrganization(params['id']);
  }


  async retrieveOrganization(id: string) {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      this.organization = await this.adminService.retrieveOrganization(id);
      this.retrieveOrganizationUsers();
      this.retrieveOrganizationUsersStats();
      this.retrieveOrganizationDocumentsStats();
      this.retrieveOrganizationDocumentsGraph();
      this.retrieveOrganizationTrademarkStats();
      this.loading = false;
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async retrieveOrganizationUsers() {
    try {
      if (this.organization && this.organization._id) {
        this.users = { ...this.users, ...await this.adminService.retrieveOrganizationUsers(this.organization?._id, this.users.page, this.users.limit, this.users.offset, this.users.sort, this.query) };
        this.filteredUsers = this.users.docs;
        this.usersDataSource = new MatTableDataSource<User>(this.filteredUsers);
      }
    } catch (err) {
      console.log(err)
    }
  }

  async retrieveOrganizationUsersStats() {
    try {
      if (this.organization && this.organization._id) {
        this.usersStats = await this.adminService.retrieveOrganizatioUsersStats(this.organization._id);
        console.log(this.usersStats)
      }

    } catch (err) {
      console.log(err)
    }
  }

  async retrieveOrganizationDocumentsStats() {
    try {
      if (this.organization && this.organization._id) {
        this.documentsStats = await this.adminService.retrieveOrganizationDocumentsStats(this.organization._id);
        console.log(this.documentsStats)
      }

    } catch (err) {
      console.log(err)
    }
  }

  async retrieveOrganizationDocumentsGraph() {
    try {

      if (this.organization && this.organization._id) {
        this.documentsGraph = await this.adminService.retrieveOrganizationDocumentsGraph(this.organization._id, undefined, undefined, this.period);
      }
    } catch (err) {
      console.log(err)
    }
  }

  async retrieveOrganizationTrademarkStats() {
    try {
      if (this.organization && this.organization._id) {
        this.trademarksStats = await this.adminService.retrieveOrganizationTrademarksStats(this.organization._id);
        console.log(this.trademarksStats)
      }

    } catch (err) {
      console.log(err)
    }
  }

  async handleUsersPage(event: any) {
    this.users.limit = event.pageSize;
    this.users.page = event.pageIndex + 1;
    this.users.offset = event.pageIndex * this.users.limit;

    this.retrieveOrganizationUsers();
  }

  onSortUsersChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.users.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveOrganizationUsers();
    }
  }

  async onPeriodChange(event: any) {
    this.period = this.periods[event].value;
    await this.retrieveOrganizationDocumentsGraph();
  }

  async onMetricChange(event: any) {
    this.metric = this.metrics[event].value;
    // if (this.documentsGraphComponent) {
    //   await this.documentsGraphComponent.makeChart();
    // }
  }


  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  formatSize(size: number) {
    if (size < 1024) {
      return {
        value: size,
        unit: 'o'
      }
    } else if (size < 1024 * 1024) {
      return {
        value: size / 1024,
        unit: 'Ko'
      }
    } else if (size < 1024 * 1024 * 1024) {
      return {
        value: size / (1024 * 1024),
        unit: 'Mo'
      }
    } else if (size < 1024 * 1024 * 1024 * 1024) {
      return {
        value: size / (1024 * 1024 * 1024),
        unit: 'Go'
      }
    }
    return {
      value: size,
      unit: 'N/A'
    }
  }

  formatCost(cost: number) {
    if (cost < 1) {
      return parseFloat(cost.toFixed(3))
    }
    return parseFloat(cost.toFixed(2))
  }
}
