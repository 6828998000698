<div [class]="class">
  <input type="file" class="file-input" (change)="onFilesSelected($event)" #fileUpload multiple
    accept="image/png, image/jpeg, image/jpg, image/tiff, application/pdf">
  <div class="file-upload fx-row justify-content-center align-items-center fx-flex-100 h-100"
    (click)="fileUpload.click()" [class.active]="active">
    <div class="upload-icon-container">
      <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
    </div>
    <div class="ml-10 fx-column">
      <span class="text main text-center">{{"HOME.UPLOAD_TITLE" | translate}}</span>
      <span class="small main text-center">{{"HOME.UPLOAD_SUBTITLE" | translate}}</span>
    </div>
  </div>
</div>