<div class="p-20 fx-row fx-wrap align-items-start justify-content-start text-center">
  <span class="title fx-flex-100 mb-10  text-center">{{title | translate}}</span>
  <div class="text fx-flex-100 text-center" [innerHTML]="text | translate"></div>
  <div class="text fx-flex-100 text-center mt-20 mb-10" [innerHTML]="confirmation.text | translate"
    *ngIf="confirmation">
  </div>
  <app-input *ngIf="confirmation" [placeholder]="confirmation.value" type="text" [(value)]="value"
    class="fx-flex-100"></app-input>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button [class]="button.class + ' fx-flex ml-10'" (click)="onConfirm()" [class.disabled]="isDisabled()"
      [disabled]="isDisabled()">{{button.text | translate}}</button>
  </div>
</div>