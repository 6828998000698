import { CdkMenu, CdkMenuBase, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subscription, concat, of } from 'rxjs';
import { NiceClass } from 'src/app/models/niceClass';
import { Trademark } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';
import { NiceService } from 'src/app/services/nice.service';


@Component({
  selector: 'app-period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['./period-select.component.scss']
})
export class PeriodSelectComponent {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() range: boolean = false;

  @Input() initial?: string = 'all';
  @Input() disabled: boolean = false;
  @Input() disabledValues: number[] = [];
  @Input() emitOnInit: boolean = true;
  @Input() min?: number;
  @Input() max?: number;

  yearRange: {
    start: number,
    end: number
  } = {
      start: moment().add(-4, 'years').startOf('year').year(),
      end: moment().add(4, 'years').endOf('year').year()
    };

  currentYears: number[] = [];

  projected?: number;

  @Output() change = new EventEmitter<any>();
  @Input() value?: number;
  @Input() valueRange: { start?: number, end?: number } = { start: undefined, end: undefined };
  @Output() valueChange = new EventEmitter<any>();
  @Output() valueRangeChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private niceService: NiceService,
    private translate: TranslateService
  ) {
    this.setCurrentYears();
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  async setInitialState() {
    if (this.range && this.valueRange && this.valueRange.start && this.valueRange.end) {
      this.selection = this.valueRange.start !== this.valueRange.end ? `${this.valueRange.start} - ${this.valueRange.end}` : `${this.valueRange.start}`;
    } else if (!this.range && this.value) {
      this.selection = `${this.value}`;
    }
  }

  setCurrentYears() {
    this.currentYears = _.range(this.yearRange.start, this.yearRange.end + 1);
  }

  previousYearRange() {
    this.yearRange.start -= 9;
    this.yearRange.end -= 9;
    this.setCurrentYears();
  }

  nextYearRange() {
    this.yearRange.start += 9;
    this.yearRange.end += 9;
    this.setCurrentYears();
  }

  getClass(year: number) {
    if (!this.range && this.value === year) {
      return 'plain';
    } else if (this.range && this.valueRange.start && this.valueRange.start === year) {
      return 'plain';
    } else if (this.range && this.valueRange.end && this.valueRange.end === year) {
      return 'plain';
    } else if (this.range && this.projected && this.projected === year) {
      return 'plain';
    } else if (this.range && this.valueRange.start && this.valueRange.end && this.valueRange.start < year && year < this.valueRange.end) {
      return 'main';
    } else if (this.range && this.valueRange.start && this.projected && this.valueRange.start < year && year < this.projected) {
      return 'main';
    } else if (this.isDisabled(year)) {
      return 'light';
    } else {
      return undefined;
    }
  }

  isDisabled(year: number) {
    return (this.range && this.valueRange.start && !this.valueRange.end && year < this.valueRange.start) || (this.min && year < this.min) || (this.max && year > this.max);
  }

  select(year: number) {

    if (this.range) {
      if (!this.valueRange.start) {
        this.valueRange.start = year;
        this.selection = `${this.valueRange.start} - `;
      } else if (!this.valueRange.end) {
        this.valueRange.end = year;
        this.selection = this.valueRange.start !== this.valueRange.end ? `${this.valueRange.start} - ${this.valueRange.end}` : `${this.valueRange.start}`;
        this.onChange();
      } else {
        this.valueRange.start = year;
        this.valueRange.end = undefined;
        this.selection = `${this.valueRange.start} - `;
      }
    } else {
      this.value = year;
      this.selection = `${this.value}`;
      this.onChange();
    }
  }

  project(year: number) {
    this.projected = year;
  }

  unproject() {
    this.projected = undefined;
  }

  onChange() {
    if (!this.range) {
      this.trigger?.close();
      this.valueChange.emit(this.value as number);
      this.change.emit(this.value);
    } else {
      this.trigger?.close();
      this.valueRangeChange.emit(this.valueRange);
      this.change.emit(this.valueRange);
    }
  }


  onClear() {
    this.setInitialState();
    if (this.range) {
      this.valueRange = {
        start: undefined,
        end: undefined
      }
    } else {
      this.value = undefined;
    }
    this.selection = undefined;
    this.unproject();
    this.onChange();
    this.trigger?.close();
  }
}
