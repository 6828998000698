import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import anime from "animejs/lib/anime.es.js";

@Component({
  selector: 'app-animated-number',
  templateUrl: './animated-number.component.html',
  styleUrls: ['./animated-number.component.scss']
})
export class AnimatedNumberComponent implements OnChanges {
  @Input() value?: number;
  @Input() class?: string;
  @Input() unit?: string;
  @Input() round?: number = 1;

  current: { value: number } = {
    value: 0
  }

  ngOnChanges(changes: SimpleChanges) {
    this.animate();
  }

  animate() {
    anime({
      targets: this.current,
      value: this.value,
      easing: "easeOutCubic",
      round: this.round,
      duration: 1000,
      delay: 100
    });
  }
}
