import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { TranslateService } from '@ngx-translate/core';
import { TrademarkStatus } from 'src/app/models/trademark';

@Component({
  selector: 'app-trademarks-statuses',
  templateUrl: './trademarks-statuses.component.html',
  styleUrls: ['./trademarks-statuses.component.scss']
})
export class TrademarksStatusesComponent {
  @Input() data: {
    _id: any,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  @Input() count: number = 0;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('graphstatusdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.PieChart;

  constructor(
    private zone: NgZone,
    private translate: TranslateService
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.makeChart();
    }, 100)

  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  _color(status: TrademarkStatus) {
    switch (status) {
      case TrademarkStatus.REGISTERED:
        return '#3a9e31';
      case TrademarkStatus.FILED:
        return '#e38847';
      default:
        return '#e34754'
    }
  }

  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (!this.chart && this.chartElement) {

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
        chart.logo.setVisibility(false);
        chart.radius = am4core.percent(65);

        chart.data = this.data.map((datum) => ({ name: this.translate.instant(`TRADEMARK.STATUS_${datum._id}`), count: datum.count, color: am4core.color(this._color(datum._id)) })).sort((a, b) => b.count - a.count);


        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "name";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.propertyFields.fill = 'color';
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.fontWeight = 'bold';
        pieSeries.labels.template.fontFamily = 'Source Sans 3, sans-serif';
        pieSeries.labels.template.text = "{category}";
        pieSeries.labels.template.wrap = true;
        pieSeries.labels.template.maxWidth = 100;
        pieSeries.alignLabels = false;
        pieSeries.ticks.template.events.on("ready", hideSmall);
        pieSeries.ticks.template.events.on("visibilitychanged", hideSmall);
        pieSeries.labels.template.events.on("ready", hideSmall);
        pieSeries.labels.template.events.on("visibilitychanged", hideSmall);


        function hideSmall(ev: any) {
          if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 5)) {
            ev.target.hide();
          }
          else {
            ev.target.show();
          }
        }

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);

        this.chart = chart;

      } else if (this.chartElement && this.chart) {
        this.chart.data = this.data.map((datum) => ({ name: this.translate.instant(`TRADEMARK.STATUS_${datum._id}`), count: datum.count, color: am4core.color(this._color(datum._id)) }));
      }

    })
  }

}
