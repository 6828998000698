import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Case, CaseTimelinePhase, ClosedPhaseMeta } from 'src/app/models/case';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-case-phase',
  templateUrl: './case-phase.component.html',
  styleUrls: ['./case-phase.component.scss']
})
export class CasePhaseComponent implements OnInit, OnChanges {
  @Input() case?: Case;
  @Input() mode: 'light' | 'full' = 'full';
  isSuspended: boolean = true;
  closedMeta?: ClosedPhaseMeta;

  constructor(
    private caseService: CaseService
  ) {

  }

  ngOnInit(): void {
    if (this.case) {
      this.isSuspended = this.caseService._isSuspended(this.case);
      this.closedMeta = this.caseService._closedMeta(this.case);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.case) {
      this.isSuspended = this.caseService._isSuspended(this.case);
      this.closedMeta = this.caseService._closedMeta(this.case);
    }
  }
}
