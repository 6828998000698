<div class="fx-column">
  <span class="label" *ngIf="label">{{label}}</span>
  <div class="fx-row justify-content-start align-items-center input-wrapper"
    [class]="{'focus':focus,'error':error.value}">
    <input [type]="type" [placeholder]="placeholder" [(ngModel)]="value" (ngModelChange)="valueUpdate.next($event)"
      (focus)="focus = true;picker.open();" (blur)="focus = false;picker.close()" [matDatepicker]="picker" [min]="min"
      [max]="max" [disabled]="disabled" class="fx-flex" #input />
    <mat-icon class="suffix light mb-5 clickable" [class.focus]="focus" fontSet="material-icons-outlined"
      (click)="picker.open()">event</mat-icon>
    <mat-datepicker #picker (closed)="input.blur()"></mat-datepicker>
  </div>
</div>