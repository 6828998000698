<mat-card class="p-0  fx-flex-100 h-100" *ngIf="style === 'expanded'">
  <div class="p-16 fx-row fx-wrap align-items-center justify-content-between main-bg">
    <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex-100">
      <span class="small white fx-flex-100 ">{{'CASE.SCORE.GLOBAL' | translate}}</span>
      <app-animated-number [value]="100*score.global" [class]="'huge white'" [unit]="'%'"></app-animated-number>
      <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
        <div class="fx-row align-items-center justify-content-start score-container white fx-flex-100">
          <div class="score white" [style.flex-basis.%]="100*score.global">
          </div>
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-start mt-10">
        <mat-icon class="mr-10 white" fontSet="material-icons-outlined">{{tip('global').icon}}</mat-icon>
        <span class="text white fx-flex">{{tip('global').message}}</span>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="fx-row fx-wrap align-items-stretch justify-content-start border-bottom ">
    <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-50 border-right border-box p-16">
      <span class="small light fx-flex-100 ">{{'CASE.SCORE.DOCUMENTS_COUNT' | translate}}</span>
      <span class="text bold main  fx-flex-100 ">{{(100*score.documentsCount).toFixed(0)}}%</span>
      <div class="fx-row align-items-center justify-content-start score-container fx-flex-100">
        <div class="score" [style.flex-basis.%]="100*score.documentsCount">
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-start mt-5">
        <mat-icon class="mr-10" [class]="tip('documentsCount').color"
          fontSet="material-icons-outlined">{{tip('documentsCount').icon}}</mat-icon>
        <span class="small fx-flex">{{tip('documentsCount').message}}</span>
      </div>
    </div>
    <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-50 border-box p-16">
      <span class="small light fx-flex-100 ">{{'CASE.SCORE.DOCUMENTS_ACCURACY' | translate}}</span>
      <span class="text bold main  fx-flex-100 ">{{(100*score.documentsAccuracy).toFixed(0)}}%</span>
      <div class="fx-row align-items-center justify-content-start score-container fx-flex-100">
        <div class="score" [style.flex-basis.%]="100*score.documentsAccuracy">
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-start mt-5">
        <mat-icon class="mr-10" [class]="tip('documentsAccuracy').color"
          fontSet="material-icons-outlined">{{tip('documentsAccuracy').icon}}</mat-icon>
        <span class="small fx-flex">{{tip('documentsAccuracy').message}}</span>
      </div>
    </div>
  </div>
  <div class="fx-row fx-wrap align-items-stretch justify-content-start">
    <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-50  border-right border-box p-16">
      <span class="small light fx-flex-100 ">{{'CASE.SCORE.DOCUMENTS_TYPES' | translate}}</span>
      <span class="text bold main  fx-flex-100 ">{{(100*score.documentsClasses).toFixed(0)}}%</span>
      <div class="fx-row align-items-center justify-content-start score-container fx-flex-100">
        <div class="score" [style.flex-basis.%]="100*score.documentsClasses">
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-start mt-5">
        <mat-icon class="mr-10" [class]="tip('documentsClasses').color"
          fontSet="material-icons-outlined">{{tip('documentsClasses').icon}}</mat-icon>
        <span class="small fx-flex">{{tip('documentsClasses').message}}</span>
      </div>
    </div>
    <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-50 border-box  p-16">
      <span class="small light fx-flex-100 ">{{'CASE.SCORE.TEMPORAL_COVERAGE' | translate}}</span>
      <span class="text bold main  fx-flex-100">{{(100*score.temporalCoverage).toFixed(0)}}%</span>
      <div class="fx-row align-items-center justify-content-start score-container fx-flex-100">
        <div class="score" [style.flex-basis.%]="100*score.temporalCoverage">
        </div>
      </div>
      <div class="fx-row align-items-center justify-content-start mt-5">
        <mat-icon class="mr-10" [class]="tip('temporalCoverage').color"
          fontSet="material-icons-outlined">{{tip('temporalCoverage').icon}}</mat-icon>
        <span class="small fx-flex">{{tip('temporalCoverage').message}}</span>
      </div>
    </div>
  </div>
</mat-card>
<div class="fx-row fx-wrap" *ngIf="style === 'condensed'">
  <app-animated-number [value]="100*score.global" [class]="'text main bold'" [unit]="'%'"></app-animated-number>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100">
    <div class="fx-row align-items-center justify-content-start score-container fx-flex-100">
      <div class="score" [style.flex-basis.%]="100*score.global">
      </div>
    </div>
  </div>
</div>