import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Batch, BatchStatus } from 'src/app/models/batch';
import { BatchService } from 'src/app/services/batch.service';

@Component({
  selector: 'app-batch-progress',
  templateUrl: './batch-progress.component.html',
  styleUrls: ['./batch-progress.component.scss']
})
export class BatchProgressComponent implements OnInit, OnDestroy {
  @Input() batch?: Batch;

  progress: number = 0;

  intervalId: any;

  @Output() refresh = new EventEmitter<any>();

  constructor(
    private batchService: BatchService
  ) {

  }

  async ngOnInit() {

    if (this.batch && [BatchStatus.PENDING, BatchStatus.PROCESSING].includes(this.batch?.status)) {
      await this.update();
      this.intervalId = setInterval(async () => {
        await this.update();
      }, 3000)
    } else {
      this.score(this.batch);
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  async update() {

    if (this.batch && this.batch._id) {
      console.log("updating")
      const batch = await this.batchService.retrieve(this.batch?._id);
      const { progress } = await this.batchService.retrieveProgress(this.batch?._id);
      this.batch = batch;
      this.progress = Math.round(100 * progress);
      console.log(this.batch.number, this.progress)
      if (this.progress >= 100) {
        this.onRefresh();
        clearInterval(this.intervalId)
      }
    }
  }


  score(batch: Batch | undefined) {
    if (this.batch)
      this.progress = Math.round(100 * ((this.batch?.report?.ok ?? 0) + (this.batch?.report?.error ?? 0) + (this.batch?.report?.warning ?? 0)) / (this.batch?.documents ?? 1))
  }

  onRefresh() {
    this.refresh.emit(this.batch)
  }



} 
