<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'INTEGRATIONS.LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'INTEGRATIONS.LIST.SUBTITLE' | translate}}</span>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<div class="fx-row fx-wrap align-items-stretch justify-content-start">
  <div class="fx-flex-25 p-8 border-box" *ngFor="let type of types">
    <mat-card class="p-0 h-100">
      <div class="fx-column h-100 align-items-start justify-content-start">
        <div class="p-16 fx-column align-items-start justify-content-start">
          <mat-icon class="main integration-icon" fontSet="material-icons-outlined"
            *ngIf="type.icon">{{type.icon}}</mat-icon>
          <img [src]="type.logo" *ngIf="!type.icon && type.logo" class="integration-logo" />
          <span class="title bold mt-16">{{type.title | translate}}</span>
        </div>
        <div class="ph-16">
          <span class="text">
            {{type.description | translate}}
          </span>
        </div>
        <div class="fx-flex-100"></div>
        <div class="fx-row align-items-center justify-content-between p-16 w-100 border-box">
          <span class="main text bold" *ngIf="type.count && type.count > 0">{{type.count}} {{((type.count > 1 ?
            'ENTITIES.INTEGRATIONS'
            :'ENTITIES.INTEGRATION') | translate).toLowerCase()}}</span>
          <span *ngIf="!type.count"></span>
          <button class="button" [routerLink]="type.key.toLowerCase()" *ngIf="type.available">
            {{'ACTIONS.CONFIGURE' | translate}}
          </button>
          <span class="main text" *ngIf="!type.available">{{'INTEGRATIONS.COMING_SOON' | translate}}</span>
        </div>
      </div>
    </mat-card>
  </div>
</div>