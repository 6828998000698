export enum Role {
  USER = 'User',
  CASE_OPERATOR = 'Case operator',
  COUNTRY_OPERATOR = 'Country operator',
  MANAGER = 'Manager',
  ADMIN = 'Admin',
  SUPERADMIN = 'Super Admin'
}

export interface User {
  _id?: string;
  email: string;
  firstName: string;
  lastName: string;
  avatar?: string;
  role?: {
    name: Role,
    level: number
  };
  organization: string;
  acls?: string[];
  lang: string;
  password?: string;
  createdAt?: Date;
  passwordVerified?: boolean;
}
