import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Organization } from 'src/app/models/organization';
import { Role } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { MessageService } from 'src/app/services/message.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  organization?: Organization;

  isManagerOrAbove: boolean = false;

  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private organizationService: OrganizationService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this._message.emitChange("NAV", "SECTION", {
      path: "organization"
    });
    this.isManagerOrAbove = this.aclService.isManagerOrAbove();
    this.retrieveMyOrganization();
  }

  async retrieveMyOrganization() {
    try {
      this._message.emitChange("LOADING", "START");
      this.organization = await this.organizationService.mine();
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.toastr.error('ERRORS.GENERIC');
      this._message.emitChange("LOADING", "END");
    }
  }


  formatDate() {
    return moment(this.organization?.createdAt).format('ll');
  }

  onChange(organization: Organization) {
    if (organization && organization._id) {
      this.organization = organization;
    }
  }
}
