import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { Case, CaseDocument } from 'src/app/models/case';
import { Document, DocumentFilters } from 'src/app/models/document';
import { NiceClass } from 'src/app/models/niceClass';
import { Paginator } from 'src/app/models/paginator';
import { Territory } from 'src/app/models/territory';
import { CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-add-case-documents-dialog',
  templateUrl: './add-case-documents-dialog.component.html',
  styleUrls: ['./add-case-documents-dialog.component.scss']
})
export class AddCaseDocumentsDialogComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;

  public filters: DocumentFilters = {
    query: undefined,
    classNumber: [],
    territories: [],
    documentClass: [],
    trademark: [],
    period: {
      start: undefined,
      end: undefined
    }
  }

  documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: Document[] = [];

  selection = new SelectionModel<Document>(true, []);

  case?: Case;

  private readonly filtersSubject = new Subject<DocumentFilters>();
  private filtersSubscription?: Subscription;

  restrictions: {
    selectedAndDisabled: string[],
    disabled: string[],
    tooltip?: string
  } = {
      selectedAndDisabled: [],
      disabled: [],
      tooltip: 'CASE.PROOF_ALREADY_LINKED_TO_CASE'
    };

  constructor(
    private _message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { case: Case },
    private caseService: CaseService,
    private dialogRef: MatDialogRef<AddCaseDocumentsDialogComponent>,
    private documentService: DocumentService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
  }

  async ngOnInit() {
    this.case = this.data.case;
    if (this.case) {
      this.filters.classNumber = this.case.niceClassification;
      this.filters.trademark = [{ ...this.case.trademark, _id: this.case.trademark.ref }];
      this.filters.period.start = moment(this.case.timePeriod.start).year();
      this.filters.period.end = moment(this.case.timePeriod.end).year();
      this.filters.nonUse = false;
      this.restrictions.selectedAndDisabled = this.case.documents?.map((doc) => doc.ref) || [];
    }

    this.loading = true;
    this.done = false;
    await this.retrieveDocuments(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveDocuments(false);
      });
  }


  async retrieveDocuments(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");
      console.log(this.filters)
      this.documentService.pager = { ...this.documentService.pager, ...await this.documentService.retrieveAll(this.documentService.pager.page, this.documentService.pager.limit, this.documentService.pager.offset, this.documentService.pager.sort, this.filters.territories, this.filters.classNumber, this.filters.trademark, this.filters.documentClass, this.filters.period, this.filters.nonUse, this.filters.query) };
      this.filteredDocuments = this.documentService.pager.docs;

      if (init && this.documentService.pager.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.documentService.pager.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.documentService.pager.limit = 10;
    this.documentService.pager.page = 1;
    this.documentService.pager.offset = 0;
  }

  async handlePage(event: any) {
    this.documentService.pager.limit = event.pageSize;
    this.documentService.pager.page = event.pageIndex + 1;
    this.documentService.pager.offset = event.pageIndex * this.documentService.pager.limit;

    this.retrieveDocuments(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.documentService.pager.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveDocuments(false);
    }
  }


  onNiceClassificationChange(event: any) {
    this.filters.classNumber = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
    this.filtersSubject.next({ ...this.filters });
  }

  onTerritoryChange(event: any) {
    this.filters.territories = event.value.map((territory: Territory) => territory.alternateCodes ? [territory.id, ...territory.alternateCodes] : [territory.id]).flat();
    this.filtersSubject.next({ ...this.filters });
  }

  onTrademarkChange(event: any) {
    this.filters.trademark = event.value;
    this.filtersSubject.next({ ...this.filters });
  }

  onDocumentClassChange(event: any) {
    this.filters.documentClass = event.value.map((v: any) => v.value);
    this.filtersSubject.next({ ...this.filters });
  }

  onPeriodChange(event: any) {
    this.filters.period = event;
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    this.filtersSubject.next({ ...this.filters });
  }


  isDisabled() {
    return this.loading || this.selection.selected.length === 0;
  }

  onCancel() {
    this.dialogRef.close({ linked: false });
  }

  async link() {
    try {
      if (this.case && this.case._id) {
        this.loading = true;
        await this.caseService.link(this.case._id, this.selection.selected.map((doc: Document) => doc._id!));
        this.toastr.success(this.translate.instant(this.selection.selected.length > 1 ? 'CASE.LINK_PROOFS_SUCCESS' : 'CASE.LINK_PROOF_SUCCESS', { n: this.selection.selected.length }));
        this.loading = false;
        this.dialogRef.close({ linked: true });
      }

    } catch (err) {
      this.loading = false;
      this.toastr.error(`ERRORS.GENERIC`);
    }

  }


}
