<app-back></app-back>
<div class="fx-row align-items-center justify-content-start" *ngIf="organization">
  <img [src]="organization.logo" class="org-logo" />
  <div class="fx-column ml-10">
    <div class="fx-row align-items-center justify-content-start">
      <span class="page-title">{{organization.name}}</span>
      <div class="chip main outline mb-8 ml-10 small">{{'ADMIN.SINCE' |
        translate}} {{formatDate(organization.createdAt)}}</div>
    </div>
    <span class="page-subtitle">{{organization.headquartersAddress}}</span>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class='fx-row fx-wrap align-items-stretch justify-content-start fx-flex-100'>
    <div class="pr-8 fx-flex-20 border-box">
      <app-counter-card label="ADMIN.DOCUMENTS" [value]="documentsStats.documents"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-20 border-box">
      <app-counter-card label="ADMIN.PAGES" [value]="documentsStats.pages"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-20 border-box">
      <app-counter-card label="ADMIN.SIZE" [value]="formatSize(documentsStats.size).value"
        [unit]="formatSize(documentsStats.size).unit" [round]="10"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-20 border-box">
      <app-counter-card label="ADMIN.COST" [value]="formatCost(documentsStats.cost)" [unit]="'€'"
        [round]="100"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-20 border-box">
      <app-counter-card label="ADMIN.AVG_COST" [value]="formatCost(documentsStats.cost / documentsStats.documents)"
        [unit]="'€'" [round]="100"></app-counter-card>
    </div>
  </div>
  <div class="mt-16 fx-flex-100">
    <mat-card class="p-0">
      <div class="p-16">
        <span class="text bold">{{'ADMIN.DOCUMENTS_GRAPH' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <div class="p-16">
        <div class="fx-row  align-items-center justify-content-start">
          <div class="fx-column">
            <span class="label">{{'ADMIN.METRIC' | translate}}</span>
            <app-button-toggle [buttons]="metrics" (change)="onMetricChange($event)"></app-button-toggle>
          </div>
          <div class="fx-column ml-10">
            <span class="label">{{'ADMIN.TEMPORALITY' | translate}}</span>
            <app-button-toggle [buttons]="periods" (change)="onPeriodChange($event)"></app-button-toggle>
          </div>
        </div>
      </div>
      <app-documents-graph [data]="documentsGraph" [period]="period" [metric]="metric"></app-documents-graph>
    </mat-card>
  </div>
  <div class="fx-row fx-flex-100 align-items-stretch justify-content-start mt-16">
    <div class="pr-8 fx-flex-50 border-box fx-column">
      <mat-card class="p-0">
        <div class="p-16">
          <span class="text bold">{{'ADMIN.DOCUMENTS_PER_CLASS' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <app-documents-classes [data]="documentsStats.classes"></app-documents-classes>
      </mat-card>
    </div>
    <div class="pl-8 fx-flex-50 border-box fx-column">
      <mat-card class="p-0">
        <div class="p-16">
          <span class="text bold">{{'ADMIN.DOCUMENTS_PER_TYPE' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <app-documents-types [data]="documentsStats.types"></app-documents-types>
      </mat-card>
    </div>
  </div>
</div>
<div class="fx-row  align-items-stretch justify-content-start mt-16">
  <div class="pr-8 fx-flex-30 border-box fx-column">
    <app-counter-card label="ADMIN.USERS" [value]="users.totalDocs"></app-counter-card>
    <mat-card class="p-0 mt-16">
      <div class="p-16">
        <span class="text bold">{{'ADMIN.USERS_PER_ROLE' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <app-users-pie [data]="usersStats"></app-users-pie>
    </mat-card>
  </div>
  <div class="pl-8 fx-flex-70 fx-column">
    <mat-card class="p-0 fx-flex">
      <div class="p-16">
        <span class="text bold">{{'ADMIN.USERS' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <table mat-table [dataSource]="users.docs" style="z-index: 0;" matSort
        (matSortChange)="onSortUsersChange($event)">
        <ng-container matColumnDef="avatar">
          <th mat-header-cell *matHeaderCellDef>
            <span class="small light bold">
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <div class="fx-row align-items-center justify-content-start">
              <app-avatar [user]="user" [size]="30"></app-avatar>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'USER.LASTNAME' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <span class="text">{{user.lastName}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="firstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'USER.FIRSTNAME' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <span class="text">{{user.firstName}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'USER.EMAIL' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <div class="fx-row align-items-center justify-content-start">
              <a [href]="'mailto:'+user.email">{{user.email}}</a>
              <mat-icon class="dark ml-5 clickable" inline fontSet="material-icons-outlined" [cdkMenuTriggerFor]="menu"
                *ngIf="user.invitedBy && user.invitedBy.firstName && user.invitedBy.lastName">info</mat-icon>
              <ng-template #menu>
                <mat-card class="menu-container p-8 elevation" cdkMenu
                  *ngIf="user.invitedBy && user.invitedBy.firstName && user.invitedBy.lastName">
                  <span class="small dark">{{'USER.INVITED_BY' | translate}}</span>
                  <div class="fx-row justify-content-start align-items-center">
                    <app-avatar [user]="user.invitedBy" [size]="20"></app-avatar>
                    <span class="small ml-5">{{user.invitedBy.firstName}} {{user.invitedBy.lastName}}</span>
                  </div>
                </mat-card>
              </ng-template>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="profile">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'USER.PROFILE' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <app-user-profile [role]="user.role"></app-user-profile>
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'USER.REGISTRATION' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let user">
            <span class="text light" *ngIf="user.passwordVerified">{{formatDate(user.createdAt)}}</span>
            <div *ngIf="!user.passwordVerified" class="fx-row fx-wrap align-items-center justify-content-start">
              <mat-icon class="light mr-5" fontSet="material-icons-outlined" inline>email</mat-icon>
              <span class="small light bold">{{'USER.PENDING_INVITE' | translate}}</span>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let user">
            <!-- <div class="fx-row align-items-center justify-content-end">
              <button mat-icon-button (click)='edit(user)' [disabled]="!canEdit(user)"
                [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="editTooltip(user)">
                <mat-icon class="main mr-5" [class.lighter]="!canEdit(user)"
                  fontSet="material-icons-outlined">edit</mat-icon>
              </button>
              <button mat-icon-button [disabled]='!canDelete(user)' (click)='delete(user)'
                [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.DELETE' | translate">
                <mat-icon [class]="canDelete(user) ? 'red' : 'lighter'"
                  fontSet="material-icons-outlined">delete</mat-icon>
              </button>
            </div> -->
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
      <div class="p-16" *ngIf="users.totalDocs === 0">
        <span class="text light">{{'ORGANIZATION.SEARCH_NO_USERS' | translate}}</span>
      </div>
      <div class="fx-flex align-items-center justify-content-end">
        <mat-paginator [length]="users.totalDocs || 0" [pageSize]="users.limit || 0"
          [pageIndex]="users.page ? (users.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
          (page)="handleUsersPage($event)">
        </mat-paginator>
      </div>
    </mat-card>
  </div>
</div>
<div class="fx-row fx-wrap align-items-stretch justify-content-start mt-16">
  <div class='fx-row align-items-stretch justify-content-start fx-flex-100 mt-16'>
    <div class='fx-flex-70 pr-8 border-box fx-column'>
      <mat-card class="p-0 h-100">
        <div class="p-16">
          <span class="text bold">{{'ADMIN.TRADEMARKS_GEOGRAPHIES' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <app-trademarks-territories [data]="trademarksStats.geographies"
          [count]="trademarksStats.total"></app-trademarks-territories>
      </mat-card>
    </div>
    <div class='fx-flex-30 pl-8 border-box fx-column'>
      <app-counter-card label="ADMIN.TRADEMARKS" [value]="trademarksStats.total"></app-counter-card>
      <mat-card class="p-0 h-100 mt-16">
        <div class="p-16">
          <span class="text bold">{{'ADMIN.TRADEMARKS_STATUSES' | translate}}</span>
        </div>
        <mat-divider></mat-divider>
        <app-trademarks-statuses [data]="trademarksStats.statuses"
          [count]="trademarksStats.total"></app-trademarks-statuses>
      </mat-card>
    </div>
  </div>
  <div class='fx-flex-100 mt-16 border-box fx-column'>
    <mat-card class="p-0 h-100">
      <div class="p-16">
        <span class="text bold">{{'ADMIN.TRADEMARKS_CLASSES' | translate}}</span>
      </div>
      <mat-divider></mat-divider>
      <app-trademarks-classes [data]="trademarksStats.classes" [count]="trademarksStats.total"></app-trademarks-classes>
    </mat-card>
  </div>
</div>