import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, firstValueFrom, map, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import jwt_decode from "jwt-decode";
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './message.service';

export interface TokenDecoded {
  sub: string;
  email: string;
  role: {
    name: string;
    level: number;
  },
  organization: {
    id: string;
    logo: string;
    name: string;
  },
  iat: number;
  exp: number;
  iss: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  private userSubject: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  public user: Observable<User | undefined>;

  constructor(
    private _message: MessageService,
    private http: HttpClient,
    private router: Router
  ) {
    const user = localStorage.getItem('uzai-user');
    this.userSubject.next(user ? JSON.parse(user) : undefined);
    this.user = this.userSubject.asObservable();
  }

  getAccessToken() {
    return localStorage.getItem('uzai-access-token');
  }

  isAccessTokenAboutToExpire() {
    const accessToken = localStorage.getItem('uzai-access-token');
    if (accessToken) {
      const decoded = jwt_decode<TokenDecoded>(accessToken);
      const expiration = moment.unix(decoded.exp);
      return expiration.isSameOrBefore(moment().add(2, 'minutes'));
    }
    return false;
  }

  getRefreshToken() {
    return localStorage.getItem('uzai-refresh-token');
  }

  setRefreshToken(token: string) {
    return localStorage.setItem('uzai-refresh-token', token);
  }

  isLoggedIn() {
    try {
      const refreshToken = this.getRefreshToken();
      if (!this.user || !refreshToken) {
        return false;
      }
      const decoded = jwt_decode<TokenDecoded>(refreshToken);
      const expiration = moment.unix(decoded.exp);
      return expiration.isAfter(moment());
    } catch (err) {
      return false;
    }

  }

  login(email: string, password: string) {
    return this.http
      .post<{ user: User, accessToken: string, refreshToken: string }>(
        `${environment.backend}/auth/login`,
        {
          email,
          password
        }
      ).pipe(
        tap((res) => {
          this.userSubject.next(res.user);
          localStorage.setItem('uzai-user', JSON.stringify(res.user));
          localStorage.setItem('uzai-access-token', res.accessToken);
          localStorage.setItem('uzai-refresh-token', res.refreshToken);
        }),
        map((res) => {
          return res.user;
        }),
        catchError((error) => {
          this.removeTokens();
          return throwError(error.error);
        })
      );
  }

  sendResetPassword(email: string) {
    return firstValueFrom(this.http
      .post<{}>(
        `${environment.backend}/auth/password/forget/send`,
        {
          email
        }
      ));
  }

  resetPassword(password: string, code: string) {
    return firstValueFrom(this.http
      .post<{}>(
        `${environment.backend}/auth/password/forget/reset`,
        {
          code,
          password
        }
      ));
  }

  refreshToken() {

    return this.http
      .post<{ accessToken: string }>(
        `${environment.backend}/auth/refreshToken`,
        {
          refreshToken: this.getRefreshToken()
        }
      ).pipe(
        tap((res) => {
          localStorage.setItem('uzai-access-token', res.accessToken);
        }),
        map((res) => {
          return res.accessToken;
        }),
        catchError((error) => {
          this.logout()
          return throwError(() => error.error);
        })
      );
  }

  getStrongAccessToken(): string | undefined {
    const token = localStorage.getItem('uzai-strong-access-token');
    if (!token) {
      return undefined;
    }
    try {
      const decoded: any = jwt_decode(token);
      if (decoded && moment().isBefore(moment.unix(decoded.exp))) {
        return token;
      }
      localStorage.removeItem('uzai-strong-access-token')
      return undefined;
    } catch (err) {
      localStorage.removeItem('uzai-strong-access-token')
      return undefined;
    }
  }

  requestOtp() {
    return this.http
      .post<{ accessToken: string }>(
        `${environment.backend}/auth/otp`,
        {}
      );
  }

  async validateOtp(otp: string) {
    const res = await firstValueFrom(this.http
      .post<{ strongAccessToken: string }>(
        `${environment.backend}/auth/otp/validate`,
        {
          otp
        }
      ));
    localStorage.setItem('uzai-strong-access-token', res.strongAccessToken);
    return res.strongAccessToken
  }

  setUser(user: User) {
    localStorage.setItem('uzai-user', JSON.stringify(user));
    this.userSubject.next(user);
  }

  removeTokens() {
    this.userSubject.next(undefined);
    localStorage.removeItem('uzai-user');
    localStorage.removeItem('uzai-access-token');
    localStorage.removeItem('uzai-refresh-token');
  }

  logout() {
    this._message.emitChange("LOADING", "END");
    this.http.delete<void>(
      `${environment.backend}/auth/logout`).subscribe(res => {
        this.removeTokens();
        this.router.navigate(['/login']);
      }, err => {
        this.removeTokens();
        this.router.navigate(['/login']);
      })
  }

  retrieveInvitation(nonce: string) {
    return firstValueFrom(this.http.get<void>(`${environment.backend}/auth/invitation/${nonce}`));
  }

  resendInvitation(email: string) {
    return firstValueFrom(this.http.post<void>(`${environment.backend}/auth/invitation/resend`, { email }));
  }

  confirmInvitation(nonce: string, password: string) {
    return this.http
      .post<{ user: User, accessToken: string, refreshToken: string }>(
        `${environment.backend}/auth/invitation/${nonce}`,
        {
          password
        }
      ).pipe(
        tap((res) => {
          this.userSubject.next(res.user);
          localStorage.setItem('uzai-user', JSON.stringify(res.user));
          localStorage.setItem('uzai-access-token', res.accessToken);
          localStorage.setItem('uzai-refresh-token', res.refreshToken);
        }),
        map((res) => {
          return res.user;
        }),
        catchError((error) => {
          this.removeTokens();
          return throwError(error.error);
        })
      );
  }
}
