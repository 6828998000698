import * as moment from "moment";
import { Moment } from "moment";
import { DocumentClass } from "./documentClass";

export enum UploadFileStatus {
  PENDING = "PENDING",
  UPLOADING = "UPLOADING",
  DONE = "DONE",
  ERROR = "ERROR",
}

export enum AnalysisMode {
  LIGHT = "LIGHT",
  FULL = 'FULL'
}

export class UploadFile extends File {
  documentId?: string;
  filename?: string;
  progress?: number;
  status?: UploadFileStatus;
  class: DocumentClass = DocumentClass.AUTOMATIC;
  analysis: AnalysisMode = AnalysisMode.LIGHT;
  confidential: boolean = false;
  territories?: string[] = [];
  niceClassification?: number[];
  langs?: string[];
  nonUse?: boolean;
  period?: {
    start?: Moment;
    end?: Moment;
  }
  edit: boolean = false;
  batch?: {
    ref: string,
    number: number
  };
}

export interface UploadAttachment extends File {
  name: string;
  description?: string;
  appendix?: boolean;
  document?: string;
  nonUse: boolean;
}
