import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { CaseOperatorConstraintDialogComponent } from 'src/app/dialogs/case-operator-constraint-dialog/case-operator-constraint-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CountryOperatorConstraintDialogComponent } from 'src/app/dialogs/country-operator-constraint-dialog/country-operator-constraint-dialog.component';
import { InviteUserDialogComponent } from 'src/app/dialogs/invite-user-dialog/invite-user-dialog.component';
import { Organization } from 'src/app/models/organization';
import { Paginator } from 'src/app/models/paginator';
import { Role, User } from 'src/app/models/user';
import { Scope } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'src/app/services/message.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-organization-iam',
  templateUrl: './organization-iam.component.html',
  styleUrls: ['./organization-iam.component.scss']
})
export class OrganizationIamComponent implements OnInit {
  @Input() organization?: Organization;

  loading: boolean = false;
  noDocs: boolean = false;
  done: boolean = false;

  displayedColumns: string[] = ['avatar', 'lastName', 'firstName', 'email', 'profile', 'createdAt', 'actions'];

  users: Paginator<User> = {
    page: 1,
    limit: 10,
    offset: 0,
    sort: 'lastName',
    docs: [],
    totalDocs: 0
  }

  public filters: { query?: string } = {
    query: undefined
  }

  private readonly filtersSubject = new Subject<{ query?: string }>();
  private filtersSubscription?: Subscription;

  user?: User;


  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }


  async ngOnInit() {
    this.loading = true;
    this.done = false;
    await this.retrieveUsers(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveUsers(false);
      });
  }


  async retrieveUsers(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.users = { ...this.users, ...await this.organizationService.retrieveAllUsers(this.users.page, this.users.limit, this.users.offset, this.users.sort, this.filters.query) };

      if (init && this.users.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.users.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.users.limit = 10;
    this.users.page = 1;
    this.users.offset = 0;
  }

  async handlePage(event: any) {
    this.users.limit = event.pageSize;
    this.users.page = event.pageIndex + 1;
    this.users.offset = event.pageIndex * this.users.limit;

    this.retrieveUsers(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.users.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveUsers(false);
    }
  }

  async invite() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        organization: this.organization
      }
    }
    const dialog = this.dialog.open(InviteUserDialogComponent, config);
    const result: { invited: boolean, user: User } = await firstValueFrom(dialog.afterClosed());
    if (result && result.invited && result.user) {
      try {
        this.edit(result.user);
        this.resetPage();
        await this.retrieveUsers(false);
      } catch (err) {
        this.loading = false;
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async editCountryOperatorConstraint(user: User) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        user
      }
    }
    const dialog = this.dialog.open(CountryOperatorConstraintDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed) {
      try {
        this.resetPage();
        await this.retrieveUsers(false);
      } catch (err) {
        this.loading = false;
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async editCaseOperatorConstraint(user: User) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        user
      }
    }
    const dialog = this.dialog.open(CaseOperatorConstraintDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed) {
      try {
        this.resetPage();
        await this.retrieveUsers(false);
      } catch (err) {
        this.loading = false;
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  canEdit(user: User) {
    return user.role && this.user && this.user.role && [Role.CASE_OPERATOR.toLowerCase(), Role.COUNTRY_OPERATOR.toLowerCase()].includes(user.role.name.toLowerCase()) && user.role.level < this.user?.role?.level;
  }

  async edit(user: User) {
    if (user && user.role && user.role.name.toLowerCase() === Role.CASE_OPERATOR.toLowerCase()) {
      await this.editCaseOperatorConstraint(user)
    } else if (user && user.role && user.role.name.toLowerCase() === Role.COUNTRY_OPERATOR.toLowerCase()) {
      await this.editCountryOperatorConstraint(user)
    }
  }

  editTooltip(user: User) {
    if (user && user.role && user.role.name.toLowerCase() === Role.CASE_OPERATOR.toLowerCase()) {
      return this.translate.instant('CASE_OPERATOR_CONSTRAINT_DIALOG.EDIT_CONSTRAINT');
    } else if (user && user.role && user.role.name.toLowerCase() === Role.COUNTRY_OPERATOR.toLowerCase()) {
      return this.translate.instant('COUNTRY_OPERATOR_CONSTRAINT_DIALOG.EDIT_CONSTRAINT');
    }
  }

  canDelete(user: User) {
    return user.role && this.user && this.user.role && user.role.level < this.user?.role?.level;
  }

  async delete(user: User) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'ORGANIZATION.REMOVE_USER_TITLE',
        text: this.translate.instant('ORGANIZATION.REMOVE_USER_TEXT', user),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'ORGANIZATION.REMOVE_USER_CONFIRMATION',
          value: user?.lastName
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && user && user._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.organizationService.removeFromOrganization(user._id);
        this.resetPage();
        await this.retrieveUsers(false);
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('ORGANIZATION.REMOVE_USER_SUCCESS', user));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }


}
