<div class="fx-row align-items-center justify-content-between">
  <div class="fx-row fx-wrap fx-flex-100 ">
    <div class="fx-row fx-flex-100 align-items-center justify-content-start">
      <app-back></app-back>
    </div>
    <div class="fx-row fx-flex-100 align-items-center justify-content-between">
      <div class="fx-column fx-flex-100">
        <span class="page-title">{{'TRADEMARK_DECLARE.TITLE' | translate}}</span>
        <span class="page-subtitle">{{'TRADEMARK_DECLARE.SUBTITLE' | translate}}</span>
      </div>
      <button class="main-button" (click)="declare()" [disabled]="!canDeclare()" [class.disabled]="!canDeclare()"
        aclBasedElement resource="TRADEMARK" [scopes]="['C']">
        {{'ACTIONS.DECLARE' | translate}}
      </button>
    </div>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<div class="fx-row align-items-stretch justify-content-start">
  <div class="fx-flex pr-8">
    <mat-card class="mode-card clickable h-100" (click)="setMode('SEARCH')" [class.selected]="mode == 'SEARCH'">
      <div class="fx-row align-items-center justify-content-between">
        <span class="text bold dark">{{"TRADEMARK_DECLARE.MODE_SEARCH" | translate}}</span>
        <mat-icon [class]="mode === 'SEARCH' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
          'SEARCH' ?
          'check_circle' : 'radio_button_unchecked'}}</mat-icon>
      </div>
      <span class="text">
        {{'TRADEMARK_DECLARE.MODE_SEARCH_HINT' | translate}}</span>
    </mat-card>
  </div>
  <div class="fx-flex ph-8">
    <mat-card class="mode-card clickable h-100" (click)="setMode('IMPORT')" [class.selected]="mode == 'IMPORT'">
      <div class="fx-row align-items-center justify-content-between">
        <span class="text bold dark">{{"TRADEMARK_DECLARE.MODE_IMPORT" | translate}}</span>
        <mat-icon [class]="mode === 'IMPORT' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
          'IMPORT' ?
          'check_circle' : 'radio_button_unchecked'}}</mat-icon>
      </div>
      <span class="text">
        {{'TRADEMARK_DECLARE.MODE_IMPORT_HINT' | translate}}</span>
    </mat-card>
  </div>
  <div class="fx-flex pl-8">
    <mat-card class="mode-card clickable h-100" (click)="setMode('CREATE')" [class.selected]="mode == 'CREATE'">
      <div class="fx-row align-items-center justify-content-between">
        <span class="text bold dark">{{"TRADEMARK_DECLARE.MODE_CREATE" | translate}}</span>
        <mat-icon [class]="mode === 'CREATE' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
          'CREATE' ?
          'check_circle' : 'radio_button_unchecked'}}</mat-icon>
      </div>
      <span class="text">
        {{'TRADEMARK_DECLARE.MODE_CREATE_HINT' | translate}}</span>
    </mat-card>
  </div>
</div>
<div class="mv-16">
  <app-trademark-declare-single *ngIf="mode === 'SEARCH'" #declareSingle></app-trademark-declare-single>
  <app-trademark-declare-import *ngIf="mode === 'IMPORT'" #declareImport></app-trademark-declare-import>
  <app-trademark-content [trademark]="trademark" [mode]="'EDITION'" (reproductionChange)="onReproductionChange($event)"
    *ngIf="mode === 'CREATE'"></app-trademark-content>
</div>