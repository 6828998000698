<div class="fx-column">
  <span class="label" *ngIf="labelOutside">{{label}}</span>
  <div class="button" [class.active]="selection !== undefined" [class]="{'error':error && error.value}"
    [cdkMenuTriggerFor]="menu" #trigger="cdkMenuTriggerFor" [class.disabled]="disabled" (cdkMenuOpened)="populate()">
    <div class="fx-row fx-flex-100 align-items-center justify-content-between label-container">
      <span class="text main left fx-flex" *ngIf="selection">{{selection}}</span>
      <span class="text left fx-flex" *ngIf="!selection">{{label}}</span>
      <mat-icon class="dark ml-5" fontSet="material-icons-outlined" *ngIf="!selection">expand_more</mat-icon>
      <mat-icon class="main ml-5" fontSet="material-icons-outlined" *ngIf="selection"
        (click)="$event.stopPropagation();onClear()">clear</mat-icon>
    </div>
  </div>
  <div class="bottom-wrapper fx-row align-items-center justify-content-start"
    *ngIf="bottomWrapper || (error && error.value && error.message)">
    <span class="small red" *ngIf="error && error.value && error.message">
      {{error.message}}
    </span>
  </div>
  <ng-template #menu>
    <mat-card class="menu-container p-0 elevation fx-flex-100" cdkMenu>
      <div class="fx-column" (click)="$event.stopPropagation()">
        <div>
          <div class="p-16">
            <div class="fx-row align-items-center justify-content-between mb-5">
              <span class="text bold" *ngIf="label">{{label | translate}}</span>
              <span class="small clickable" (click)="onClear()">{{'ACTIONS.CLEAR' | translate}}</span>
            </div>
            <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" class="mt-10"
              *ngIf="search" [(value)]="query" (change)="onQueryChange($event)" [autoFocus]="true"></app-input>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="options-container pv-16" *ngIf="multiple" [class.search]="search">
          <div class="fx-column" *ngIf="search && selectedToDisplay().length > 0">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple actionable"
              *ngFor="let option of selectedToDisplay(); let index = index" (click)="removeFromPreselection(option)">
              <mat-checkbox color="primary" [checked]="option.selected">
              </mat-checkbox>
              <span class="text ml-5 option-label">{{option.label | translate}}</span>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple"
              *ngFor="let option of filtered(); let index = index" (click)="select(option)" [class.actionable]="search">
              <mat-checkbox color="primary" [checked]="option.selected">
              </mat-checkbox>
              <span class="text ml-5 option-label">{{option.label | translate}}</span>
            </div>
          </div>
        </div>
        <div class="options-container" *ngIf="!multiple" [class.search]="search">
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option actionable p-16"
              *ngFor="let option of filtered(); let index = index" (click)="select(option)"
              [class.selected]="option.selected">
              <span class="text">{{option.label | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-template>
</div>