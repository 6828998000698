<div class="fx-column">
  <div class="graph-territory-container" [style.height.px]="height">
    <div class="fx-row align-items-center justify-content-start w-100">
      <div class="fx-row fx-wrap fx-flex-20 pr-16" *ngIf="!error && !noData">
        <span class="text mb-10 fx-flex-100">{{('DOCUMENTS_REPORT.TOP_COUNTRIES' | translate)}}</span>
        <div class="fx-row align-items-center justify-content-start fx-flex-100 mb-8" *ngFor="let top of top5">
          <img [src]="'/assets/countries/'+top.id.toLowerCase()+'.svg'" class="country-flag" />
          <div class="fx-column ml-10 fx-flex">
            <div class="fx-row align-items-center justify-content-between">
              <span class="small bold">{{('TERRITORIES.'+top.id.toUpperCase() | translate)}}</span>
              <span class="small light ml-8">{{top.percent}}%</span>
            </div>
            <app-progress-bar [mode]="'determinate'" [value]="top.percent" [loading]="true"
              [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="top.value + ' '+ ('ENTITIES.DOCUMENT_S'|translate).toLowerCase()"></app-progress-bar>
          </div>
        </div>
      </div>
      <div id="graphterritorydiv" #graphterritorydiv class="graph-territory fx-flex-80" [style.height.px]="height"
        *ngIf="!error && !noData">
      </div>
    </div>
    <!-- <mat-progress-spinner class="main-spinner" mode="indeterminate" diameter="25" *ngIf="loading && !error && !noData">
    </mat-progress-spinner> -->
    <div class="fx-column align-items-center justify-content-center" *ngIf="error">
      <mat-icon class="red mb-5" fontSet="material-icons-outlined">error</mat-icon>
      <span class="text light">{{'DOCUMENTS_REPORT.ERROR' | translate}}</span>
    </div>
    <div class="fx-column align-items-center justify-content-center" *ngIf="!loading && noData">
      <span class="text light">{{'DOCUMENTS_LIST.SEARCH_NO_DOCUMENTS' | translate}}</span>
    </div>
  </div>
</div>