import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Case, CaseAttachment } from 'src/app/models/case';
import { UploadAttachment } from 'src/app/models/uploadFile';
import { CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-upload-case-attachment-dialog',
  templateUrl: './upload-case-attachment-dialog.component.html',
  styleUrls: ['./upload-case-attachment-dialog.component.scss']
})
export class UploadCaseAttachmentDialogComponent {

  mode: "create" | "edit" = "create";
  attachment?: UploadAttachment;

  case?: Case;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { case: Case, attachment: UploadAttachment, mode: "create" | "edit" },
    private dialogRef: MatDialogRef<UploadCaseAttachmentDialogComponent>,
    private documentService: DocumentService
  ) {
  }

  ngOnInit(): void {
    this.case = this.data.case;
    this.mode = this.data.mode;
    this.attachment = this.data.attachment;
  }

  ngOnDestroy(): void {
  }

  isDisabled() {
    return !this.attachment || !this.attachment.name || !this.case;
  }


  icon() {
    return this.documentService.icon(this.attachment)
  }

  size() {
    return this.documentService.size(this.attachment)
  }

  onCancel(): void {
    this.dialogRef.close({
      uploaded: false
    });
  }

  async onUpload() {
    this.dialogRef.close({
      uploaded: true,
      updated: false,
      attachment: this.attachment
    });
  }

  async onUpdate() {
    this.dialogRef.close({
      uploaded: true,
      updated: true,
      attachment: this.attachment
    });
  }
}
