<div class="p-20 fx-row fx-wrap align-items-center justify-content-center">
  <div class="fx-flex-100 align-items-center justify-content-start">
    <span class="title mb-10">{{'CASE.LINK_NON_USE_PROOF' | translate}}</span>
  </div>
  <div class="mt-20 fx-row fx-wrap fx-flex-100">
    <span class="text fx-flex-100"
      [innerHTML]="'CASE.LINK_NON_USE_PROOF_DESCRIPTION' | translate: {trademark:trademarkName}"></span>
    <span class="text bold fx-flex-100 mt-10" [innerHTML]="'CASE.LINK_NON_USE_PROOF_UPLOAD' | translate"></span>
    <app-select label="{{'CASE.NON_USE_PROOF' | translate}}" [options]="documentOptions" [multiple]="false"
      (change)="onDocumentChange($event)" [labelOutside]="false" class="fx-flex-100 mt-10"></app-select>
    <div class="fx-row align-items-start justify-content-start fx-flex-100 mt-10" *ngIf="document">
      <div class="fx-column fx-flex-50">
        <span class="label">{{'ENTITIES.TRADEMARK' | translate}}</span>
        <span class="text">{{document.trademarks[0].name || document.trademarks[0].identifierNumber}}</span>
      </div>
      <div class="fx-column fx-flex-50">
        <span class="label">{{'ENTITIES.PRODUCTS_SERVICES' | translate}}</span>
        <app-trademark-nice-classification [niceClassification]="document.niceClassification"
          *ngIf="document && document.niceClassification && document.niceClassification.length > 0"></app-trademark-nice-classification>
      </div>
    </div>
    <div class="fx-row align-items-start justify-content-start fx-flex-100 mt-10" *ngIf="document">
      <div class="fx-column fx-flex-50">
        <span class="label">{{'ENTITIES.TERRITORIES' | translate}}</span>
        <span class="text">{{formatTerritories(document.territories)}}</span>
      </div>
      <div class="fx-column fx-flex-50">
        <span class="label">{{'ENTITIES.PERIOD' | translate}}</span>
        <span class="text">{{formatPeriod(document)}}</span>
      </div>
    </div>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onConfirm()" [disabled]="!canConfirm()"
      [class.disabled]="!canConfirm()">{{'ACTIONS.CONFIRM'
      | translate}}</button>
  </div>
</div>