import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TrademarkDeclareSingleComponent } from './trademark-declare-single/trademark-declare-single.component';
import { TrademarkDeclareImportComponent } from './trademark-declare-import/trademark-declare-import.component';
import { MessageService } from 'src/app/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExternalCreateTrademarkResult, TrademarkService } from 'src/app/services/trademark.service';
import { Trademark, TrademarkSearch } from 'src/app/models/trademark';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TrademarkExternalCreateDialogComponent } from 'src/app/dialogs/trademark-external-create-dialog/trademark-external-create-dialog.component';

@Component({
  selector: 'app-trademark-declare',
  templateUrl: './trademark-declare.component.html',
  styleUrls: ['./trademark-declare.component.scss']
})
export class TrademarkDeclareComponent implements OnInit {

  mode: "SEARCH" | "IMPORT" | "CREATE" = "SEARCH";

  @ViewChild("declareSingle") declareSingle?: TrademarkDeclareSingleComponent;
  @ViewChild("declareImport") declareImport?: TrademarkDeclareImportComponent;

  private loading: boolean = false;

  trademark: Trademark = {
    _id: undefined,
    identifierNumber: '',
    registrationNumber: undefined,
    applicationNumber: undefined,
    countryOfDesignation: '',
    kind: undefined,
    type: undefined,
    niceClassification: [],
    applicationDate: undefined,
    publicationDate: undefined,
    registrationDate: undefined,
    expirationDate: undefined,
    reproductionURI: undefined,
    name: undefined,
    organizationId: undefined
  };

  reproduction?: File;

  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

  back() {
    this.router.navigate([".."], { relativeTo: this.route })
  }

  setMode(mode: "SEARCH" | "IMPORT" | "CREATE") {
    this.mode = mode;
  }

  onReproductionChange(reproduction: any) {
    this.reproduction = reproduction;
    if (!this.reproduction && this.trademark) {
      this.trademark.reproductionURI = undefined;
    }
  }

  canDeclare() {
    return !this.loading && ((this.mode === 'SEARCH' && this.declareSingle && this.declareSingle.selection.selected.length > 0)
      || (this.mode === 'IMPORT' && this.declareImport && this.declareImport.selection.selected.length > 0))
      || (this.mode === 'CREATE' && this.isTrademarkValid());
  }

  isTrademarkValid() {
    return this.trademark.niceClassification && this.trademark.niceClassification.length > 0
      && (this.trademark.registrationNumber || this.trademark.applicationNumber) && this.trademark.countryOfDesignation && this.trademark.propertyOffice && this.trademark.type
  }

  async declare() {
    if (['SEARCH', 'IMPORT'].includes(this.mode)) {
      let trademarks: TrademarkSearch[] = [];
      if (this.mode === 'SEARCH' && this.declareSingle && this.declareSingle.selection.selected.length > 0) {
        trademarks = this.declareSingle.selection.selected;
      } else if (this.mode === 'IMPORT' && this.declareImport && this.declareImport.selection.selected.length > 0) {
        trademarks = this.declareImport.selection.selected;
      }
      if (trademarks.length > 0) {
        try {
          this.loading = true;
          this._message.emitChange("LOADING", "START");

          const declared = await this.trademarkService.createExternal(trademarks);
          this.loading = false;
          this._message.emitChange("LOADING", "END");
          const hasErrors = declared.errors && declared.errors.length > 0;
          if (declared && declared.errors.length > 0) {
            const message = trademarks.length === 1 ? this.translate.instant('TRADEMARK_DECLARE.TRADEMARK_DECLARED_WITH_ERROR') : this.translate.instant('TRADEMARK_DECLARE.TRADEMARK_DECLARED_WITH_ERRORS', { count: declared.errors.length })
            const toast = this.toastr.error(message, undefined, {
              disableTimeOut: true
            });
            toast.onTap.subscribe((res) => {
              this.declarationReport(declared);
            })
          } else {
            const message = trademarks.length === 1 ? this.translate.instant('TRADEMARK_DECLARE.TRADEMARK_DECLARED', { name: declared.declared[0].trademark.name || declared.declared[0].trademark.identifierNumber }) : this.translate.instant('TRADEMARK_DECLARE.TRADEMARKS_DECLARED', { count: declared.declared.length })
            const toast = this.toastr.success(message);
            toast.onTap.subscribe((res) => {
              this.declarationReport(declared);
            })
            this.location.back();
          }

        } catch (err: any) {
          this.loading = false;
          this._message.emitChange("LOADING", "END");
          this.toastr.error(`ERRORS.GENERIC`);
        }
      }
    } else {
      if (this.isTrademarkValid()) {
        try {
          this.loading = true;
          this._message.emitChange("LOADING", "START");
          if (this.trademark.registrationNumber !== undefined || this.trademark.applicationNumber !== undefined)
            this.trademark.identifierNumber = this.trademark.registrationNumber !== undefined ? this.trademark.registrationNumber : this.trademark.applicationNumber!;
          if (this.reproduction) {
            const upload = await this.trademarkService.uploadReproduction(this.reproduction);
            this.trademark.reproductionURI = upload.url;
          }
          await this.trademarkService.create(this.trademark);
          this.loading = false;
          this._message.emitChange("LOADING", "END");
          this.toastr.success(this.translate.instant('TRADEMARK_DECLARE.TRADEMARK_DECLARED', { name: this.trademark.name || this.trademark.identifierNumber }));
          this.location.back();
        } catch (err: any) {
          this.loading = false;
          this._message.emitChange("LOADING", "END");
          this.toastr.error(`ERRORS.GENERIC`);
        }
      }

    }
  }

  declarationReport(results: { declared: ExternalCreateTrademarkResult[], errors: ExternalCreateTrademarkResult[] }) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '600px',
      maxHeight: '600px',
      autoFocus: false,
      data: {
        results
      }
    }
    const dialog = this.dialog.open(TrademarkExternalCreateDialogComponent, config);
  }


}
