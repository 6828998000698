import { Injectable } from '@angular/core';
import { User } from '../models/user';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, firstValueFrom, map, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private authService: AuthenticationService,
    private http: HttpClient
  ) { }

  me(): Promise<User> {
    return firstValueFrom(this.http
      .get<User>(`${environment.backend}/users/me`));
  }

  uploadAvatar(avatar: File): Promise<{ url: string }> {
    const formData = new FormData();
    formData.append("image", avatar);
    return firstValueFrom(this.http.post<{ url: string }>(`${environment.backend}/upload/userAvatar`, formData));
  }

  update(user: User) {
    let body: any = { ...user };
    Object.keys(body).forEach(key => {
      if (!body[key]) body[key] = null;
    });
    delete body.email;
    delete body.organizationId;
    return this.http
      .put<User>(`${environment.backend}/users/me`, body).pipe(
        tap((res) => {
          this.authService.setUser(res)
        }),
        map((res) => {
          return res;
        }),
        catchError((error) => {
          return throwError(error.error);
        })
      );
  }

  addOperatorConstraint(userId: string, constraint: string): Observable<User> {
    return this.http.put<User>(`${environment.backend}/users/${userId}/constraints/${constraint}`, {});
  }

  deleteOperatorConstraint(userId: string, constraint: string): Observable<User> {

    console.log(`${environment.backend}/users/${userId}/constraints/${constraint}`)
    return this.http.delete<User>(`${environment.backend}/users/${userId}/constraints/${constraint}`, {});
  }

}
