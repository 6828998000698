import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterService } from 'src/app/services/router.service';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss']
})
export class BackComponent {
  @Input() action?: Function;
  @Input() disabled: boolean = false;

  constructor(
    private location: Location,
    private routerService: RouterService
  ) {

  }

  back() {
    if (!this.action) {
      this.location.back();
    } else {
      this.action();
    }
  }
}
