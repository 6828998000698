import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Document } from 'src/app/models/document';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-edit-document-lang-dialog',
  templateUrl: './edit-document-lang-dialog.component.html',
  styleUrls: ['./edit-document-lang-dialog.component.scss']
})
export class EditDocumentLangDialogComponent {
  document?: Document;
  langs: { selected: boolean, value: string, label: string }[] = [
    { value: "ar", selected: false, label: "LANGUAGES.ar" },
    { value: "bg", selected: false, label: "LANGUAGES.bg" },
    { value: "bs", selected: false, label: "LANGUAGES.bs" },
    { value: "cs", selected: false, label: "LANGUAGES.cs" },
    { value: "da", selected: false, label: "LANGUAGES.da" },
    { value: "de", selected: false, label: "LANGUAGES.de" },
    { value: "el", selected: false, label: "LANGUAGES.el" },
    { value: "en", selected: false, label: "LANGUAGES.en" },
    { value: "es", selected: false, label: "LANGUAGES.es" },
    { value: "et", selected: false, label: "LANGUAGES.et" },
    { value: "fi", selected: false, label: "LANGUAGES.fi" },
    { value: "fr", selected: false, label: "LANGUAGES.fr" },
    { value: "hr", selected: false, label: "LANGUAGES.hr" },
    { value: "hu", selected: false, label: "LANGUAGES.hu" },
    { value: "is", selected: false, label: "LANGUAGES.is" },
    { value: "it", selected: false, label: "LANGUAGES.it" },
    { value: "ja", selected: false, label: "LANGUAGES.ja" },
    { value: "ka", selected: false, label: "LANGUAGES.ka" },
    { value: "ko", selected: false, label: "LANGUAGES.ko" },
    { value: "lt", selected: false, label: "LANGUAGES.lt" },
    { value: "lv", selected: false, label: "LANGUAGES.lv" },
    { value: "me", selected: false, label: "LANGUAGES.me" },
    { value: "mk", selected: false, label: "LANGUAGES.mk" },
    { value: "mt", selected: false, label: "LANGUAGES.mt" },
    { value: "nl", selected: false, label: "LANGUAGES.nl" },
    { value: "no", selected: false, label: "LANGUAGES.no" },
    { value: "pl", selected: false, label: "LANGUAGES.pl" },
    { value: "pt", selected: false, label: "LANGUAGES.pt" },
    { value: "ro", selected: false, label: "LANGUAGES.ro" },
    { value: "ru", selected: false, label: "LANGUAGES.ru" },
    { value: "sk", selected: false, label: "LANGUAGES.sk" },
    { value: "sl", selected: false, label: "LANGUAGES.sl" },
    { value: "sq", selected: false, label: "LANGUAGES.sq" },
    { value: "sr", selected: false, label: "LANGUAGES.sr" },
    { value: "sv", selected: false, label: "LANGUAGES.sv" },
    { value: "tr", selected: false, label: "LANGUAGES.tr" },
    { value: "zh", selected: false, label: "LANGUAGES.zh" }
  ];
  lang?: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { lang: string, document: Document },
    private dialogRef: MatDialogRef<EditDocumentLangDialogComponent>,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.document = this.data.document;
    this.lang = this.data && this.data.lang ? this.data.lang : undefined;
    this.langs.sort((a, b) => this.translate.instant(a.label).localeCompare(this.translate.instant(b.label)))
  }

  ngOnDestroy(): void {
  }



  onCancel(): void {
    this.dialogRef.close({
      edited: false
    });
  }

  async onConfirm() {
    this.dialogRef.close({
      edited: true,
      lang: this.lang
    });
  }
}
