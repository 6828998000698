<div class="fx-column">
  <span class="label" *ngIf="labelOutside">{{label}}</span>
  <div class="button" [class.active]="selection !== undefined" style="max-width:100%;"
    [cdkMenuTriggerFor]="disabled ? null : menu" #trigger="cdkMenuTriggerFor" [class.disabled]="disabled"
    (cdkMenuOpened)="populate()">
    <div class="fx-row fx-flex-100 align-items-center justify-content-between label-container">
      <span class="text main left fx-flex" *ngIf="selection">{{selection}}</span>
      <div class="text left fx-flex" *ngIf="!selection">{{label}}</div>
      <mat-icon class="dark ml-5" fontSet="material-icons-outlined" *ngIf="!selection">expand_more</mat-icon>
      <mat-icon class="main ml-5" fontSet="material-icons-outlined" *ngIf="selection"
        (click)="$event.stopPropagation();onClear()">clear</mat-icon>
    </div>
  </div>
  <ng-template #menu>
    <mat-card class="menu-container p-0 elevation" cdkMenu>
      <div class="fx-column" (click)="$event.stopPropagation()">
        <div>
          <div class="p-16">
            <div class="fx-row align-items-center justify-content-between mb-5">
              <span class="text bold" *ngIf="label">{{label | translate}}</span>
              <span class="small clickable" (click)="onClear()" *ngIf="multiple">{{'ACTIONS.CLEAR' | translate}}</span>
            </div>
            <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" class="mt-10"
              *ngIf="search" [(value)]="query" (change)="onQueryChange($event)" [autoFocus]="true"></app-input>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="options-container pv-16" *ngIf="multiple" [scrollWindow]="false" [class.search]="search"
          infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
          <div class="fx-column" *ngIf="selectedToDisplay().length > 0">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple actionable"
              *ngFor="let trademark of selectedToDisplay(); let index = index"
              (click)="removeFromPreselection(trademark)">
              <mat-checkbox color="primary" [checked]="trademark.selected">
              </mat-checkbox>
              <div class="fx-column align-items-start justify-content-start ml-5 option-label" *ngIf="trademark.name">
                <span class="text option-label">{{trademark?.name}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}} - {{trademark?.identifierNumber}}</span>
              </div>
              <div class="fx-column align-items-start justify-content-start ml-5 option-label" *ngIf="!trademark.name">
                <span class="text option-label">{{trademark?.identifierNumber}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}}</span>
              </div>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple"
              *ngFor="let trademark of filtered(); let index = index"
              (click)="trademark.disabled ? null : select(trademark)" [class.disabled]="trademark.disabled"
              [class.actionable]="search">
              <mat-checkbox color="primary" [checked]="trademark.selected">
              </mat-checkbox>
              <div class="fx-column align-items-start justify-content-start ml-5" *ngIf="trademark.name">
                <span class="text option-label">{{trademark?.name}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}} - {{trademark?.identifierNumber}}</span>
              </div>
              <div class="fx-column align-items-start justify-content-start ml-5" *ngIf="!trademark.name">
                <span class="text option-label">{{trademark?.identifierNumber}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}}</span>
              </div>
            </div>
          </div>
          <div class="ph-16" *ngIf="filtered().length === 0">
            <span class="light text">{{'PAGINATOR.GENERIC_SEARCH_NO_RESULTS' | translate}}</span>
          </div>
        </div>
        <div class="options-container" *ngIf="!multiple" [scrollWindow]="false" [class.search]="search" infiniteScroll
          [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option actionable p-8"
              *ngFor="let trademark of filtered(); let index = index" [class.selected]="trademark.selected"
              (click)="trademark.disabled ? null : select(trademark)" [class.disabled]="trademark.disabled">
              <div class="fx-column align-items-start justify-content-start ml-5" *ngIf="trademark.name">
                <span class="text option-label">{{trademark?.name}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}} - {{trademark?.identifierNumber}}</span>
              </div>
              <div class="fx-column align-items-start justify-content-start ml-5" *ngIf="!trademark.name">
                <span class="text option-label">{{trademark?.identifierNumber}}</span>
                <span class="small light">{{trademark?.countryOfDesignation}}</span>
              </div>
            </div>
          </div>
          <div class="p-16" *ngIf="filtered().length === 0">
            <span class="light text">{{'PAGINATOR.GENERIC_SEARCH_NO_RESULTS' | translate}}</span>
          </div>
        </div>
      </div>
      <app-progress-bar [mode]="'indeterminate'" [loading]="loading"></app-progress-bar>
    </mat-card>
  </ng-template>
</div>