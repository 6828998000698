import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Role, User } from '../models/user';
import * as _ from 'lodash';


export type Resource = 'USER' | 'ORGANIZATION' | 'DOCUMENT' | 'TRADEMARK' | 'CASE' | 'BATCH' | 'BATCH_UPLOAD' | 'CONTROL' | 'SUPERVISING';
export enum Scope {
  C = 'C',
  R = 'R',
  U = 'U',
  D = 'D',
  S = 'S'
}
export type ConstraintIdentifier = 'COUNTRY' | 'CASE' | '*' | 'MODE';

@Injectable({
  providedIn: 'root'
})
export class AclService {

  user?: User;

  aceRegex = /^([A-Z_]+)(\[([^:]*)?:?([A-Z]+)?\])?:([C|R|U|D|S]),?([C|R|U|D|S])?,?([C|R|U|D|S])?,?([C|R|U|D|S])?,?([C|R|U|D|S])?$/;

  acls: { [resource: string]: Scope[] } = {};
  constraints: { [identifier: string]: string[] } = {};

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user.subscribe(user => {
      this.user = user;
      if (this.user && this.user.acls) {
        this.user.acls.map(this.deserializeAce.bind(this));
      }
    })
  }

  isCountryOperator() {
    return this.user?.role?.name.toLowerCase() === Role.COUNTRY_OPERATOR.toLowerCase();
  }
  isCaseOperator() {
    return this.user?.role?.name.toLowerCase() === Role.CASE_OPERATOR.toLowerCase();
  }
  isManager() {
    return this.user?.role?.name.toLowerCase() === Role.MANAGER.toLowerCase();
  }
  isAdmin() {
    return this.user?.role?.name.toLowerCase() === Role.ADMIN.toLowerCase();
  }
  isSuperAdmin() {
    return this.user?.role?.name.toLowerCase() === Role.SUPERADMIN.toLowerCase();
  }
  role() {
    return this.user?.role?.name ?? undefined;
  }
  level() {
    return this.user?.role?.level ?? undefined;
  }
  isManagerOrAbove() {
    return this.user && this.user.role && this.user.role.level ? this.user?.role?.level >= 50 : false;
  }
  countries() {
    if (this.isCountryOperator()) {
      return this.constraints['COUNTRY'];
    }
    return [];
  }
  cases() {
    if (this.isCaseOperator()) {
      return this.constraints['CASE'];
    }
    return [];
  }



  deserializeAce(ace: string) {
    if (this.aceRegex.test(ace)) {
      const [fullAce, resource, constraint, constraintValues, constraintIdentifier, scope1, scope2, scope3, scope4, scope5]: RegExpExecArray = this.aceRegex.exec(ace)!;
      this.acls[resource] = [scope1 as Scope, scope2 as Scope, scope3 as Scope, scope4 as Scope, scope5 as Scope].filter((scope) => !!scope);
      if (constraint) {
        const values = constraintValues?.split('|');
        if (!this.constraints[constraintIdentifier]) {
          this.constraints[constraintIdentifier] = values;
        } else {
          this.constraints[constraintIdentifier] = _.uniq(_.union(this.constraints[constraintIdentifier], values))
        }
      }
    }
  }

  getCountriesConstraints(acls: string[]) {
    const countries: string[] = [];
    acls.forEach(ace => {
      if (this.aceRegex.test(ace)) {
        const [fullAce, resource, constraint, constraintValues, constraintIdentifier, scope1, scope2, scope3, scope4, scope5]: RegExpExecArray = this.aceRegex.exec(ace)!;
        this.acls[resource] = [scope1 as Scope, scope2 as Scope, scope3 as Scope, scope4 as Scope, scope5 as Scope].filter((scope) => !!scope);
        if (constraint && constraintIdentifier === 'COUNTRY') {
          const values = constraintValues?.split('|');
          if (values && values.length > 0) {
            values.forEach(country => {
              if (!countries.includes(country)) {
                countries.push(country)
              }
            })
          }
        }
      }
    })
    return countries;
  }

  getCasesConstraints(acls: string[]) {
    const cases: string[] = [];
    acls.forEach(ace => {
      if (this.aceRegex.test(ace)) {
        const [fullAce, resource, constraint, constraintValues, constraintIdentifier, scope1, scope2, scope3, scope4, scope5]: RegExpExecArray = this.aceRegex.exec(ace)!;
        this.acls[resource] = [scope1 as Scope, scope2 as Scope, scope3 as Scope, scope4 as Scope, scope5 as Scope].filter((scope) => !!scope);
        if (constraint && constraintIdentifier === 'CASE') {
          const values = constraintValues?.split('|');
          if (values && values.length > 0) {
            values.forEach(caseObject => {
              if (!cases.includes(caseObject)) {
                cases.push(caseObject)
              }
            })
          }
        }
      }
    })
    return cases;
  }

}
