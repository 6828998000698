import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { Subject, Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import * as moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent {

  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() type?: string = "text";
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() pattern?: string;
  @Input() class?: string;
  @Input() hint?: string;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;
  @Input() disabled: boolean = false;
  @Input() min?: Moment;
  @Input() max?: Moment;


  public focus: boolean = false;
  private _pattern?: RegExp;

  @Input() value?: Moment;
  @Output() valueChange = new EventEmitter<any>();
  valueUpdate: Subject<any> = new Subject<any>();

  @Output() change = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    if (this.pattern) {
      this._pattern = new RegExp(this.pattern);
    }
    this.valueUpdate
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        this.valueChange.emit(this.value);
        this.change.emit(this.value);
      })
  }


}
