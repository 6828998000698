import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() label?: string;
  @Input() class?: string;
  @Input() values?: {
    value: boolean;
    label: string;
  }[] = [];

  @Input() value: boolean = false;
  valueLabel?: string;

  @Output() change = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();

  constructor() {

  }

  ngOnInit(): void {
    console.log(this.values, this.value)
    this.valueLabel = this.values?.find(v => v.value === this.value)?.label;
  }

  onChange() {
    this.valueLabel = this.values?.find(v => v.value === this.value)?.label;
    this.change.emit({ value: this.value });
    this.valueChange.emit(this.value);
  }

}
