import { CdkMenu, CdkMenuBase, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription, concat, of } from 'rxjs';
import { NiceClass } from 'src/app/models/niceClass';
import { Trademark } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';
import { NiceService } from 'src/app/services/nice.service';

type NiceClassOption = NiceClass & { selected: boolean, disabled?: boolean, display?: boolean }
type NiceClassSelectedOption = { value: number, selected: boolean, display?: boolean }

@Component({
  selector: 'app-nice-select',
  templateUrl: './nice-select.component.html',
  styleUrls: ['./nice-select.component.scss']
})
export class NiceSelectComponent implements OnInit, OnChanges {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;

  @Input() disabled: boolean = false;
  @Input() disabledValues: number[] = [];
  @Input() emitOnInit: boolean = true;
  @Input() restrict: number[] = [];

  classes: NiceClassOption[] = [];
  selected: NiceClassSelectedOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: number | number[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private niceService: NiceService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['restrict'] && !_.isEqual(changes['restrict']['currentValue'], changes['restrict']['previousValue'])) {
      this.retrieveClasses();
    }
    if (changes['value'] && changes['value']['currentValue'] !== changes['value']['previousValue']) {
      this.setInitialState();
    }
  }

  _isDisabled(option: number) {
    return this.disabledValues.includes(option);
  }

  _isSelected(option: number) {
    return this.selected.map((t) => t.value).includes(option);
  }

  populate() {
    if (this.multiple && this.value && (this.value as number[]).length > 0) {
      const values = (this.value as number[]);
      this.selected = values.map((t) => ({ value: t, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as number);
      this.selected = [{ value, selected: true, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  isAllSelected() {
    return this.classes.every((niceClass) => niceClass.selected);
  }

  toggleAllSelection() {
    if (!this.isAllSelected()) {
      this.classes.forEach((niceClass) => {
        niceClass.selected = true;
      })
      this.selected = this.classes.map((c) => ({ value: c.classNumber, selected: true, display: false }));
    } else {
      this.classes.forEach((niceClass) => {
        niceClass.selected = false;
      })
      this.selected = [];
    }
    this.onChange();
  }

  async setInitialState() {
    await this.retrieveClasses();
    if (this.multiple && this.value && (this.value as number[]).length > 0) {
      const values = (this.value as number[]);
      this.selected = values.map((t) => ({ value: t, selected: true }));

      this.classes.forEach(niceClass => {
        if (values.includes(niceClass.classNumber)) {
          niceClass.selected = true;
        } else {
          niceClass.selected = false;
        }
      });
      const value = _.unionWith([...this.classes.filter(t => t.selected).map((t) => t.classNumber), ...this.selected.map((o) => o.value)]);
      if (value.length > 0) {
        this.selection = value.length === 1 ? (this.translate.instant(`NICE_CLASSES.CLASS_NUMBER`) + value[0]) : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as number);
      this.selected = [{ value, selected: true }];
      const option = this.classes.find(t => t.classNumber == value);
      if (option) {
        option.selected = true;
      }
      this.selection = value ? `${this.translate.instant(`NICE_CLASSES.CLASS_NUMBER`)}${value} - ${this.translate.instant(`NICE_CLASSES.${value}`)}` : undefined;
    } else {
      this.classes.forEach(niceClass => {
        niceClass.selected = false;
      });
      this.selection = undefined;
    }
    if (this.emitOnInit) {
      this.onChange();
    }
  }

  removeFromPreselection(option: NiceClassSelectedOption) {
    this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    const doc = this.classes.find((t) => t.classNumber === option.value);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: NiceClassOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option.classNumber === o.value), 1);
    } else if (!this._isSelected(option.classNumber)) {
      this.selected.push({ value: option.classNumber, selected: true, display: false });
    }
  }

  async retrieveClasses() {
    const classes = (await this.niceService.retrieve()).map(niceClass => ({ ...niceClass, selected: this._isSelected(niceClass.classNumber), disabled: this._isDisabled(niceClass.classNumber) }));
    if (this.restrict && this.restrict.length > 0) {
      this.classes = classes.filter((niceClass) => this.restrict.includes(niceClass.classNumber))
    } else {
      this.classes = classes;
    }
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.classes.forEach(c => {
        if (c.classNumber !== option.classNumber) {
          c.selected = false;
        }
      })
    }
    this.onChange();
  }

  onChange() {
    if (!this.multiple) {
      const value = this.classes.find(c => c.selected);
      this.selection = value ? `${this.translate.instant(`NICE_CLASSES.CLASS_NUMBER`)}${value.classNumber} - ${this.translate.instant(`NICE_CLASSES.${value.classNumber}`)}` : undefined;
      this.valueChange.emit(value?.classNumber);
      this.change.emit({ value });
      this.trigger?.close();
    } else {
      const values = _.unionWith([...this.classes.filter(c => c.selected).map(c => c.classNumber), ...this.selected.map((o) => o.value)]);
      if (values.length > 0) {
        this.selection = values.length === 1 ? (this.translate.instant(`NICE_CLASSES.CLASS_NUMBER`) + values[0]) : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values);
      this.change.emit({ value: this.classes.filter((t) => values.includes(t.classNumber)) });
    }
  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return (this._filter && this._filter.length > 0) ? this.classes.filter(niceClass => {
      return niceClass.classNumber.toString().includes(this._filter as string) || this.translate.instant(`NICE_CLASSES.${niceClass.classNumber}`).toLowerCase().includes(this._filter?.toLowerCase())
    }) : this.classes;
  }

  onSearch(event: any) {
    if (event && event.hasOwnProperty('value')) {
      this._filter = event.value;
    }
  }

  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.classes.forEach(niceClass => {
      niceClass.selected = false;
    });
    this.selected = [];
    this.selection = undefined
    this._filter = undefined;
    this.onChange();
    this.trigger?.close();
  }

  truncate(value: string) {
    if (value.length < 30) return value;
    return `${value.substring(0, 30)}...`
  }
}
