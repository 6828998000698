<div class="fx-column">
  <span class="label" *ngIf="labelOutside">{{label}}</span>
  <div class="button" [class.active]="selection !== undefined" [cdkMenuTriggerFor]="menu" #trigger="cdkMenuTriggerFor"
    [class.disabled]="disabled" [class.error]="error.value" (cdkMenuOpened)="populate()">
    <div class="fx-row fx-flex-100 align-items-center justify-content-between">
      <span class="text main left fx-flex" *ngIf="selection">{{selection}}</span>
      <span class="text" *ngIf="!selection">{{label}}</span>
      <mat-icon class="dark ml-5" fontSet="material-icons-outlined" *ngIf="!selection">expand_more</mat-icon>
      <mat-icon class="main ml-5" fontSet="material-icons-outlined" *ngIf="selection"
        (click)="$event.stopPropagation();onClear()">clear</mat-icon>
    </div>
  </div>
  <div class="fx-row justify-content-start align-items-center bottom-wrapper"
    *ngIf="bottomWrapper || (hint || (error.value && error.message))">
    <span class="small light" *ngIf="hint && !(error.value && error.message)">
      {{hint}}
    </span>
    <span class="small red" *ngIf="error.value && error.message">
      {{error.message}}
    </span>
  </div>
  <ng-template #menu>
    <mat-card class="menu-container p-0 elevation" cdkMenu>
      <div class="fx-column" (click)="$event.stopPropagation()">
        <div>
          <div class="p-16">
            <div class="fx-row align-items-center justify-content-between mb-5">
              <span class="text bold" *ngIf="label">{{label | translate}}</span>
              <span class="small clickable" (click)="onClear()" *ngIf="multiple">{{'ACTIONS.CLEAR' | translate}}</span>
            </div>
            <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" class="mt-10"
              *ngIf="search" [(value)]="_filter" [autoFocus]="true"></app-input>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div class="options-container pv-16" *ngIf="multiple" [class.search]="search"
          (click)="$event.stopPropagation()">
          <div class="fx-column" *ngIf="search && selectedToDisplay().length > 0">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple actionable"
              *ngFor="let propertyOffice of selectedToDisplay(); let index = index"
              (click)="removeFromPreselection(propertyOffice)">
              <mat-checkbox color="primary" [checked]="propertyOffice.selected">
              </mat-checkbox>
              <!-- <img [src]="propertyOffice.logo" class="office-logo mr-5" /> -->
              <span class="text option-label">{{propertyOffice.name}}</span>
            </div>
            <mat-divider></mat-divider>
          </div>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option-multiple"
              *ngFor="let propertyOffice of filtered(); let index = index" (click)="select(propertyOffice)"
              [class.actionable]="search">
              <mat-checkbox color="primary" [checked]="propertyOffice.selected">
              </mat-checkbox>
              <!-- <img [src]="propertyOffice.logo" class="office-logo mr-5" /> -->
              <span class="text option-label">{{propertyOffice.name}}</span>
            </div>
          </div>
          <div class="ph-16" *ngIf="filtered().length === 0">
            <span class="light text">{{'PAGINATOR.GENERIC_SEARCH_NO_RESULTS' | translate}}</span>
          </div>
        </div>
        <div class="options-container" *ngIf="!multiple" [class.search]="search">
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start clickable option actionable p-16"
              [class.selected]="propertyOffice.selected" *ngFor="let propertyOffice of filtered(); let index = index"
              (click)="select(propertyOffice)">
              <!-- <img [src]="propertyOffice.logo" class="office-logo mr-5" /> -->
              <span class="text option-label">{{propertyOffice.name}}</span>
            </div>
          </div>
          <div class="p-16" *ngIf="filtered().length === 0">
            <span class="light text">{{'PAGINATOR.GENERIC_SEARCH_NO_RESULTS' | translate}}</span>
          </div>
        </div>
      </div>
      <app-progress-bar [mode]="'indeterminate'" [loading]="loading"></app-progress-bar>
    </mat-card>
  </ng-template>
</div>