import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Case, CaseStatus } from 'src/app/models/case';
import { DocumentClass } from 'src/app/models/documentClass';
import { Paginator } from 'src/app/models/paginator';
import { UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CaseService, CasesByPhase } from 'src/app/services/case.service';
import { Data, DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { TrademarkService } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loading: boolean = false;
  done: boolean = false;

  user?: User;

  counts: {
    trademarks: number,
    documents: number,
    cases: number,
    users: number
  } = {
      trademarks: 0,
      documents: 0,
      cases: 0,
      users: 0
    }

  openCases: Paginator<Case> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 3,
    totalDocs: 0,
    sort: '-createdAt'
  };

  casesByPhases: CasesByPhase[] = [];
  trademarksObligation?: {
    obligated: number,
    notObligated: number
  };

  files: UploadFile[] = [];
  active: boolean = false;
  private accepted: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

  isManagerOrAbove: boolean = false;
  countries: string[] = [];

  displayedColumns: string[] = ['name', 'status', 'trademark', 'nice', 'territories', 'period', 'actions'];

  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private authService: AuthenticationService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private organizationService: OrganizationService,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }

  async ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: ""
    });
    this._message.emitChange("LOADING", "START");
    this.loading = true;
    this.done = false;
    await this.countEntities();
    this.loading = false;
    this.done = true;
    this._message.emitChange("LOADING", "END");
    this.graphCases();
    this.getTrademarksObligation();
    this.getOpenCases();
  }

  async countEntities() {
    await Promise.all([
      this.countTrademarks(),
      this.countDocuments(),
      this.countCases(),
      this.countUsers()
    ])
  }

  async countTrademarks() {
    const trademarks = await this.trademarkService.countAll();
    this.counts.trademarks = trademarks.count;
  }

  async countDocuments() {
    const documents = await this.documentService.countAll();
    this.counts.documents = documents.count;
  }

  async countCases() {
    const cases = await this.caseService.countAll();
    this.counts.cases = cases.count;
  }

  async countUsers() {
    const users = await this.organizationService.countUsers();
    this.counts.users = users.count;
  }

  async graphCases() {
    this.casesByPhases = await this.caseService.graphAll();
  }

  async getOpenCases() {
    const cases = await this.caseService.retrieveAll(1, 999, 0, '-createdAt', undefined, [CaseStatus.INSTRUCTION], moment(), undefined);
    this.openCases = { ...cases, docs: cases.docs.map((caseObject: Case) => ({ ...caseObject, phase: this.caseService._currentPhase(caseObject) })).sort(this._caseComparator) };
  }

  _caseComparator(a: Case, b: Case) {
    return (a.phase && a.phase.endsIn ? a.phase.endsIn : -1) - (b.phase && b.phase.endsIn ? b.phase.endsIn : -1)
  }

  async getTrademarksObligation() {
    this.trademarksObligation = await this.trademarkService.graphAll();
  }

  openUploadDialog() {
    this.documentService.openUploadDialog([]);
  }

  toTrademarks() {
    this.router.navigateByUrl('/trademarks/new')
  }

  toSupport() {
    this.router.navigateByUrl('/support')
  }
}
