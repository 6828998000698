import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Case, CasePossiblePhase, SubPhase, SuspendedLabel } from 'src/app/models/case';

@Component({
  selector: 'app-case-timeline-action-dialog',
  templateUrl: './case-timeline-action-dialog.component.html',
  styleUrls: ['./case-timeline-action-dialog.component.scss']
})
export class CaseTimelineActionDialogComponent {
  action?: CasePossiblePhase;
  defaultDuration?: {
    value: number,
    unit: moment.unitOfTime.DurationConstructor
  };
  duration: {
    value: number,
    unit: moment.unitOfTime.DurationConstructor
  } = {
      value: 1,
      unit: 'months'
    };
  case?: Case;

  date: moment.Moment = moment();
  start: moment.Moment = moment();
  minSubphase: moment.Moment = moment();
  maxSubphase?: moment.Moment = moment();
  end?: moment.Moment;
  suspensionStart?: moment.Moment;
  withdrawal: boolean = false;
  decision: "LOST" | "MITIGATED" | "WON" = "MITIGATED"
  definitive: boolean = false;
  label?: string;

  caseIdentifier?: { identifier: string };

  durations: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "days",
        label: "DATES.DAYS",
        selected: false
      },
      {
        value: "weeks",
        label: "DATES.WEEKS",
        selected: false
      },
      {
        value: "months",
        label: "DATES.MONTHS",
        selected: false
      }
    ];

  errors: any = {
    label: {
      value: false,
      message: undefined
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { action?: CasePossiblePhase, case?: Case, subphase?: SubPhase },
    private dialogRef: MatDialogRef<CaseTimelineActionDialogComponent>,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    moment.locale(this.translate.currentLang)
  }

  ngOnInit(): void {
    if (this.data) {
      this.action = this.data.action;
      if (this.action && this.action.duration) {
        this.defaultDuration = {
          value: parseInt(this.action.duration.split(" ")[0]),
          unit: this.action.duration.split(" ")[1].toLowerCase() as moment.unitOfTime.DurationConstructor
        }
        this.duration = JSON.parse(JSON.stringify(this.defaultDuration))
        this.date = moment().add(this.duration.value, this.duration.unit);
        if (this.action.method === 'next' || this.action.method === 'suspend') {
          this.start = moment();
          this.end = moment().add(this.duration.value, this.duration.unit);
        }
      }


      this.case = this.data.case;
      if (this.case) {
        this.caseIdentifier = {
          identifier: this.case.identifier || this.case.reference
        };
        if (this.action && this.action.method === 'unsuspend' && this.case.phase?.subPhases) {
          const suspension = this.case.phase?.subPhases.find(sp => sp.label === SuspendedLabel);
          this.suspensionStart = moment(suspension?.start)
        }
        if (this.action && ['subphase', 'editsubphase'].includes(this.action.method) && this.case.phase) {
          const subPhases = this.case.phase?.subPhases;
          const subPhasesStarts: moment.Moment[] = [];
          const subPhasesEnds: moment.Moment[] = [];
          subPhases?.forEach(sp => {
            if (sp.start) {
              subPhasesStarts.push(sp.start.clone())
            }
            if (sp.end) {
              subPhasesEnds.push(sp.end.clone())
            }
          })
          this.minSubphase = moment.max([...subPhasesEnds, this.case.phase.start]);
          this.maxSubphase = this.case.phase.end;
          if (this.action.method === 'subphase') {
            this.start = this.minSubphase;
            this.end = this.maxSubphase;
          } else if (this.action.method === 'editsubphase' && this.data.subphase) {
            this.start = this.data.subphase.start;
            this.end = this.data.subphase.end;
            this.label = this.data.subphase.label;
          }

        }
      }
    }
  }


  suspensionPeriod() {
    return this.translate.instant('CASE.TIMELINE_ACTION_SUSPENDED_UNTIL', {
      start: this.start.format('ll'),
      end: this.end?.format('ll')
    })
  }

  ngOnDestroy(): void {
  }

  _hasErrors() {
    this.errors.label = {
      value: false,
      message: undefined
    }
    if (!this.label) {
      this.errors.label.value = true;
      this.errors.label.message = this.translate.instant('ERRORS.PHASE_LABEL_REQUIRED');
    } else if (this.label.toLowerCase() === SuspendedLabel) {
      this.errors.label.value = true;
      this.errors.label.message = this.translate.instant('ERRORS.FOBIDDEN_PHASE_LABEL');
    }

    return this.errors.label.value;
  }


  onCancel(): void {
    this.dialogRef.close({
      confirmed: false
    });
  }

  canConfirm() {
    switch (this.action?.method) {
      case 'close':
        return this.date !== undefined;
      case 'suspend':
        return this.start !== undefined;
      case 'unsuspend':
        return this.date !== undefined;
      case 'next':
        return this.start !== undefined;
      case 'subphase':
      case 'editsubphase':
        return this.label !== undefined && this.start !== undefined && this.end !== undefined && this.start.isBefore(this.end);
      default:
        return false;
    }
  }

  async onConfirm() {
    try {
      if (this.action && ['subphase', 'editsubphase'].includes(this.action?.method) && this._hasErrors()) {
        return;
      } else {
        this.dialogRef.close({
          confirmed: true,
          data: {
            duration: this.duration,
            date: this.date,
            start: this.start,
            end: this.end,
            label: this.label,
            meta: {
              withdrawal: this.withdrawal,
              definitive: this.definitive,
              decision: this.decision
            }
          }
        });
      }
    } catch (err: any) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }
}
