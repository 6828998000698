import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  constructor(
    private _message: MessageService
  ) {

  }

  ngOnInit(): void {
    this._message.emitChange("NAV", "SECTION", {
      path: "admin"
    });
  }
}
