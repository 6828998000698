import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/models/user';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {

  user?: User;

  constructor(
    private _message: MessageService,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this._message.emitChange("NAV", "SECTION", {
      path: "settings"
    });
    this.retrieveMe();
  }

  async retrieveMe() {
    try {
      this._message.emitChange("LOADING", "START");
      this.user = await this.userService.me();
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this.toastr.error('ERRORS.GENERIC');
      this._message.emitChange("LOADING", "END");
    }
  }

  onChange(user: User) {
    if (user && user._id) {
      this.user = user;
      this.translate.use(this.user.lang);
    }

  }

}
