<mat-expansion-panel hideToggle [expanded]="expanded" (opened)="expanded = true" (closed)="expanded = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="fx-row align-items-center justify-content-between fx-flex-100">
        <div class="fx-row align-items-center justify-content-start fx-flex">
          <span class="text bold main">{{status().label | translate}}</span>
          <mat-icon *ngIf="status().done && !status().error" class="main ml-10">check_circle</mat-icon>
          <mat-icon *ngIf="status().done && status().error" class="red ml-10">error</mat-icon>
          <app-progress-spinner [value]="progress() || 0" *ngIf="status().loading" class="ml-10"></app-progress-spinner>
          <span class="text main bold ml-10"
            *ngIf="status().loading || (status().done && !status().error)">{{progress()}}%</span>
        </div>
        <div class="fx-row align-items-center justify-content-end ml-20">
          <mat-icon class="main clickable" (click)="toggle($event)">{{expanded ? 'expand_less' :
            'expand_more'}}</mat-icon>
          <mat-icon class="main ml-10" (click)="close($event)">close</mat-icon>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="fx-row fx-wrap align-items-start justify-content-start">
    <div class="upload-complete-container fx-column" *ngIf="status().done">
      <span class="text white">{{((batch && batch.mode === 'NON_USE') ?
        'UPLOAD_DOCUMENTS.BATCH_CLOSED_NON_USE' : 'UPLOAD_DOCUMENTS.BATCH_CLOSED') | translate}}</span>
      <div class='fx-row align-items-center justify-content-star mt-5' (click)='toBatch($event)'>
        <span class="text bold clickable white">
          {{((batch && batch.mode === 'NON_USE') ? 'UPLOAD_DOCUMENTS.BATCH_VIEW' : 'UPLOAD_DOCUMENTS.BATCH_LINK') |
          translate}}
        </span>
        <mat-icon class="white" fontSet="material-icons-outlined">navigate_next</mat-icon>
      </div>
    </div>
    <div class="fx-row align-items-center justify-content-start file-container" *ngFor="let file of files()"
      [class.actionable]="file.documentId" [class.clickable]="file.documentId" (click)="toDocument($event,file)">
      <img [src]="icon(file)" [height]="25" class="mr-10" />
      <span class="text filename">{{file.name}}</span>
      <app-progress-spinner [value]="file.progress || 0" *ngIf="file.status === 'UPLOADING'"
        class="ml-20"></app-progress-spinner>
      <mat-icon *ngIf="file.status === 'DONE'" class="main ml-20">check_circle</mat-icon>
      <mat-icon *ngIf="file.status === 'ERROR'" class="red ml-20">error</mat-icon>
    </div>
  </div>
</mat-expansion-panel>