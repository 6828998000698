<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'TRADEMARKS_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'TRADEMARKS_LIST.SUBTITLE' | translate}}</span>
  </div>
  <button class="main-button ml-20" routerLink="new" *ngIf="!loading && done && !noDocs" aclBasedElement
    resource="TRADEMARK" [scopes]="['C']">
    <div class="fx-row align-items-center justify-content-center">
      <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_circle_outline</mat-icon>
      {{'TRADEMARKS_LIST.NEW' | translate}}
    </div>
  </button>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card class="p-0" *ngIf="!loading && done && !noDocs">
  <div class="fx-row align-items-center justify-content-between p-16">
    <div class="fx-row align-items-center justify-content-start">
      <app-territory-select label="{{'ENTITIES.COUNTRY_OF_DESIGNATION' | translate}}" [multiple]="true" [search]="true"
        initial="none" (change)="onCountryOfDesignationChange($event)" [emitOnInit]="false"
        style="width:210px;"></app-territory-select>
      <app-nice-select label="{{'TRADEMARKS_LIST.FILTERS.PRODUCTS_SERVICES' | translate}}" [multiple]="true"
        [search]="true" class="ml-10" initial="none" (change)="onNiceClassificationChange($event)" [emitOnInit]="false"
        style="width:210px;"></app-nice-select>
      <div class="fx-row fw-wrap align-items-baseline justify-content-start ml-10">
        <app-animated-number [value]="trademarks.totalDocs" [class]="'bold main'"></app-animated-number>
        &nbsp;
        <span class="text"><span class="bold main">{{((trademarks.totalDocs > 1 ? 'ENTITIES.TRADEMARKS' :
            'ENTITIES.TRADEMARK') |
            translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
      </div>
    </div>
    <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="filters.query"
      (change)="onQueryChange($event)"></app-input>
  </div>
  <mat-divider></mat-divider>
  <table mat-table [dataSource]="dataSource" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'TRADEMARK.REPRODUCTION_STANDARD_CHARACTERS_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <span class="main bold" *ngIf="trademark.name">{{trademark.name}}</span>
        <span class="text light" *ngIf="!trademark.name && trademark.type !== 'Figurative'">-</span>
        <span class="small light" *ngIf="!trademark.name && trademark.type === 'Figurative'">({{'TRADEMARK.FIGURATIVE' |
          translate}})</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.IDENTIFIER_NUMBER_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-column">
          <span class="text">{{trademark.identifierNumber}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="countryOfDesignation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'ENTITIES.TERRITORY' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <app-trademark-country [wipoCode]="trademark.countryOfDesignation"></app-trademark-country>
      </td>
    </ng-container>
    <ng-container matColumnDef="niceClassification">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.NICE_CLASSIFICATION_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-row fx-wrap align-items-center justify-content-start">
          <app-trademark-nice-classification
            [niceClassification]="trademark.niceClassification"></app-trademark-nice-classification>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span class="small light bold">
          {{'TRADEMARK.STATUS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <div class="fx-row align-items-center justify-content-start">
          <app-trademark-status [status]="trademark.status" *ngIf="trademark.status"></app-trademark-status>
        </div>
        <span class="text light" *ngIf="!trademark.status">-</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="representation">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'TRADEMARK.REPRODUCTION_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <img [src]="trademark.reproductionURI" class="trademark-representation" *ngIf="trademark.reproductionURI" />
        <span class="text light" *ngIf="!trademark.reproductionURI">-</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let trademark; columns: displayedColumns;" class="clickable" [routerLink]="trademark._id">
    </tr>
  </table>
  <div class="p-16" *ngIf="done && !loading && trademarks.totalDocs === 0">
    <span class="text light">{{'TRADEMARKS_LIST.SEARCH_NO_TRADEMARKS' | translate}}</span>
  </div>
  <mat-paginator [length]="trademarks.totalDocs || 0" [pageSize]="trademarks.limit || 0"
    [pageIndex]="trademarks && trademarks.page ? (trademarks.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="handlePage($event)">
  </mat-paginator>
</mat-card>
<app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
  [title]="'TRADEMARKS_LIST.NO_TRADEMARKS'" [message]="'TRADEMARKS_LIST.NO_TRADEMARKS_MESSAGE'"
  [button]="noTrademarkButton"></app-empty-placeholder>