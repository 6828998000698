<div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class="p-16 border-right fx-flex-33 border-box">
    <span class="text bold mb-16">{{'DOCUMENTS_REPORT.BY_CLASS' | translate}}</span>
    <app-loading-container *ngIf="!documentClasses.started || documentClasses.loading" [height]="300">
    </app-loading-container>
    <app-graph-documents-class [data]="documentClasses.data" [loading]="documentClasses.loading"
      [error]="documentClasses.error" [noData]="documentClasses.started && documentClasses.data.length === 0"
      *ngIf="!documentClasses.loading && !documentClasses.error && documentClasses.data.length > 0"></app-graph-documents-class>
  </div>
  <div class="p-16 fx-flex-33 border-box">
    <span class="text bold mb-16">{{'DOCUMENTS_REPORT.BY_TRADEMARK' | translate}}</span>
    <app-loading-container *ngIf="!documentTrademarks.started || documentTrademarks.loading" [height]="300">
    </app-loading-container>
    <app-graph-documents-trademark [data]="documentTrademarks.data" [loading]="documentTrademarks.loading"
      [error]="documentTrademarks.error" [noData]="documentTrademarks.started && documentTrademarks.data.length === 0"
      *ngIf="!documentTrademarks.loading && !documentTrademarks.error && documentTrademarks.data.length > 0"></app-graph-documents-trademark>
  </div>
  <div class="p-16 border-left fx-flex-33 border-box">
    <span class="text bold mb-16">{{'DOCUMENTS_REPORT.BY_NICE' | translate}}</span>
    <app-loading-container *ngIf="!documentNice.started || documentNice.loading" [height]="300">
    </app-loading-container>
    <app-graph-documents-nice [data]="documentNice.data" [loading]="documentNice.loading" [error]="documentNice.error"
      [height]="300" [noData]="documentNice.started && documentNice.data.length === 0"
      *ngIf="!documentNice.loading && !documentNice.error && documentNice.data.length > 0"></app-graph-documents-nice>
  </div>
</div>
<div class="fx-row fx-wrap align-items-center justify-content-start border-bottom">
  <div class="p-16 fx-flex-100 border-top border-box">
    <span class="text bold mb-16">{{'DOCUMENTS_REPORT.BY_TERRITORY' | translate}}</span>
    <app-loading-container *ngIf="!documentTerritories.started || documentTerritories.loading" [height]="400">
    </app-loading-container>
    <app-graph-documents-map [data]="documentTerritories.data" [loading]="documentTerritories.loading"
      [error]="documentTerritories.started && documentTerritories.error"
      [noData]="documentTerritories.data.length === 0"
      *ngIf="!documentTerritories.loading && !documentTerritories.error && documentTerritories.data.length > 0"
      [count]="count"></app-graph-documents-map>
  </div>
  <div class="p-16 border-top fx-flex-100 border-box">
    <span class="text bold mb-16">{{'DOCUMENTS_REPORT.BY_PERIOD' | translate}}</span>
    <app-loading-container *ngIf="!documentPeriods.started || documentPeriods.loading" [height]="400">
    </app-loading-container>
    <app-graph-documents-timeline [data]="documentPeriods.data" [loading]="documentPeriods.loading"
      [error]="documentPeriods.started && documentPeriods.error" [noData]="documentPeriods.data.length === 0"
      *ngIf="!documentPeriods.loading && !documentPeriods.error && documentPeriods.data.length > 0"></app-graph-documents-timeline>
  </div>
</div>