<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <app-back></app-back>
    <span class="page-title">{{'BATCHES_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'BATCHES_LIST.SUBTITLE' | translate}}</span>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card class="p-0" *ngIf="!loading && done && !noDocs">
  <div class="fx-row align-items-center justify-content-between p-16">
    <div class="fx-row align-items-center justify-content-start">
      <app-select initial="none" label="{{'BATCHES_LIST.FILTERS.MODE' | translate}}" [options]="modes" [multiple]="true"
        (change)="onModeChange($event)" [emitOnInit]="false" style="width:180px;"></app-select>
      <app-territory-select label="{{'ENTITIES.COUNTRIES' | translate}}" [multiple]="true" [search]="true" class="ml-10"
        initial="none" (change)="onTerritoryChange($event)" [emitOnInit]="false"
        style="width:180px;"></app-territory-select>
      <app-trademark-select label="{{'ENTITIES.TRADEMARK' | translate}}" [multiple]="true" [search]="true" class="ml-10"
        initial="none" (change)="onTrademarkChange($event)" [(value)]="filters.trademarks" [emitOnInit]="false"
        style="width:180px;"></app-trademark-select>
      <div class="fx-row fw-wrap align-items-baseline justify-content-start ml-10">
        <app-animated-number [value]="batches.totalDocs" [class]="'bold main'"></app-animated-number>
        &nbsp;
        <span class="text"><span class="bold main">{{((batches.totalDocs > 1 ? 'ENTITIES.BATCHES' : 'ENTITIES.BATCH') |
            translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
      </div>
    </div>
    <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="filters.query"
      (change)="onQueryChange($event)"></app-input>
  </div>
  <mat-divider></mat-divider>
  <table mat-table [dataSource]="filteredBatches" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'BATCH.NUMBER' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        <div class="fx-column align-items-start justify-content-start clickable">
          <span class="text main">Import n°{{batch.number}}</span>
          <span class="small light">{{formatDate(batch.createdAt)}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'BATCH.STATUS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        <app-batch-progress [batch]="batch" (refresh)="refresh($event,batch)"></app-batch-progress>
      </td>
    </ng-container>
    <ng-container matColumnDef="mode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'BATCH.MODE' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        <div class="fx-column">
          <span class="text">{{'BATCH.MODE_'+batch.mode | translate}}</span>
          <!-- <a (click)="toTrademark(document.trademarks[0])">{{document.trademarks[0].name}}</a>&nbsp;<span
            class="text">{{'UTILS.AND_X_MORE' |
            translate:{x:document?.trademarks.length-1} }}</span> -->
          <span class="small main" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="tooltipMode(batch)"
            *ngIf="batch.mode === 'TRADEMARKS'">
            {{formatTrademarks(batch.trademarks)}}
          </span>
          <span class="small main" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="tooltipMode(batch)"
            *ngIf="batch.mode === 'COUNTRIES'">
            {{formatCountries(batch.countries)}}
          </span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="documents">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'ENTITIES.DOCUMENTS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        {{batch.documents}} {{((batch.documents > 1 ? 'ENTITIES.DOCUMENTS' : 'ENTITIES.DOCUMENT') |
        translate).toLowerCase()}}
      </td>
    </ng-container>
    <ng-container matColumnDef="uploader">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'BATCH.UPLOADER' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        <div class="fx-row justify-content-start align-items-center" *ngIf="batch.uploader && batch.uploader.ref">
          <app-avatar [user]="batch.uploader" [size]="25"></app-avatar>
          <div class="fx-column align-items-start justify-content-start ml-5">
            <span class="small">{{batch.uploader.firstName}} {{batch.uploader.lastName}}</span>
          </div>
        </div>
        <div class="fx-row justify-content-start align-items-center" *ngIf="!batch.uploader || !batch.uploader.ref">
          <img src="/assets/icons/icon.svg" class="uzai-avatar" style="width:25px;height:25px;" />
          <div class="fx-column align-items-start justify-content-start ml-5">
            <span class="small">UZAI <span class="small light">({{'ENTITIES.INTEGRATION' | translate}})</span></span>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="score">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'BATCH.SCORE' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let batch">
        <app-batch-score [score]="score(batch)" [status]="batch.status" *ngIf="batch.report"></app-batch-score>
        <span class="text light" *ngIf="!batch.report"></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let batch">
        <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let batch; columns: displayedColumns;" class="clickable" (click)="toBatch(batch)">
    </tr>
  </table>
  <div class="p-16" *ngIf="done && !loading && batches.totalDocs === 0">
    <span class="text light">{{'BATCHES_LIST.SEARCH_NO_BATCHES' | translate}}</span>
  </div>
  <mat-paginator [length]="batches.totalDocs || 0" [pageSize]="batches.limit || 0"
    [pageIndex]="batches && batches.page ? (batches.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="handlePage($event)">
  </mat-paginator>
</mat-card>
<app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
  [title]="'BATCHES_LIST.NO_BATCHES'" [message]="'BATCHES_LIST.NO_BATCHES_MESSAGE'"
  [button]="noDocumentButton"></app-empty-placeholder>