<div class="uploading-documents-box fx-row fx-wrap">
  <div class="fx-row align-items-center justify-content-between fx-flex-100 mb-10">
    <div class="fx-row align-items-center justify-content-start fx-flex-100">
      <mat-icon *ngIf="status().done && !status().error" class="main mr-10">check_circle</mat-icon>
      <mat-icon *ngIf="status().done && status().error" class="main mr-10">error</mat-icon>
      <mat-icon *ngIf="!status().done && status().loading" class="main mr-10">sync</mat-icon>
      <span class="text main bold" *ngIf="!status().done && status().loading">{{documentService.uploadDocuments.length}}
        {{((documentService.uploadDocuments.length === 1 ? 'UPLOAD_DOCUMENTS.DOCUMENT_BEING_UPLOADED' :
        'UPLOAD_DOCUMENTS.DOCUMENTS_BEING_UPLOADED') | translate).toLowerCase()}}</span>
      <span class="text main bold" *ngIf="status().done">{{documentService.uploadDocuments.length}}
        {{((documentService.uploadDocuments.length === 1 ? 'UPLOAD_DOCUMENTS.DOCUMENT_UPLOADED' :
        'UPLOAD_DOCUMENTS.DOCUMENTS_UPLOADED') | translate).toLowerCase()}}</span>
    </div>
    <span class="text main bold ml-10"
      *ngIf="status().loading || (status().done && !status().error)">{{progress()}}%</span>
  </div>
  <app-progress-bar [value]="progress()" [mode]="'determinate'" [loading]="true" class="fx-flex-100"></app-progress-bar>
</div>