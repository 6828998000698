<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'DOCUMENTS_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'DOCUMENTS_LIST.SUBTITLE' | translate}}</span>
  </div>
  <div class="fx-row align-items-center justify-content-end">
    <div class="danger-button" *ngIf="selection && selection.selected && selection.selected.length > 0"
      (click)="deleteDocuments()">
      {{ 'ACTIONS.DELETE' | translate}} {{selection.selected.length}} {{((selection.selected.length > 1 ?
      'ENTITIES.DOCUMENTS' :
      'ENTITIES.DOCUMENT') | translate).toLowerCase()}}
    </div>
    <button class="button ml-10" (click)="toBatches()">
      {{'DOCUMENTS_LIST.UPLOADS_HISTORY' | translate}}
    </button>
    <button class="main-button ml-20" (click)="upload()" *ngIf="!loading && done && !noDocs" aclBasedElement
      resource="DOCUMENT" [scopes]="['C']">
      <div class="fx-row align-items-center justify-content-center">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
        {{'ACTIONS.UPLOAD' | translate}}
      </div>
    </button>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card *ngIf="!loading && done && !noDocs">
  <div class="mb-16" *ngIf="uploadingBox">
    <app-uploading-documents-box class="mb-16"></app-uploading-documents-box>
  </div>
  <div class="fx-row fx-wrap">
    <div class="fx-row fw-wrap align-items-center justify-content-between fx-flex-100"
      *ngIf="displayMode !== 'hierarchy'">
      <app-document-class-select label="{{'ENTITIES.DOCUMENT_TYPES' | translate}}" [multiple]="true" [search]="true"
        [emitOnInit]="false" class="fx-flex" (change)="onDocumentClassChange($event)"
        [(value)]="documentService.filters.documentClass" [noAutomatic]="true"></app-document-class-select>
      <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
        class="ml-10 fx-flex" (change)="onTrademarkChange($event)" [(value)]="documentService.filters.trademark"
        [emitOnInit]="false"></app-trademark-select>
      <app-nice-select label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}" [multiple]="true" [search]="true"
        class="ml-10 fx-flex" [(value)]="documentService.filters.classNumber"
        (change)="onNiceClassificationChange($event)" [emitOnInit]="false"></app-nice-select>
      <app-territory-select label="{{'ENTITIES.TERRITORIES' | translate}}" [multiple]="true" [search]="true"
        class="ml-10 fx-flex" [(value)]="documentService.filters.territories" (change)="onTerritoryChange($event)"
        [emitOnInit]="false"></app-territory-select>
      <app-period-select label="{{'ENTITIES.PERIOD' | translate}}" class="ml-10 fx-flex" [range]="true"
        (change)="onPeriodChange($event)" [(valueRange)]="documentService.filters.period"></app-period-select>
      <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search"
        [(value)]="documentService.filters.query" (change)="onQueryChange($event)" class="ml-10 fx-flex"></app-input>
    </div>
    <div class="fx-row fw-wrap align-items-center justify-content-start  fx-flex-100"
      *ngIf="displayMode === 'hierarchy'">
      <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_1' | translate}}" [options]="level1Options"
        [multiple]="false" class="ml-10" [(value)]="levels[1]" (change)="onLevelChange(1,$event)" [emitOnInit]="false"
        style="width:180px;"></app-select>
      <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_2' | translate}}" [options]="level2Options"
        [multiple]="false" class="ml-10" [(value)]="levels[2]" (change)="onLevelChange(2,$event)" [emitOnInit]="false"
        style="width:180px;"></app-select>
      <app-select initial="none" label="{{'DOCUMENTS_HIERARCHY.LEVEL_3' | translate}}" [options]="level3Options"
        [multiple]="false" class="ml-10" [(value)]="levels[3]" (change)="onLevelChange(3,$event)" [emitOnInit]="false"
        style="width:180px;"></app-select>
    </div>
  </div>
  <div class="fx-row fx-wrap align-items-baseline justify-content-end fx-flex-100 documents-counter"
    [style.top.px]="uploadingBox ? 166 : 94">
    <app-animated-number [value]="count" [class]="'bold main'"></app-animated-number>
    <span class="text ml-5"><span class="bold main">{{((count > 1 ? 'ENTITIES.DOCUMENTS' : 'ENTITIES.DOCUMENT') |
        translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
  </div>
  <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="!loading && done && !noDocs"
    (selectedTabChange)="onTabChange($event)" class="mt-20">
    <mat-tab>
      <ng-template mat-tab-label class="text">
        {{'DOCUMENTS_LIST.DISPLAY_MODE_LIST' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <app-documents-table [filteredDocuments]="filteredDocuments" (sort)="onSortChange($event)"
          (page)="handlePage($event)" (reload)="reload()" [select]="true"
          [(selection)]="selection"></app-documents-table>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class=" text">
        {{'DOCUMENTS_LIST.DISPLAY_MODE_REPORT' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <app-documents-report #documentsReport [filters]="documentService.filters"></app-documents-report>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label class="text">
        {{'DOCUMENTS_LIST.DISPLAY_MODE_HIERARCHY' | translate}}
      </ng-template>
      <ng-template matTabContent>
        <app-documents-hierarchy [first]="levels[1]" [second]="levels[2]" [third]="levels[3]"></app-documents-hierarchy>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-card>
<app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
  [title]="'DOCUMENTS_LIST.NO_DOCUMENTS'" [message]="'DOCUMENTS_LIST.NO_DOCUMENTS_MESSAGE'"
  [button]="noDocumentButton"></app-empty-placeholder>