import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Data, DocumentService } from 'src/app/services/document.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

export interface DocumentData {
  _id: moment.Moment,
  documents: number,
  size: number,
  pages: number,
  cost: number
}

@Component({
  selector: 'app-documents-graph',
  templateUrl: './documents-graph.component.html',
  styleUrls: ['./documents-graph.component.scss']
})
export class DocumentsGraphComponent implements AfterViewInit {

  @Input() data: DocumentData[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  @Input() period: "hour" | "day" | "week" | "month" | "year" = "day";

  @Input() metric: string = 'documents';


  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('graphdocumentsdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.XYChart;

  constructor(
    private zone: NgZone,
    private documentService: DocumentService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }
  ngAfterViewInit(): void {
    this.makeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.makeChart();
  }

  _formatDate(datum: DocumentData) {

    if (this.period === 'hour') {
      return moment.utc(datum._id, "YYYY-MM-DD HH").format('lll')
    } else if (this.period === 'day') {
      return moment.utc(datum._id, "YYYY-MM-DD").format('ll')
    } else if (this.period === 'week') {
      return moment.utc(datum._id, "YYYY WW").format("YYYY Wo")
    } else if (this.period === 'month') {
      return moment.utc(datum._id, "YYYY-MM").format("MMM, YYYY")
    } else {
      return moment.utc(datum._id, "YYYY").format("YYYY")
    }
  }

  _getFormatter() {

    if (this.period === 'hour') {
      return {
        formatter: "yyyy-MM-dd HH", key: undefined, value: undefined
      }
    } else if (this.period === 'day') {
      return {
        formatter: "yyyy-MM-dd", key: undefined, value: undefined
      }
    } else if (this.period === 'week') {
      return {
        formatter: "yyyy w", key: 'week', value: 'yyyy w'
      }
    } else if (this.period === 'month') {
      return {
        formatter: "yyyy-MM", key: 'month', value: 'MMM'
      }
    } else {
      return {
        formatter: "yyyy", key: undefined, value: undefined
      }
    }
  }

  _getLabel() {
    if (this.metric === "documents") {
      return this.translate.instant('ADMIN.DOCUMENTS').toLowerCase()
    } else if (this.metric === "pages") {
      return this.translate.instant('ADMIN.PAGES').toLowerCase()
    } else if (this.metric === "size") {
      return "{unit}"
    } else if (this.metric === "cost") {
      return "€"
    }
  }

  formatSize(size: number) {
    if (size < 1024) {
      return {
        value: size,
        unit: 'o'
      }
    } else if (size < 1024 * 1024) {
      return {
        value: Math.round(size / 1024),
        unit: 'Ko'
      }
    } else if (size < 1024 * 1024 * 1024) {
      return {
        value: parseFloat((size / (1024 * 1024)).toFixed(1)),
        unit: 'Mo'
      }
    } else if (size < 1024 * 1024 * 1024 * 1024) {
      return {
        value: parseFloat((size / (1024 * 1024 * 1024)).toFixed(1)),
        unit: 'Go'
      }
    }
    return {
      value: size,
      unit: 'N/A'
    }
  }


  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (this.chartElement) {
        am4core.useTheme(am4themes_animated.default);

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
        chart.logo.setVisibility(false);

        chart.data = this.data.map((datum) => ({
          dateLabel: this._formatDate(datum),
          size: datum.size,
          sizeLabel: this.formatSize(datum.size).value,
          unit: this.formatSize(datum.size).unit,
          documents: datum.documents,
          cost: datum.cost,
          pages: datum.pages,
          _id: datum._id
        }));

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.renderer.grid.template.opacity = 0.4;
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fill = am4core.color("#13022B");

        // const from = moment.utc().add(-1 * this.valuePeriod.value, this.valuePeriod.period as never);
        // const to = moment.utc();

        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.labels.template.fontSize = 10;
        dateAxis.renderer.labels.template.fill = am4core.color("#13022B");
        dateAxis.renderer.labels.template.location = 0.5;
        // dateAxis.min = from.toDate().getTime();
        // dateAxis.max = to.toDate().getTime();
        dateAxis.baseInterval = {
          "timeUnit": this.period,
          "count": 1
        };
        const dateFormatter = this._getFormatter();
        dateAxis.dateFormatter.inputDateFormat = dateFormatter.formatter;
        if (dateFormatter.key && dateFormatter.value) {
          dateAxis.periodChangeDateFormats.setKey(dateFormatter.key as am4core.TimeUnit, dateFormatter.value);
        }

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = this.metric;
        series.dataFields.dateX = "_id";
        series.columns.template.column.cornerRadiusTopLeft = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.fill = am4core.color("#8847E3");
        series.columns.template.fillOpacity = 1;
        series.columns.template.stroke = am4core.color("#8847E3");
        // series.columns.template.strokeOpacity = 1;
        // series.columns.template.strokeWidth = 2;
        series.columns.template.tooltipText = `{dateLabel}: {${this.metric === "size" ? "sizeLabel" : this.metric}} ${this._getLabel()}`


        // categoryAxis.sortBySeries = series;

        this.chart = chart;
        this.chart.events.on('ready', () => {
          self.ready = true;
          this.changeDetector.detectChanges()
        })
      }
    })
  }
}
