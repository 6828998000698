import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NiceClass } from 'src/app/models/niceClass';

@Component({
  selector: 'app-trademark-nice-classification',
  templateUrl: './trademark-nice-classification.component.html',
  styleUrls: ['./trademark-nice-classification.component.scss']
})
export class TrademarkNiceClassificationComponent implements OnInit {
  @Input() niceClassification?: (NiceClass | number)[];
  classification: {
    classes: number[],
    others: {
      enabled: boolean,
      tooltip: string,
      label: string,
      count: number
    }
  } = {
      classes: [],
      others: {
        enabled: false,
        tooltip: '',
        label: '',
        count: 0
      }
    };

  @Input() size: string = 'normal';

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    if (this.niceClassification) {
      const classes = this.niceClassification.map((niceClass) => typeof niceClass === "number" ? niceClass : niceClass.classNumber).sort((a, b) => a - b);
      this.classification.classes = classes.slice(0, 3);
      if (this.niceClassification.length > 3) {
        this.classification.others = {
          enabled: true,
          tooltip: classes.slice(3, this.niceClassification.length).map((niceClass) => {
            return `${this.translate.instant('NICE_CLASSES.CLASS_NUMBER')} ${niceClass}`;
          }).join(', '),
          label: this.translate.instant('UTILS.X_MORE', { x: this.niceClassification.length - 3 }),
          count: this.niceClassification.length - 3
        };
      }
    }

  }
}
