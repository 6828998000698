import { CdkMenu, CdkMenuBase, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription, concat, of } from 'rxjs';
import { Territory } from 'src/app/models/territory';
import { AclService } from 'src/app/services/acl.service';
import { MessageService } from 'src/app/services/message.service';
import { TerritoryService } from 'src/app/services/territory.service';

type TerritoryOption = Territory & { selected: boolean }
type TerritorySelectedOption = { value: string, selected: boolean, display?: boolean }

@Component({
  selector: 'app-territory-select',
  templateUrl: './territory-select.component.html',
  styleUrls: ['./territory-select.component.scss']
})
export class TerritorySelectComponent {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() class?: string;
  @Input() hint?: string;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;
  @Input() emitOnInit: boolean = true;

  territories: TerritoryOption[] = [];
  selected: TerritorySelectedOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: string | string[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  countries: string[] = [];

  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private territoryService: TerritoryService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit() {
    this.countries = this.aclService.countries();
    this.setInitialState();
  }

  _isSelected(option: string) {
    return this.selected.map((t) => t.value).includes(option);
  }

  async populate() {
    const territories = await this.territoryService.retrieve();
    if (this.multiple && this.value && (this.value as string[]).length > 0) {
      const values = (this.value as string[]);
      const matching = territories.filter((t) => _.intersection([t.id, ...t.alternateCodes], values).length > 0)
      this.selected = matching.map((t) => ({ value: t.id, selected: true, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as string);
      const matching = territories.find((t) => _.intersection([t.id, ...t.alternateCodes], [value]).length > 0);
      if (matching)
        this.selected = [{ value: matching.id, selected: true, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  async setInitialState() {
    await this.retrieveTerritories();
    if (this.multiple && this.value && (this.value as string[]).length > 0) {
      const values = (this.value as string[]);
      this.selected = values.map((t) => ({ value: t, selected: true }));
      this.territories.forEach(territory => {
        if (values.includes(territory.id) || (territory.alternateCodes && _.intersection(values, territory.alternateCodes).length > 0)) {
          territory.selected = true;
        } else {
          territory.selected = false;
        }
      });
      const value = _.unionWith([...this.territories.filter(t => t.selected).map((t) => t.id), ...this.selected.map((o) => o.value)]);
      if (value.length > 0) {
        this.selection = value.length === 1 ? this.translate.instant(`TERRITORIES.${value[0]}`) : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as string);
      this.selected = [{ value, selected: true }];
      const option = this.territories.find(t => t.id == value);
      if (option) {
        option.selected = true;
      }
      this.selection = value ? this.translate.instant(`TERRITORIES.${value}`) : undefined;
    }
    if (this.emitOnInit) {
      this.onChange();
    }
  }

  removeFromPreselection(option: TerritorySelectedOption) {
    this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    const doc = this.territories.find((t) => t.id === option.value);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: TerritoryOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option.id === o.value), 1);
    } else if (!this._isSelected(option.id)) {
      this.selected.push({ value: option.id, selected: true, display: false });
    }
  }

  async retrieveTerritories() {
    this.territories = (await this.territoryService.retrieve()).map(territory => ({ ...territory, selected: this._isSelected(territory.id) }));
    if (this.countries && this.countries.length > 0) {
      this.territories = this.territories.filter(territory => this.countries.includes(territory.code));
    }
    this.territories.sort((a, b) => this.translate.instant(`TERRITORIES.${a.id}`).localeCompare(this.translate.instant(`TERRITORIES.${b.id}`)))
  }

  formatSelection() {
    if (!this.multiple) {
      const value = this.territories.find(c => c.selected);
      return value ? this.translate.instant(`TERRITORIES.${value.id}`) : undefined;
    } else {
      const value = this.territories.filter(c => c.selected);
      if (value.length > 0) {
        return value.length === 1 ? this.translate.instant(`TERRITORIES.${value[0].id}`) : `${this.label} (${value.length})`;
      } else {
        return undefined;
      }
    }
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.territories.forEach(c => {
        if (c.id !== option.id) {
          c.selected = false;
        }
      })
    }
    this.onChange();
  }

  async onChange() {
    if (!this.multiple) {
      const value = this.territories.find(c => c.selected);
      this.selection = value ? this.translate.instant(`TERRITORIES.${value.id}`) : undefined;
      this.valueChange.emit(value?.id as string);
      this.change.emit({ value });
      this.trigger?.close();
    } else {
      const values = _.unionWith([...this.territories.filter(c => c.selected).map(c => c.id), ...this.selected.map((o) => o.value)]);
      if (values.length > 0) {
        this.selection = values.length === 1 ? this.translate.instant(`TERRITORIES.${values[0]}`) : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values as string[]);
      this.change.emit({ value: this.territories.filter((t) => values.includes(t.id)) });

    }

  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }



  filtered() {
    return (this._filter && this._filter.length > 0) ? this.territories.filter(territory => {
      return this.translate.instant(`TERRITORIES.${territory.id}`).toLowerCase().includes(this._filter?.toLowerCase())
    }) : this.territories;
  }


  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.territories.forEach(territory => {
      territory.selected = false
    });
    this.selected = [];
    this.selection = undefined;
    this._filter = undefined;
    this.onChange();
    this.trigger?.close();
  }

  display() {
    if (this.selection && this.multiple && this.value) {
      return `${this.label} (${(this.value as string[]).length})`
    } else if (this.selection && !this.multiple && this.value) {
      return this.selection;
    } else {
      return undefined;
    }
  }
}
