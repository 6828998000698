import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

  constructor(
    private _message: MessageService,
    private documentService: DocumentService
  ) {

  }

  ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: "documents"
    });
  }

  ngOnDestroy(): void {
    this.documentService.resetDocumentsSearch()
  }

}
