import { Component, Input, OnInit } from '@angular/core';
import { Territory } from 'src/app/models/territory';
import { TerritoryService } from 'src/app/services/territory.service';

@Component({
  selector: 'app-trademark-country',
  templateUrl: './trademark-country.component.html',
  styleUrls: ['./trademark-country.component.scss']
})
export class TrademarkCountryComponent implements OnInit {
  @Input() wipoCode?: string;
  territories: Territory[] = [];
  territory?: Territory;

  constructor(
    private territoryService: TerritoryService
  ) {

  }

  ngOnInit(): void {
    this.retrieveTerritories();
  }

  async retrieveTerritories() {
    this.territories = await this.territoryService.retrieve();
    this.territory = this.territories.find((territory) => territory.id === this.wipoCode?.toUpperCase() || (territory.alternateCodes && territory.alternateCodes.includes(this.wipoCode?.toUpperCase() || '')));
  }
}
