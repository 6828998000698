<mat-card [class]="'mb-10 fx-flex-100 clickable ' + class" *ngIf="case" [routerLink]="'/cases/'+case._id">
  <div class="fx-row align-items-center justify-content-between">
    <div class="fx-row align-items-center justify-content-start">
      <span class="text bold">{{case.identifier || case.reference}}</span>
      <div class="chip small light ml-10" *ngIf="case && isSuspended">{{'CASE.SUSPENDED' | translate}}
      </div>
    </div>
    <div class="fx-row align-items-center justify-content-end ml-10" *ngIf="case.phase && case.phase.endsIn">
      <mat-icon class="main mr-5" fontSet="material-icons-outlined"
        *ngIf=" case.phase.endsIn <= 7">error_outline</mat-icon>
      <span class="small main">{{'CASE.PHASE_ENDS_IN' |
        translate:case.phase}}</span>
    </div>
    <span class="small main"
      *ngIf="case.phase && case.phase.name !== 'CLOSED' && !case.phase.endsIn">{{'CASE.PHASE_ENDS_MANUAL' |
      translate}}</span>
  </div>
  <mat-divider class="mv-10"></mat-divider>
  <div class="fx-row align-items-center justify-content-start mt-5">
    <div class="fx-column fx-flex-50">
      <span class="small light">{{'ENTITIES.TRADEMARK' | translate}}</span>
      <span class="text ">{{case.trademark.name || case.trademark.identifierNumber}}</span>
    </div>
    <div class="fx-column fx-flex-50" *ngIf="case">
      <app-trademark-nice-classification [niceClassification]="case.niceClassification"
        class="mt-5"></app-trademark-nice-classification>
    </div>
  </div>
  <div class="fx-column fx-flex-100 mt-5">
    <span class="small light">{{'CASE.PERIOD' | translate}}</span>
    <span class="text">{{formatDate(case.timePeriod.start)}} - {{formatDate(case.timePeriod.end)}}</span>
  </div>
  <div class="mt-5">
    <app-case-score [score]="case.score || {global:0}" [style]="'condensed'"></app-case-score>
  </div>
  <div class="fx-row align-items-center justify-content-start mt-10"
    *ngIf="case.phase && case.phase.name === 'CLOSED' && closedMeta">
    <div class="bullet" [class.green]="closedMeta['decision'] === 'WON'"
      [class.orange]="closedMeta['decision'] === 'MITIGATED'" [class.red]="closedMeta['decision'] === 'LOST'"
      *ngIf="!closedMeta['withdrawal']" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CASE.CLOSED_'+closedMeta['decision'] |
        translate">
    </div>
    <div class="fx-row align-items-center justify-content-start ml-10" *ngIf="!closedMeta['withdrawal']">
      <mat-icon class="mr-5" [class.green]="closedMeta['definitive']" [class.orange]="!closedMeta['definitive']"
        fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="(closedMeta['definitive'] ? 'CASE.DEFINITIVE' : 'CASE.NOT_DEFINITIVE') |
            translate">{{closedMeta['definitive'] ? 'check_circle' :
        'error_outline'}}</mat-icon>
      <span class="text">{{(closedMeta['definitive'] ? 'CASE.DEFINITIVE' :
        'CASE.NOT_DEFINITIVE')
        |
        translate}}</span>
    </div>
  </div>
</mat-card>