import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-nice-classes',
  templateUrl: './document-nice-classes.component.html',
  styleUrls: ['./document-nice-classes.component.scss']
})
export class DocumentNiceClassesComponent implements OnInit, OnChanges {
  @Input() metadata?: DocumentMetadata[] = [];
  @Input() niceClassification: number[] = [];
  @Input() shown: boolean = false;
  niceClasses: {
    value: number;
    confidence: number;
  }[] = []
  limit: number = 3;


  constructor(
  ) { }

  ngOnInit(): void {
    this.populateConfidence()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.niceClassification = this.niceClassification.sort();
    this.populateConfidence()
  }

  populateConfidence() {
    this.niceClasses = this.niceClassification.map(nice => ({
      value: nice,
      confidence: this.getConfidence(nice)
    }))
  }

  getConfidence(niceClass: number) {
    return (this.metadata?.find(datum => datum.type === MetadataType.NICE_CLASS && datum.value === `${niceClass}`)?.confidence ?? 0) * 100
  }

  toggle() {
    if (this.limit === 3) {
      this.limit = this.niceClassification.length;
    } else {
      this.limit = 3;
    }
  }
}
