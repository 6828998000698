import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CaseDocument } from 'src/app/models/case';
import { Document } from 'src/app/models/document';
import { Paginator } from 'src/app/models/paginator';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-documents-case',
  templateUrl: './documents-case.component.html',
  styleUrls: ['./documents-case.component.scss']
})
export class DocumentsCaseComponent implements OnChanges {
  @Input() from?: string;
  @Input() documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  @Input() filteredDocuments: CaseDocument[] = [];
  @Input() readonly: boolean = false;
  displayedColumns: string[] = ['select', 'name', 'trademark', 'nice', 'territories', 'period', 'score'];
  dataSource: MatTableDataSource<CaseDocument> = new MatTableDataSource<CaseDocument>([])
  @Input() selection = new SelectionModel<CaseDocument>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<CaseDocument>>();

  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<PageEvent>();
  @Output() select = new EventEmitter<any>();


  constructor(
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSource = new MatTableDataSource<CaseDocument>(this.filteredDocuments);
  }

  documentIcon(document: CaseDocument) {
    return this.documentService.icon(document);
  }

  formatSize(document: CaseDocument) {
    return this.documentService.size(document);
  }

  formatPeriod(document: CaseDocument) {
    return this.documentService.period(document);
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  flushSelection() {
    this.selection = new SelectionModel<CaseDocument>(true, []);
    this.selectionChange.emit(this.selection);
  }

  hasPageSelection() {
    return this.dataSource.data.some(row => this.isSelected(row))
  }

  isSelected(row: CaseDocument) {
    return this.selection.selected.map(doc => doc._id).includes(row._id)
  }

  isAllSelected() {
    return this.dataSource.data.every(row => this.isSelected(row));
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      const difference = _.differenceBy(this.selection.selected, this.dataSource.data, '_id')
      this.selection.setSelection(...difference)
      this.selectionChange.emit(this.selection);
      return;
    }
    this.selection.setSelection(...[...this.selection.selected, ...this.dataSource.data.filter(row => !this.isSelected(row))])
    this.selectionChange.emit(this.selection);
  }

  toggle(row: CaseDocument) {
    if (this.isSelected(row)) {
      this.selection.setSelection(...this.selection.selected.filter(doc => doc._id !== row._id))
    } else {
      this.selection.setSelection(...[...this.selection.selected, row])
    }
    this.selectionChange.emit(this.selection);
  }

  onSortChange(event: any) {
    if (event.active === 'period') {
      if (event.direction === 'asc') {
        event.active = 'period.start'
      } else {
        event.active = 'period.end'
      }
    } else if (event.active === 'trademark') {
      event.active = 'trademark.name'
    }
    this.sort.emit(event)
  }

  async handlePage(event: PageEvent) {
    this.page.emit(event);
  }


  toDocument(document: CaseDocument) {
    this.router.navigateByUrl(`/documents/${document._id}`, {
      state: {
        from: "CASE"
      }
    })
  }

  toTrademark(trademark: any) {
    if (trademark && trademark.ref) {
      this.router.navigate(['trademarks', trademark.ref], {
        state: {
          from: "CASE"
        }
      })
    }
  }

  downloadDocument(document: CaseDocument) {
    // this.otpService.check(() => console.log("ok"), () => console.log("ko"))
  }

  documentCases(document: CaseDocument) {

  }

  linkDocument(document: CaseDocument) {

  }

  deleteDocument(document: CaseDocument) {

  }
}
