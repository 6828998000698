import { Moment } from "moment";

export enum MetadataType {
  CLASS = 'CLASS',
  LANG = 'LANG',
  TRADEMARK = 'TRADEMARK',
  NICE_CLASS = 'NICE_CLASS',
  LOCATION = 'LOCATION',
  DATE = 'DATE',
  PERIOD = 'PERIOD',
  USER = 'USER'
}


export interface DocumentMetadata {
  _id?: string;
  type: MetadataType;
  value: string;
  document: string;
  manual: boolean;
  timestamping?: boolean;
  confidence: number;
  user?: {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  createdAt?: Moment;
}


export interface DocumentMetadataAndEvidence {
  _id?: string;
  type: MetadataType;
  value: string;
  evidence?: {
    page: number;
    start: number[]
    end: number[]
  }
}