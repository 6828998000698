import { Directive, ElementRef, Input } from '@angular/core';
import { AclService, Resource, Scope } from '../services/acl.service';
import * as _ from 'lodash';

@Directive({
  selector: '[aclBasedElement]'
})
export class AclBasedElementDirective {

  @Input() resource?: Resource;;
  @Input() scopes: string[] = [];

  constructor(
    private aclService: AclService,
    private el: ElementRef
  ) { }

  ngOnInit() {
    const acls = this.aclService.acls;
    if (this.resource && this.scopes.length > 0) {
      const hasRight = acls.hasOwnProperty(this.resource) && _.intersection(acls[this.resource], this.scopes.map(s => Scope[s as keyof typeof Scope])).length > 0;
      if (!hasRight) {
        this.el.nativeElement.parentNode.removeChild(this.el.nativeElement);
      }
    }
  }

}
