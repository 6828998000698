import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<any>();

  private emitChangeSource = new Subject<{
    key: string,
    data: any
  }>();

  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(type: string, action: string, data?: any) {
    // if (!environment.production) {
    //   console.log(`[${moment().format()}] Emitting event with key ${type}.${action} and data`, data)
    // }
    this.emitChangeSource.next({
      key: `${type}.${action}`,
      data
    });
  }

  sendMessage(type: string, data: string) {
    this.subject.next({ type: type, data: data });
  }

  clearMessages() {
    this.subject.next(null);
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
}