<div class="fx-row fx-wrap align-items-start justify-content-start">
  <div class="fx-row align-items-stretch justify-content-start fx-flex-100 mb-16">
    <div class="fx-flex-50 fx-row fx-wrap pr-16" style="height:200px;">
      <app-upload-documents-box class="h-100 fx-flex-100"></app-upload-documents-box>
    </div>
    <div class="fx-flex-50 pl-16" style="height:200px;">
      <app-document-map [territories]="countries"></app-document-map>
    </div>
  </div>
  <div class="fx-flex-100" *ngIf="!loading && done && !noDocs">
    <mat-card class="fx-flex-100 mv-8">
      <div class="fx-row align-items-center justify-content-between fx-flex-100 mb-8">
        <span class="text dark bold">{{'HOME.RECENTLY_UPLOADED_FOR_COUNTRY' | translate}}</span>
        <a href="/documents">{{'ACTIONS.VIEW_ALL' | translate}}</a>
      </div>
      <table mat-table [dataSource]="lastDocuments.docs" style="z-index: 0;" matSort>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'DOCUMENT.NAME' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document">
            <div class="fx-row align-items-center justify-content-start">
              <img [src]="documentIcon(document)" [height]="35" class="mr-10" />
              <div class="fx-column name-container">
                <span class="text bold name">{{document?.name || '-'}}</span>
                <div class="fx-row align-items-center justify-content-start">
                  <span class="small light">{{'DOCUMENT_CLASSES.'+document.class.toUpperCase() | translate}}</span>
                  <mat-icon class="ml-5 main" fontSet="material-icons-outlined" *ngIf="document.confidential"
                    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CONFIDENTIALITY.CONFIDENTIAL' | translate"
                    inset inline>lock</mat-icon>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'DOCUMENT.STATUS' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document" [matTooltipClass]="{'uzai-tooltip':true}"
            [matTooltip]="'DOCUMENT.STATUS_'+document.status+'_TOOLTIP' | translate">
            <div class="fx-row align-items-center justify-content-start">
              <mat-icon class="light" fontSet="material-icons-outlined"
                *ngIf="document.status === 'PENDING'">pending</mat-icon>
              <mat-icon class=" main spin" fontSet="material-icons-outlined"
                *ngIf="document.status === 'PROCESSING'">sync</mat-icon>
              <mat-icon class=" green" fontSet="material-icons-outlined"
                *ngIf="document.status === 'PROCESSED'">check_circle</mat-icon>
              <mat-icon class=" red" fontSet="material-icons-outlined"
                *ngIf="document.status === 'ERROR'">error_outline</mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="trademark">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'ENTITIES.TRADEMARKS' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document">
            <div class="fx-column" *ngIf="document?.trademarks && document?.trademarks.length === 1">
              <span class="text main">{{document.trademarks[0].name}}</span>
            </div>
            <div class="fx-column" *ngIf="document?.trademarks && document?.trademarks.length > 1">
              <span class="main">{{document?.trademarks.length}} {{('ENTITIES.TRADEMARKS' |
                translate).toLowerCase()}}</span>
            </div>
            <div class="text light" *ngIf="!document?.trademarks || document?.trademarks?.length === 0">-</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="nice">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'DOCUMENT.NICE_CLASSIFICATION' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document">
            <app-trademark-nice-classification [niceClassification]="document?.niceClassification"
              class="mt-5"></app-trademark-nice-classification>
          </td>
        </ng-container>
        <ng-container matColumnDef="territories">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'DOCUMENT.TERRITORIES' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document">
            <app-document-countries [territories]="document.territories"
              *ngIf="document.territories && document.territories.length > 0"></app-document-countries>
            <div class="text light" *ngIf="!document.territories || document.territories.length === 0">-</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'DOCUMENT.PERIOD' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let document">
            <span class="text"
              *ngIf="document?.period && document.period.start && document.period.end">{{formatPeriod(document)}}</span>
            <div class="text light" *ngIf="!document?.period || !document.period.start || !document.period.end">-</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let document">
            <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let document; columns: displayedColumns;" class="clickable"
          (click)="toDocument(document)">
        </tr>
      </table>
    </mat-card>
  </div>
</div>