import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, concat, toArray } from 'rxjs';
import { Case } from 'src/app/models/case';
import { User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { CaseService } from 'src/app/services/case.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-case-operator-constraint-dialog',
  templateUrl: './case-operator-constraint-dialog.component.html',
  styleUrls: ['./case-operator-constraint-dialog.component.scss']
})
export class CaseOperatorConstraintDialogComponent {
  user?: User;
  constraints: string[] = [];
  cases: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user?: User },
    private aclService: AclService,
    private caseService: CaseService,
    private dialogRef: MatDialogRef<CaseOperatorConstraintDialogComponent>,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService
  ) {
  }

  async ngOnInit() {
    if (this.data && this.data.user) {
      this.user = this.data.user;
      if (this.user.acls) {
        this.constraints = this.aclService.getCasesConstraints(this.user.acls);
        this.cases = await Promise.all(this.constraints.map(async c => await this.caseService.retrieve(c)));
      }
    }
  }

  ngOnDestroy(): void {
  }


  onCaseChange(event: any) {
    console.log(event)
  }

  onCancel(): void {
    this.dialogRef.close({
      confirmed: false
    });
  }

  async onConfirm() {
    try {
      if (this.user && this.user._id) {
        const observables: Observable<User>[] = [];
        this.constraints.forEach(async constraint => {
          if (!this.cases.map(c => c._id).includes(constraint)) {
            observables.push(this.userService.deleteOperatorConstraint(this.user?._id!, `${constraint}:CASE`))
          }
        });
        this.cases.forEach(caseObject => {
          if (caseObject._id && !this.constraints.includes(caseObject._id)) {
            observables.push(this.userService.addOperatorConstraint(this.user?._id!, `${caseObject._id}:CASE`))
          }
        });
        concat(...observables).pipe(toArray()).subscribe((res) => {
          this.dialogRef.close({
            confirmed: true
          });
          this.toastr.success(this.translate.instant("CASE_OPERATOR_CONSTRAINT_DIALOG.CONSTRAINT_MODIFIED", this.user));
        }, err => {
          this.toastr.error(`ERRORS.GENERIC`)
        })
      }
    } catch (err: any) {
      console.log(err)
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }
}
