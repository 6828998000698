<div class="fx-column">
  <span class="label" *ngIf="labelOutside">{{label}}</span>
  <div class="button" [class.active]="selection !== undefined" style="max-width:100%;" [cdkMenuTriggerFor]="menu"
    #trigger="cdkMenuTriggerFor" [class.disabled]="disabled">
    <div class="fx-row fx-flex-100 align-items-center justify-content-between label-container">
      <div class="text main label-container" *ngIf="selection">{{selection}}</div>
      <div class="text label-container" *ngIf="!selection">{{label}}</div>
      <mat-icon class="dark ml-5" fontSet="material-icons-outlined" *ngIf="!selection">expand_more</mat-icon>
      <mat-icon class="main ml-5" fontSet="material-icons-outlined" *ngIf="selection"
        (click)="$event.stopPropagation();onClear()">clear</mat-icon>
    </div>
  </div>
  <ng-template #menu>
    <mat-card class="menu-container p-0 elevation" cdkMenu>
      <div class="fx-column" (click)="$event.stopPropagation()">
        <div>
          <div class="fx-row align-items-center justify-content-between p-16">
            <span class="text bold" *ngIf="label">{{label | translate}}</span>
            <span class="small clickable" (click)="onClear()">{{'ACTIONS.CLEAR' | translate}}</span>
          </div>
          <mat-divider></mat-divider>
          <div class="fx-row align-items-center justify-content-between p-16">
            <span class="text bold" *ngIf="label">{{yearRange.start}} - {{yearRange.end}}</span>
            <div class="fx-row align-items-center justify-content-end">
              <mat-icon class="dark mr-5 clickable" fontSet="material-icons-outlined"
                (click)="previousYearRange()">keyboard_arrow_left</mat-icon>
              <mat-icon class="dark ml-5 clickable" fontSet="material-icons-outlined"
                (click)="nextYearRange()">keyboard_arrow_right</mat-icon>
            </div>
          </div>
          <mat-divider></mat-divider>
        </div>
        <div>
          <div class="fx-row fx-wrap align-items-center justify-content-start">
            <div class="fx-flex-25 p-10" *ngFor="let year of currentYears"
              (click)="isDisabled(year) ? null : select(year)" (mouseover)="isDisabled(year) ? null : project(year)"
              (mouseout)="isDisabled(year) ? null : unproject()">
              <div class="chip text-center" [class.clickable]="!isDisabled(year)" [class]="getClass(year)">
                {{year}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-template>
</div>