import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import * as _ from "lodash";
import { Document, DocumentFilters } from '../../../models/document';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DocumentService } from 'src/app/services/document.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CdkMenuTrigger } from '@angular/cdk/menu';
import { OtpService } from 'src/app/services/otp.service';
import { TrademarkService } from 'src/app/services/trademark.service';
import { Role, User } from 'src/app/models/user';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Trademark } from 'src/app/models/trademark';
import { MatCard } from '@angular/material/card';
import { Subject, Subscription, debounce, debounceTime, distinctUntilChanged, firstValueFrom, fromEvent, interval, last, throttle } from 'rxjs';
import { Paginator } from 'src/app/models/paginator';
import { PageEvent } from '@angular/material/paginator';
import { MessageService } from 'src/app/services/message.service';
import { NiceClass } from 'src/app/models/niceClass';
import { identifierName } from '@angular/compiler';
import { DocumentsReportComponent } from 'src/app/components/documents-display/documents-report/documents-report.component';
import { Territory } from 'src/app/models/territory';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';




@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent implements OnInit {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;

  count: number = 0;

  filteredDocuments: Document[] = [];
  selection = new SelectionModel<Document>(true, []);

  private readonly filtersSubject = new Subject<DocumentFilters>();
  private filtersSubscription?: Subscription;

  @ViewChild("documentsReport") documentsReport?: DocumentsReportComponent;

  classes: any[] = [];

  user?: User;

  displayMode: string = "table";

  levelOptions: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "trademark",
        label: "ENTITIES.TRADEMARK",
        selected: false
      },
      {
        value: "territory",
        label: "DOCUMENTS_HIERARCHY.TERRITORY",
        selected: false
      },
      {
        value: "nice",
        label: "DOCUMENTS_HIERARCHY.NICE_CLASSIFICATION",
        selected: false
      },
      {
        value: "year",
        label: "DOCUMENTS_HIERARCHY.YEAR",
        selected: false
      },
      {
        value: "language",
        label: "DOCUMENTS_HIERARCHY.LANGUAGE",
        selected: false
      },
      {
        value: "class",
        label: "DOCUMENTS_HIERARCHY.DOCUMENT_CLASS",
        selected: false
      }
    ];

  level1Options: {
    value: string,
    label: string,
    selected: boolean
  }[] = [];
  level2Options: {
    value: string,
    label: string,
    selected: boolean
  }[] = [];
  level3Options: {
    value: string,
    label: string,
    selected: boolean
  }[] = []

  levels: {
    [level: number]: string
  } = {
      1: 'trademark',
      2: 'territory',
      3: 'nice'
    };
  active: boolean = false;

  noDocumentButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'ACTIONS.UPLOAD',
      icon: 'cloud_upload',
      class: 'main-button',
      action: () => { this.documentService.openUploadDialog([]) }
    }

  @ViewChild('trigger') trigger?: CdkMenuTrigger;
  @ViewChild('documentscontainer', { read: ElementRef }) documentsContainer?: ElementRef;

  subscription: Subscription;
  uploadingBox: boolean = false;

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    public documentService: DocumentService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "UPLOAD.SHOW") {
        this.uploadingBox = true;
      }
      if (change && change.key == "UPLOAD.HIDE") {
        this.uploadingBox = false;
      }
    });
    if (window.history && window.history.state) {
      if (window.history.state.trademarks) {
        this.documentService.filters.trademark = window.history.state.trademarks;
      }
    }
  }

  async ngOnInit() {
    this.loading = true;
    this.done = false;
    await this.countDocuments();
    await this.retrieveDocuments(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.countDocuments();
        if (this.displayMode === 'report' && this.documentsReport) {
          await this.documentsReport.aggregateDocuments();
        } else {
          await this.retrieveDocuments(false);
        }
      });
    this.level1Options = this.levelOptions.map((option) => ({ ...option, selected: option.value === 'trademark' }));
    this.level2Options = this.levelOptions.map((option) => ({ ...option, selected: option.value === 'territory' }));
    this.level3Options = this.levelOptions.map((option) => ({ ...option, selected: option.value === 'nice' }));
  }

  async reload() {
    this.resetPage();
    await this.countDocuments();
    if (this.displayMode === 'report' && this.documentsReport) {
      await this.documentsReport.aggregateDocuments();
    } else {
      await this.retrieveDocuments(false);
    }
  }

  async onTabChange(event: any) {
    if (event.index === 0) {
      this.displayMode = "table";
    } else if (event.index === 1) {
      this.displayMode = "report";
    } else if (event.index === 2) {
      this.displayMode = "hierarchy";
    }
    if (this.displayMode === "table") {
      await this.retrieveDocuments(false);
    }
  }

  async countDocuments() {
    try {
      this.count = (await this.documentService.countAll(this.documentService.filters.territories, this.documentService.filters.classNumber, this.documentService.filters.trademark, this.documentService.filters.documentClass, this.documentService.filters.period, this.documentService.filters.query)).count;
    } catch (err) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  async retrieveDocuments(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.documentService.pager = { ...this.documentService.pager, ...await this.documentService.retrieveAll(this.documentService.pager.page, this.documentService.pager.limit, this.documentService.pager.offset, this.documentService.pager.sort, this.documentService.filters.territories, this.documentService.filters.classNumber, this.documentService.filters.trademark, this.documentService.filters.documentClass, this.documentService.filters.period, this.documentService.filters.nonUse, this.documentService.filters.query) };
      this.filteredDocuments = this.documentService.pager.docs;

      if (init && this.documentService.pager.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.documentService.pager.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.documentService.pager.limit = 10;
    this.documentService.pager.page = 1;
    this.documentService.pager.offset = 0;
  }

  async handlePage(event: any) {
    this.documentService.pager.limit = event.pageSize;
    this.documentService.pager.page = event.pageIndex + 1;
    this.documentService.pager.offset = event.pageIndex * this.documentService.pager.limit;

    this.retrieveDocuments(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.documentService.pager.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveDocuments(false);
    }
  }


  onNiceClassificationChange(event: any) {
    if (event && event.value) {
      this.documentService.filters.classNumber = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
      this.filtersSubject.next({ ...this.documentService.filters });
    }
  }

  onTerritoryChange(event: any) {
    if (event && event.value) {
      this.documentService.filters.territories = event.value.map((territory: Territory) => territory.alternateCodes ? [territory.id, ...territory.alternateCodes] : [territory.id]).flat();
      this.filtersSubject.next({ ...this.documentService.filters });
    }
  }

  onTrademarkChange(event: any) {
    if (event && event.value) {
      this.documentService.filters.trademark = event.value;
      this.filtersSubject.next({ ...this.documentService.filters });
    }
  }

  onDocumentClassChange(event: any) {
    if (event && event.value) {
      this.documentService.filters.documentClass = event.value.map((v: any) => v.value);
      this.filtersSubject.next({ ...this.documentService.filters });
    }
  }

  onPeriodChange(event: any) {
    if (event && event.hasOwnProperty("start") && event.hasOwnProperty("end")) {
      this.documentService.filters.period = event;
      this.filtersSubject.next({ ...this.documentService.filters });
    }

  }

  onQueryChange(query: any) {
    if (typeof query === 'string') {
      this.filtersSubject.next({ ...this.documentService.filters });
    }

  }

  onLevelChange(level: number, event: any) {
    if (event && event.value && event.value.value) {
      this.levels[level] = event.value.value;
    }
  }

  upload() {
    this.documentService.openUploadDialog([]);
  }

  toBatches() {
    this.router.navigateByUrl('/documents/batches', {
      state: {
        from: "DOCUMENTS"
      }
    })
  }


  async deleteDocuments() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'DOCUMENTS_LIST.DELETE_DOCUMENTS_TITLE',
        text: this.translate.instant('DOCUMENTS_LIST.DELETE_DOCUMENTS_TEXT', { length: this.selection.selected.length }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.documentService.deleteMany(this.selection.selected.map((doc) => doc._id!));
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('DOCUMENTS_LIST.DOCUMENTS_DELETED', { length: this.selection.selected.length }));
        this.selection.clear();
        await this.countDocuments();
        await this.retrieveDocuments(false);


      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

}


