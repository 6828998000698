<div class="fx-row align-items-center justify-content-start" *ngIf="mode !== 'row'">
  <img *ngFor="let country of territories.slice(0,limit); let index = index"
    [src]="'/assets/countries/'+country.toLowerCase()+'.svg'" class="country-flag"
    [style.margin-left.px]="index === 0 ? 0 : -10" [style.z-index]="territories.length - index"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'TERRITORIES.'+country.toUpperCase() | translate"
    [matTooltipClass]="{ 'uzai-tooltip': true }" />
  <!-- <div class="more-container"  [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="moreTooltip()"> -->
  <span class="small main ml-5 bold" *ngIf="territories.length > 3">+{{territories.length - limit}}</span>
  <!-- </div> -->
</div>
<div *ngIf="mode === 'row'">
  <div class="mb-8 fx-row align-items-center justify-content-between" *ngIf="title">
    <span class="text light">{{'DOCUMENT.TERRITORIES' | translate}}</span>
    <mat-icon [class.shown]="shown" class="visibility-icon" fontSet="material-icons-outlined">{{shown ? 'visibility' :
      'visibility_off'}}</mat-icon>
  </div>
  <div class="fx-row fx-wrap align-items-center justify-content-between mb-10">
    <div class="fx-row fx-flex-50 border-box align-items-center justify-content-between mb-10"
      *ngFor="let territory of territories.slice(0,limit); let index = index" [class.pl-10]="index % 2 == 1"
      [class.pr-10]="index % 2 == 0">
      <div class="fx-row fx-flex-100 align-items-center justify-content-start">
        <img [src]="'/assets/countries/'+territory.toLowerCase()+'.svg'" class="country-flag" />
        <span class="text ml-10">{{('TERRITORIES.'+territory.toUpperCase() | translate)}}</span>
      </div>
      <app-metadata-confidence class="ml-20" [value]="getConfidence(territory.toUpperCase())"></app-metadata-confidence>
    </div>
  </div>
  <span class="text" *ngIf="!territories || territories.length === 0">-</span>
  <div class="fx-row w-100 align-items-center justify-content-end mt-10 clickable" *ngIf="territories.length > 4">
    <span class="text main " (click)="toggle()">{{(limit === 4 ? 'UTILS.SEE_MORE' : 'UTILS.SEE_LESS') |
      translate}}</span>
    <mat-icon class="main ml-5" fontSet="material-icons-outlined">{{limit === 4 ? 'keyboard_arrow_down' :
      'keyboard_arrow_up'}}</mat-icon>
  </div>
</div>