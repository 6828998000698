import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-trademarks',
  templateUrl: './document-trademarks.component.html',
  styleUrls: ['./document-trademarks.component.scss']
})
export class DocumentTrademarksComponent implements OnInit, OnChanges {

  @Input() metadata?: DocumentMetadata[] = [];
  @Input() trademarks: {
    ref: string;
    name: string;
    identifierNumber: string;
    countryOfDesignation: string;
    confidence?: number;
  }[] = [];
  @Input() shown: boolean = false;
  limit: number = 3;

  focused: boolean = false;
  @Output() focus = new EventEmitter<boolean>();


  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.populateConfidence()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateConfidence()
  }

  populateConfidence() {
    this.trademarks.forEach(trademark => {
      trademark.confidence = this.getConfidence(trademark)
    })
  }

  getConfidence(trademark: {
    ref: string;
    name: string;
    identifierNumber: string;
    countryOfDesignation: string;
  }) {
    return (this.metadata?.find(datum => datum.type === MetadataType.TRADEMARK && datum.value === `${trademark.ref}_${trademark.name || ''}_${trademark.identifierNumber}_${trademark.countryOfDesignation}`)?.confidence ?? 0) * 100
  }

  toggle() {
    if (this.limit === 3) {
      this.limit = this.trademarks.length;
    } else {
      this.limit = 3;
    }
  }


  toTrademark(id: string | undefined) {
    if (id) {
      this.router.navigate(['trademarks', id], {
        state: {
          from: "DOCUMENT"
        }
      })
    }
  }

  enterFocus() {
    this.focused = true;
    this.focus.emit(true);
  }

  leaveFocus() {
    this.focused = false;
    this.focus.emit(false);
  }

}
