import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(
    private http: HttpClient
  ) {
  }

  askQuestion(question: string) {
    return firstValueFrom(this.http
      .post<void>(`${environment.backend}/support/question`, { question }));
  }

  phoneBack(phoneNumber: string) {
    return firstValueFrom(this.http
      .post<void>(`${environment.backend}/support/phoneBack`, { phoneNumber }));
  }
}
