import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CasesByPhase } from 'src/app/services/case.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CaseStatus } from 'src/app/models/case';

@Component({
  selector: 'app-cases-chart',
  templateUrl: './cases-chart.component.html',
  styleUrls: ['./cases-chart.component.scss']
})
export class CasesChartComponent {
  @Input() data: CasesByPhase[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('chartcasesdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.XYChart;

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }
  ngAfterViewInit(): void {
    this.makeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.makeChart();
  }


  makeChart() {
    const priority = [CaseStatus.INSTRUCTION, CaseStatus.DECISION, CaseStatus.CLOSED];
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (this.chartElement) {
        am4core.useTheme(am4themes_animated.default);

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
        chart.logo.setVisibility(false);

        chart.data = this.data.map((datum) => ({
          count: datum.count,
          name: datum._id.name,
          phase: this.translate.instant(`CASE.PHASE_${datum._id.name.toUpperCase()}`)
        })).sort((a, b) => priority.indexOf(a.name) - priority.indexOf(b.name));

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "phase";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "phase";
        series.dataFields.valueX = "count";
        series.tooltipText = "{valueX.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.fill = am4core.color("#8847E3");

        var labelBullet = series.bullets.push(new am4charts.LabelBullet())
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.dx = 10;
        labelBullet.label.text = "{values.valueX.workingValue.formatNumber('#.')}";
        labelBullet.locationX = 1;
        labelBullet.label.fill = am4core.color("#fff");
        labelBullet.label.fontSize = 14;
        labelBullet.label.fontWeight = 'bold';


        this.chart = chart;
        this.chart.events.on('ready', () => {
          self.ready = true;
          this.changeDetector.detectChanges()
        })
      }
    })
  }
}
