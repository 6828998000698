<div class="panel fx-column" (keyup.enter)="login()">
  <div class="fx-row align-items-center justify-content-start">
    <img src="/assets/img/logo.svg" height="60">
  </div>
  <div class="fx-column fx-flex-100 align-items-center justify-content-center panel-content">
    <div class="fx-row fx-wrap justify-content-center align-items-center">
      <div class="fx-column fx-flex-100 align-items-center justify-content-center">
        <span class="title main bold">{{'LOGIN.TITLE' | translate}}</span>
        <span class="text">{{'LOGIN.SUBTITLE' | translate}}</span>
      </div>
      <div class="fx-column mv-30 fx-flex-50">
        <app-input [placeholder]="('LOGIN.EMAIL' | translate) + '...'" type="text" prefix="mail" class="mb-10"
          [(value)]="email" [error]="errors.email" [bottomWrapper]="true"></app-input>
        <app-input [placeholder]="('LOGIN.PASSWORD' | translate) + '...'" type="password" prefix="lock"
          [(value)]="password" [error]="errors.password" [bottomWrapper]="true"></app-input>
        <div class="fx-row align-items-center justify-content-end mt-10 fx-flex">
          <a class="small" href="/forgot-password">{{'LOGIN.FORGOT_PASSWORD' | translate}}</a>
        </div>
        <div class="fx-row align-items-center justify-content-center mt-10 fx-flex">
          <button class="main-button fx-flex" (click)="login()" [disabled]="loading">
            {{'ACTIONS.LOGIN' | translate}}
          </button>
        </div>
      </div>
      <div class="fx-column fx-flex-100 align-items-center justify-content-center">
        <a>{{'LOGIN.NO_ACCOUNT' | translate}}</a>
      </div>
    </div>
  </div>
  <div class="fx-column align-items-center justify-content-center">
    <img src="/assets/icons/icon.svg" height="50" class="mb-10">
    <a class="main">uzai.tech</a>
  </div>
</div>