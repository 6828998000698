import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import * as _ from "lodash";
import { TranslateService } from '@ngx-translate/core';
import { DocumentMetadata } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-timeline',
  templateUrl: './document-timeline.component.html',
  styleUrls: ['./document-timeline.component.scss']
})
export class DocumentTimelineComponent implements OnInit {
  @Input() metadata?: DocumentMetadata[] = [];
  @Input("period") period?: {
    start: moment.Moment,
    end: moment.Moment,
    dates: moment.Moment[]
  } = {
      start: moment(),
      end: moment(),
      dates: []
    };
  @Input() shown: boolean = false;

  years: number[] = [];
  months: string[] = [];

  heatmap: {
    [year: number]: number[]
  } = {};

  constructor(private translateService: TranslateService) {
    moment.locale(this.translateService.currentLang)
  }

  ngOnInit(): void {
    this.months = _.range(1, 13).map((step) => moment.utc(step, "M").format("MMM"))
    this.buildHeatmap();
  }

  // getConfidence(user: string) {
  //   return (this.metadata?.find(datum => datum.type === MetadataType.USER && datum.value === user.replace(' ', '_'))?.confidence ?? 0) * 100
  // }

  buildHeatmap() {
    if (this.period && this.period.start && moment.utc(this.period.start).isValid() && this.period.end && moment.utc(this.period.end).isValid()) {
      const start = moment.utc(this.period.start);
      const end = moment.utc(this.period.end)
      let date = start.clone();
      while (date.year() <= end.year()) {
        this.years.push(date.year());
        date = date.add(1, 'year');
      }
      this.years.forEach(year => {
        this.heatmap[year] = []
        this.months.forEach(month => {
          this.heatmap[year].push(0)
        })
      })
      this.period.dates.forEach(date => {
        const momentDate = moment.utc(date);
        this.heatmap[momentDate.year()][momentDate.month()] = 100;
      })
    } else {
      let date = moment.utc().add(-3, 'years').clone();
      while (date.year() <= moment.utc().year()) {
        this.years.push(date.year());
        date = date.add(1, 'year');
      }
      this.years.forEach(year => {
        this.heatmap[year] = []
        this.months.forEach(month => {
          this.heatmap[year].push(0)
        })
      })
    }

  }
}
