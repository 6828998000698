<table mat-table [dataSource]="filteredDocuments" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary"
        [checked]="hasPageSelection() && isAllSelected()" [indeterminate]="hasPageSelection() && !isAllSelected()"
        [disabled]="readonly">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(row) : null"
        [checked]="isSelected(row)" [disabled]="readonly">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'DOCUMENT.NAME' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-row align-items-center justify-content-start clickable" (click)="toDocument(document)">
        <img [src]="documentIcon(document)" [height]="35" class="mr-10" />
        <div class="fx-column name-container">
          <span class="text bold name">{{document?.name || '-'}}</span>
          <div class="fx-row align-items-center justify-content-start">
            <span class="small light">{{'DOCUMENT_CLASSES.'+document.class.toUpperCase() | translate}}</span>
            <mat-icon class="ml-5 main" fontSet="material-icons-outlined" *ngIf="document.confidential"
              [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CONFIDENTIALITY.CONFIDENTIAL' | translate" inset
              inline>lock</mat-icon>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="trademark">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'ENTITIES.TRADEMARKS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-column" *ngIf="document?.trademarks && document?.trademarks.length === 1">
        <a (click)="toTrademark(document.trademarks[0])">{{document.trademarks[0].name}}</a>
      </div>
      <div class="fx-column" *ngIf="document?.trademarks && document?.trademarks.length > 1">
        <span class="main">{{document?.trademarks.length}} {{('ENTITIES.TRADEMARKS' | translate).toLowerCase()}}</span>
      </div>
      <div class="text light" *ngIf="!document?.trademarks || document?.trademarks?.length === 0">-</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="nice">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'DOCUMENT.NICE_CLASSIFICATION' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <app-trademark-nice-classification [niceClassification]="document?.niceClassification"
        class="mt-5"></app-trademark-nice-classification>
    </td>
  </ng-container>
  <ng-container matColumnDef="territories">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'DOCUMENT.TERRITORIES' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <app-document-countries [territories]="document.territories"
        *ngIf="document.territories && document.territories.length > 0"></app-document-countries>
      <div class="text light" *ngIf="!document.territories || document.territories.length === 0">-</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="period">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'DOCUMENT.PERIOD' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <span class="text"
        *ngIf="document?.period && document.period.start && document.period.end">{{formatPeriod(document)}}</span>
      <div class="text light" *ngIf="!document?.period || !document.period.start || !document.period.end">-</div>
    </td>
  </ng-container>
  <ng-container matColumnDef="score">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'CASE.DOCUMENT_RELEVANCE' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <app-document-case-score [score]="document.score"></app-document-case-score>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;">
  </tr>
</table>
<div class="p-16" *ngIf="documents.totalDocs === 0">
  <span class="text light">{{'CASE.NO_PROOFS' | translate}}</span>
</div>
<mat-paginator [length]="documents.totalDocs || 0" [pageSize]="documents.limit || 0"
  [pageIndex]="documents && documents.page ? (documents.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
  (page)="handlePage($event)">
</mat-paginator>