import { Component, Input } from '@angular/core';
import { Document } from 'src/app/models/document';

@Component({
  selector: 'app-metadata-missing',
  templateUrl: './metadata-missing.component.html',
  styleUrls: ['./metadata-missing.component.scss']
})
export class MetadataMissingComponent {
  @Input() document?: Document;
}
