import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-metadata-confidence',
  templateUrl: './metadata-confidence.component.html',
  styleUrls: ['./metadata-confidence.component.scss']
})
export class MetadataConfidenceComponent {
  @Input() value: number = 25;
  @Input() class?: string;

  constructor(
    private translate: TranslateService
  ) {

  }

  tooltip() {
    return this.translate.instant('UTILS.CONFIDENCE', { confidence: Math.round(this.value) })
  }
}
