import { Injectable } from "@angular/core";
import { MD5 } from "crypto-js";
import * as moment from 'moment';

export enum TimeToLive {
  fiveMinutes = 5,
  twentyMinutes = 15,
  oneHour = 60,
  sixHours = 360,
  twelveHours = 720,
  oneDay = 1440
}

interface StoredData {
  requestKey: string;
  data: unknown;
  expirationDate: moment.Moment;
}

@Injectable({
  providedIn: "root"
})
export class DataStore {

  data: {
    [service: string]: {
      [hash: string]: StoredData
    }
  };

  constructor() {
    this.data = {};
  }

  private get(httpService: string, hash: string) {
    return this?.data?.[httpService]?.[hash];
  }

  private set(httpService: string, hash: string, value: any) {
    if (!this.data) {
      this.data = {};
    }
    if (!this.data[httpService]) {
      this.data[httpService] = {};
    }
    this.data[httpService][hash] = value;
  }

  private delete(httpService: string, hash: string): void {
    delete this.data[httpService][hash];
  }

  store(httpService: string, propertyKey: string, descriptor: PropertyDescriptor, ttlMinutes = TimeToLive.oneHour, forceRefresh = false) {
    const originalMethod = descriptor.value as (...args: []) => void;
    const self = this;
    // Purpose is to change the descriptor
    descriptor.value = async function wrapWithStore<T>(...args: []) {
      const hash = MD5(`${propertyKey}${JSON.stringify(args)}`).toString();
      const storedData = self.get(httpService, hash);
      if (storedData) {
        if (!forceRefresh && storedData.expirationDate.isAfter(moment().utc())) {
          return storedData.data as T;
        }
        self.delete(httpService, hash);
      }
      const data = await originalMethod.apply(this, args);
      const expirationDate = moment().utc().add(ttlMinutes, "minutes");
      self.set(httpService, hash, { requestKey: propertyKey, data, expirationDate });
      return data as T;
    };
  }

  clear(httpService: string) {
    if (this?.data?.[httpService]) {
      this.data[httpService] = {};
    }
  }
}
