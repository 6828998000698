import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Trademark, TrademarkType } from 'src/app/models/trademark';

@Component({
  selector: 'app-create-trademark-family-dialog',
  templateUrl: './create-trademark-family-dialog.component.html',
  styleUrls: ['./create-trademark-family-dialog.component.scss']
})
export class CreateTrademarkFamilyDialogComponent {

  name?: string;
  nameDisabled: boolean = false;
  type?: string;
  label?: string;
  notes?: string;
  trademarks: Trademark[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { name?: string, type?: string, trademarks: Trademark[] },
    private dialogRef: MatDialogRef<CreateTrademarkFamilyDialogComponent>,
    private translate: TranslateService
  ) {
    this.name = this.data.name;

    this.type = this.data.type;
    if (this.name || (this.type && this.type === TrademarkType.FIGURATIVE)) {
      this.nameDisabled = true;
    }
    this.trademarks = this.data.trademarks || [];
  }

  isDisabled() {
    return !this.label;
  }

  hint() {
    if (this.name && this.type) {
      return this.translate.instant('TRADEMARK_FAMILY.SUGGESTION_HINT', { name: this.name, type: this.type }).replace(/<\/?[^>]+(>|$)/g, "");
    } else {
      return this.translate.instant('TRADEMARK_FAMILY.NOTES');
    }

  }

  onTrademarkTypeChange(event: any) {
    console.log(event)
    console.log(this.type)
  }

  onCreate() {
    this.dialogRef.close({
      created: true,
      family: {
        label: this.label,
        notes: this.notes,
        type: this.type,
        name: this.name
      }
    })
  }

  onCancel() {
    this.dialogRef.close({
      created: false
    })
  }
}
