<div class="p-20 fx-row fx-wrap align-items-center justify-content-center text-center">
  <div class="fx-flex-100 align-items-center justify-content-start text-center">
    <span class="title mb-10 text-center">{{'CASE_OPERATOR_CONSTRAINT_DIALOG.TITLE' | translate}}</span>
  </div>
  <span class="text mt-20" [innerHTML]="'CASE_OPERATOR_CONSTRAINT_DIALOG.INSTRUCTIONS' | translate:user"></span>
  <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center mv-20">
    <app-case-select label="{{'ENTITIES.CASES' | translate}}" [multiple]="true" [search]="true"
      (change)="onCaseChange($event)" [(value)]="cases" [emitOnInit]="false" class="fx-flex-100"></app-case-select>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onConfirm()">{{'ACTIONS.CONFIRM'
      | translate}}</button>
  </div>
</div>