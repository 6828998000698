import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { Case, CaseType } from 'src/app/models/case';
import { NiceClass } from 'src/app/models/niceClass';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { Trademark } from 'src/app/models/trademark';
import { PropertyOfficeService } from 'src/app/services/property-office.service';
import { TrademarkService } from 'src/app/services/trademark.service';
import { NiceSelectComponent } from '../nice-select/nice-select.component';

@Component({
  selector: 'app-case-content',
  templateUrl: './case-content.component.html',
  styleUrls: ['./case-content.component.scss']
})
export class CaseContentComponent implements OnInit, OnChanges {
  @Input() mode: "CONSULTATION" | "EDITION" | "COMPARISON" = "CONSULTATION";
  @Input() case?: Case;
  @Input() propertyOffice?: PropertyOffice;

  @ViewChild("niceSelect") niceSelect?: NiceSelectComponent;


  trademark?: Trademark;
  niceClassification: number[] = [];
  period: {
    notificationDate?: moment.Moment;
    start?: moment.Moment;
    end?: moment.Moment;
  } = {
      notificationDate: undefined,
      start: undefined,
      end: undefined
    };
  type?: CaseType;
  lang?: string;



  caseTypes: {
    value: string,
    label: string,
    selected: boolean,
    disabled: boolean
  }[] = [
      {
        value: CaseType.PRINCIPAL,
        label: "CASE.TYPE_PRINCIPAL",
        selected: false,
        disabled: false
      },
      {
        value: CaseType.COUNTERCLAIM,
        label: "CASE.TYPE_COUNTERCLAIM",
        selected: false,
        disabled: false
      }
    ];


  langs: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "en",
        label: "LANGUAGES.en",
        selected: false
      },
      {
        value: "fr",
        label: "LANGUAGES.fr",
        selected: false
      }
    ]


  constructor(
    private propertyOfficeService: PropertyOfficeService,
    private trademarkService: TrademarkService
  ) {
  }

  ngOnInit(): void {
    this.populateEntities();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes &&
      (changes['case'] && changes['case']['previousValue'] != changes['case']['currentValue'])
      || (changes['mode'] && changes['mode']['previousValue'] != changes['mode']['currentValue'])) {
      this.populateEntities();
    }
  }

  async populateEntities() {
    if (this.case) {
      if (this.case.trademark) {
        try {
          const trademark = await this.trademarkService.retrieve(this.case.trademark.ref);
          if (trademark) {
            this.trademark = {
              _id: trademark._id!,
              name: trademark.name!,
              identifierNumber: trademark.identifierNumber,
              countryOfDesignation: trademark.countryOfDesignation,
              niceClassification: trademark.niceClassification?.map((niceClass) => ({ classNumber: niceClass.classNumber }))
            }
          }
        } catch (err) {
          console.log("Error occured when retrieving case trademark")
        }

      }
      if (this.case.niceClassification) {
        this.niceClassification = this.case.niceClassification;
      }
      if (this.case.timePeriod) {
        this.period.notificationDate = moment(this.case.timePeriod.notificationDate);
        this.period.start = moment(this.case.timePeriod.start);
        this.period.end = moment(this.case.timePeriod.end);
      }
      if (this.case.lang) {
        this.lang = this.case.lang;
      }
      if (this.case.type) {
        this.type = this.case.type;
      }
    }
  }

  onTrademarkChange(event: { value?: Trademark }) {
    if (event && event.value) {
      this.trademark = {
        _id: event.value._id!,
        name: event.value.name!,
        identifierNumber: event.value.identifierNumber,
        countryOfDesignation: event.value.countryOfDesignation,
        niceClassification: event.value.niceClassification?.map(niceClass => ({ classNumber: niceClass.classNumber })) ?? []
      };
    } else {
      this.trademark = {
        _id: '',
        name: '',
        countryOfDesignation: '',
        identifierNumber: ''
      };
    }
    if (this.case && this.trademark) {
      this.case.trademark = {
        ref: this.trademark._id!,
        name: this.trademark.name!,
        identifierNumber: this.trademark.identifierNumber,
        countryOfDesignation: this.trademark.countryOfDesignation
      };
    }
  }

  onNiceClassificationChange(event: any) {
    this.niceClassification = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
    if (this.case) {
      this.case.niceClassification = this.niceClassification;
    }
  }

  onPeriodChange(event: any) {
    this.period = {
      ...this.period,
      start: event.start,
      end: event.end
    };
    if (this.case && this.period.start && this.period.end) {
      this.case.timePeriod = {
        ...this.case.timePeriod,
        start: this.period.start,
        end: this.period.end
      }
    }
  }

  onNotificationDateChange(event: any) {
    this.period = {
      ...this.period,
      notificationDate: event
    };
    if (this.case && this.period.notificationDate) {
      this.case.timePeriod = {
        ...this.case.timePeriod,
        notificationDate: this.period.notificationDate
      }
    }
  }

  onPropertyOfficeChange(event: any) {
    if (event && event.value) {
      this.propertyOffice = event.value;
      if (this.case) {
        this.case.propertyOffice = this.propertyOffice?._id;
      }

    }
  }

  onCaseTypeChange(event: any) {
    if (event && event.value) {
      this.type = event.value.value;
      if (this.case && this.type) {
        this.case.type = this.type
      }
    }
  }

  onLangChange(event: any) {
    if (event && event.value) {
      this.lang = event.value.value;
      if (this.case && this.lang) {
        this.case.lang = this.lang
      }
    }
  }

  formatYear(date: Date | string) {
    return moment(date).format("YYYY");
  }

  formatDate(date: moment.Moment) {
    return moment(date).format("ll");
  }

  classesRestrictions() {
    return (this.trademark && this.trademark.niceClassification) ? this.trademark.niceClassification.map((n) => n.classNumber) : [];
  }
}
