import { Component, Input } from '@angular/core';
import { TrademarkStatus } from 'src/app/models/trademark';

@Component({
  selector: 'app-trademark-status',
  templateUrl: './trademark-status.component.html',
  styleUrls: ['./trademark-status.component.scss']
})
export class TrademarkStatusComponent {
  @Input() status?: TrademarkStatus;
}
