import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Organization } from 'src/app/models/organization';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent {
  organization?: Organization;

  user?: User;

  userToInvite: {
    firstName?: string,
    lastName?: string,
    email?: string,
    role?: string
  } = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      role: undefined
    };

  roles: {
    label: string,
    value: string,
    selected: boolean,
    disabled: boolean,
    level: number
  }[] = [
      {
        label: 'USER_ROLES.CASE_OPERATOR',
        value: 'case_operator',
        selected: false,
        disabled: false,
        level: 10
      },
      {
        label: 'USER_ROLES.COUNTRY_OPERATOR',
        value: 'country_operator',
        selected: false,
        disabled: false,
        level: 10
      },
      {
        label: 'USER_ROLES.MANAGER',
        value: 'manager',
        selected: false,
        disabled: false,
        level: 50
      },
      {
        label: 'USER_ROLES.ADMIN',
        value: 'admin',
        selected: false,
        disabled: false,
        level: 100
      }
    ]

  errors: {
    [key: string]: {
      value: boolean,
      message?: string
    }
  } = {
      firstName: {
        value: false,
        message: undefined
      },
      lastName: {
        value: false,
        message: undefined
      },
      email: {
        value: false,
        message: undefined
      },
      roles: {
        value: false,
        message: undefined
      }
    }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<InviteUserDialogComponent>,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
      this.roles = this.roles.filter(role => {
        return role.level <= (this.user?.role?.level ?? 0);
      })
    });
  }

  ngOnInit(): void {
    this.organization = this.data.organization;
  }

  ngOnDestroy(): void {
  }

  isDisabled() {
    return !this.userToInvite.firstName || !this.userToInvite.lastName || !this.userToInvite.email || !this.userToInvite.role;
  }

  _hasErrors() {
    this.errors['firstName'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.firstName) {
      this.errors['firstName'].value = true;
      this.errors['firstName'].message = this.translate.instant('ERRORS.USER_FIRSTNAME_REQUIRED');
    }

    this.errors['lastName'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.lastName) {
      this.errors['lastName'].value = true;
      this.errors['lastName'].message = this.translate.instant('ERRORS.USER_LASTNAME_REQUIRED');
    }

    this.errors['email'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.email) {
      this.errors['email'].value = true;
      this.errors['email'].message = this.translate.instant('ERRORS.USER_EMAIL_REQUIRED');
    } else {
      if (!this.utils.validateEmailPattern(this.userToInvite.email)) {
        this.errors['email'].value = true;
        this.errors['email'].message = this.translate.instant('ERRORS.EMAIL_FORMAT');
      }
    }
    this.errors['role'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.role) {
      this.errors['role'].value = true;
      this.errors['role'].message = this.translate.instant('ERRORS.USER_ROLE_REQUIRED');
    }
    return this.errors['firstName'].value || this.errors['lastName'].value || this.errors['email'].value || this.errors['role'].value;
  }

  onCancel(): void {
    this.dialogRef.close({
      invited: false
    });
  }

  async onInvite() {
    try {
      if (this._hasErrors()) {
        return
      } else {
        const user = await this.organizationService.invite(this.userToInvite);
        this.toastr.success(this.translate.instant('INVITE_USER_DIALOG.INVITE_SENT', this.userToInvite));
        this.dialogRef.close({
          invited: true,
          user
        });
      }

    } catch (err: any) {
      if (err && err.error && err.error.message && this.utils.hasTranslation(`ERRORS.${err.error.message}`)) {
        this.toastr.error(`ERRORS.${err.error.message}`)
      } else {
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }
}
