<mat-card class="p-0 fx-flex-100 h-100">
  <div class="p-16">
    <span class="text bold">{{'CASE.INFORMATION' | translate}}</span>
  </div>
  <mat-divider></mat-divider>
  <div class="p-8 fx-row fx-wrap align-items-start justify-content-start" *ngIf="case && mode === 'CONSULTATION'">
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'ENTITIES.TRADEMARK' | translate}}</span>
      <span class="text main">{{case.trademark.name || case.trademark.identifierNumber}}</span>
      <span class="small">{{case.trademark.countryOfDesignation}} - {{case.trademark.identifierNumber}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'CASE.NICE_CLASSIFICATION' | translate}}</span>
      <div class="fx-row fx-wrap align-items-center justify-content-start">
        <div *ngFor="let niceClass of case.niceClassification" class="chip main mr-10 mt-5"
          [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'NICE_CLASSES.'+niceClass |
              translate">
          {{'NICE_CLASSES.CLASS_NUMBER' | translate}}{{niceClass}}
        </div>
      </div>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column" *ngIf="case.type">
      <span class="small light">{{'CASE.TYPE' | translate}}</span>
      <span class="text">{{'CASE.TYPE_'+case.type.toUpperCase() | translate}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'ENTITIES.PROPERTY_OFFICE' | translate}}</span>
      <span class="text">{{propertyOffice?.name}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column" *ngIf="case.timePeriod">
      <span class="small light">{{'CASE.NOTIFICATION_DATE' | translate}}</span>
      <span class="text">{{formatDate(case.timePeriod.notificationDate)}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'CASE.PERIOD' | translate}}</span>
      <span class="text">{{formatDate(case.timePeriod.start)}} - {{formatDate(case.timePeriod.end)}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <span class="small light">{{'CASE.REFERENCE' | translate}}</span>
      <span class="text">{{case.identifier || case.reference}}</span>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column" *ngIf="case.lang">
      <span class="small light">{{'CASE.LANG' | translate}}</span>
      <span class="text">{{'LANGUAGES.'+case.lang | translate}}</span>
    </div>
  </div>
  <div class="p-8 fx-row fx-wrap align-items-start justify-content-start" *ngIf="case && mode === 'EDITION'">
    <div class="fx-flex-50 p-8 border-box fx-column" *ngIf="trademark">
      <app-trademark-select [labelOutside]="true" label="{{'ENTITIES.TRADEMARK' | translate}}" [multiple]="false"
        [search]="true" initial="none" (change)="onTrademarkChange($event)" [(value)]="trademark" [emitOnInit]="true"
        class="fx-flex-100"></app-trademark-select>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column" *ngIf="trademark && niceClassification">
      <app-nice-select #niceSelect [labelOutside]="true" label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}"
        [multiple]="true" [search]="false" initial="none" (change)="onNiceClassificationChange($event)"
        [(value)]="niceClassification" [emitOnInit]="true" class="fx-flex-100"
        [restrict]="classesRestrictions()"></app-nice-select>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-select initial="none" label="{{'CASE.TYPE' | translate}}" [options]="caseTypes" [multiple]="false"
        (change)="onCaseTypeChange($event)" [emitOnInit]="false" [value]="type" [labelOutside]="true"
        class="fx-flex-100"></app-select>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-property-office-select [labelOutside]="true" label="{{'ENTITIES.PROPERTY_OFFICE' | translate}}"
        class="fx-flex-100" [search]="true" (change)="onPropertyOfficeChange($event)"
        [(value)]="propertyOffice"></app-property-office-select>
      <!-- <app-property-office-select [labelOutside]="true" label="{{'ENTITIES.PROPERTY_OFFICE' | translate}}"
        class="fx-flex-100" [search]="true" (change)="onPropertyOfficeChange($event)"
        *ngIf="!propertyOffice"></app-property-office-select> -->
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-datepicker label="{{'CASE.NOTIFICATION_DATE' | translate}}"
        placeholder="{{'CASE.NOTIFICATION_DATE' | translate}}" [value]="period.notificationDate"
        (change)="onNotificationDateChange($event)" class="fx-flex-100"></app-datepicker>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-daterangepicker label="{{'ENTITIES.PERIOD' | translate}}" placeholder="{{'ENTITIES.PERIOD' | translate}}"
        [(value)]="period" class="fx-flex-100"></app-daterangepicker>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-select initial="none" label="{{'CASE.LANG' | translate}}" [options]="langs" [multiple]="false"
        (change)="onLangChange($event)" [emitOnInit]="false" [value]="lang" [labelOutside]="true"
        class="fx-flex-100"></app-select>
    </div>
    <div class="fx-flex-50 p-8 border-box fx-column">
      <app-input label="{{'CASE.REFERENCE' | translate}}" placeholder="{{'CASE.REFERENCE' | translate}}..."
        class="fx-flex-100" type="text" [(value)]="case.identifier"></app-input>
    </div>
  </div>
</mat-card>