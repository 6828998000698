import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UploadFile } from 'src/app/models/uploadFile';

@Component({
  selector: 'app-upload-picture',
  templateUrl: './upload-picture.component.html',
  styleUrls: ['./upload-picture.component.scss']
})
export class UploadPictureComponent implements OnChanges {

  public active: boolean = false;
  private accepted: string[] = ['image/jpeg', 'image/jpg', 'image/png'];

  file?: File;

  @Input() src?: string;
  @Input() label?: string;
  public visible: boolean = false;

  @Input() height?: number = 200;

  @Output() pictureChange = new EventEmitter<any>();

  constructor(
    private toastr: ToastrService
  ) {
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
    const file: File = event.dataTransfer.file;
    this.handleFile(file);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes["src"] && changes["src"].currentValue !== changes["src"].previousValue) {
      this.src = changes["src"].currentValue;
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.handleFile(file);
  }

  handleFile(file: File) {
    this.file = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.src = event.target.result;
    }
    this.pictureChange.emit(this.file);
    reader.readAsDataURL(file);
  }

  remove() {
    this.src = undefined;
    this.file = undefined;
    this.pictureChange.emit(undefined);
  }

  onFileHover(event: any) {
    if (event.dataTransfer.types && event.dataTransfer.types.some((t: string) => t === "Files")) {
      this.active = true;
    } else {
      this.active = false;
    }
  }
}
