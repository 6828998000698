<mat-card class="p-0 fx-flex-100">
  <div class="p-16 fx-row align-items-center justify-content-between">
    <span class="text bold">{{'CASE.NOTES' | translate}}</span>
    <!-- <mat-icon class="dark clickable ml-5" fontSet="material-icons-outlined" (click)="drawer.close()">close</mat-icon> -->
  </div>
  <mat-divider></mat-divider>
  <div class="fx-column align-items-start justify-content-start ph-16" *ngIf="case && case.notes">
    <div class="scrollable" #scrollable>
      <div *ngFor="let note of case.notes" class="note" (mouseover)="focusedNote = note"
        (mouseout)="focusedNote = undefined">
        <div class="fx-row justify-content-between align-items-center  fx-flex-100">
          <div class="fx-row justify-content-start align-items-center">
            <app-avatar [user]="note.author" [size]="25"></app-avatar>
            <div class="fx-column ml-10">
              <span class="text">{{note.author.firstName}} {{note.author.lastName}}</span>
              <span class="small light">{{timeAgo(note.timestamp)}}</span>
            </div>
          </div>
          <mat-icon class="red clickable ml-5 delete-note"
            [style.visibility]="focusedNote && focusedNote._id === note._id ? 'visible' : 'hidden'"
            fontSet="material-icons-outlined" (click)="deleteNote(note)" *ngIf="canDeleteNote(note)">delete</mat-icon>
        </div>
        <mat-card class="p-8 mt-10">
          <span class="text">{{note.comment}}</span>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="add-note-container fx-row align-items-center justify-content-start">
    <app-input placeholder="{{'ACTIONS.ADD_NOTE' | translate}}..." type="text" prefix="edit_note" [(value)]="comment"
      class="fx-flex"></app-input>
    <button class="main-button ml-10" (click)="isNoteDisabled() ? null : addNote()" [class.disabled]="isNoteDisabled()"
      [disabled]="isNoteDisabled()">
      {{'ACTIONS.SEND' | translate}}
    </button>
  </div>
</mat-card>