import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  password?: string;
  confirmation?: string;
  code?: string;

  errors: any = {
    confirmation: {
      value: false,
      message: undefined
    },
    password: {
      value: false,
      message: undefined
    }
  }

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {

  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.code = params['code'];
        if (!this.code) {
          setTimeout(() => {
            this.router.navigateByUrl("/login");
          }, 5000)
        }
      }
      );
  }

  _passwordStrength() {
    return [
      {
        label: 'MIN_LENGTH',
        valid: this.password && this.password.length >= 8
      },
      {
        label: 'MIN_LOWERCASE',
        valid: this.password && /[a-z]+/.test(this.password)
      },
      {
        label: 'MIN_UPPERCASE',
        valid: this.password && /[A-Z]+/.test(this.password)
      },
      {
        label: 'MIN_NUMBER',
        valid: this.password && /[0-9]+/.test(this.password)
      },
      {
        label: 'MIN_SYMBOL',
        valid: this.password && /[!@#\$%\^&\*]+/.test(this.password)
      }
    ]
  }

  _hasErrors() {

    this.errors.password = {
      value: false,
      message: undefined
    }
    if (!this.password) {
      this.errors.password.value = true;
      this.errors.password.message = this.translate.instant('ERRORS.PASSWORD_REQUIRED');
    }
    if (this._passwordStrength().some(attr => !attr.valid)) {
      this.errors.password.value = true;
      this.errors.password.message = this.translate.instant('ERRORS.PASSWORD_DO_NOT_MATCH_REQUIREMENTS');
    }
    this.errors.confirmation = {
      value: false,
      message: undefined
    }
    if (!this.confirmation) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.CONFIRMATION_REQUIRED');
    }
    if (this.confirmation && this.confirmation !== this.password) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.PASSWORDS_DO_NOT_MATCH');
    }
    return this.errors.password.value || this.errors.confirmation.value;
  }

  async resetPassword() {
    if (this._hasErrors()) {
      return;
    } else {
      try {
        const res = await this.authService.resetPassword(this.password as string, this.code as string);
        this.router.navigate(["/"]);
        this.toastr.info(this.translate.instant("RESET_PASSWORD.PASSWORD_RESET", res));
      } catch (err: any) {

        if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
          this.toastr.error(`ERRORS.${err.message}`)
        } else {
          this.toastr.error(`ERRORS.GENERIC`)
        }
      }
    }


  }
}
