import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Case } from 'src/app/models/case';
import { Paginator } from 'src/app/models/paginator';
import { CaseService } from 'src/app/services/case.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-dashboard-case-operator',
  templateUrl: './dashboard-case-operator.component.html',
  styleUrls: ['./dashboard-case-operator.component.scss']
})
export class DashboardCaseOperatorComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  cases: Paginator<Case> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 999,
    totalDocs: 0,
    sort: '-createdAt'
  };

  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private toastr: ToastrService
  ) {

  }

  async ngOnInit() {
    this.loading = true;
    this.done = false;
    await this.retrieveCases(true);
    this.loading = false;
    this.done = true;
  }

  async retrieveCases(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      const cases = await this.caseService.retrieveAll(this.cases.page, this.cases.limit, this.cases.offset, this.cases.sort || '-createdAt', undefined, undefined, undefined);
      this.cases = { ...cases, docs: cases.docs.map((caseObject: Case) => ({ ...caseObject, phase: this.caseService._currentPhase(caseObject) })).sort(this._caseComparator) };

      if (init && this.cases.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.cases.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  _caseComparator(a: Case, b: Case) {
    return (a.phase && a.phase.endsIn ? a.phase.endsIn : -1) - (b.phase && b.phase.endsIn ? b.phase.endsIn : -1)
  }

}
