import { SelectionModel } from '@angular/cdk/collections';
import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { CreateTrademarkFamilyDialogComponent } from 'src/app/dialogs/create-trademark-family-dialog/create-trademark-family-dialog.component';
import { Territory } from 'src/app/models/territory';
import { Trademark, TrademarkSearch } from 'src/app/models/trademark';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';
import { MessageService } from 'src/app/services/message.service';
import { TrademarkFamilyCreate, TrademarkFamilyService } from 'src/app/services/trademark-family.service';
import { TrademarkService } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-trademark-declare-single',
  templateUrl: './trademark-declare-single.component.html',
  styleUrls: ['./trademark-declare-single.component.scss']
})
export class TrademarkDeclareSingleComponent {

  public query: { identifierNumber?: string, countryOfDesignation: string[] } = {
    identifierNumber: undefined,
    countryOfDesignation: []
  };

  displayedColumns: string[] = ['select', 'tmName', 'applicationNumber', 'tmOffice', 'niceClassification', 'status', 'representation'];
  public trademarks: TrademarkSearch[] = [];
  dataSource: MatTableDataSource<TrademarkSearch> = new MatTableDataSource<TrademarkSearch>([])
  public selection = new SelectionModel<TrademarkSearch>(true, []);

  public newClass: {
    classNumber?: number,
    details?: string
  } = {
      classNumber: undefined,
      details: undefined
    };

  public loading: boolean = false;
  public found: boolean = false;
  public done: boolean = false;

  familyModes: { value: string, label: string, selected: boolean }[] = [
    { selected: true, label: 'TRADEMARK_DECLARE.IMPORT_TRADEMARK_FAMILY_AUTO', value: 'auto' },
    { selected: false, label: 'TRADEMARK_DECLARE.IMPORT_TRADEMARK_FAMILY_SELECT', value: 'select' },
    { selected: false, label: 'TRADEMARK_DECLARE.IMPORT_TRADEMARK_FAMILY_CREATE', value: 'create' }
  ];
  familyMode?: 'auto' | 'create' | 'select' = 'auto';
  family?: TrademarkFamily;

  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private trademarkFamilyService: TrademarkFamilyService,
    private translate: TranslateService
  ) {
  }

  back() {
    this.router.navigate([".."], { relativeTo: this.route })
  }

  // isDeclareDisabled() {
  //   return !this.trademark || (!this.trademark.registrationNumber && !this.trademark.applicationNumber) || !this.trademark.countryOfDesignation || this.loading;
  // }

  isSearchDisabled() {
    return this.loading || !this.query.identifierNumber || !this.query.countryOfDesignation || this.query.countryOfDesignation.length === 0;
  }

  cancel() {
    this.done = false;
    this.loading = false;
  }

  onCountryOfDesignationChange(event: { value: Territory }) {
    if (event && event.value) {
      this.query.countryOfDesignation = event.value.alternateCodes ? [event.value.id, ...event.value.alternateCodes] : [event.value.id];
    }
  }

  onFamilyModeChange(event: any) {
    this.family = undefined;
  }

  async search() {
    if (this.query.identifierNumber && this.query.countryOfDesignation) {
      this.loading = true;
      this.done = false;
      try {
        this.trademarks = await this.trademarkService.search([{ identifierNumber: this.query.identifierNumber.trim(), countryOfDesignation: this.query.countryOfDesignation }]);
        this.trademarks.forEach((trademark: TrademarkSearch) => {
          if (this.familyMode === 'auto') {
            trademark.family = {
              mode: this.familyMode
            }
          } else if (this.familyMode && ['create', 'select'].includes(this.familyMode)) {
            trademark.family = {
              mode: this.familyMode,
              ref: this.family?._id
            }
          }
        });

        this.dataSource = new MatTableDataSource<TrademarkSearch>(this.trademarks);
        this.loading = false;
        this.done = true;
      } catch (err: any) {
        if (err && err.status === 404) {
          this.found = false;
          this.loading = false;
          this.done = true;
        } else {
          this.loading = false;
          this.done = false;
          this.toastr.error(`ERRORS.GENERIC`);
        }
      }
    }
  }

  isSelected(row: TrademarkSearch) {
    return this.selection.selected.map(doc => `${doc.tmOffice}_${doc.ST13}`).includes(`${row.tmOffice}_${row.ST13}`)
  }

  isDisabled(row: TrademarkSearch) {
    return row.tmOffice === 'WO';
  }

  isAllSelected() {
    return this.dataSource.data.filter(row => !this.isDisabled(row)).every(row => this.isSelected(row));
  }

  isSomeSelected() {
    return !this.dataSource.data.filter(row => !this.isDisabled(row)).every(row => this.isSelected(row)) && this.dataSource.data.filter(row => !this.isDisabled(row)).some(row => this.isSelected(row));
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      const difference = _.differenceBy(this.selection.selected, this.dataSource.data.filter(row => !this.isDisabled(row)), x => `${x.tmOffice}_${x.ST13}`)
      this.selection.setSelection(...difference)
      return;
    }
    this.selection.setSelection(...[...this.selection.selected, ...this.dataSource.data.filter(row => !this.isDisabled(row)).filter(row => !this.isSelected(row))])
  }

  toggle(row: TrademarkSearch) {
    if (this.isSelected(row)) {
      this.selection.setSelection(...this.selection.selected.filter(doc => `${doc.tmOffice}_${doc.ST13}` !== `${row.tmOffice}_${row.ST13}`))
    } else {
      this.selection.setSelection(...[...this.selection.selected, row])
    }
  }

  canDeclare() {
    return this.selection.selected.length > 0;
  }


  onSortChange(event: any) {

    if (event.active === 'tmName') {
      this.trademarks.sort((a, b) => event.direction === 'desc' ? (b.tmName || '').localeCompare(a.tmName || '') : (a.tmName || '').localeCompare(b.tmName || ''));
    } else if (event.active === 'applicationNumber') {
      this.trademarks = this.trademarks.sort((a, b) => event.direction === 'desc' ? b.applicationNumber.localeCompare(a.applicationNumber) : a.applicationNumber.localeCompare(b.applicationNumber));
    } else if (event.active === 'tmOffice') {
      this.trademarks = this.trademarks.sort((a, b) => event.direction === 'desc' ? this.translate.instant(`TERRITORIES.${b.tmOffice.toUpperCase()}`).localeCompare(this.translate.instant(`TERRITORIES.${a.tmOffice.toUpperCase()}`)) : this.translate.instant(`TERRITORIES.${a.tmOffice.toUpperCase()}`).localeCompare(this.translate.instant(`TERRITORIES.${b.tmOffice.toUpperCase()}`)));
    }
    this.dataSource = new MatTableDataSource<TrademarkSearch>(this.trademarks);
  }

  async newFamily() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
      }
    }
    const dialog = this.dialog.open(CreateTrademarkFamilyDialogComponent, config);
    const result: { created: boolean, family?: Partial<TrademarkFamily> } = await firstValueFrom(dialog.afterClosed());
    if (result && result.created && result.family) {
      try {
        const toCreate: TrademarkFamilyCreate = {
          name: result.family.name,
          label: result.family.label,
          notes: result.family.notes,
          type: result.family.type,
          trademarks: []
        }
        this.family = await this.trademarkFamilyService.create(toCreate);
        this.toastr.success(this.translate.instant(`TRADEMARK_FAMILY.FAMILY_CREATED`, { name: toCreate.label }));
      } catch (err) {
        this.toastr.error(`ERRORS.GENERIC`)
      }

    }
  }


}
