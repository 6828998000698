import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CasesByPhase } from 'src/app/services/case.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CaseStatus } from 'src/app/models/case';

@Component({
  selector: 'app-trademarks-pie',
  templateUrl: './trademarks-pie.component.html',
  styleUrls: ['./trademarks-pie.component.scss']
})
export class TrademarksPieComponent {
  @Input() data?: {
    obligated: number,
    notObligated: number
  };

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('trademarkspiediv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.PieChart;

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }
  ngAfterViewInit(): void {
    this.makeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.makeChart();
  }


  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (this.chartElement) {
        am4core.useTheme(am4themes_animated.default);

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
        chart.radius = am4core.percent(65);
        chart.logo.setVisibility(false);

        chart.data = this.data ? [
          {
            category: this.translate.instant("TRADEMARK.USAGE_OBLIGATION"),
            count: this.data.obligated,
            color: am4core.color("#8847E3")
          },
          {
            category: this.translate.instant("TRADEMARK.NOT_USAGE_OBLIGATION"),
            count: this.data.notObligated,
            color: am4core.color("#747479")
          }
        ] : [];
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.labels.template.wrap = true;
        pieSeries.labels.template.maxWidth = 150;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.fontSize = 10;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);


        this.chart = chart;
        this.chart.events.on('ready', () => {
          self.ready = true;
          this.changeDetector.detectChanges()
        })
      }
    })
  }
}
