<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'CASES_LIST.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'CASES_LIST.SUBTITLE' | translate}}</span>
  </div>
  <button class="main-button ml-20" routerLink="new" *ngIf="!loading && done && !noDocs" aclBasedElement resource="CASE"
    [scopes]="['C']">
    <div class="fx-row align-items-center justify-content-center">
      <mat-icon class="white mr-5" fontSet="material-icons-outlined">create_new_folder</mat-icon>
      {{'CASES_LIST.NEW' | translate}}
    </div>
  </button>
</div>
<mat-divider class="mv-20"></mat-divider>
<div class="mb-10" *ngIf="!loading && done && !noDocs">
  <div class="fx-row align-items-center justify-content-between">
    <div class="fx-row align-items-center justify-content-start">
      <app-trademark-select label="{{'ENTITIES.TRADEMARK' | translate}}" [multiple]="true" [search]="true"
        initial="none" (change)="onTrademarkChange($event)" [(value)]="filters.trademark" [emitOnInit]="false"
        style="width:180px;"></app-trademark-select>
      <app-select initial="none" label="{{'CASE.PHASE' | translate}}" [options]="phases" [multiple]="true" class="ml-10"
        (change)="onPhaseChange($event)" [emitOnInit]="false" style="width:180px;"></app-select>
      <div class="fx-row fw-wrap align-items-baseline justify-content-start ml-10">
        <app-animated-number [value]="cases.totalDocs" [class]="'bold main'"></app-animated-number>
        &nbsp;
        <span class="text"><span class="bold main">{{((cases.totalDocs > 1 ? 'ENTITIES.CASES' :
            'ENTITIES.CASE') |
            translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
      </div>
    </div>
    <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="filters.query"
      (change)="onQueryChange($event)"></app-input>
  </div>
</div>
<div class="fx-row align-items-start justify-content-start" *ngIf="!loading && done && !noDocs">
  <div class="fx-row fx-wrap case-column fx-flex-33">
    <div class="fx-row align-items-center justify-content-start fx-flex-100 mb-16">
      <span class="title bold">{{'CASE.PHASE_INSTRUCTION' | translate}}</span>
      <div class="chip main ml-10">{{casesByPhase['INSTRUCTION'].length}}</div>
    </div>
    <div class="scroll-container fx-flex-100">
      <app-case-card *ngFor="let case of casesByPhase['INSTRUCTION']" [case]="case"
        class="mb-10 fx-flex-100"></app-case-card>
      <span class="text light" *ngIf="casesByPhase['INSTRUCTION'].length === 0">{{'CASES_LIST.NO_INSTRUCTION_CASE' |
        translate}}</span>
    </div>
  </div>
  <div class="fx-row fx-wrap case-column fx-flex-33">
    <div class="fx-row align-items-center justify-content-start fx-flex-100 mb-16">
      <span class="title bold">{{'CASE.PHASE_DECISION' | translate}}</span>
      <div class="chip main ml-10">{{casesByPhase['DECISION'].length}}</div>
    </div>
    <div class="scroll-container fx-flex-100">
      <app-case-card *ngFor="let case of casesByPhase['DECISION']" [case]="case"
        class="mb-10 fx-flex-100"></app-case-card>
      <span class="text light" *ngIf="casesByPhase['DECISION'].length === 0">{{'CASES_LIST.NO_DECISION_CASE' |
        translate}}</span>
    </div>
  </div>
  <div class="fx-row fx-wrap case-column fx-flex-33">
    <div class="fx-row align-items-center justify-content-start fx-flex-100 mb-16">
      <span class="title bold">{{'CASE.PHASE_CLOSED' | translate}}</span>
      <div class="chip main ml-10">{{casesByPhase['CLOSED'].length}}</div>
    </div>
    <div class="scroll-container fx-flex-100">
      <app-case-card *ngFor="let case of casesByPhase['CLOSED']" [case]="case"
        class="mb-10 fx-flex-100"></app-case-card>
      <span class="text light" *ngIf="casesByPhase['CLOSED'].length === 0">{{'CASES_LIST.NO_CLOSED_CASE' |
        translate}}</span>
    </div>
  </div>
</div>
<app-empty-placeholder *ngIf="!loading && done && noDocs" roleBasedElement [roles]="['SUPERADMIN', 'ADMIN', 'MANAGER']"
  class="fx-flex" [offset]="125" [title]="'CASES_LIST.NO_CASES'" [message]="'CASES_LIST.NO_CASES_MESSAGE'"
  [button]="noCaseButton"></app-empty-placeholder>
<app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
  [title]="'HOME.CASE_OPERATOR_NO_CASES'" [message]="'HOME.CASE_OPERATOR_NO_CASES_MESSAGE'" roleBasedElement
  [roles]="['CASE_OPERATOR']"></app-empty-placeholder>