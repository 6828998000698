<mat-card class="p-0">
  <div class="header">
  </div>
  <div class="fx-row align-items-end justify-content-start identity-container p-16">
    <div class="company-logo" [style.background-image]="'url('+organization?.logo+')'">
    </div>
    <div class="fx-column  ml-10 mb-10">
      <span class="title bold">{{organization?.name}}</span>
      <span class="text light" roleBasedElement
        [roles]="['COUNTRY_OPERATOR','CASE_OPERATOR']">{{'ORGANIZATION.INIVTED_TO_COLLABORATE' |
        translate}}</span>
      <span class="text light" roleBasedElement
        [roles]="['SUPERADMIN','ADMIN','MANAGER']">{{'ORGANIZATION.REGISTERED_SINCE' | translate}}
        {{formatDate()}}</span>
    </div>
  </div>
  <div class="p-16">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" *ngIf="organization">
      <mat-tab>
        <ng-template mat-tab-label class="text">
          {{'ORGANIZATION.INFORMATION_TAB' | translate}}
        </ng-template>
        <div class="p-16">
          <app-organization-information [existingOrganization]="organization"
            (change)="onChange($event)"></app-organization-information>
        </div>
      </mat-tab>
      <mat-tab *ngIf="isManagerOrAbove">
        <ng-template mat-tab-label class="text">
          {{'ORGANIZATION.IAM_TAB' | translate}}
        </ng-template>
        <div class="p-16">
          <app-organization-iam [organization]="organization"></app-organization-iam>
        </div>
      </mat-tab>
      <!-- <mat-tab>
        <ng-template mat-tab-label class="text">
          {{'ORGANIZATION.PLAN_USAGE_TAB' | translate}}
        </ng-template>
        <div class="p-16">
        </div>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</mat-card>