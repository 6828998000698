import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataStore, TimeToLive } from './data-store.service';
import { firstValueFrom } from 'rxjs';
import { PropertyOffice } from '../models/propertyOffice';
import { environment } from 'src/environments/environment';

function store(ttlMinutes = TimeToLive.oneHour, forceRefresh = false) {
  function storeDecorator(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return target.store("PropertyOffice", propertyKey, descriptor, ttlMinutes, forceRefresh);
  }
  return storeDecorator;
}

@Injectable({
  providedIn: 'root'
})
export class PropertyOfficeService extends DataStore {
  offices: PropertyOffice[] = [];

  constructor(
    private http: HttpClient
  ) {
    super();
    this.retrieve();
  }

  @store(TimeToLive.oneDay)
  async retrieve() {
    this.offices = await firstValueFrom(this.http
      .get<PropertyOffice[]>(`${environment.backend}/propertyOffices`));
    return this.offices;
  }

  async retrieveOne(_id: string) {
    if (!this.offices || this.offices.length === 0) {
      await this.retrieve();
    }
    return this.offices.find((office) => office._id === _id);
  }
}
