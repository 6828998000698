<div class="p-20 fx-row fx-wrap align-items-start justify-content-start text-center">
  <span class="title fx-flex-100 mb-10  text-center">{{title | translate}}</span>
  <div class="text fx-flex-100 text-center" [innerHTML]="text | translate"></div>
  <table mat-table [dataSource]="trademarks" style="z-index: 0;">
    <ng-container matColumnDef="line">
      <th mat-header-cell *matHeaderCellDef>
        <span class="upper small light bold">
          {{'TRADEMARK_DECLARE.LINE' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        {{trademark.line}}
      </td>
    </ng-container>
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef>
        <span class="upper small light bold">
          {{'TRADEMARK.IDENTIFIER_NUMBER_SHORT' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <span class="text" [class.red]="!trademark.identifierNumber">{{(trademark.identifierNumber ||
          'TRADEMARK_DECLARE.MISSING_IDENTIFIER') | translate}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="country">
      <th mat-header-cell *matHeaderCellDef>
        <span class="upper small light bold">
          {{'TRADEMARK.DESIGNATION_COUNTRY_SHORT' | translate }}
        </span>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <span class="text" [class.red]="!trademark.country">{{(trademark.country || 'TRADEMARK_DECLARE.MISSING_COUNTRY')
          | translate}}</span>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
  </div>
</div>