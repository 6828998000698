<div class="p-20 fx-row fx-wrap align-items-center justify-content-center text-center">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{(mode === 'create' ? 'CASE.UPLOAD_ATTACHMENT_TITLE' :
      'CASE.UPDATE_ATTACHMENT_TITLE') | translate}}</span>
  </div>
  <img [src]="icon()" [height]="100" class="mt-20 mb-10" />
  <span class="text bold fx-flex-100" *ngIf="attachment">{{attachment.name}}</span>
  <span class="small light fx-flex-100" *ngIf="attachment">{{size()}}</span>
  <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center mv-20" *ngIf="attachment">
    <app-input [placeholder]="('CASE.ATTACHMENT_NAME' | translate) + '...'" type="text" class="mb-10 fx-flex-100"
      [(value)]="attachment.name"></app-input>
    <app-input [placeholder]="('CASE.ATTACHMENT_DESCRIPTION' | translate) + '...'" type="text" class="mb-10 fx-flex-100"
      [(value)]="attachment.description"></app-input>
    <mat-checkbox color="primary" [(ngModel)]="attachment.appendix"
      class="fx-flex-100 fx-row align-items-center justify-content-start">
      <span class="text">{{'CASE.INCLUDE_ATTACHMENT_APPENDIX' | translate}}</span>
    </mat-checkbox>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="mode === 'create' ? onUpload() : onUpdate()"
      [class.disabled]="isDisabled()" [disabled]="isDisabled()">{{(mode === 'create' ? 'ACTIONS.UPLOAD' :
      'ACTIONS.UPDATE')
      | translate}}</button>
  </div>
</div>