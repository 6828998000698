import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-documents-hierarchy',
  templateUrl: './documents-hierarchy.component.html',
  styleUrls: ['./documents-hierarchy.component.scss']
})
export class DocumentsHierarchyComponent implements OnInit, OnChanges {

  @Input() first: string = 'trademark';
  @Input() second: string = 'territory';
  @Input() third: string = 'nice';

  values: {
    [level: string]: any
  } = {
      'first': undefined,
      'second': undefined,
      'third': undefined
    }

  data: any[] = [];

  total: number = 0;

  level: number = 1;

  current: string = 'trademark';

  done: boolean = false;

  query?: string;

  public page: {
    offset: number;
    limit: number;
    current: number;
    sort: string;
  } = {
      offset: 0,
      limit: 10,
      current: 1,
      sort: 'data'
    }

  constructor(
    private _message: MessageService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.hierarchize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes['first'] || changes['second'] || changes['third'])) {
      if (this.level === 1 && changes['first'] && changes['first'].previousValue !== changes['first'].currentValue) {
        this.hierarchize();
      } else if (this.level === 2 && changes['second'] && changes['second'].previousValue !== changes['second'].currentValue) {
        this.hierarchize();
      } else if (this.level === 3 && changes['third'] && changes['third'].previousValue !== changes['third'].currentValue) {
        this.hierarchize();
      } else {
        if ((this.level === 2 && changes['first'] && changes['first'].previousValue !== changes['first'].currentValue)
          || (this.level === 3 && (
            (changes['second'] && changes['second'].previousValue !== changes['second'].currentValue)
            || (changes['first'] && changes['first'].previousValue !== changes['first'].currentValue)
          ))) {
          this.reset();
        }
      }
    }
  }

  resetPage() {
    this.page.limit = 10;
    this.page.offset = 0;
    this.page.current = 1;
  }

  reset() {
    this.level = 1;
    this.resetPage();
    this.page.sort = 'data';
    this.hierarchize();
  }

  getLevelLabel(type: string) {
    switch (type) {
      case 'trademark': return this.translate.instant('ENTITIES.TRADEMARKS');
      case 'territory': return this.translate.instant('DOCUMENTS_HIERARCHY.TERRITORIES');
      case 'nice': return this.translate.instant('DOCUMENTS_HIERARCHY.NICE_CLASSIFICATION');
      case 'year': return this.translate.instant('DOCUMENTS_HIERARCHY.YEARS');
      case 'language': return this.translate.instant('DOCUMENTS_HIERARCHY.LANGUAGES');
      case 'class': return this.translate.instant('DOCUMENTS_HIERARCHY.DOCUMENT_CLASSES');
      case 'document': return this.translate.instant('ENTITIES.DOCUMENT');
    }
  }

  getValueLabel(level: string, type: string) {
    if (this.values[level]) {
      switch (type) {
        case 'trademark': return `${this.values[level].name} (${this.values[level].identifierNumber})`;
        case 'territory': return this.translate.instant(`COUNTRIES.${this.values[level].toUpperCase()}`);
        case 'nice': return `${this.translate.instant('NICE_CLASSES.CLASS_NUMBER')}${this.values[level]} ${this.translate.instant(`NICE_CLASSES.${this.values[level]}`)}`;
        case 'year': return `${this.translate.instant('DOCUMENTS_HIERARCHY.YEAR')} ${this.values[level]}`;
        case 'language': return this.translate.instant(`LANGUAGES.${this.values[level].toLowerCase()}`);
        case 'class': return this.translate.instant(`DOCUMENT_CLASSES.${this.values[level].toUpperCase()}`);
        default: return '';
      }
    } else {
      return '';
    }
  }

  getColumnLabel() {
    return this.getLevelLabel(this.current)
  }

  _validateDatum(datum: any) {
    switch (this.current) {
      case 'trademark': return datum && datum._id && datum._id.identifierNumber;
      case 'territory': return datum && datum._id;
      case 'nice': return datum && datum._id;
      case 'year': return datum && datum._id;
      case 'language': return datum && datum._id;
      case 'class': return datum && datum._id;
      default: return true;
    }
  }

  _filters() {
    let filters: { [type: string]: any } = {};
    if (this.level === 2) {
      filters[this.first] = this.values['first'];
    }
    if (this.level === 3) {
      filters[this.first] = this.values['first'];
      filters[this.second] = this.values['second'];
    }
    if (this.level === 4) {
      filters[this.first] = this.values['first'];
      filters[this.second] = this.values['second'];
      filters[this.third] = this.values['third'];
    }
    return filters;
  }

  _currentLevel() {
    switch (this.level) {
      case 1: return this.first;
      case 2: return this.second;
      case 3: return this.third;
      case 4: return 'document';
      default: return this.first;
    }
  }

  open(datum: any) {
    if (this.level === 4) {
      this.router.navigate([datum._id._id], {
        relativeTo: this.route
      })
    } else {
      const accessor = this.level === 1 ? 'first' : (this.level === 2 ? 'second' : 'third');
      this.values[accessor] = datum._id;
      this.level += 1;
      this.resetPage();
      this.hierarchize();
    }

  }

  back(level: number) {
    if (level === this.level) {
      return;
    } else if (level < this.level) {
      _.range(this.level, level, -1).forEach(step => {
        const accessor = step === 1 ? 'first' : (step === 2 ? 'second' : 'third')
        this.values[accessor] = undefined;
        this.level -= 1;
      })
      this.resetPage();
      this.hierarchize();
    }
  }

  isPreviousDisabled() {
    return this.page.current === 1;
  }

  isNextDisabled() {
    return ((this.page.current - 1) * this.page.offset + this.page.limit) >= this.total;
  }

  previous() {
    this.page.current -= 1;
    this.page.offset -= this.page.limit;
    this.hierarchize();
  }

  next() {
    this.page.current += 1;
    this.page.offset += this.page.limit;
    this.hierarchize();
  }

  sort() {
    this.page.sort = this.page.sort === 'data' ? '-data' : 'data';
    this.hierarchize()
  }

  // TODO: Handle query change
  async hierarchize() {
    try {
      this._message.emitChange("LOADING", "START");
      const docs = await this.documentService.hierarchize(this.page.current, this.page.limit, this.page.offset, this.page.sort, this._currentLevel(), this._filters(), this.query);
      this.data = docs.data;
      console.log(this.data)
      this.total = docs.count;
      this.current = this._currentLevel();
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }



  documentIcon(type: string) {
    return this.documentService.icon({ type });
  }

}

