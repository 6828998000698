<div class="fx-row align-items-start justify-content-start fx-flex-100" #timelineContainer>
  <div [style.width.%]="100*(!phase.duration ? 100 : phase.duration)/total"
    *ngFor="let phase of phases; let index = index; let last = last" class="fx-row fx-wrap" [class.ml-8]="index > 0"
    [class.mr-8]="!last">
    <div class="fx-row align-items-center justify-content-between fx-flex-100" style="min-width:0;">
      <span class="text main upper bold ellipsis">{{('CASE.PHASE_'+phase.name.toUpperCase() |
        translate).toUpperCase()}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-between fx-flex-100 mb-5" style="min-width:0;">
      <span class="small light ellipsis mr-10">{{formatDate(phase.start)}}</span>
      <span class="small light ellipsis ml-10" *ngIf="phase.end && !phase.infinite">{{formatDate(phase.end)}}</span>
    </div>
    <div class="phase-container fx-flex-100" [cdkMenuTriggerFor]="menu" [id]="'phase-'+phase.name">
      <div class="phase-elapsed-container" [style.width.%]="elapsed(phase)">
      </div>
    </div>
    <div *ngIf="phase && phase.subPhases && phase.subPhases.length > 0"
      class="fx-row align-items-start justify-content-start fx-flex-100 mt-5">
      <div [style.width.%]="100*(subphase.duration ?? 0)/(phase.duration ?? 100)"
        *ngFor="let subphase of phase.subPhases; let subIndex = index; let subLast = last"
        [style.margin-left.%]="100 * (subphase.offset ?? 0) / (phase.duration ?? 100)">
        <div class="phase-container subphase fx-flex-100" [class.suspended]="subphase.label === 'suspended'"
          [cdkMenuTriggerFor]="subphasemenu">
          <div class="phase-elapsed-container subphase" [class.suspended]="subphase.label === 'suspended'"
            [style.width.%]="elapsed(subphase)">
          </div>
          <div class="fx-row align-items-center justify-content-between subphase-label-container"
            [style.max-width.%]="90">
            <div class="fx-column" style="min-width:0 !important;">
              <span class="small white bold subphase-label">
                {{(subphase.label === 'suspended' ? ('CASE.SUBPHASE_'+subphase.label.toUpperCase() | translate) :
                subphase.label)}}
              </span>
            </div>
          </div>
        </div>
        <ng-template #subphasemenu>
          <mat-card class="menu-container p-8 elevation" cdkMenu>
            <div class="fx-row fx-wrap justify-content-start align-items-center">
              <div class="fx-row align-items-center justify-content-between fx-flex-100">
                <div class="fx-column">
                  <span class="text main upper bold ellipsis fx-flex-100">{{('CASE.PHASE_'+phase.name.toUpperCase() |
                    translate).toUpperCase()}}</span>
                  <span class="text dark ellipsis fx-flex-100">{{(subphase.label === 'suspended' ?
                    ('CASE.SUBPHASE_'+subphase.label.toUpperCase() | translate) :
                    subphase.label)}}</span>
                </div>
                <mat-icon class="dark ml-10 clickable" fontSet="material-icons-outlined"
                  [cdkMenuTriggerFor]="subphaseactionmenu" aclBasedElement resource="CASE"
                  [scopes]="['U']">more_vert</mat-icon>
                <ng-template #subphaseactionmenu>
                  <mat-card class="menu-container p-0 elevation" cdkMenu>
                    <div class="fx-column">
                      <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                        (click)="edit.emit({phase:subphase,index:subIndex})" cdkMenuItem>
                        <mat-icon class="dark mr-5" fontSet="material-icons-outlined">edit</mat-icon>
                        <span class="text">{{'ACTIONS.EDIT' | translate}}</span>
                      </div>
                      <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                        (click)="delete.emit({phase:subphase,index:subIndex})" cdkMenuItem>
                        <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
                        <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
                      </div>
                    </div>
                  </mat-card>
                </ng-template>
              </div>
              <div class="fx-row align-items-center justify-content-between fx-flex-100">
                <span class="text light ellipsis">{{formatDate(subphase.start)}}</span>
                <mat-icon class="mh-10" fontSet="material-icons-outlined">arrow_right_alt</mat-icon>
                <span class="text light ellipsis">{{subphase.end ? formatDate(subphase.end) : '...'}}</span>
              </div>
            </div>
          </mat-card>
        </ng-template>
      </div>
    </div>
    <ng-template #menu>
      <mat-card class="menu-container p-8 elevation" cdkMenu>
        <div class="fx-row fx-wrap justify-content-start align-items-center">
          <span class="text main upper bold ellipsis fx-flex-100">{{('CASE.PHASE_'+phase.name.toUpperCase() |
            translate).toUpperCase()}}</span>
          <div class="fx-row align-items-center justify-content-between fx-flex-100">
            <span class="text light ellipsis">{{formatDate(phase.start)}}</span>
            <mat-icon class="mh-10" fontSet="material-icons-outlined">arrow_right_alt</mat-icon>
            <span class="text light ellipsis">{{phase.end ? formatDate(phase.end) : '...'}}</span>
          </div>
        </div>
      </mat-card>
    </ng-template>
  </div>
</div>
<div class="cursor" [style.left.px]="cursorMargin"></div>