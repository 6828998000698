import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { DocumentType } from 'src/app/models/document';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-graph-documents-nice',
  templateUrl: './graph-documents-nice.component.html',
  styleUrls: ['./graph-documents-nice.component.scss']
})
export class GraphDocumentsNiceComponent {
  @Input() data: {
    _id: any,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  @Input() count: number = 0;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @Input() classBalance: {
    display: boolean,
    qualification?: 'good' | 'average' | 'bad',
    classes: number[]
  } = {
      display: false,
      classes: []
    };

  @ViewChild('graphnicediv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.PieChart;

  constructor(
    private zone: NgZone,
    private documentService: DocumentService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.loading) {
      setTimeout(() => {
        this.makeChart();
      }, 100)
    }

  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (!this.chart && this.chartElement) {

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
        chart.logo.setVisibility(false);
        chart.radius = am4core.percent(65);

        chart.data = this.data.map((datum) => ({ name: this.translate.instant('NICE_CLASSES.CLASS_NUMBER') + datum._id, count: datum.count })).sort((a, b) => b.count - a.count);

        if (this.classBalance.display) {
          this.computeClassBalance();
        }

        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "name";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.fill = am4core.color("#747479");
        pieSeries.labels.template.fontSize = 10;
        pieSeries.labels.template.fontWeight = 'bold';
        pieSeries.labels.template.fontFamily = 'Source Sans 3, sans-serif';
        pieSeries.labels.template.text = "{category}";
        pieSeries.labels.template.wrap = true;
        pieSeries.labels.template.maxWidth = 100;
        pieSeries.alignLabels = false;
        pieSeries.ticks.template.events.on("ready", hideSmall);
        pieSeries.ticks.template.events.on("visibilitychanged", hideSmall);
        pieSeries.labels.template.events.on("ready", hideSmall);
        pieSeries.labels.template.events.on("visibilitychanged", hideSmall);


        function hideSmall(ev: any) {
          if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 5)) {
            ev.target.hide();
          }
          else {
            ev.target.show();
          }
        }

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);

        this.chart = chart;

      } else if (this.chartElement && this.chart) {
        this.chart.data = this.data.map((datum) => ({ name: this.translate.instant('NICE_CLASSES.CLASS_NUMBER') + datum._id, count: datum.count }));
      }

    })
  }

  computeClassBalance() {
    const qualifications: string[] = [];
    const target = this.count / this.classBalance.classes.length;
    this.classBalance.classes.forEach((c) => {
      const found = this.data.find((d) => d._id === c)
      if (found) {
        if (found.count < 0.7 * target || found.count > 1.3 * target) {
          qualifications.push('bad');
        } else if (found.count < 0.9 * target || found.count > 1.1 * target) {
          qualifications.push('average');
        } else {
          qualifications.push('good');
        }
      } else {
        qualifications.push('bad');
      }
    })
    if (qualifications.includes('bad')) {
      this.classBalance.qualification = 'bad';
    } else if (qualifications.includes('average')) {
      this.classBalance.qualification = 'average';
    } else {
      this.classBalance.qualification = 'good';
    }
  }
}
