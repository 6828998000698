import { Component, HostListener, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Case } from 'src/app/models/case';
import { DocumentClass } from 'src/app/models/documentClass';
import { UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-upload-documents-box',
  templateUrl: './upload-documents-box.component.html',
  styleUrls: ['./upload-documents-box.component.scss']
})
export class UploadDocumentsBoxComponent {

  @Input() class?: string;
  @Input() case?: Case;
  files: UploadFile[] = [];
  active: boolean = false;
  private accepted: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

  constructor(
    private documentService: DocumentService,
    private toastr: ToastrService
  ) {

  }


  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
  }

  // Drop listener
  @HostListener('drop', ['$event']) public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.active = false;
    const files = event.dataTransfer.files;
    this.handleFiles([...files]);
  }

  onFilesSelected(event: any) {
    const files: File[] = event.target.files;
    this.handleFiles([...files]);
  }

  handleFiles(files: File[]) {
    const unauthorized = {
      format: files.filter(file => !this.accepted.includes(file.type)),
      size: files.filter(file => file.size >= 20 * 1024 * 1024)
    }
    const authorized = files.filter(file => this.accepted.includes(file.type) && file.size < 20 * 1024 * 1024);
    if (unauthorized.format.length > 0) {
      this.toastr.warning("UPLOAD_DOCUMENTS.UNAUTHORIZED_FORMAT_MESSAGE")
    }
    if (unauthorized.size.length > 0) {
      this.toastr.warning("UPLOAD_DOCUMENTS.UNAUTHORIZED_SIZE_MESSAGE")
    }
    this.files = [...this.files, ...authorized.map(file => file as UploadFile)];
    this.files.forEach((file) => {
      file.confidential = false;
      file.filename = file.name;
      file.status = UploadFileStatus.PENDING;
      file.class = DocumentClass.AUTOMATIC;
    });
    this.documentService.openUploadDialog(this.files);
  }


  onFilesHover(event: any) {
    if (event.dataTransfer.types && event.dataTransfer.types.some((t: string) => t === "Files")) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  openUploadDialog() {
    this.documentService.openUploadDialog([], this.case);
  }
}
