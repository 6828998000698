import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-trademarks',
  templateUrl: './trademarks.component.html',
  styleUrls: ['./trademarks.component.scss']
})
export class TrademarksComponent implements OnInit {
  constructor(
    private _message: MessageService
  ) {

  }

  ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: "trademarks"
    });
  }
}
