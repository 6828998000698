<div class="fx-column">
  <div class="fx-row align-items-center justify-content-between">
    <app-back [action]="back.bind(this)"></app-back>
    <div class="fx-row align-items-center justify-content-end">
      <button class="main-button ml-20" (click)="openEditMetadata()"
        [disabled]="document?.status !== 'PROCESSED' || document?.nonUse"
        [class.disabled]="document?.status !== 'PROCESSED' || document?.nonUse" *ngIf="mode !== 'EDIT_METADATA'">
        {{'DOCUMENT.EDIT_METADATA' | translate}}
      </button>
      <button [cdkMenuTriggerFor]="menu" class="button ml-10">
        <div class="fx-row align-items-center justify-content-between fx-flex-100">
          <span class="dark fx-flex">{{'ACTIONS.ACTIONS' | translate}}</span>
          <mat-icon class="dark ml-5" fontSet="material-icons-outlined">arrow_drop_down</mat-icon>
        </div>
      </button>
    </div>
    <ng-template #menu>
      <mat-card class="menu-container p-0 elevation" cdkMenu>
        <div class="fx-column">
          <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
            (click)="canDisplayOrDownload('download') ? download() : null" cdkMenuItem>
            <mat-icon class="dark mr-5" [class.light]="!canDisplayOrDownload('download')"
              fontSet="material-icons-outlined">file_download</mat-icon>
            <span class="text" [class.light]="!canDisplayOrDownload('download')">{{'DOCUMENT.DOWNLOAD' |
              translate}}</span>
          </div>
          <div class="fx-row align-items-center justify-content-start menu-item" [class.clickable]="!document?.nonUse"
            [class.actionable]="!document?.nonUse" (click)="document?.nonUse ? null : reanalyze()" cdkMenuItem
            aclBasedElement resource="DOCUMENT" [scopes]="['U']">
            <mat-icon class="dark mr-5" fontSet="material-icons-outlined"
              [class.light]="document?.nonUse">sync</mat-icon>
            <span class="text" [class.light]="document?.nonUse">{{'ACTIONS.REANALYZE' | translate}}</span>
          </div>
          <div class="fx-row align-items-center justify-content-start menu-item actionable clickable" (click)="delete()"
            cdkMenuItem aclBasedElement resource="DOCUMENT" [scopes]="['D']">
            <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
            <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
          </div>
        </div>
      </mat-card>
    </ng-template>
  </div>
</div>
<mat-card class="p-0 mt-20" *ngIf="document">
  <div class="fx-row align-items-start justify-content-start">
    <div class="document-viewer fx-flex-60">
      <div class="document-overlay" *ngIf="locked && document && done">
        <mat-card class="overlay-card elevation">
          <div class="fx-column align-items-center justify-content-center">
            <div class="lock-container">
              <mat-icon class="main" fontSet="material-icons-outlined">lock</mat-icon>
            </div>
            <span class="title">
              {{'DOCUMENT.PROTECTED' | translate}}
            </span>
            <span class="text text-center">
              {{'DOCUMENT.PROTECTED_NEED_OTP' | translate}}
            </span>
            <div class="fx-row align-items-center justify-content-center mt-10 fx-flex">
              <button class="main-button fx-flex" (click)="authenticate()">
                {{'ACTIONS.AUTHENTICATE' | translate}}
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div *ngIf="loading" class="document-loader">
        <mat-progress-spinner class="white-spinner" mode="indeterminate" diameter="45">
        </mat-progress-spinner>
      </div>
      <div *ngIf="!loading && src" class="pdf-viewer" #pdfViewer>
        <div class="canvas-container" #canvasContainer>
        </div>
        <div class="annotation-canvas-container" #annotationCanvasContainer>
        </div>
        <div class="drawing-canvas-container" #drawingCanvasContainer>
        </div>
        <mat-card class="drawing-help-popup" *ngIf="isDraw && helpPopup" [@fadeInOut]>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-between">
              <span class="text bold main">
                {{'DOCUMENT.POPUP_ANNOTATE_TITLE' | translate}}
              </span>
              <mat-icon class="light ml-20 clickable" (click)='closeDrawingHelpPopup()'>close</mat-icon>
            </div>
            <span class="text dark">{{'DOCUMENT.POPUP_ANNOTATE_MESSAGE'| translate}}</span>
          </div>
        </mat-card>
        <div class="pdf-actions border-right border-box border-top">
          <div class="fx-row align-items-center justify-content-start">
            <span class="small mr-10">Page {{currentPage}} {{('UTILS.OF' | translate).toLowerCase()}} {{pdf ?
              pdf.numPages : 1}}</span>
            <mat-icon class="light ml-10 clickable" [class.lighter]="isPreviousPageDisabled()"
              [class.disabled]="isPreviousPageDisabled()" (click)="previousPage()" fontSet="material-icons-outlined"
              *ngIf="document?.type === 'application/pdf'">navigate_before</mat-icon>
            <mat-icon class="light ml-10 clickable" [class.lighter]="isNextPageDisabled()"
              [class.disabled]="isNextPageDisabled()" (click)="nextPage()" fontSet="material-icons-outlined"
              *ngIf="document?.type === 'application/pdf'">navigate_next</mat-icon>
          </div>
          <div class="fx-row align-items-center justify-content-start">
            <mat-icon class="light ml-10 clickable" (click)="!canFitWidth ? null : fitWidth()"
              [class.lighter]="!canFitWidth" [class.disabled]="!canFitWidth" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'ACTIONS.FIT_WIDTH' | translate" [matTooltipDisabled]="!canFitWidth">
              width_full
            </mat-icon>
            <mat-icon class="light ml-10 clickable" (click)="!canFitHeight ? null : fitHeight()"
              [class.lighter]="!canFitHeight" [class.disabled]="!canFitHeight" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'ACTIONS.FIT_HEIGHT' | translate" [matTooltipDisabled]="!canFitHeight">
              height
            </mat-icon>
            <mat-icon class="light ml-10 clickable" (click)="resetDisplay()" [class.lighter]="zoom === 1"
              [class.disabled]="zoom === 1" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'ACTIONS.RESET_LAYOUT' | translate"
              [matTooltipDisabled]="zoom === 1">fullscreen_exit</mat-icon>
            <mat-icon class="light ml-10 clickable" (click)="zoomInClick()" fontSet="material-icons-outlined"
              [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.ZOOM_IN' | translate">zoom_in</mat-icon>
            <mat-icon class="light ml-10 clickable" [class.lighter]="zoom === 1" [class.disabled]="zoom === 1"
              (click)="zoom === 1 ? null :zoomOutClick()" fontSet="material-icons-outlined"
              [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.ZOOM_OUT' | translate"
              [matTooltipDisabled]="zoom === 1">zoom_out</mat-icon>
          </div>
        </div>
      </div>
      <!-- <img [src]="src" *ngIf="!loading && src && document?.type !== 'application/pdf'" class="image-viewer" /> -->
      <div *ngIf="!loading && !src" class="placeholder-viewer"></div>
    </div>
    <div class="document-metadata fx-flex-40 fx-column">
      <app-edit-document-metadata *ngIf="mode === 'EDIT_METADATA'" [document]="document" [evidences]="evidences"
        [metadata]="metadata" (close)="closeEditMetadata()" (mouseenter)="showPreciseEvidence($event)"
        (mouseleave)="hidePreciseEvidence($event)" (add)="addMetadata($event)" (update)="updateMetadata($event)"
        (delete)="deleteMetadata($event)" (toggleDraw)="toggleDraw()" [drawing]="drawing" [isDraw]="isDraw"
        (confirmAnnotation)="confirmAnnotation($event)" (cancelAnnotation)="cancelAnnotation()"
        #editDocumentMetadata></app-edit-document-metadata>
      <div *ngIf="mode === 'CONSULT'">
        <div class="fx-row align-items-center justify-content-between p-16">
          <div class="fx-column">
            <span class="title bold">
              {{document.name}}
            </span>
            <span class="small light" *ngIf="document?.type === 'application/pdf' && document?.pages">{{document.pages}}
              {{((document.pages &&
              document.pages > 1 ? 'DOCUMENT.PAGES' : 'DOCUMENT.PAGE') | translate).toLowerCase()}} |
              {{formatSize()}}</span>
            <span class="small light"
              *ngIf="document?.type !== 'application/pdf' || !document?.pages">{{formatSize()}}</span>
            <div class="fx-row align-items-center justify-content-start" *ngIf="document.confidential">
              <mat-icon class="mr-5 main" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
                [matTooltip]="'CONFIDENTIALITY.CONFIDENTIAL' | translate" inset inline>lock</mat-icon>
              <span class="text main">{{'CONFIDENTIALITY.CONFIDENTIAL' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start" *ngIf="document.nonUse">
              <mat-icon class="mr-5 main" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
                [matTooltip]="'DOCUMENT.NON_USE' | translate" inset inline>assignment_late</mat-icon>
              <span class="text main">{{'DOCUMENT.NON_USE' | translate}}</span>
            </div>
            <div class="mt-10">
              <mat-slide-toggle color="main" [checked]="displayMode === 'evidence'"
                [disabled]="document.status !== 'PROCESSED' || document.nonUse" (change)="onDisplayModeChange($event)">
                {{"DOCUMENT.EVIDENCE_VERSION"|translate}}
              </mat-slide-toggle>
            </div>
          </div>
          <img [src]="documentIcon()" [height]="45" />
        </div>
        <mat-divider></mat-divider>
        <div class="fx-row fx-wrap align-items-stretch justify-content-start">
          <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex-50  border-box p-16">
            <span class="text light mb-8 fx-flex-100">{{'DOCUMENT.STATUS' | translate}}</span>
            <div class="fx-row align-items-center justify-content-start fx-flex-100">
              <mat-icon class="mr-5 light" fontSet="material-icons-outlined"
                *ngIf="['PENDING','PROCESSING'].includes(document.status)">pending</mat-icon>
              <mat-icon class="mr-5 green" fontSet="material-icons-outlined"
                *ngIf="document.status === 'PROCESSED'">check_circle</mat-icon>
              <mat-icon class="mr-5 red" fontSet="material-icons-outlined"
                *ngIf="document.status === 'ERROR'">error_outline</mat-icon>
              <div class="fx-column">
                <span class="text"
                  [class.light]="['PENDING','PROCESSING'].includes(document.status)">{{'DOCUMENT.STATUS_'+(document.nonUse
                  ? 'NON_USE' : document.status)
                  |
                  translate}}</span>
                <span class="small light" *ngIf="document.timestamp" [matTooltip]="'DOCUMENT.TIMESTAMP' | translate"
                  [matTooltipClass]="{'uzai-tooltip':true}">{{formatDatetime(document.timestamp)}}</span>
              </div>
            </div>
          </div>
          <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex-50  border-box  border-left p-16">
            <span class="text light mb-8 fx-flex-100">{{'DOCUMENT.UPLOAD' | translate}}</span>
            <div class="fx-row justify-content-start align-items-center fx-flex-100"
              *ngIf="document.uploader && document.uploader.ref">
              <app-avatar [user]="document.uploader" [size]="30"></app-avatar>
              <div class="fx-column ml-10">
                <span class="text">{{document.uploader.firstName}} {{document.uploader.lastName}}</span>
                <span class="text light"><a class="text main" (click)="toBatch(document.batch.ref)">Batch
                    n°{{document.batch.number}}</a>
                  -
                  {{formatDate(document.createdAt)}}</span>
              </div>
            </div>
            <div class="fx-row justify-content-start align-items-center fx-flex-100"
              *ngIf="!document.uploader || !document.uploader.ref">
              <img src="/assets/icons/icon.svg" class="uzai-avatar" />
              <div class="fx-column ml-10">
                <span class="text">UZAI <span class="small light">({{'ENTITIES.INTEGRATION' | translate}})</span></span>
                <span class="text light"><a class="text main" (click)="toBatch(document.batch.ref)">Batch
                    n°{{document.batch.number}}</a>
                  -
                  {{formatDate(document.createdAt)}}</span>
              </div>
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="fx-row fx-wrap align-items-stretch justify-content-start">
          <div class="fx-flex-50 fx-row fx-wrap align-items-start justify-content-start p-16 border-box">
            <app-document-class class="w-100" [documentClass]="document.class"
              [metadata]="metadata"></app-document-class>
          </div>
          <div class="fx-flex-50 fx-row fx-wrap align-items-start justify-content-start p-16 border-box border-left">
            <app-document-langs class="w-100" [langs]="document.langs" [metadata]="metadata"></app-document-langs>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="fx-row fx-wrap align-items-stretch justify-content-start">
          <div class="fx-flex-50 fx-column  align-items-start justify-content-start p-16 border-box"
            (mouseenter)="showTypeEvidences('TRADEMARK')" (mouseleave)="hideTypeEvidences('TRADEMARK')">
            <app-document-trademarks class="w-100" [shown]="evidenceTypes['TRADEMARK'] && !document.nonUse"
              [trademarks]="document.trademarks" [metadata]="metadata"></app-document-trademarks>
          </div>
          <div class="fx-flex-50 p-16 fx-column align-items-start justify-content-start border-box border-left"
            (mouseenter)="showTypeEvidences('NICE_CLASS')" (mouseleave)="hideTypeEvidences('NICE_CLASS')">
            <app-document-nice-classes class="w-100" [shown]="evidenceTypes['NICE_CLASS'] && !document.nonUse"
              [niceClassification]="document.niceClassification" [metadata]="metadata"></app-document-nice-classes>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="fx-row fx-wrap align-items-stretch justify-content-start"
          (mouseenter)="showTypeEvidences('LOCATION')" (mouseleave)="hideTypeEvidences('LOCATION')">
          <div class="p-16 fx-row fx-flex-100 align-items-center justify-content-between">
            <span class="text light">{{'DOCUMENT.TERRITORIES' | translate}}</span>
            <mat-icon [class.shown]="evidenceTypes['LOCATION'] && !document.nonUse" class="visibility-icon"
              fontSet="material-icons-outlined">{{evidenceTypes['LOCATION'] ? 'visibility' :
              'visibility_off'}}</mat-icon>
          </div>
          <div class="fx-row align-items-center justify-content-between fx-flex-100 border-left border-box">
            <app-document-map [territories]='document.territories' class="fx-flex-100 h-100"></app-document-map>
          </div>
          <div class="fx-flex-100 p-16 fx-column align-items-start justify-content-start border-box">
            <app-document-countries class="w-100" [shown]="evidenceTypes['LOCATION'] && !document.nonUse" [mode]="'row'"
              [territories]="document.territories" [metadata]="metadata" [title]="false"></app-document-countries>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start p-16"
          (mouseenter)="showTypeEvidences('DATE');showTypeEvidences('PERIOD')"
          (mouseleave)="hideTypeEvidences('DATE');hideTypeEvidences('PERIOD')">
          <div class="fx-row align-items-center justify-content-between fx-flex-100">
            <app-document-timeline [shown]="evidenceTypes['DATE'] && !document.nonUse" [period]='document.period'
              class="fx-flex-100"></app-document-timeline>
          </div>
          <div class="fx-row align-items-center justify-content-center fx-flex-100 mt-10">
            <span class="text" *ngIf="document.period && document.period.start && document.period.end">
              {{formatPeriod()}}
            </span>
            <span class="text" *ngIf="!document.period || !document.period.start || !document.period.end">-</span>
          </div>
        </div>
        <mat-divider *ngIf="document.users && document.users.length > 0"></mat-divider>
        <div class="fx-row fx-wrap align-items-start justify-content-start p-16"
          (mouseenter)="showTypeEvidences('USER');showTypeEvidences('USER')"
          (mouseleave)="hideTypeEvidences('USER');hideTypeEvidences('USER')"
          *ngIf="document.users && document.users.length > 0">
          <div class="fx-row align-items-center justify-content-between fx-flex-100">
            <app-document-users [shown]="evidenceTypes['USER']" [users]='document.users || []' class="fx-flex-100"
              [metadata]="metadata"></app-document-users>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>