import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Organization } from 'src/app/models/organization';
import { MessageService } from 'src/app/services/message.service';
import { OrganizationService } from 'src/app/services/organization.service';

@Component({
  selector: 'app-organization-information',
  templateUrl: './organization-information.component.html',
  styleUrls: ['./organization-information.component.scss']
})
export class OrganizationInformationComponent implements OnInit {
  @Input() existingOrganization?: Organization;

  organization?: Organization;

  @Output() change = new EventEmitter<Organization>();

  logo?: File;

  loading: boolean = false;

  constructor(
    private _message: MessageService,
    private organizationService: OrganizationService,
    private toastr: ToastrService
  ) {

  }

  async ngOnInit() {
    await this.retrieveOrganization();
  }

  async retrieveOrganization() {
    this.organization = await this.organizationService.mine();
  }

  isUpdateDisabled() {
    return this.loading;
  }

  onLogoChange(logo?: any) {
    this.logo = logo;
    if (this.logo === undefined && this.organization) {
      this.organization.logo = undefined;
    }
  }

  async update() {
    try {
      if (this.organization) {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        if (this.logo) {
          const res = await this.organizationService.uploadLogo(this.logo);
          this.organization.logo = res.url;
        } else if (!this.existingOrganization?.logo) {
          this.organization.logo = undefined;
        }
        const organization = await this.organizationService.update(this.organization);
        this.onChange(organization)
        this.toastr.success('ORGANIZATION.UPDATE_SUCCESS_MESSAGE');
        this._message.emitChange("LOADING", "END");
        this.loading = false;
      }

    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async cancel() {
    this.organization = undefined;
    this.organization = await this.organizationService.mine();
    if (this.organization)
      this.onChange(this.organization)
  }

  onChange(organization: Organization) {
    this.change.emit(organization);
  }
}
