import { Moment } from "moment";

export enum IntegrationType {
  WEBSITE = 'WEBSITE'
}
export enum IntegrationTypeComingsoon {
  MAIL = 'MAIL',
  INVOICING = 'INVOICING',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
  SOCIAL_NETWORK = 'SOCIAL_NETWORK'
}
export const IntegrationRecurrenceValues = ['month', 'week', 'once'] as const;

export type IntegrationRecurrence = typeof IntegrationRecurrenceValues[number];

export interface Integration {
  _id: string;
  name: string,
  organizationId: string,
  type: IntegrationType,
  recurrence: IntegrationRecurrence,
  trademarks: [{
    ref: string,
    name: string;
    identifierNumber: string;
    countryOfDesignation: string;
  }],
  owner:
  {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  },
  url: string,
  createdAt: Moment
}