import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-documents-classes',
  templateUrl: './documents-classes.component.html',
  styleUrls: ['./documents-classes.component.scss']
})
export class DocumentsClassesComponent {
  @Input() data: {
    _id: string,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('documentclassesdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.PieChart;

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }
  ngAfterViewInit(): void {
    this.makeChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.makeChart();
  }


  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (this.chartElement) {
        am4core.useTheme(am4themes_animated.default);

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.PieChart);
        chart.radius = am4core.percent(65);
        chart.logo.setVisibility(false);

        chart.data = this.data.map(d => ({ ...d, _id: this.translate.instant(`DOCUMENT_CLASSES.${d._id}`) })).sort((a, b) => b.count - a.count);;
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "count";
        pieSeries.dataFields.category = "_id";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.labels.template.wrap = true;
        pieSeries.labels.template.maxWidth = 75;
        pieSeries.alignLabels = false;
        pieSeries.labels.template.fontSize = 10;

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        chart.hiddenState.properties.radius = am4core.percent(0);

        pieSeries.ticks.template.events.on("ready", hideSmall);
        pieSeries.ticks.template.events.on("visibilitychanged", hideSmall);
        pieSeries.labels.template.events.on("ready", hideSmall);
        pieSeries.labels.template.events.on("visibilitychanged", hideSmall);


        function hideSmall(ev: any) {
          if (ev.target.dataItem && (ev.target.dataItem.values.value.percent < 5)) {
            ev.target.hide();
          }
          else {
            ev.target.show();
          }
        }



        this.chart = chart;
        this.chart.events.on('ready', () => {
          self.ready = true;
          this.changeDetector.detectChanges()
        })
      }
    })
  }
}
