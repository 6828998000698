<div class="p-20 fx-row fx-wrap align-items-center justify-content-start ">
  <div class="fx-flex-100 align-items-center justify-content-center text-center">
    <span class="title mb-10 text-center">{{'DOCUMENT.EDIT_DOCUMENT_CLASS' | translate}}</span>
  </div>
  <span class="text mt-20">{{'DOCUMENT.EDIT_DOCUMENT_CLASS_HINT' | translate:document}}</span>
  <div class="fx-flex-100 fx-row fx-wrap align-items-center justify-content-center mv-20">
    <app-document-class-select label="{{'DOCUMENTS_LIST.FILTERS.DOCUMENT_CLASS' | translate}}" [multiple]="false"
      [emitOnInit]="false" class="fx-flex" [(value)]="class"></app-document-class-select>
  </div>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" [disabled]="!class" [class.disabled]="!class"
      (click)="onConfirm()">{{'ACTIONS.CONFIRM'
      | translate}}</button>
  </div>
</div>