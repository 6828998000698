import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { IntegrationDialogComponent } from 'src/app/dialogs/integration-dialog/integration-dialog.component';
import { Integration, IntegrationRecurrenceValues, IntegrationType } from 'src/app/models/integration';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';
import { IntegrationService } from 'src/app/services/integration.service';
import { MessageService } from 'src/app/services/message.service';

interface IntegrationFilters {
  query?: string,
  type: IntegrationType[],
  recurrence: ('week' | 'month')[],
  trademark: Trademark[]
}

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;

  integrationType?: {
    key: string,
    icon?: string,
    logo?: string,
    title: string,
    available: boolean,
    description: string
  };

  integrations: Paginator<Integration> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  displayedColumns: string[] = ['name', 'url', 'recurrence', 'trademark', 'owner', 'actions'];
  filters: IntegrationFilters = {
    query: undefined,
    type: [],
    recurrence: [],
    trademark: []
  };
  recurrences: {
    value: string,
    label: string,
    selected: boolean,
    disabled: boolean
  }[] = [
      {
        value: IntegrationRecurrenceValues[2],
        label: "DATES.ONCE",
        selected: false,
        disabled: false
      },
      {
        value: IntegrationRecurrenceValues[1],
        label: "DATES.WEEKLY",
        selected: false,
        disabled: false
      },
      {
        value: IntegrationRecurrenceValues[0],
        label: "DATES.MONTHLY",
        selected: false,
        disabled: false
      }
    ];

  private readonly filtersSubject = new Subject<IntegrationFilters>();
  private filtersSubscription?: Subscription;


  noIntegrationButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'INTEGRATIONS.CREATE_TITLE',
      icon: 'power',
      class: 'main-button',
      action: () => { this.addIntegrationDialog() }
    }


  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private integrationService: IntegrationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.integrationType = this.integrationService.types().find((t) => t.key.toLowerCase() === params['type']);
    this.loading = true;
    this.done = false;
    this.retrieveIntegrations(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveIntegrations(false);
      });
  }

  async retrieveIntegrations(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.integrations = { ...this.integrations, ...await this.integrationService.retrieveAll(this.integrations.page, this.integrations.limit, this.integrations.offset, this.integrations.sort, this.filters.type, this.filters.recurrence, this.filters.trademark, this.filters.query) };

      if (init && this.integrations.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.integrations.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.integrations.limit = 10;
    this.integrations.page = 1;
    this.integrations.offset = 0;
  }

  async handlePage(event: PageEvent) {
    this.integrations.limit = event.pageSize;
    this.integrations.page = event.pageIndex + 1;
    this.integrations.offset = event.pageIndex * this.integrations.limit;
    this.retrieveIntegrations(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.integrations.sort = `${event.direction === 'desc' ? '-' : ''}${event.active === 'recurrence' ? 'createdAt' : event.active}`;
      this.retrieveIntegrations(false);
    }
  }


  onTrademarkChange(event: any) {
    this.filters.trademark = event.value;
    this.filtersSubject.next({ ...this.filters });
  }
  onRecurrenceChange(event: any) {
    this.filters.recurrence = event.value;
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    this.filtersSubject.next({ ...this.filters });
  }

  async addIntegrationDialog() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      autoFocus: false,
      data: {
        mode: 'create',
        type: this.integrationType
      }
    }
    const dialog = this.dialog.open(IntegrationDialogComponent, config);
    const result: { created: boolean, edited: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.created) {
      this.resetPage();
      await this.retrieveIntegrations(true);
    }
  }

  async edit(integration: Integration) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      autoFocus: false,
      data: {
        mode: 'edit',
        integration,
        type: this.integrationType
      }
    }
    const dialog = this.dialog.open(IntegrationDialogComponent, config);
    const result: { created: boolean, edited: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.edited) {
      this.resetPage();
      await this.retrieveIntegrations(true);
    }
  }

  async delete(integration: Integration) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'INTEGRATIONS.DELETE_INTEGRATION_TITLE',
        text: this.translate.instant('INTEGRATIONS.DELETE_INTEGRATION_TEXT', integration),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'INTEGRATIONS.DELETE_INTEGRATION_CONFIRMATION',
          value: integration?.name
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && integration && integration._id) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.integrationService.delete(integration._id);
        this.resetPage();
        await this.retrieveIntegrations(true);
        this.toastr.success(this.translate.instant(`INTEGRATIONS.INTEGRATION_DELETED`, { name: integration.name }));
        this.loading = false;
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async trigger(integration: Integration) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'INTEGRATIONS.TRIGGER_INTEGRATION_TITLE',
        text: this.translate.instant('INTEGRATIONS.TRIGGER_INTEGRATION_TEXT', integration),
        button: {
          text: 'ACTIONS.TRIGGER',
          class: 'main-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && integration && integration._id) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.integrationService.trigger(integration._id);
        this.toastr.success(this.translate.instant(`INTEGRATIONS.INTEGRATION_TRIGGERED`, { name: integration.name }));
        this.loading = false;
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  nextTrigger(integration: Integration) {
    if (integration.recurrence === 'week') {
      return moment().add(1, 'week').startOf('week').format('ll');
    } else {
      return moment().add(1, 'month').startOf('month').format('ll');
    }
  }

  onceTrigger(integration: Integration) {
    return moment(integration.createdAt).format('ll');
  }

}
