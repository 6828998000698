<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-9">
    <div *ngIf="title" class="text bold" [attr.aria-label]="title">
      {{ title | translate }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="text" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="text fx-flex"
      [attr.aria-label]="message">
      {{ message | translate }}
    </div>
  </div>
  <div class="col-3 text-right">
    <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm">
      close
    </a>
  </div>
</div>
<div *ngIf="options.progressBar" class="mt-10">
  <app-progress-bar [mode]="'indeterminate'" [loading]="true"></app-progress-bar>
</div>