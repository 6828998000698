<div class="fx-column">
  <div class="fx-row align-items-center justify-content-between">
    <app-back></app-back>
    <button class="main-button" (click)="addIntegrationDialog()">
      <div class="fx-row align-items-center justify-content-start">
        <mat-icon class="white mr-5" fontSet="material-icons-outlined">power</mat-icon>
        {{'INTEGRATIONS.CREATE_TITLE' | translate}}
      </div>
    </button>
  </div>
  <div class="fx-row align-items-center justify-content-between">
    <div class="fx-row align-items-center justify-content-start" *ngIf="integrationType">
      <mat-icon class="main mr-10 type-icon" fontSet="material-icons-outlined">{{integrationType.icon}}</mat-icon>
      <div class="fx-column">
        <span class="page-title">{{integrationType.title | translate}}</span>
        <span class="page-subtitle">{{integrationType.description | translate}}</span>
      </div>
    </div>
  </div>
  <mat-divider class="mv-20"></mat-divider>
  <mat-card class="p-0" *ngIf="!loading && done && !noDocs">
    <div class="fx-row align-items-center justify-content-between p-16">
      <div class="fx-row align-items-center justify-content-start">
        <app-select initial="none" label="{{'INTEGRATIONS.WEBSITE.RECURRENCE' | translate}}" [options]="recurrences"
          [multiple]="true" (change)="onRecurrenceChange($event)" [emitOnInit]="false" [value]="filters.recurrence"
          style="width:210px;"></app-select>
        <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
          style="width:210px;" (change)="onTrademarkChange($event)" [(value)]="filters.trademark" [emitOnInit]="false"
          class="ml-10"></app-trademark-select>
        <div class="fx-row fw-wrap align-items-baseline justify-content-start ml-10">
          <app-animated-number [value]="integrations.totalDocs" [class]="'bold main'"></app-animated-number>
          &nbsp;
          <span class="text"><span class="bold main">{{((integrations.totalDocs > 1 ? 'ENTITIES.INTEGRATIONS' :
              'ENTITIES.INTEGRATION') |
              translate).toLowerCase()}}</span> {{('UTILS.MATCHING' | translate).toLowerCase()}}</span>
        </div>
      </div>
      <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="filters.query"
        (change)="onQueryChange($event)" style="width:210px;"></app-input>
    </div>
    <mat-divider></mat-divider>
    <table mat-table [dataSource]="integrations.docs" style="z-index: 0;" matSort
      (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="small light bold">
            {{'INTEGRATIONS.WEBSITE.NAME' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let integration">
          <span class="text">{{integration.name}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="url">
        <th mat-header-cell *matHeaderCellDef>
          <span class=" small light bold">
            {{'INTEGRATIONS.WEBSITE.URL' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let integration">
          <a [href]="integration.url" target="_blank">{{integration.url | shortUrl}}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="recurrence">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="small light bold">
            {{'INTEGRATIONS.WEBSITE.RECURRENCE' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let integration">
          <div class="fx-column" *ngIf="integration.recurrence !== 'once'">
            <span class="text">{{'DATES.'+integration.recurrence.toUpperCase()+'LY' | translate}}</span>
            <span class="small light" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'INTEGRATIONS.NEXT_TRIGGER' | translate">{{nextTrigger(integration)}}</span>
          </div>
          <div class="fx-column" *ngIf="integration.recurrence === 'once'">
            <span class="text">{{'DATES.ONCE' | translate}}</span>
            <span class="small light" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'INTEGRATIONS.ONCE_TRIGGER' | translate"
              *ngIf="integration.createdAt">{{onceTrigger(integration)}}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="trademark">
        <th mat-header-cell *matHeaderCellDef>
          <span class=" small light bold">
            {{'ENTITIES.TRADEMARKS' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let integration">
          <div class="fx-column" *ngIf="integration?.trademarks && integration?.trademarks.length === 1">
            <span class="text">{{integration.trademarks[0].name ||
              integration.trademarks[0].identifierNumber}}</span>
          </div>
          <div class="fx-row align-items-center justify-content-start"
            *ngIf="integration?.trademarks && integration?.trademarks.length > 1">
            <span class="text">{{integration.trademarks[0].name ||
              integration.trademarks[0].identifierNumber}}</span>&nbsp;<span class="text">{{'UTILS.AND_X_MORE' |
              translate:{x:integration?.trademarks.length-1} }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
          <span class="small light bold">
            {{'INTEGRATIONS.WEBSITE.OWNER' | translate}}
          </span>
        </th>
        <td mat-cell *matCellDef="let integration">
          <div class="fx-row justify-content-start align-items-center">
            <app-avatar [user]="integration.owner" [size]="25"></app-avatar>
            <div class="fx-column align-items-start justify-content-start ml-5">
              <span class="small">{{integration.owner.firstName}} {{integration.owner.lastName}}</span>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let integration">
          <button mat-icon-button [cdkMenuTriggerFor]="menu" roleBasedElement
            [roles]="['SUPERADMIN','ADMIN','MANAGER']">
            <mat-icon class="dark" fontSet="material-icons-outlined">more_vert</mat-icon>
          </button>
          <ng-template #menu>
            <mat-card class="menu-container p-0 elevation" cdkMenu>
              <div class="fx-column">
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="trigger(integration)" cdkMenuItem>
                  <mat-icon class="dark mr-5" fontSet="material-icons-outlined">play_circle</mat-icon>
                  <span class="text">{{'ACTIONS.TRIGGER' | translate}}</span>
                </div>
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="edit(integration)" cdkMenuItem>
                  <mat-icon class="dark mr-5" fontSet="material-icons-outlined">edit</mat-icon>
                  <span class="text">{{'ACTIONS.EDIT' | translate}}</span>
                </div>
                <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
                  (click)="delete(integration)" cdkMenuItem>
                  <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
                  <span class="text">{{'ACTIONS.DELETE' | translate}}</span>
                </div>
              </div>
            </mat-card>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let trademark; columns: displayedColumns;">
      </tr>
    </table>
    <div class="p-16" *ngIf="done && !loading && integrations.totalDocs === 0">
      <span class="text light">{{'INTEGRATIONS.SEARCH_NO_INTEGRATION' | translate}}</span>
    </div>
    <mat-paginator [length]="integrations.totalDocs || 0" [pageSize]="integrations.limit || 0"
      [pageIndex]="integrations && integrations.page ? (integrations.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
      (page)="handlePage($event)">
    </mat-paginator>
  </mat-card>
  <app-empty-placeholder *ngIf="!loading && done && noDocs" class="fx-flex" [offset]="125"
    [title]="'INTEGRATIONS.NO_INTEGRATION'" [message]="'INTEGRATIONS.LIST.SUBTITLE'"
    [button]="noIntegrationButton"></app-empty-placeholder>
</div>