import { Component } from '@angular/core';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})
export class BatchesComponent {
  constructor(
    private _message: MessageService
  ) {

  }

  ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: "documents"
    });
  }
}
