import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() type?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() pattern?: string;
  @Input() class?: string;

  inputs: any[] = [
    {
      id: 1,
      focus: false,
      error: false,
      value: null
    },
    {
      id: 2,
      focus: false,
      error: false,
      value: null
    },
    {
      id: 3,
      focus: false,
      error: false,
      value: null
    },
    {
      id: 4,
      focus: false,
      error: false,
      value: null
    }
  ]


  public focus: boolean = false;
  public error: boolean = false;
  private _pattern?: RegExp;

  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();

  @Output() change = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  onModelChange(input: any, event: any) {

    input.value = input.value.toUpperCase();
    if (input.value !== '') {
      if (input.id < 4) {
        const element = document.getElementById('input-' + (input.id + 1));
        element?.focus();
      } else {
        const element = document.getElementById('input-' + input.id);
        element?.blur();
      }
    }
    this.valueChange.emit(this.inputs.map(i => i.value).join(""));
  }


}
