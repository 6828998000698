import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AclService } from '../services/acl.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleGuard {

  constructor(
    private router: Router,
    private aclService: AclService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const role = this.aclService.role();

    if (!role) {
      void this.router.navigate([`/errors/403`]);
      return false;
    }

    const valid = !route.data['roles'] || route.data['roles'].length === 0 || (route.data['roles'].map((r: string) => r.toLowerCase())).includes(role.toLowerCase());
    if (!valid) {
      void this.router.navigate([`/errors/403`]);
    }
    return valid;
  }
}