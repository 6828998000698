import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Batch, BatchDocumentStatus, BatchMode } from 'src/app/models/batch';
import { Paginator } from 'src/app/models/paginator';
import { Territory } from 'src/app/models/territory';
import { Trademark } from 'src/app/models/trademark';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BatchService } from 'src/app/services/batch.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

interface BatchFilters {
  query?: string,
  territories: string[],
  trademarks: {
    identifierNumber: string,
    countryOfDesignation: string
  }[],
  modes: string[]
}


@Component({
  selector: 'app-batches-list',
  templateUrl: './batches-list.component.html',
  styleUrls: ['./batches-list.component.scss']
})
export class BatchesListComponent {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  batches: Paginator<Batch> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-number'
  };
  filteredBatches: Batch[] = [];
  displayedColumns: string[] = ['number', 'status', 'mode', 'documents', 'uploader', 'score', 'actions'];
  dataSource: MatTableDataSource<Batch> = new MatTableDataSource<Batch>([])
  selection = new SelectionModel<Batch>(true, []);

  modes: { value: string, label: string, selected: boolean }[] = [
    {
      value: BatchMode.GENERIC,
      label: "BATCH.MODE_GENERIC",
      selected: false
    },
    {
      value: BatchMode.TRADEMARKS,
      label: "BATCH.MODE_TRADEMARKS",
      selected: false
    },
    {
      value: BatchMode.COUNTRIES,
      label: "BATCH.MODE_COUNTRIES",
      selected: false
    }
  ];

  filters: BatchFilters = {
    query: undefined,
    territories: [],
    trademarks: [],
    modes: []
  }

  private readonly filtersSubject = new Subject<BatchFilters>();
  private filtersSubscription?: Subscription;

  user?: User;

  noDocumentButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'ACTIONS.UPLOAD',
      icon: 'cloud_upload',
      class: 'main-button',
      action: () => { this.documentService.openUploadDialog([]) }
    }

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private batchService: BatchService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }


  async ngOnInit() {
    this.loading = true;
    this.done = false;
    this.retrieveBatches(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveBatches(false);
      });
  }

  async retrieveBatches(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");
      this.batches = { ...this.batches, ...await this.batchService.retrieveAll(this.batches.page, this.batches.limit, this.batches.offset, this.batches.sort, this.filters.modes, this.filters.territories, this.filters.trademarks, this.filters.query) };
      this.filteredBatches = this.batches.docs;
      this.dataSource = new MatTableDataSource<Batch>(this.filteredBatches);

      if (init && this.batches.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.batches.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`);
    }
  }

  refresh(event: any, batch: Batch) {
    batch = event;
    this.cdRef.detectChanges()
  }

  resetPage() {
    this.batches.limit = 10;
    this.batches.page = 1;
    this.batches.offset = 0;
  }

  async handlePage(event: any) {
    this.batches.limit = event.pageSize;
    this.batches.page = event.pageIndex + 1;
    this.batches.offset = event.pageIndex * this.batches.limit;
    this.retrieveBatches(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.batches.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveBatches(false);
    }
  }

  score(batch: Batch) {
    if (batch.report) {
      return {
        total: batch.documents,
        ...batch.report
      }
    }
    return {
      ok: 0,
      warning: 0,
      error: 0,
      total: 1
    };
  }

  tooltipMode(batch: Batch) {
    if (batch.mode === BatchMode.COUNTRIES) {
      return batch.countries?.map((country) => this.translate.instant(`TERRITORIES.${country.toUpperCase()}`)).join(', ') || '';
    } else if (batch.mode === BatchMode.TRADEMARKS) {
      return batch.trademarks?.map((trademark) => (trademark.name || trademark.identifierNumber)).join(', ') || '';
    }
    return '';
  }


  onTerritoryChange(event: any) {
    this.filters.territories = event.value.map((territory: Territory) => territory.alternateCodes ? [territory.id, ...territory.alternateCodes] : [territory.id]).flat();
    this.filtersSubject.next({ ...this.filters });
  }

  onTrademarkChange(event: any) {
    this.filters.trademarks = event.value;
    this.filtersSubject.next({ ...this.filters });
  }

  onModeChange(event: any) {
    this.filters.modes = event.value;
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    this.filtersSubject.next({ ...this.filters });
  }


  toBatch(batch: Batch) {
    this.router.navigate([batch._id], {
      relativeTo: this.route,
      state: {
        from: "BATCHES"
      }
    })
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  formatTrademarks(trademarks: any[]) {
    if (trademarks.length === 1) {
      return trademarks[0].name
    } else {
      return `${trademarks[0].name} ${this.translate.instant('UTILS.AND_X_MORE', { x: trademarks.length - 1 })}`
    }
  }

  formatCountries(countries: string[]) {
    if (countries.length === 1) {
      return this.translate.instant(`TERRITORIES.${countries[0]}`);
    } else {
      return `${this.translate.instant(`TERRITORIES.${countries[0]}`)} ${this.translate.instant('UTILS.AND_X_MORE', { x: countries.length - 1 })}`
    }
  }
}
