import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterState } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';
import { AclService } from 'src/app/services/acl.service';
import { MessageService } from 'src/app/services/message.service';
import { TerritoryService } from 'src/app/services/territory.service';
import { TrademarkService } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-trademark',
  templateUrl: './trademark.component.html',
  styleUrls: ['./trademark.component.scss']
})
export class TrademarkComponent implements OnInit {
  mode: "CONSULTATION" | "EDITION" | "COMPARISON" = "CONSULTATION";
  from: string = "TRADEMARKS";

  trademark: Trademark = {
    _id: undefined,
    identifierNumber: '',
    registrationNumber: undefined,
    applicationNumber: undefined,
    countryOfDesignation: '',
    kind: undefined,
    type: undefined,
    niceClassification: [],
    applicationDate: undefined,
    publicationDate: undefined,
    registrationDate: undefined,
    expirationDate: undefined,
    reproductionURI: undefined,
    name: undefined,
    organizationId: undefined
  };
  refreshed?: Trademark;
  isObligated: boolean = false;

  reproduction?: File;

  loading: boolean = false;

  documents: Paginator<Document> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: Document[] = [];
  displayedColumns: string[] = ['select', 'name', 'trademark', 'country', 'period', 'upload', 'uploader', 'actions'];


  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private dialog: MatDialog,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private territoryService: TerritoryService,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private translate: TranslateService
  ) {
    this.from = window.history.state.from || "TRADEMARKS";
    this.mode = window.history.state.mode || "CONSULTATION";
  }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.params);
    this.retrieveTrademark(params['id']);
  }


  async retrieveTrademark(id: string) {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      this.trademark = await this.trademarkService.retrieve(id);
      this.isObligated = (await this.trademarkService.retrieveObligationOfUse(id)).isObligated;
      this.loading = false;
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  _hasTrademark() {
    return this.trademark && this.trademark.identifierNumber && this.trademark.identifierNumber !== '' && this.trademark.countryOfDesignation && this.trademark.countryOfDesignation.length > 0;
  }

  async refresh() {
    try {
      if (this._hasTrademark() && this.trademark._id && this.trademark.ST13) {

        this.loading = true;
        this._message.emitChange("LOADING", "START");
        const territory = (await this.territoryService.retrieve()).find((territory) => territory.id === this.trademark.countryOfDesignation || (territory.alternateCodes && territory.alternateCodes.includes(this.trademark.countryOfDesignation)));

        const trademark = await this.trademarkService.retrieveExternal(this.trademark._id);
        const found = trademark != undefined;
        if (found) {
          this.refreshed = trademark;
          this.mode = "COMPARISON";
        } else {
          this.toastr.error(`ERRORS.GENERIC`);
        }
        this.loading = false;
        this._message.emitChange("LOADING", "END");
      }
    } catch (err: any) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`);
    }
  }

  async update() {
    try {
      if (this._hasTrademark()) {

        this._message.emitChange("LOADING", "START");
        this.loading = true;
        if (this.mode === 'COMPARISON' && this.refreshed) {
          this.trademark.identifierNumber = this.refreshed.identifierNumber;
          this.trademark.registrationNumber = this.refreshed.registrationNumber;
          this.trademark.applicationNumber = this.refreshed.applicationNumber;
          this.trademark.countryOfDesignation = this.refreshed.countryOfDesignation;
          this.trademark.kind = this.refreshed.kind;
          this.trademark.type = this.refreshed.type;
          this.trademark.niceClassification = this.refreshed.niceClassification;
          this.trademark.applicationDate = this.refreshed.applicationDate;
          this.trademark.publicationDate = this.refreshed.publicationDate;
          this.trademark.registrationDate = this.refreshed.registrationDate;
          this.trademark.expirationDate = this.refreshed.expirationDate;
          this.trademark.reproductionURI = this.refreshed.reproductionURI;
          this.trademark.name = this.refreshed.name;
          this.trademark.propertyOffice = this.refreshed.propertyOffice;
          this.trademark.status = this.refreshed.status;
        }
        if (this.mode === 'EDITION' && this.reproduction && this.trademark) {
          const res = await this.trademarkService.uploadReproduction(this.reproduction);
          this.trademark.reproductionURI = res.url;
        }
        this.trademark = await this.trademarkService.update(this.trademark);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('TRADEMARK.UPDATE_SUCCESS', { name: this.trademark.name || this.trademark.identifierNumber }));
        this.mode = 'CONSULTATION';
      }
    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async delete() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'TRADEMARK.DELETE_TRADEMARK_TITLE',
        text: this.translate.instant('TRADEMARK.DELETE_TRADEMARK_TEXT', { name: this.trademark.name ?? '', identifierNumber: this.trademark.identifierNumber }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'TRADEMARK.DELETE_TRADEMARK_CONFIRMATION',
          value: this.trademark?.identifierNumber
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.trademark) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        this.trademarkService.delete(this.trademark);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.location.back();
        this.toastr.success(this.translate.instant('TRADEMARK.DELETE_TRADEMARK_SUCCESS', { name: this.trademark.name ?? '', identifierNumber: this.trademark.identifierNumber }));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  onReproductionChange(reproduction: any) {
    this.reproduction = reproduction;
    if (!this.reproduction && this.trademark) {
      this.trademark.reproductionURI = undefined;
    }
  }


}
