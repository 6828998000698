import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Trademark } from 'src/app/models/trademark';
import { DocumentAggregatedData, DocumentService } from 'src/app/services/document.service';
import { TerritoryService } from 'src/app/services/territory.service';

@Component({
  selector: 'app-trademark-coverage',
  templateUrl: './trademark-coverage.component.html',
  styleUrls: ['./trademark-coverage.component.scss']
})
export class TrademarkCoverageComponent {

  @Input() trademark?: Trademark;

  count: number = 0;

  documentClasses: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTypes: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTerritories: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentPeriods: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentTrademarks: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };
  documentNice: DocumentAggregatedData = {
    started: false,
    data: [],
    loading: false,
    error: false
  };

  tradermarkSearch: { identifierNumber: string, countryOfDesignation: string }[] = []

  constructor(
    private documentService: DocumentService,
    private router: Router,
    private toastr: ToastrService
  ) {

  }


  ngOnInit(): void {
    this.aggregateDocuments();
  }

  async aggregateDocuments() {
    await this.countDocuments();
    await this.aggregateDocumentsClasses();
    await this.aggregateDocumentsNice();
    await this.aggregateDocumentsTerritories();
    await this.aggregateDocumentsPeriods();
  }

  async countDocuments() {
    try {
      if (this.trademark) {
        this.count = (await this.documentService.countAll(undefined, undefined, [this.trademark], undefined, undefined, undefined)).count;
      }
    } catch (err) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  async aggregateDocumentsClasses() {
    try {
      if (this.trademark) {
        this.documentClasses.started = true;
        this.documentClasses.loading = true;
        this.documentClasses.data = await this.documentService.aggregate('class', undefined, undefined, [this.trademark], undefined, undefined, undefined);
        this.documentClasses.loading = false;
      }
    } catch (err) {
      this.documentClasses.loading = false;
      this.documentClasses.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsTerritories() {
    try {
      if (this.trademark) {
        this.documentTerritories.started = true;
        this.documentTerritories.loading = true;
        this.documentTerritories.data = await this.documentService.aggregate('territory', undefined, undefined, [this.trademark], undefined, undefined, undefined);
        this.documentTerritories.loading = false;
      }

    } catch (err) {
      this.documentTerritories.loading = false;
      this.documentTerritories.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsPeriods() {
    try {
      if (this.trademark) {
        this.documentPeriods.started = true;
        this.documentPeriods.loading = true;
        this.documentPeriods.data = await this.documentService.aggregate('period', undefined, undefined, [this.trademark], undefined, undefined, undefined);
        this.documentPeriods.loading = false;
      }

    } catch (err) {
      this.documentPeriods.loading = false;
      this.documentPeriods.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async aggregateDocumentsNice() {
    try {
      if (this.trademark) {
        this.documentNice.started = true;
        this.documentNice.loading = true;
        this.documentNice.data = await this.documentService.aggregate('nice', undefined, undefined, [this.trademark], undefined, undefined, undefined);
        this.documentNice.loading = false;
      }

    } catch (err) {
      this.documentNice.loading = false;
      this.documentNice.error = true;
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }


  toTrademarkDocuments() {
    this.router.navigate(['/', 'documents'], {
      state: {
        trademarks: [this.trademark]
      }
    })
  }


}
