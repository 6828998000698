import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Case } from 'src/app/models/case';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { CaseCoverageComponent } from '../case-coverage/case-coverage.component';

@Component({
  selector: 'app-case-information',
  templateUrl: './case-information.component.html',
  styleUrls: ['./case-information.component.scss']
})
export class CaseInformationComponent {

  @Input() case?: Case;
  @Input() propertyOffice?: PropertyOffice;
  @Input() mode: 'CONSULTATION' | 'EDITION' = 'CONSULTATION';
  @Output() toProofs = new EventEmitter<any>();

  @ViewChild('caseCoverage') caseCoverage?: CaseCoverageComponent;

  refreshCoverage() {
    if (this.caseCoverage) {
      this.caseCoverage.aggregateDocuments();
    }
  }

}
