import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Case, CaseStatus } from 'src/app/models/case';
import { Document } from 'src/app/models/document';
import { DocumentClass } from 'src/app/models/documentClass';
import { Paginator } from 'src/app/models/paginator';
import { UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { Role, User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CaseService } from 'src/app/services/case.service';
import { Data, DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { TrademarkService } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  loading: boolean = false;
  done: boolean = false;

  user?: User;



  counts: {
    trademarks: number,
    documents: number,
    cases: number,
    users: number
  } = {
      trademarks: 0,
      documents: 0,
      cases: 0,
      users: 0
    }

  openCases: Paginator<Case> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 3,
    totalDocs: 0,
    sort: '-createdAt'
  };

  valuePeriod: {
    value: number,
    period: string
  } = {
      value: 12,
      period: 'month'
    };
  documentsGraph: Data[] = [];

  files: UploadFile[] = [];
  active: boolean = false;
  private accepted: string[] = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];

  isManagerOrAbove: boolean = false;
  isCountryOperator: boolean = false;
  isCaseOperator: boolean = false;



  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private authService: AuthenticationService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private organizationService: OrganizationService,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
      this.isManagerOrAbove = this.aclService.isManagerOrAbove();
      this.isCountryOperator = this.aclService.isCountryOperator();
      this.isCaseOperator = this.aclService.isCaseOperator();

    });
  }

  async ngOnInit() {
    this._message.emitChange("NAV", "SECTION", {
      path: ""
    });
  }


}
