<div class="fx-row align-items-center justify-content-between">
  <div class="fx-column">
    <span class="page-title">{{'ADMIN.TITLE' | translate}}</span>
    <span class="page-subtitle">{{'ADMIN.SUBTITLE' | translate}}</span>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card class="p-0" *ngIf="!loading && done && !noDocs">
  <table mat-table [dataSource]="dataSource" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'ORGANIZATION.NAME' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let organization">
        <div class="fx-row align-items-center justify-content-start">
          <img [src]="organization.logo" class="org-logo mr-10" *ngIf="organization.logo" />
          <span class="main bold">{{organization.name}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="headquartersAddress">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'ORGANIZATION.HEADQUARTERS' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let organization">
        <span class="text">{{organization.headquartersAddress}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'ORGANIZATION.REGISTERED_SINCE' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let organization">
        <span class="text">{{formatDate(organization.createdAt)}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let trademark">
        <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let organization; columns: displayedColumns;" class="clickable"
      [routerLink]="organization._id">
    </tr>
  </table>
  <div class="p-16" *ngIf="done && !loading && organizations.totalDocs === 0">
    <span class="text light">{{'ADMIN.SEARCH_NO_ORGANIZATIONS' | translate}}</span>
  </div>
  <mat-paginator [length]="organizations.totalDocs || 0" [pageSize]="organizations.limit || 0"
    [pageIndex]="organizations && organizations.page ? (organizations.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="handlePage($event)">
  </mat-paginator>
</mat-card>