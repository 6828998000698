import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  @Input("user") user: any;

  @Input("size") size: number = 40;

  @Input("mode") mode?: string;

  public initials?: string;

  public error: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.initials = `${this.user.firstName ? this.user.firstName[0] : ''}${this.user.lastName ? this.user.lastName[0] : ''}`
  }

  getInitialsClass() {
    if (this.size > 35) {
      return 'initials-title';
    } else if (this.size > 25) {
      return 'initials-text';
    } else {
      return 'initials-small';
    }
  }
}
