<span class="label" *ngIf="label">{{label}}</span>
<input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload
  accept="image/png, image/jpeg, image/jpg">
<div class="file-upload fx-column justify-content-center align-items-center" [style.height.px]="height"
  (click)="fileUpload.click()" [class.active]="active" *ngIf="!src">
  <div class="upload-icon-container">
    <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
  </div>
  <div class="mt-10 fx-column">
    <span class="text main text-center">{{"UPLOAD_PICTURE.INSTRUCTION" | translate}}</span>
    <span class="small main text-center">{{"UPLOAD_PICTURE.FILE_TYPES" | translate}}</span>
  </div>
</div>
<div class="fx-row align-items-center justify-content-start" *ngIf="src">
  <div class="fx-flex">
    <img [src]="src" style="max-width: 100%;" [style.max-height.px]="height" />
    <!-- <span>toto</span> -->
  </div>
  <div class="fx-column ml-20">
    <button class="button" (click)="fileUpload.click()">
      <div class="fx-row align-items-center justify-content-start">
        {{'ACTIONS.REPLACE_IMAGE' | translate}}
      </div>
    </button>
    <button class="button mt-10" (click)="remove()">
      {{'ACTIONS.REMOVE_IMAGE' | translate}}
    </button>
  </div>
</div>