import { Injectable } from '@angular/core';
import { Integration, IntegrationRecurrence, IntegrationType, IntegrationTypeComingsoon } from '../models/integration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Trademark } from '../models/trademark';
import { Paginator } from '../models/paginator';

export interface Data {
  _id: any;
  count: number;
}


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {

  constructor(
    private http: HttpClient
  ) { }

  icon(integrationType: string) {
    switch (integrationType) {
      case IntegrationType.WEBSITE:
        return 'language';
      case IntegrationTypeComingsoon.MAIL:
        return 'email';
      case IntegrationTypeComingsoon.INVOICING:
        return 'receipt_long';
      case IntegrationTypeComingsoon.DOCUMENT_MANAGEMENT:
        return 'folder';
      case IntegrationTypeComingsoon.SOCIAL_NETWORK:
        return 'share';
      default:
        return 'question_mark'
    }
  }

  types() {
    return [
      ...Object.keys(IntegrationType).map(key => ({
        key,
        icon: this.icon(key),
        logo: undefined,
        available: true,
        title: `INTEGRATIONS.${key.toUpperCase()}.TITLE`,
        description: `INTEGRATIONS.${key.toUpperCase()}.DESCRIPTION`
      })),
      ...Object.keys(IntegrationTypeComingsoon).map(key => ({
        key,
        icon: this.icon(key),
        logo: undefined,
        available: false,
        title: `INTEGRATIONS.${key.toUpperCase()}.TITLE`,
        description: `INTEGRATIONS.${key.toUpperCase()}.DESCRIPTION`
      }))]
  }

  countAll(type?: IntegrationType[], recurrence?: string[], trademark?: Trademark[], query?: string): Promise<{ count: number }> {
    let params = new HttpParams();

    if (type) {
      type.forEach(t => {
        params = params.append('type[]', t.toUpperCase());
      })
    }

    if (recurrence) {
      recurrence.forEach(r => {
        params = params.append('recurrence[]', r);
      })
    }

    if (trademark) {
      trademark.forEach(tm => {
        if (tm._id)
          params = params.append('trademark[]', tm._id);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<{ count: number }>(`${environment.backend}/integrations/count`, { params }));
  }


  retrieveAll(pageNumber: number, limit: number, offset: number, sort?: string, type?: IntegrationType[], recurrence?: string[], trademark?: Trademark[], query?: string): Promise<Paginator<Integration>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    if (type) {
      type.forEach(t => {
        params = params.append('type[]', t.toUpperCase());
      })
    }

    if (recurrence) {
      recurrence.forEach(r => {
        params = params.append('recurrence[]', r);
      })
    }

    if (trademark) {
      trademark.forEach(tm => {
        if (tm._id)
          params = params.append('trademark[]', tm._id);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Integration>>(`${environment.backend}/integrations/search`, { params }));
  }

  aggregate(type?: IntegrationType[], recurrence?: string[], trademark?: Trademark[], query?: string): Promise<Data[]> {
    let params = new HttpParams();

    if (type) {
      type.forEach(t => {
        params = params.append('type[]', t.toUpperCase());
      })
    }

    if (recurrence) {
      recurrence.forEach(r => {
        params = params.append('recurrence[]', r);
      })
    }

    if (trademark) {
      trademark.forEach(tm => {
        if (tm._id)
          params = params.append('trademark[]', tm._id);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Data[]>(`${environment.backend}/integrations/aggregate/type`, { params }));
  }

  create(name: string, type: string, recurrence: IntegrationRecurrence, url: string, trademarks: Trademark[]): Promise<Integration> {
    return firstValueFrom(this.http
      .post<Integration>(`${environment.backend}/integrations`, {
        name,
        type,
        recurrence,
        trademarks: trademarks.map(tm => tm._id),
        url
      }));
  }

  update(id: string, name: string, type: string, recurrence: IntegrationRecurrence, url: string, trademarks: Trademark[]): Promise<Integration> {
    return firstValueFrom(this.http
      .put<Integration>(`${environment.backend}/integrations/${id}`, {
        name,
        type,
        recurrence,
        trademarks: trademarks.map(tm => tm._id),
        url
      }));
  }

  trigger(id: string,): Promise<void> {
    return firstValueFrom(this.http
      .post<void>(`${environment.backend}/integrations/${id}/launch`, {}));
  }

  delete(id: string): Promise<void> {
    return firstValueFrom(this.http
      .delete<void>(`${environment.backend}/integrations/${id}`));
  }
}
