import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subscription, firstValueFrom } from 'rxjs';
import { CaseAttachmentsComponent } from 'src/app/components/case-structure/case-attachments/case-attachments.component';
import { CaseEvidenceRecordsComponent } from 'src/app/components/case-structure/case-evidence-records/case-evidence-records.component';
import { CaseInformationComponent } from 'src/app/components/case-structure/case-information/case-information.component';
import { CaseNotesComponent } from 'src/app/components/case-structure/case-notes/case-notes.component';
import { CaseProofsComponent } from 'src/app/components/case-structure/case-proofs/case-proofs.component';
import { CaseUsersComponent } from 'src/app/components/case-structure/case-users/case-users.component';
import { DocumentsCaseComponent } from 'src/app/components/documents-display/documents-case/documents-case.component';
import { AddCaseDocumentsDialogComponent } from 'src/app/dialogs/add-case-documents-dialog/add-case-documents-dialog.component';
import { CaseTimelineActionDialogComponent } from 'src/app/dialogs/case-timeline-action-dialog/case-timeline-action-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Case, CaseDocument, CasePossiblePhase, CaseStatus, CaseTimelinePhase, Note, SubPhase } from 'src/app/models/case';
import { Document } from 'src/app/models/document';
import { EvidenceRecord } from 'src/app/models/evidenceRecord';
import { Paginator } from 'src/app/models/paginator';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { User } from 'src/app/models/user';
import { AclService, Scope } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CaseCreationOrUpdate, CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { PropertyOfficeService } from 'src/app/services/property-office.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit {
  from: string = 'CASES';
  mode: 'CONSULTATION' | 'EDITION' = 'CONSULTATION';
  loading: boolean = false;

  case?: Case;

  phases: CaseTimelinePhase[] = [];
  possibleActions: CasePossiblePhase[] = [];

  propertyOffice?: PropertyOffice;

  comment?: string;

  selectedIndex: number = 0;

  numberOfAttachments: number = 0;

  documents: Paginator<CaseDocument> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredDocuments: CaseDocument[] = [];

  selection = new SelectionModel<CaseDocument>(true, []);

  @ViewChild('caseTabs') caseTabs?: MatTabGroup;
  @ViewChild('caseInformation') caseInformation?: CaseInformationComponent;
  @ViewChild('caseProofs') caseProofs?: CaseProofsComponent;
  @ViewChild('caseEvidenceRecords') caseEvidenceRecords?: CaseEvidenceRecordsComponent;
  @ViewChild('caseAttachments') caseAttachments?: CaseAttachmentsComponent;
  @ViewChild('caseNotes') caseNotes?: CaseNotesComponent;
  @ViewChild('caseUsers') caseUsers?: CaseUsersComponent;

  @ViewChild('scrollable') scrollable?: ElementRef;

  noLinkedDocumentsButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'CASES_LIST.NEW',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.router.navigate(['new'], { relativeTo: this.route }) }
    }

  private user?: User;

  focusedNote?: Note;

  private subscription: Subscription;

  isManagerOrAbove: boolean = false;



  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private authService: AuthenticationService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private location: Location,
    private propertyOfficeService: PropertyOfficeService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
    this.from = window.history.state.from || "CASES";
    this.mode = window.history.state.mode || "CONSULTATION";
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "CASE.REFRESH" && change.data && change.data.caseId && this.case && this.case._id && change.data.caseId === this.case?._id) {
        this.retrieveCase(this.case._id)
      }
    });
  }

  async ngOnInit() {
    this.isManagerOrAbove = this.aclService.isManagerOrAbove();
    const params = await firstValueFrom(this.route.params);
    const queryParams = await firstValueFrom(this.route.queryParams);
    await this.retrieveCase(params['id']);
    if (queryParams["download"]) {
      await this.downloadEvidenceRecord(queryParams["download"]);
      this.router.navigate([], {
        queryParams: {
          'download': null
        },
        queryParamsHandling: 'merge'
      })
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index === 1) {
      if (this.caseProofs) {
        this.caseProofs?.init();
      }
    } else if (event.index === 2) {
      if (this.caseEvidenceRecords) {
        this.caseEvidenceRecords?.init();
      }
    } else if (event.index === 3) {
      if (this.caseAttachments) {
        this.caseAttachments?.retrieveAttachments();
      }
    } else if (event.index === 4) {
      if (this.caseNotes) {
        this.caseNotes?.scroll();
      }
    } else if (event.index === 5) {
      if (this.caseUsers) {
        this.caseUsers?.init();
      }
    }
  }

  canUpdate() {
    return this.case && this.case.phase && this.case.phase.name !== CaseStatus.CLOSED;
  }

  isUpdateDisabled() {
    return this.loading || (!this.case?.trademark && this.case?.trademark.ref === '')
      || (this.case?.niceClassification.length === 0)
      || (!this.case?.timePeriod || !this.case.timePeriod.start || !this.case.timePeriod.end)
  }

  async retrieveCase(id: string) {
    try {
      this._message.emitChange("LOADING", "START");
      this.loading = true;
      this.case = await this.caseService.retrieve(id);
      this.phases = this.case.timeline.map((phase) => this.caseService._phaseProcessor(phase));
      if (this.case) {
        this.case.phase = this.caseService._currentPhase(this.case);
      }
      if (this.caseProofs) {
        this.caseProofs?.init();
      }
      if (this.case && this.case._id) {
        this.numberOfAttachments = await this.caseService.countAttachments(this.case._id);
      }
      if (this.case.propertyOffice) {
        this.propertyOffice = await this.propertyOfficeService.retrieveOne(this.case?.propertyOffice);
      }
      await this.retrievePossibleActions();
      this.loading = false;
      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async retrievePossibleActions() {
    try {
      if (this.case && this.propertyOffice && this.case.phase) {
        this.possibleActions = (await this.caseService.getPossiblePhases(this.case.phase.name, this.case?.type, this.propertyOffice.code)).filter((p) => p.method !== 'endDate')
        if (this.caseService._isSuspended(this.case)) {
          this.possibleActions = this.possibleActions.filter(a => a.method !== 'suspend');
          this.possibleActions.unshift({
            method: 'unsuspend',
            duration: '',
            status: CaseStatus.INSTRUCTION
          })
        }
        if (this.case && this.case.phase && this.case.phase.name === CaseStatus.INSTRUCTION) {
          this.possibleActions.unshift({
            method: 'subphase',
            duration: '',
            status: CaseStatus.INSTRUCTION
          })
        }
      }


    } catch (err) {
      console.log(err)
    }
  }

  async timelineAction(action: CasePossiblePhase) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '640px',
      autoFocus: false,
      data: {
        action,
        case: this.case
      }
    }
    const dialog = this.dialog.open(CaseTimelineActionDialogComponent, config);
    const result: {
      confirmed: boolean,
      data: {
        duration?: {
          value: number,
          unit: moment.unitOfTime.DurationConstructor
        },
        date?: moment.Moment,
        start?: moment.Moment,
        end?: moment.Moment,
        label?: string,
        meta: {
          withdrawal?: boolean,
          definitive?: boolean,
          decision?: string
        }
      }
    } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.case && this.case._id && this.case.phase) {
      try {
        this._message.emitChange("LOADING", "START");
        if (action.method === 'close' && result.data.date) {
          this.case = await this.caseService.closeTimeline(this.case._id, result.data.date, result.data.meta);
          this.toastr.info(this.translate.instant('CASE.TIMELINE_ACTION_CLOSE_SUCCESS', { identifier: this.case.identifier || this.case.reference }));
        } else if (action.method === 'suspend' && result.data.duration && result.data.start && result.data.end) {
          this.case = await this.caseService.suspendTimeline(this.case._id, result.data.start, result.data.end);
          this.toastr.info(this.translate.instant('CASE.TIMELINE_ACTION_SUSPEND_SUCCESS', { identifier: this.case.identifier || this.case.reference, start: result.data.start.format('ll'), end: result.data.end.format('ll') }));
        } else if (action.method === 'unsuspend' && result.data.date) {
          this.case = await this.caseService.unsuspendTimeline(this.case._id, result.data.date);
          this.toastr.info(this.translate.instant('CASE.TIMELINE_ACTION_UNSUSPEND_SUCCESS', { identifier: this.case.identifier || this.case.reference }));
        } else if (action.method === 'next' && result.data.start) {
          this.case = await this.caseService.nextPhase(this.case._id, action.status, result.data.start, result.data.end);
          this.toastr.info(this.translate.instant(`CASE.TIMELINE_ACTION_NEXT_${action.status.toUpperCase()}_SUCCESS`, { identifier: this.case.identifier || this.case.reference }));
        } else if (action.method === 'subphase' && result.data.label && result.data.start && result.data.end) {
          const subPhases = [
            ...(this.case.phase.subPhases ? this.case.phase.subPhases : []),
            {
              label: result.data.label,
              start: result.data.start,
              end: result.data.end
            }
          ];
          this.case = await this.caseService.defineSubphases(this.case._id, subPhases);
          this.toastr.info(this.translate.instant(`CASE.TIMELINE_ACTION_SUBPHASE_SUCCESS`, { identifier: this.case.identifier || this.case.reference, label: result.data.label.length > 20 ? `${result.data.label.substring(0, 20)}...` : result.data.label }));
        }
        this.phases = this.case.timeline.map((phase) => this.caseService._phaseProcessor(phase));
        this.case.phase = this.caseService._currentPhase(this.case);
        await this.retrievePossibleActions();
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async editSubPhase(event: { phase: SubPhase, index: number }) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '640px',
      autoFocus: false,
      data: {
        action: {
          method: "editsubphase",
          duration: '',
          status: CaseStatus.INSTRUCTION
        },
        subphase: event.phase,
        case: this.case
      }
    }
    const dialog = this.dialog.open(CaseTimelineActionDialogComponent, config);
    const result: {
      confirmed: boolean,
      data: {
        duration?: {
          value: number,
          unit: moment.unitOfTime.DurationConstructor
        },
        date?: moment.Moment,
        start?: moment.Moment,
        end?: moment.Moment,
        label?: string,
        meta: {
          withdrawal?: boolean,
          definitive?: boolean,
          decision?: string
        }
      }
    } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.case && this.case._id && this.case.phase && this.case.phase.subPhases) {
      try {
        this._message.emitChange("LOADING", "START");
        if (result.data.label && result.data.start && result.data.end) {
          const subPhases = this.case.phase.subPhases;
          subPhases[event.index] = {
            start: result.data.start,
            end: result.data.end,
            label: result.data.label
          }
          this.case = await this.caseService.defineSubphases(this.case._id, subPhases);
          this.toastr.info(this.translate.instant(`CASE.TIMELINE_ACTION_EDITSUBPHASE_SUCCESS`, { identifier: this.case.identifier || this.case.reference, label: result.data.label.length > 20 ? `${result.data.label.substring(0, 20)}...` : result.data.label }));
        }
        this.phases = this.case.timeline.map((phase) => this.caseService._phaseProcessor(phase));
        this.case.phase = this.caseService._currentPhase(this.case);
        await this.retrievePossibleActions();
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async deleteSubPhase(event: { phase: SubPhase, index: number }) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'CASE.TIMELINE_ACTION_DELETESUBPHASE_TITLE',
        text: this.translate.instant('CASE.TIMELINE_ACTION_DELETESUBPHASE_TEXT', { reference: this.case?.identifier || this.case?.reference, label: event.phase.label.length > 20 ? `${event.phase.label.substring(0, 20)}...` : event.phase.label }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.case && this.case._id && this.case.phase && this.case.phase.subPhases) {
      try {
        this._message.emitChange("LOADING", "START");
        const subPhases = this.case.phase.subPhases;
        subPhases.splice(event.index, 1);
        this.case = await this.caseService.defineSubphases(this.case._id, subPhases);
        this.toastr.info(this.translate.instant(`CASE.TIMELINE_ACTION_DELETESUBPHASE_SUCCESS`, { identifier: this.case.identifier || this.case.reference, label: event.phase.label ? `${event.phase.label.substring(0, 20)}...` : event.phase.label }));
        this.phases = this.case.timeline.map((phase) => this.caseService._phaseProcessor(phase));
        this.case.phase = this.caseService._currentPhase(this.case);
        await this.retrievePossibleActions();
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }



  async onRefresh() {
    if (this.case && this.case._id) {
      this.retrieveCase(this.case._id);
      if (this.caseInformation) {
        this.caseInformation.refreshCoverage();
      }
      if (this.caseAttachments) {
        this.numberOfAttachments = await this.caseService.countAttachments(this.case._id);
      }
    }
  }


  openEditMode() {
    if (this.caseTabs) {
      this.caseTabs.selectedIndex = 0;
      this.mode = 'EDITION';
    }

  }


  async update() {
    try {
      if (this.case && this.case._id) {

        this._message.emitChange("LOADING", "START");
        this.loading = true;
        const caseToUpdate: CaseCreationOrUpdate = {
          trademark: this.case.trademark.ref,
          propertyOffice: this.case.propertyOffice,
          niceClassification: this.case.niceClassification,
          identifier: this.case.identifier,
          timePeriod: {
            notificationDate: moment(this.case.timePeriod.notificationDate).format("YYYY-MM-DD"),
            endInstructionDate: moment(this.case.timePeriod.endInstructionDate).format("YYYY-MM-DD"),
            start: moment(this.case.timePeriod.start).format("YYYY-MM-DD"),
            end: moment(this.case.timePeriod.end).format("YYYY-MM-DD")
          },
          lang: this.case.lang,
          type: this.case.type
        }
        this.case = await this.caseService.update(this.case._id, caseToUpdate);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('CASE.UPDATE_SUCCESS', { reference: this.case.identifier || this.case.reference }));
        this.mode = 'CONSULTATION';
      }
    } catch (err) {
      this.loading = false;
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  async delete() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'CASE.DELETE_CASE_TITLE',
        text: this.translate.instant('CASE.DELETE_CASE_TEXT', { reference: this.case?.identifier || this.case?.reference }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'CASE.DELETE_CASE_CONFIRMATION',
          value: this.case?.identifier || this.case?.reference
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.case) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.caseService.delete(this.case._id!);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.router.navigateByUrl('/cases');
        this.toastr.success(this.translate.instant('CASE.DELETE_CASE_SUCCESS', { reference: this.case?.identifier || this.case?.reference }));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async generateEvidenceRecord() {
    try {
      if (this.case && this.case._id) {
        this._message.emitChange("LOADING", "START");
        await this.caseService.generateEvidenceRecord(this.case?._id);
        if (this.caseEvidenceRecords) {
          this.caseEvidenceRecords.init();
        }
        this.toastr.success('EVIDENCE_RECORDS.GENERATION_SUCCESS_MESSAGE', 'EVIDENCE_RECORDS.GENERATION_SUCCESS_TITLE');
        this._message.emitChange("LOADING", "END");
      }

    } catch (err: any) {
      this._message.emitChange("LOADING", "END");
      if (err && err.error && err.error.message && this.utils.hasTranslation(`ERRORS.${err.error.message}`)) {
        this.toastr.error(`ERRORS.${err.error.message}`)
      } else {
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  async downloadEvidenceRecord(evidenceRecordId: string) {

    try {

      if (this.case && this.case._id && evidenceRecordId) {
        const evidenceRecord = await this.caseService.retrieveEvidenceRecord(this.case._id, evidenceRecordId);
        const base_name = `${this.case.identifier || this.case.reference}_evidence_record_${evidenceRecord.name}`;
        const toast = this.toastr.info(this.translate.instant('EVIDENCE_RECORDS.DOWNLOAD_MESSAGE', evidenceRecord), "EVIDENCE_RECORDS.DOWNLOAD_TITLE", {
          progressBar: true,
          disableTimeOut: true
        })
        const blob = await this.caseService.downloadEvidenceRecord(this.case._id, evidenceRecordId);

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = `${base_name}.zip`;
        a.click();
        this.toastr.clear(toast.toastId);
        URL.revokeObjectURL(objectUrl);
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

}
