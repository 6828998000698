import * as moment from "moment";
import { Moment } from "moment";
import { Document } from "./document";

export enum CaseStatus {
  NOTIFICATION = 'NOTIFICATION',
  INSTRUCTION = 'INSTRUCTION',
  DECISION = 'DECISION',
  APPEAL = 'APPEAL',
  CLOSED = 'CLOSED'
}

export enum CaseType {
  PRINCIPAL = 'PRINCIPAL',
  COUNTERCLAIM = 'COUNTERCLAIM'
}

export interface Note {
  _id?: string,
  comment: string,
  timestamp: Date | Moment,
  author: {
    ref: string;
    firstName: string;
    lastName: string;
    avatar?: string;
  }
}

export interface CaseAttachment {
  _id?: string;
  case: string;
  name: string;
  description?: string;
  type: string;
  appendix?: boolean;
  blobKey?: string;
  nonUse?: boolean;
  document?: string;
  uploader: {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  organizationId: string;
}

export type CaseDocument = Document & { score: number };

export interface CaseScore {
  global: number,
  documentsCount: number,
  documentsAccuracy: number,
  documentsClasses: number,
  temporalCoverage: number
}

export const SuspendedLabel = 'suspended';

export interface SubPhase {
  label: string,
  start: Moment,
  end?: Moment,
  duration?: number,
  infinite?: boolean,
  offset?: number
}

export interface InstructionPhaseMeta { subPhases?: SubPhase[] }

export const ClosedDecisionArray = ['LOST', 'MITIGATED', 'WON'] as const;
type ClosedDecision = typeof ClosedDecisionArray[number];
export interface ClosedPhaseMeta { withdrawal: boolean, decision: ClosedDecision, definitive: boolean }


export interface CaseTimelinePhase {
  name: CaseStatus;
  start: Moment;
  end?: Moment;
  suspended: Boolean;
  duration?: number;
  infinite?: boolean;
  canAddEvidence: boolean;
  meta?: InstructionPhaseMeta | ClosedPhaseMeta;
  endsIn?: number;
  subPhases?: SubPhase[]
}

export interface CasePossiblePhase {
  duration: string,
  method: string,
  status: CaseStatus
}

export interface Case {
  _id?: string;
  identifier?: string;
  reference: string; // Define with First 3 letter or less + time or other attributes + 6 (or less) random char
  status: CaseStatus;
  type: CaseType;
  trademark: {
    ref: string,
    name: string;
    identifierNumber: string;
    countryOfDesignation: string;
  };
  propertyOffice?: string; // TODEFINE useful or not according to trademark office
  niceClassification: number[],
  createdAt: Date,
  timePeriod: {
    notificationDate: Moment,
    endInstructionDate: Moment,
    start: Moment,
    end: Moment
  };
  lang: string;
  timeline: CaseTimelinePhase[];
  phase?: CaseTimelinePhase;
  documents?: { ref: string, score: number }[];
  owner?:
  {
    ref: string;
    firstName: string;
    lastName: string;
    avatar: string;
  },
  // users : []  Part 2,
  score: CaseScore,
  notes: Note[],
  organizationId?: string;
}