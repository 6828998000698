import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Document } from 'src/app/models/document';
import { DocumentClass } from 'src/app/models/documentClass';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-edit-document-class-dialog',
  templateUrl: './edit-document-class-dialog.component.html',
  styleUrls: ['./edit-document-class-dialog.component.scss']
})
export class EditDocumentClassDialogComponent {
  document?: Document;
  classes: any[] = [];
  class?: DocumentClass

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EditDocumentClassDialogComponent>,
    private documentService: DocumentService
  ) {

  }

  ngOnInit(): void {
    this.document = this.data.document;
    this.class = this.document && this.document.class ? this.document.class : DocumentClass.OTHER;
  }

  ngOnDestroy(): void {
  }



  onCancel(): void {
    this.dialogRef.close({
      edited: false
    });
  }

  async onConfirm() {
    this.dialogRef.close({
      edited: true,
      class: this.class
    });
  }
}
