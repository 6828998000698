import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { TrademarkType } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';

type TrademarkTypeOption = { value: TrademarkType, selected: boolean, disabled?: boolean, display?: boolean }

@Component({
  selector: 'app-trademark-type-select',
  templateUrl: './trademark-type-select.component.html',
  styleUrls: ['./trademark-type-select.component.scss']
})
export class TrademarkTypeSelectComponent {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;

  @Input() disabled: boolean = false;
  @Input() disabledValues: string[] = [];
  @Input() emitOnInit: boolean = true;
  @Input() restrict: string[] = [];

  types: TrademarkTypeOption[] = [
    {
      value: TrademarkType.SHAPE_3D,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COLOUR,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COLOURS_COMBINATION,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.COMBINED,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.FIGURATIVE,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.GENERAL,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.HOLOGRAM,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.KENNFADEN,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.MOTION,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.MULTIMEDIA,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.NUMBER,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.OLFACTORY,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.OTHER,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.PACKING,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.PATTERN,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.POSITION,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.SOUND,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.STYLIZED_CHARACTERS,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.UNDEFINED,
      selected: false,
      disabled: false
    },
    {
      value: TrademarkType.WORD,
      selected: false,
      disabled: false
    }
  ]
  selected: TrademarkTypeOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: string | string[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['restrict'] && !_.isEqual(changes['restrict']['currentValue'], changes['restrict']['previousValue'])) {
      this.retrieveClasses();
    }
    if (changes['value'] && changes['value']['currentValue'] !== changes['value']['previousValue']) {
      this.setInitialState();
    }
  }

  _isDisabled(option: TrademarkType) {
    return this.disabledValues.includes(option);
  }

  _isSelected(option: TrademarkType) {
    return this.selected.map((t) => t.value).includes(option);
  }

  populate() {
    if (this.multiple && this.value && (this.value as TrademarkType[]).length > 0) {
      const values = (this.value as TrademarkType[]);
      this.selected = values.map((t) => ({ value: t, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as TrademarkType);
      this.selected = [{ value, selected: true, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  isAllSelected() {
    return this.types.every((type) => type.selected);
  }

  toggleAllSelection() {
    if (!this.isAllSelected()) {
      this.types.forEach((type) => {
        type.selected = true;
      })
      this.selected = this.types;
    } else {
      this.types.forEach((type) => {
        type.selected = false;
      })
      this.selected = [];
    }
    this.onChange();
  }

  async setInitialState() {
    await this.retrieveClasses();
    if (this.multiple && this.value && (this.value as TrademarkType[]).length > 0) {
      const values = (this.value as TrademarkType[]);
      this.selected = values.map((t) => ({ value: t, selected: true }));

      this.types.forEach(type => {
        if (values.includes(type.value)) {
          type.selected = true;
        } else {
          type.selected = false;
        }
      });
      const value = _.unionWith([...this.types.filter(t => t.selected).map((t) => t.value), ...this.selected.map((o) => o.value)]);
      if (value.length > 0) {
        this.selection = value.length === 1 ? (this.translate.instant(`TRADEMARK.TYPES.${value[0].toUpperCase().replaceAll(' ', '_')}`)) : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as TrademarkType);
      this.selected = [{ value, selected: true }];
      const option = this.types.find(t => t.value == value);
      if (option) {
        option.selected = true;
      }
      this.selection = value ? this.translate.instant(`TRADEMARK.TYPES.${value.toUpperCase().replaceAll(' ', '_')}`) : undefined;
    } else {
      this.types.forEach(type => {
        type.selected = false;
      });
      this.selection = undefined;
    }
    if (this.emitOnInit) {
      this.onChange();
    }
  }

  removeFromPreselection(option: TrademarkTypeOption) {
    this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    const doc = this.types.find((t) => t.value === option.value);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: TrademarkTypeOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    } else if (!this._isSelected(option.value)) {
      this.selected.push({ value: option.value, selected: true, display: false });
    }
  }

  async retrieveClasses() {
    const types = this.types.map(type => ({ ...type, selected: this._isSelected(type.value), disabled: this._isDisabled(type.value) }));
    if (this.restrict && this.restrict.length > 0) {
      this.types = types.filter((type) => this.restrict.includes(type.value))
    } else {
      this.types = types;
    }
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.types.forEach(t => {
        if (t.value !== option.value) {
          t.selected = false;
        }
      })
    }
    this.onChange();
  }

  onChange() {
    if (!this.multiple) {
      const value = this.types.find(t => t.selected);
      this.selection = value ? this.translate.instant(`TRADEMARK.TYPES.${value.value.toUpperCase().replaceAll(' ', '_')}`) : undefined;
      this.valueChange.emit(value?.value);
      this.change.emit({ value });
      this.trigger?.close();
    } else {
      const values = _.unionWith([...this.types.filter((t) => t.selected).map((t) => t.value), ...this.selected.map((o) => o.value)]);
      if (values.length > 0) {
        this.selection = values.length === 1 ? (this.translate.instant(`TRADEMARK.TYPES.${values[0].toUpperCase().replaceAll(' ', '_')}`)) : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values);
      this.change.emit({ value: this.types.filter((t) => values.includes(t.value)) });
    }
  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return (this._filter && this._filter.length > 0) ? this.types.filter(type => {
      return type.value.toString().includes(this._filter as string) || this.translate.instant(`TRADEMARK.TYPES.${type.value.toUpperCase().replaceAll(' ', '_')}`).toLowerCase().includes(this._filter?.toLowerCase())
    }) : this.types;
  }

  onSearch(event: any) {
    if (event && event.hasOwnProperty('value')) {
      this._filter = event.value;
    }
  }

  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.types.forEach(type => {
      type.selected = false;
    });
    this.selected = [];
    this.selection = undefined
    this._filter = undefined;
    this.onChange();
    this.trigger?.close();
  }

  truncate(value: string) {
    if (value.length < 30) return value;
    return `${value.substring(0, 30)}...`
  }
}
