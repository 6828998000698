<div class="fx-row fx-wrap">
  <div class="fx-row align-items-center justify-content-start  fx-flex-100">
    <app-back></app-back>
  </div>
  <div class="fx-row align-items-center justify-content-between fx-flex-100">
    <div class="fx-row align-items-center justify-content-start">
      <span class="page-title" *ngIf="case">{{case.identifier || case.reference}}</span>
      <app-case-phase [case]="case" *ngIf="case" class="ml-10 mb-10"></app-case-phase>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button [cdkMenuTriggerFor]="menu" class="button ml-20" *ngIf="mode === 'CONSULTATION'">
        <div class="fx-row align-items-center justify-content-between fx-flex-100">
          <span class="dark fx-flex">{{'ACTIONS.ACTIONS' | translate}}</span>
          <mat-icon class="dark ml-5" fontSet="material-icons-outlined">arrow_drop_down</mat-icon>
        </div>
      </button>
      <ng-template #menu>
        <mat-card class="menu-container p-0 elevation" style="max-width:180px;" cdkMenu>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-between menu-item actionable clickable"
              (click)="generateEvidenceRecord()">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">folder_special</mat-icon>
              <span class="text fx-flex">{{'CASE.GENERATE_EVIDENCE_RECORD' | translate}}</span>
            </div>
            <mat-divider></mat-divider>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="timelineAction(action)" *ngFor="let action of possibleActions" cdkMenuItem aclBasedElement
              resource="CASE" [scopes]="['U']">
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">schedule</mat-icon>
              <span class="text fx-flex">{{(action?.method === 'next' ?
                'CASE.TIMELINE_ACTION_NEXT_'+action?.status?.toUpperCase() :
                'CASE.TIMELINE_ACTION_'+action?.method?.toUpperCase())
                | translate}}</span>
            </div>
            <mat-divider *ngIf="possibleActions.length > 0"></mat-divider>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="canUpdate() ? openEditMode() : null" [class.disabled]="!canUpdate()" cdkMenuItem aclBasedElement
              resource="CASE" [scopes]="['U']">
              <mat-icon class="dark mr-5" [class.light]="!canUpdate()" fontSet="material-icons-outlined">edit</mat-icon>
              <span class="text fx-flex" [class.light]="!canUpdate()">{{'ACTIONS.EDIT' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="delete()" cdkMenuItem aclBasedElement resource="CASE" [scopes]="['D']">
              <mat-icon class="red mr-5" fontSet="material-icons-outlined">delete</mat-icon>
              <span class="text fx-flex">{{'ACTIONS.DELETE' | translate}}</span>
            </div>
          </div>
        </mat-card>
      </ng-template>
      <button class="button ml-20 fx-flex" *ngIf="mode === 'EDITION'" (click)="mode = 'CONSULTATION'">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" *ngIf="mode === 'EDITION'" (click)="update()" [disabled]="isUpdateDisabled()"
        [class.disabled]="isUpdateDisabled()">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
  <div class="mt-10  fx-flex-100 fx-row">
    <app-case-timeline [phases]="phases" class="fx-flex-100" (edit)="editSubPhase($event)"
      (delete)="deleteSubPhase($event)"></app-case-timeline>
  </div>
</div>
<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" class="mt-20" *ngIf="case"
  (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="selectedIndex" #caseTabs>
  <mat-tab>
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_INFORMATION' | translate}}
    </ng-template>
    <div class="mt-20">
      <app-case-information [case]="case" [propertyOffice]="propertyOffice" [mode]="mode" (toProofs)="selectedIndex = 1"
        #caseInformation></app-case-information>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_PROOFS' | translate}}
      <div class="badge ml-5" *ngIf="case && case.documents && case.documents.length > 0">
        <span class="white small bold">{{case.documents.length}}</span>
      </div>
    </ng-template>
    <div class="mt-20">
      <app-case-proofs [case]="case" (refresh)="onRefresh()"
        [readonly]="case && case.phase && case.phase ? !case.phase.canAddEvidence : false"
        #caseProofs></app-case-proofs>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_EVIDENCE_RECORDS' | translate}}
    </ng-template>
    <div class="mt-20">
      <app-case-evidence-records [case]="case" #caseEvidenceRecords></app-case-evidence-records>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_ATTACHMENTS' | translate}}
      <div class="badge ml-5" *ngIf="numberOfAttachments > 0">
        <span class="white small bold">{{numberOfAttachments}}</span>
      </div>
    </ng-template>
    <div class="mt-20">
      <app-case-attachments [case]="case" (refresh)="onRefresh()" #caseAttachments></app-case-attachments>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_NOTES' | translate}}
      <div class="badge ml-5" *ngIf="case && case.notes && case.notes.length > 0">
        <span class="white small bold">{{case.notes.length}}</span>
      </div>
    </ng-template>
    <div class="mt-20">
      <app-case-notes [case]="case" #caseNotes></app-case-notes>
    </div>
  </mat-tab>
  <mat-tab *ngIf="isManagerOrAbove">
    <ng-template mat-tab-label class="text">
      {{'CASE.TAB_USERS' | translate}}
    </ng-template>
    <div class="mt-20">
      <app-case-users [case]="case" #caseUsers></app-case-users>
    </div>
  </mat-tab>
</mat-tab-group>