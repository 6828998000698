<mat-card class="p-0">
  <div class="header">
  </div>
  <div class="fx-row align-items-end justify-content-start identity-container p-16">
    <app-avatar [user]="user" [size]="80" *ngIf="user" [mode]="'shadow'"></app-avatar>
    <div class="fx-column ml-10 mb-10">
      <span class="title bold">{{user?.firstName}} {{user?.lastName}}</span>
      <span class="text light">{{user?.email}}</span>
    </div>
  </div>
  <div class="p-16">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab>
        <ng-template mat-tab-label class="text">
          {{'SETTINGS.INFORMATION.TAB' | translate}}
        </ng-template>
        <div class="p-16">
          <app-settings-information [existingUser]="user" (change)="onChange($event)"></app-settings-information>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label class="text">
          {{'SETTINGS.SECURITY.TAB' | translate}}
        </ng-template>
        <div class="p-16">
          <app-settings-security [user]="user"></app-settings-security>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</mat-card>