import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OtpDialogComponent } from '../dialogs/otp-dialog/otp-dialog.component';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(
    private authService: AuthenticationService,
    private dialog: MatDialog
  ) { }

  check(onSuccess: Function, onFailure: Function) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px'
    }
    const dialog = this.dialog.open(OtpDialogComponent, config);
    dialog.afterClosed().subscribe((result: { success: boolean, canceled: boolean }) => {
      if (result && result.success) {
        return onSuccess();
      } else {
        return onFailure();
      }
    })
  }
}
