import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { CaseTimelinePhase, InstructionPhaseMeta, SubPhase } from 'src/app/models/case';

@Component({
  selector: 'app-case-timeline',
  templateUrl: './case-timeline.component.html',
  styleUrls: ['./case-timeline.component.scss']
})
export class CaseTimelineComponent implements OnInit, OnChanges {
  @ViewChild("timelineContainer") timelineContainer?: ElementRef;
  @Input() phases: CaseTimelinePhase[] = [];

  total: number = 0;
  totalElapsed: number = 0;
  phasesDone: number = 0;
  today: moment.Moment = moment.utc();
  cursorMargin: number = 0;

  @Output() edit = new EventEmitter<{ phase: SubPhase, index: number }>();
  @Output() delete = new EventEmitter<{ phase: SubPhase, index: number }>();

  ngOnInit(): void {
    this.total = 0;
    this.phases.forEach((phase) => {
      this.total += !phase.duration ? 100 : phase.duration;
    })
    setTimeout(() => {
      this.animate()
    }, 100)
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.total = 0;
    this.phases.forEach((phase) => {
      this.total += !phase.duration ? 100 : phase.duration;
    })
    setTimeout(() => {
      this.animate()
    }, 100)
  }

  animate() {
    let cursorMargin = 0;
    this.phases.forEach((phase, index) => {

      const elapsed = this.elapsed(phase);
      if (this.timelineContainer) {
        const phaseWidth = (this.timelineContainer.nativeElement.clientWidth - 16 * (this.phases.length - 1)) * (!phase.duration ? 100 : phase.duration) / this.total;

        cursorMargin += phaseWidth * elapsed / 100;
        if (elapsed === 100 && index < (this.phases.length - 1)) {
          cursorMargin += 16;
        }
      }
    })
    this.cursorMargin = cursorMargin;
  }

  formatDate(date: moment.Moment) {
    return date.format('ll')
  }

  formatDay(date: moment.Moment) {
    return date.format('MMM DD')
  }

  elapsed(phase: CaseTimelinePhase | SubPhase) {
    if (!phase.infinite) {
      if (this.today.isSameOrAfter(phase.end)) {
        return 100;
      } else if (this.today.isSameOrBefore(phase.start)) {
        return 0;
      } else {
        return 100 * this.today.diff(phase.start, 'days', true) / (phase.duration ?? 100);
      }
    } else {
      return this.today.isSameOrBefore(phase.start) ? 0 : 100;
    }
  }

}
