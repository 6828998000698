import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  email?: string;
  password?: string;
  confirmation?: string;
  nonce?: string;
  resent: boolean = false;

  errors: any = {
    email: {
      value: false,
      message: undefined
    },
    confirmation: {
      value: false,
      message: undefined
    },
    password: {
      value: false,
      message: undefined
    }
  }

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {

  }

  ngOnInit() {
    this.route.params
      .subscribe(async params => {
        const nonce = params['nonce'];
        this.retrieveInvitation(nonce)
      }
      );
  }

  async retrieveInvitation(nonce: string) {
    try {
      if (nonce) {
        const res = await this.authService.retrieveInvitation(nonce);
        this.nonce = nonce;
      }
    } catch (err) {

    }

  }

  _passwordStrength() {
    return [
      {
        label: 'MIN_LENGTH',
        valid: this.password && this.password.length >= 8
      },
      {
        label: 'MIN_LOWERCASE',
        valid: this.password && /[a-z]+/.test(this.password)
      },
      {
        label: 'MIN_UPPERCASE',
        valid: this.password && /[A-Z]+/.test(this.password)
      },
      {
        label: 'MIN_NUMBER',
        valid: this.password && /[0-9]+/.test(this.password)
      },
      {
        label: 'MIN_SYMBOL',
        valid: this.password && /[!@#\$%\^&\*]+/.test(this.password)
      }
    ]
  }

  _hasPasswordErrors() {

    this.errors.password = {
      value: false,
      message: undefined
    }
    if (!this.password) {
      this.errors.password.value = true;
      this.errors.password.message = this.translate.instant('ERRORS.PASSWORD_REQUIRED');
    }
    if (this._passwordStrength().some(attr => !attr.valid)) {
      this.errors.password.value = true;
      this.errors.password.message = this.translate.instant('ERRORS.PASSWORD_DO_NOT_MATCH_REQUIREMENTS');
    }
    this.errors.confirmation = {
      value: false,
      message: undefined
    }
    if (!this.confirmation) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.CONFIRMATION_REQUIRED');
    }
    if (this.confirmation && this.confirmation !== this.password) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.PASSWORDS_DO_NOT_MATCH');
    }
    return this.errors.password.value || this.errors.confirmation.value;
  }

  _hasEmailErrors() {
    this.errors.email = {
      value: false,
      message: undefined
    }
    if (!this.email) {
      this.errors.email.value = true;
      this.errors.email.message = this.translate.instant('ERRORS.EMAIL_REQUIRED');
    } else {
      if (!this.utils.validateEmailPattern(this.email)) {
        this.errors.email.value = true;
        this.errors.email.message = this.translate.instant('ERRORS.EMAIL_FORMAT');
      }
    }
    return this.errors.email.value;
  }

  async resendInvitation() {
    if (this._hasEmailErrors()) {
      return;
    } else {
      try {
        if (this.email) {
          const res = this.authService.resendInvitation(this.email);
          this.resent = true;
          this.toastr.info(this.translate.instant("SIGNUP.INVITATION_MAYBE_RESENT", res), undefined, { timeOut: 10000 });
        }

      } catch (err: any) {

        if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
          this.toastr.error(`ERRORS.${err.message}`)
        } else {
          this.toastr.error(`ERRORS.GENERIC`)
        }
      }
    }
  }

  async confirmInvitation() {
    if (this._hasPasswordErrors()) {
      return;
    } else {
      try {
        const res = await firstValueFrom(this.authService.confirmInvitation(this.nonce as string, this.password as string));
        this.translate.use(res.lang);
        this.router.navigate(["/"]);
        this.toastr.info(this.translate.instant("SIGNUP.PASSWORD_SET", res));
      } catch (err: any) {

        if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
          this.toastr.error(`ERRORS.${err.message}`)
        } else {
          this.toastr.error(`ERRORS.GENERIC`)
        }
      }
    }
  }

  toLogin() {
    this.router.navigateByUrl('/login')
  }
}
