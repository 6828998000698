import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Batch, BatchPopulatedDocument } from '../models/batch';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';
import { Paginator } from '../models/paginator';
import { BatchDocumentStatus } from '../models/document';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  lastBatchId?: string;
  public pager: Paginator<BatchPopulatedDocument> = {
    page: 0,
    limit: 10,
    offset: 0,
    sort: '-createdAt',
    docs: [],
    totalDocs: 0
  }

  constructor(
    private http: HttpClient
  ) { }


  retrieveAll(pageNumber: number, limit: number, offset: number, sort?: string, modes?: string[], countriesOfDesignation?: string[], trademarks?: { identifierNumber: string, countryOfDesignation: string }[], query?: string): Promise<Paginator<Batch>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    if (modes) {
      modes.forEach(mode => {
        params = params.append('mode[]', mode.toUpperCase());
      })
    }

    if (countriesOfDesignation) {
      countriesOfDesignation.forEach(country => {
        params = params.append('countryOfDesignation[]', country.toUpperCase());
      })
    }

    if (trademarks) {
      trademarks.forEach((trademark) => {
        params = params.append('trademark[]', `${trademark.identifierNumber}:${trademark.countryOfDesignation}`);
      })
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Batch>>(`${environment.backend}/batches/search`, { params }));
  }

  retrieve(id: string): Promise<Batch> {
    if (id !== this.lastBatchId) {
      this.resetBatchDocumentsPager();
      this.lastBatchId = id;
    }

    return firstValueFrom(this.http
      .get<Batch>(`${environment.backend}/batches/${id}`));
  }

  retrieveDocuments(id: string, statuses: BatchDocumentStatus[]): Promise<BatchPopulatedDocument[]> {
    let params = new HttpParams();

    if (statuses) {
      statuses.forEach(status => {
        params = params.append('status[]', status.toUpperCase());
      })
    }
    return firstValueFrom(this.http
      .get<BatchPopulatedDocument[]>(`${environment.backend}/batches/${id}/documents`, { params }));
  }

  retrieveProgress(id: string): Promise<{ progress: number }> {
    return firstValueFrom(this.http
      .get<{ progress: number }>(`${environment.backend}/batches/${id}/progress`));
  }


  create(batch: any) {
    return firstValueFrom(this.http
      .post<Batch>(`${environment.backend}/batches`, batch));
  }

  close(id: string) {
    return firstValueFrom(this.http
      .put<Batch>(`${environment.backend}/batches/${id}/close`, {}));
  }

  analyze(id: string) {
    return firstValueFrom(this.http
      .put<void>(`${environment.backend}/batches/${id}/analyze`, {}));
  }

  resetBatchDocumentsPager() {
    this.pager = {
      page: 0,
      limit: 10,
      offset: 0,
      sort: '-createdAt',
      docs: [],
      totalDocs: 0
    }
  }
}
