import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { TranslateService } from '@ngx-translate/core';
import { TrademarkStatus } from 'src/app/models/trademark';

@Component({
  selector: 'app-trademarks-classes',
  templateUrl: './trademarks-classes.component.html',
  styleUrls: ['./trademarks-classes.component.scss']
})
export class TrademarksClassesComponent {
  @Input() data: {
    _id: any,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  @Input() count: number = 0;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('graphclassesdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.XYChart;

  constructor(
    private zone: NgZone,
    private translate: TranslateService
  ) {

  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    setTimeout(() => {
      this.makeChart();
    }, 100)

  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  _color(status: TrademarkStatus) {
    switch (status) {
      case TrademarkStatus.REGISTERED:
        return '#3a9e31';
      case TrademarkStatus.FILED:
        return '#e38847';
      default:
        return '#e34754'
    }
  }

  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (!this.chart && this.chartElement) {

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
        chart.logo.setVisibility(false);
        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "name";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 30;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.fontSize = 10;
        categoryAxis.renderer.labels.template.fill = am4core.color("#13022B");
        categoryAxis.renderer.labels.template.location = 0.5;

        chart.data = this.data.map((datum) => ({ name: this.translate.instant('NICE_CLASSES.CLASS_NUMBER') + datum._id, count: datum.count, class: parseInt(datum._id) })).sort((a, b) => a.class - b.class);

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.opacity = 0.4;
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fill = am4core.color("#13022B");



        // Create series
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.valueY = "count";
        series.dataFields.categoryX = "name";
        series.name = this.translate.instant('ENTITIES.TRADEMARKS');
        series.columns.template.tooltipText = `{categoryX}: [bold]{valueY} ${this.translate.instant('ENTITIES.TRADEMARK_S').toLowerCase()}[/]`;
        series.columns.template.column.cornerRadiusTopLeft = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.fill = am4core.color("#8847E3");
        series.columns.template.fillOpacity = 1;
        series.columns.template.stroke = am4core.color("#8847E3");

        var columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        this.chart = chart;

      } else if (this.chartElement && this.chart) {
        this.chart.data = this.data.map((datum) => ({ name: this.translate.instant('NICE_CLASSES.CLASS_NUMBER') + datum._id, count: datum.count, class: parseInt(datum._id) })).sort((a, b) => a.class - b.class);
        console.log(this.chart.data)
      }

    })
  }
} 
