import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { InviteCaseOperatorDialogComponent } from 'src/app/dialogs/invite-case-operator-dialog/invite-case-operator-dialog.component';
import { Case } from 'src/app/models/case';
import { Paginator } from 'src/app/models/paginator';
import { Role, User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CaseService } from 'src/app/services/case.service';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-case-users',
  templateUrl: './case-users.component.html',
  styleUrls: ['./case-users.component.scss']
})
export class CaseUsersComponent {

  @Input() case?: Case;

  noDocs: boolean = false;
  displayedColumns: string[] = ['avatar', 'lastName', 'firstName', 'email', 'profile', 'actions'];

  users: Paginator<User> = {
    page: 1,
    limit: 10,
    offset: 0,
    sort: 'lastName',
    docs: [],
    totalDocs: 0
  }

  user?: User;

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }

  init() {
    this.resetPage();
    this.retrieveUsers(true);
  }

  async retrieveUsers(init: boolean) {
    try {
      if (this.case && this.case._id) {
        this._message.emitChange("LOADING", "START");

        this.users = { ...this.users, ...await this.caseService.retrieveUsers(this.case._id, this.users.page, this.users.limit, this.users.offset, this.users.sort || 'lastName') };
        if (init && this.users.totalDocs === 0) {
          this.noDocs = true;
        } else if (init && this.users.totalDocs > 0) {
          this.noDocs = false;
        }

        this._message.emitChange("LOADING", "END");
      }

    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.users.limit = 10;
    this.users.page = 1;
    this.users.offset = 0;
  }

  async handlePage(event: any) {
    this.users.limit = event.pageSize;
    this.users.page = event.pageIndex + 1;
    this.users.offset = event.pageIndex * this.users.limit;

    this.retrieveUsers(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.users.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveUsers(false);
    }
  }

  canDelete(user: User) {
    return user.role && user.role.name.toLowerCase() === Role.CASE_OPERATOR.toLowerCase() && this.user && this.user.role && this.user.role.level > user.role.level;
  }

  async delete(user: User) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'CASE.REVOKE_USER_ACCESS_TITLE',
        text: this.translate.instant('CASE.REVOKE_USER_ACCESS_TEXT', user),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && user && user._id && this.case && this.case._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await firstValueFrom(this.userService.deleteOperatorConstraint(user._id, `${this.case?._id}:CASE`));
        this.resetPage();
        await this.retrieveUsers(false);
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('CASE.REVOKE_USER_ACCESS_SUCCESS', user));
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async inviteCaseOperator() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        case: this.case
      }
    }
    const dialog = this.dialog.open(InviteCaseOperatorDialogComponent, config);
    const result: { invited: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.invited) {
      this.resetPage();
      await this.retrieveUsers(false);
    }
  }

}
