<div class="fx-row fx-wrap align-items-center justify-content-between">
  <app-back></app-back>
  <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100">
    <div class="fx-column fx-flex">
      <span class="page-title">{{'CASE_DECLARE.TITLE' | translate}}</span>
      <span class="page-subtitle">{{'CASE_DECLARE.SUBTITLE' | translate}}</span>
    </div>
    <button class="main-button ml-20" (click)="declare()" [disabled]="isStepDisabled(5) || loading"
      [class.disabled]="isStepDisabled(9) || loading" aclBasedElement resource="CASE" [scopes]="['C']">
      <div class="fx-row align-items-center justify-content-center">
        {{'CASES_LIST.OPEN_CASE' | translate}}
      </div>
    </button>
  </div>
</div>
<mat-divider class="mv-20"></mat-divider>
<mat-card>
  <div class="fx-row fx-wrap align-items-center justify-content-start">
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(1)">
          <span class="text white bold">
            1
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_TRADEMARK' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_TRADEMARK_HINT' | translate}}</span>
        </div>
      </div>
      <app-trademark-select label="{{'ENTITIES.TRADEMARK' | translate}}" [multiple]="false" [search]="true"
        initial="none" (change)="onTrademarkChange($event)" [emitOnInit]="false" style="width:210px;"
        [disabled]="isStepDisabled(1)"></app-trademark-select>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(2)">
          <span class="text white bold">
            2
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_CLASSES' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_CLASSES_HINT' | translate}}</span>
        </div>
      </div>
      <app-nice-select label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}" [multiple]="true" [search]="false"
        initial="none" (change)="onNiceClassificationChange($event)" [emitOnInit]="false" style="width:210px;"
        [disabled]="isStepDisabled(2)" [restrict]="trademark?.niceClassification ?? []"></app-nice-select>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(3)">
          <span class="text white bold">
            3
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_CASE_TYPE' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_CASE_TYPE_HINT' | translate}}</span>
        </div>
      </div>
      <app-select initial="none" label="{{'CASE.TYPE' | translate}}" [options]="caseTypes" [multiple]="false"
        (change)="onCaseTypeChange($event)" [emitOnInit]="false" [disabled]="isStepDisabled(3)"
        style="width:210px;"></app-select>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(4)">
          <span class="text white bold">
            4
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_OFFICE' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_OFFICE_HINT' | translate}}</span>
        </div>
      </div>
      <app-property-office-select label="{{'ENTITIES.PROPERTY_OFFICE' | translate}}" style="width:210px;"
        [search]="true" (change)="onPropertyOfficeChange($event)"
        [disabled]="isStepDisabled(4)"></app-property-office-select>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(5)">
          <span class="text white bold">
            5
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_NOTIFICATION_DATE' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_NOTIFICATION_DATE_HINT' | translate}}</span>
        </div>
      </div>
      <app-datepicker placeholder="{{'CASE.NOTIFICATION_DATE' | translate}}" (change)="onNotificationDateChange($event)"
        style="width:210px;" [disabled]="isStepDisabled(5)" [max]="today"></app-datepicker>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(6)">
          <span class="text white bold">
            6
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_END_INSTRUCTION_DATE' | translate}}</span>
          <span class="small light">{{(endInstructionDateHint ? 'CASE_DECLARE.SELECT_END_INSTRUCTION_DATE_HINT' :
            'CASE_DECLARE.SELECT_END_INSTRUCTION_NO_DATE_HINT') | translate}}</span>
        </div>
      </div>
      <app-datepicker placeholder="{{'CASE.END_INSTRUCTION_DATE' | translate}}" [value]="period?.endInstructionDate"
        style="width:210px;" [disabled]="isStepDisabled(6)"
        (change)="onEndInstructionDateChange($event)"></app-datepicker>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(7)">
          <span class="text white bold">
            7
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_PERIOD' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_PERIOD_HINT' | translate}}</span>
          <span class="small main" *ngIf="type">{{_periodMessage()}}</span>
          <div class="fx-row align-items-center justify-content-start small" *ngIf="durationWarning()">
            <mat-icon class="red mr-5" fontSet="material-icons-outlined" inline>error_outline</mat-icon>
            <span class="small red">{{_exceedMessage()}}</span>
          </div>
        </div>
      </div>
      <app-daterangepicker placeholder="{{'ENTITIES.PERIOD' | translate}}"
        [value]="{start:period?.start,end:period?.end}" (change)="onPeriodChange($event)" style="width:210px;"
        [disabled]="isStepDisabled(7)"></app-daterangepicker>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 mb-16 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(8)">
          <span class="text white bold">
            8
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SELECT_LANG' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SELECT_LANG_HINT' | translate}}</span>
        </div>
      </div>
      <app-select initial="none" label="{{'CASE.LANG' | translate}}" [options]="langs" [multiple]="false"
        (change)="onLangChange($event)" [emitOnInit]="false" [disabled]="isStepDisabled(8)"
        style="width:210px;"></app-select>
    </div>
    <div class="fx-row fx-wrap align-items-center justify-content-between fx-flex-100 ">
      <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex">
        <div class="step" [class.disabled]="isStepDisabled(9)">
          <span class="text white bold">
            9
          </span>
        </div>
        <div class="fx-column ml-10 fx-flex">
          <span class="text bold">{{'CASE_DECLARE.SET_IDENTIFIER' | translate}}</span>
          <span class="small light">{{'CASE_DECLARE.SET_IDENTIFIER_HINT' | translate}}</span>
        </div>
      </div>
      <app-input placeholder="{{'CASE_DECLARE.IDENTIFIER' | translate}}..." style="width:210px;" type="text"
        [(value)]="identifier" [disabled]="isStepDisabled(9)"></app-input>
    </div>
  </div>
</mat-card>