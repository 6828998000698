import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-class',
  templateUrl: './document-class.component.html',
  styleUrls: ['./document-class.component.scss']
})
export class DocumentClassComponent implements OnInit, OnChanges {
  @Input() metadata?: DocumentMetadata[] = [];
  @Input() documentClass?: string;
  confidence: number = 0;

  constructor(
  ) {
  }

  ngOnInit(): void {
    this.populateConfidence()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateConfidence()
  }

  populateConfidence() {
    this.confidence = this.getConfidence();
  }


  getConfidence() {
    return (this.metadata?.find(datum => datum.type === MetadataType.CLASS && datum.value === this.documentClass)?.confidence ?? 0) * 100
  }

}
