import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent implements OnInit, OnChanges {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() type?: string = "text";
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() class?: string;
  @Input() hint?: string;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;
  @Input() disabled: boolean = false;

  selection?: string;

  public focus: boolean = false;
  private _pattern?: RegExp;

  @Input() value: {
    start?: Moment,
    end?: Moment
  } = {
      start: undefined,
      end: undefined
    }
  @Output() valueChange = new EventEmitter<any>();
  valueUpdate: Subject<any> = new Subject<any>();

  @Output() change = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
    if (this.value.start || this.value.end) {
      this.selection = `${this.value.start ? this.value.start?.format('l') : ''} - ${this.value.end ? this.value.end?.format('l') : ''}`
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value.start || this.value.end) {
      this.selection = `${this.value.start ? this.value.start?.format('l') : ''} - ${this.value.end ? this.value.end?.format('l') : ''}`
    }
  }

  onStartDateChange(event: any) {
    this.value.start = event.value;
    this.selection = `${this.value.start ? this.value.start?.format('l') : ''} - ${this.value.end ? this.value.end?.format('l') : ''}`
    this.valueChange.emit(this.value);
    this.change.emit(this.value);
  }
  onEndDateChange(event: any) {
    this.value.end = event.value;
    this.selection = `${this.value.start ? this.value.start?.format('l') : ''} - ${this.value.end ? this.value.end?.format('l') : ''}`
    this.valueChange.emit(this.value);
    this.change.emit(this.value);
  }
}
