<div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class="nice-class" [class.small]="size === 'small'"
    *ngFor="let niceClass of classification.classes; let first = first; let last = last; let index = index;"
    [style.margin-left.px]="index === 0 ? 0 : -5" [style.z-index]="(classification.classes.length+1) - index"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'NICE_CLASSES.'+niceClass | translate"
    [matTooltipClass]="{'uzai-tooltip':true}">
    <span class="text">{{niceClass}}</span>
  </div>
  <div class="nice-class main text other" [class.small]="size === 'small'" *ngIf="classification.others.enabled"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="classification.others.tooltip"
    matTooltipClass="uzai-tooltip">
    <span class="text">+{{classification.others.count}}</span>
  </div>
</div>
<!-- <div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class="fx-row fx-wrap align-items-center justify-content-start"
    *ngFor="let niceClass of classification.classes; let last = last">
    <span class="main bold text" [class.small]="size === 'small'"  [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'NICE_CLASSES.'+niceClass | translate">
      {{niceClass}}
    </span>
    <span class="dark text" [class.small]="size === 'small'" *ngIf="(last && classification.others.enabled) || !last">
      ,&nbsp;
    </span>
  </div>
  <div class="main text" [class.small]="size === 'small'" *ngIf="classification.others.enabled"
     [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="classification.others.tooltip">
    <b>{{classification.others.label}}</b>
  </div>
</div> -->