import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom, switchMap } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NiceClass } from 'src/app/models/niceClass';
import { Paginator } from 'src/app/models/paginator';
import { Territory } from 'src/app/models/territory';
import { Trademark } from 'src/app/models/trademark';
import { MessageService } from 'src/app/services/message.service';
import { TrademarkService } from 'src/app/services/trademark.service';

interface TrademarkFilters {
  query?: string,
  classNumber: number[],
  countryOfDesignation: string[]
}

@Component({
  selector: 'app-trademarks-list',
  templateUrl: './trademarks-list.component.html',
  styleUrls: ['./trademarks-list.component.scss']
})
export class TrademarksListComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  trademarks: Paginator<Trademark> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  filteredTrademarks: Trademark[] = [];
  displayedColumns: string[] = ['name', 'identifier', 'countryOfDesignation', 'niceClassification', 'status', 'representation', 'actions'];
  dataSource: MatTableDataSource<Trademark> = new MatTableDataSource<Trademark>([])

  filters: TrademarkFilters = {
    query: undefined,
    classNumber: [],
    countryOfDesignation: []
  }

  private readonly filtersSubject = new Subject<TrademarkFilters>();
  private filtersSubscription?: Subscription;

  noTrademarkButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'TRADEMARKS_LIST.NEW',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.router.navigate(['new'], { relativeTo: this.route }) }
    }


  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.loading = true;
    this.done = false;
    this.retrieveTrademarks(true);
    this.loading = false;
    this.done = true;
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveTrademarks(false);
      });
  }

  public ngOnDestroy(): void {
    this.filtersSubscription?.unsubscribe();
  }

  async retrieveTrademarks(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.trademarks = { ...this.trademarks, ...await this.trademarkService.retrieveAll(this.trademarks.page, this.trademarks.limit, this.trademarks.offset, this.trademarks.sort, this.filters.countryOfDesignation, this.filters.classNumber, this.filters.query) };
      this.filteredTrademarks = this.trademarks.docs;

      this.dataSource = new MatTableDataSource<Trademark>(this.filteredTrademarks);

      if (init && this.trademarks.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.trademarks.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.trademarks.limit = 10;
    this.trademarks.page = 1;
    this.trademarks.offset = 0;
  }

  async handlePage(event: PageEvent) {
    this.trademarks.limit = event.pageSize;
    this.trademarks.page = event.pageIndex + 1;
    this.trademarks.offset = event.pageIndex * this.trademarks.limit;
    this.retrieveTrademarks(false);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.trademarks.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveTrademarks(false);
    }
  }

  onNiceClassificationChange(event: any) {
    this.filters.classNumber = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
    this.filtersSubject.next({ ...this.filters });
  }

  onCountryOfDesignationChange(event: any) {
    this.filters.countryOfDesignation = event.value.map((territory: Territory) => territory.alternateCodes ? [territory.id, ...territory.alternateCodes] : [territory.id]).flat();
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    if (typeof query == 'string') {
      this.filtersSubject.next({ ...this.filters });
    }

  }

  toTrademark(trademark: Trademark) {
    this.router.navigate([trademark._id], {
      relativeTo: this.route,
      state: {
        from: "TRADEMARKS"
      }
    })
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

}
