<mat-card>
  <div class="fx-column">
    <div class="fx-row align-items-center justify-content-between">
      <span class="text light">
        {{label | translate}}
      </span>
      <a [routerLink]="link.href" class="small" *ngIf="link">
        {{link.label | translate}}
      </a>
    </div>
    <div class="fx-row align-items-baseline justify-content-start">
      <app-animated-number [value]="value" [class]="'huge main'" [round]="round"></app-animated-number>
      <span class="huge main" *ngIf="unit">&nbsp;{{unit}}</span>
    </div>
  </div>
</mat-card>