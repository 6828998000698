import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseScore } from 'src/app/models/case';

@Component({
  selector: 'app-case-score',
  templateUrl: './case-score.component.html',
  styleUrls: ['./case-score.component.scss']
})
export class CaseScoreComponent {

  @Input() style: 'condensed' | 'expanded' = 'expanded';
  @Input() score: CaseScore = {
    global: 0,
    documentsCount: 0,
    documentsAccuracy: 0,
    documentsClasses: 0,
    temporalCoverage: 0
  }

  constructor(
    private translate: TranslateService
  ) {

  }

  tip(type: keyof CaseScore) {
    let message: string;
    let icon: string;
    let color: string;
    let key: string;
    switch (type) {
      case 'global':
        key = 'GLOBAL';
        break;
      case 'documentsCount':
        key = 'DOCUMENTS_COUNT';
        break;
      case 'documentsAccuracy':
        key = 'DOCUMENTS_ACCURACY';
        break;
      case 'documentsClasses':
        key = 'DOCUMENTS_TYPES';
        break;
      case 'temporalCoverage':
        key = 'TEMPORAL_COVERAGE';
        break;
      default:
        key = '';
    }
    if (this.score[type] < 0.25) {
      icon = 'error_outline';
      message = `CASE.SCORE.${key}_POOR`;
      color = 'red';
    } else if (this.score[type] < 0.50) {
      icon = 'tips_and_updates';
      message = `CASE.SCORE.${key}_LOW`;
      color = 'gold';
    } else if (this.score[type] < 0.75) {
      icon = 'tips_and_updates';
      message = `CASE.SCORE.${key}_MEDIUM`;
      color = 'gold';
    } else {
      icon = 'check_circle';
      message = `CASE.SCORE.${key}_GOOD`;
      color = 'green';
    }
    return {
      icon,
      color,
      message: this.translate.instant(message)
    }
  }

}
