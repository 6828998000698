import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NonUseAttachmentDialogComponent } from 'src/app/dialogs/non-use-attachment-dialog/non-use-attachment-dialog.component';
import { UploadCaseAttachmentDialogComponent } from 'src/app/dialogs/upload-case-attachment-dialog/upload-case-attachment-dialog.component';
import { Case, CaseAttachment } from 'src/app/models/case';
import { UploadAttachment } from 'src/app/models/uploadFile';
import { CaseService } from 'src/app/services/case.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-case-attachments',
  templateUrl: './case-attachments.component.html',
  styleUrls: ['./case-attachments.component.scss']
})
export class CaseAttachmentsComponent {
  @Input() case?: Case;

  attachments: CaseAttachment[] = [];

  file?: UploadAttachment;
  active: boolean = false;

  @Output() refresh = new EventEmitter<any>();

  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  async retrieveAttachments() {
    try {
      if (this.case && this.case._id) {

        this._message.emitChange("LOADING", "START");
        this.attachments = await this.caseService.retrieveAttachments(this.case._id);

        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.handleFile(file);
  }

  async handleFile(file: File) {
    if (file.size >= 50 * 1024 * 1024) {
      this.toastr.warning("CASE.ATTACHMENT_UNAUTHORIZED_SIZE_MESSAGE")
    }
    this.file = file as UploadAttachment;

    const dialog = this.dialog.open(UploadCaseAttachmentDialogComponent, {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        attachment: this.file,
        case: this.case,
        mode: "create"
      }
    });
    const res = await firstValueFrom(dialog.afterClosed());
    if (res && res.uploaded && res.attachment && this.case && this.case._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.caseService.createAttachment(this.case?._id, res.attachment);
        await this.retrieveAttachments();
        this.refresh.emit();
        this.toastr.success(this.translate.instant('CASE.ATTACHMENT_UPLOAD_SUCCESS', { name: res.attachment.name }));
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error(`ERRORS.GENERIC`)
      }

    }
  }


  onFilesHover(event: any) {
    if (event.dataTransfer.types && event.dataTransfer.types.some((t: string) => t === "Files")) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  icon(attachment: CaseAttachment) {
    return this.documentService.icon(attachment)
  }

  size(attachment: CaseAttachment) {
    return this.documentService.size(attachment)
  }

  async edit(attachment: CaseAttachment) {
    const dialog = this.dialog.open(UploadCaseAttachmentDialogComponent, {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        mode: 'edit',
        attachment: attachment,
        file: this.file,
        case: this.case
      }
    });
    const res = await firstValueFrom(dialog.afterClosed());
    if (res && res.updated && res.attachment && this.case && this.case._id && attachment && attachment._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.caseService.updateAttachment(this.case?._id, attachment._id, res.attachment);
        await this.retrieveAttachments();
        this.toastr.success(this.translate.instant('CASE.ATTACHMENT_UPDATE_SUCCESS', { name: res.attachment.name }));
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error(`ERRORS.GENERIC`)
      }

    }
  }

  async delete(attachment: CaseAttachment) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'CASE.DELETE_ATTACHMENT_TITLE',
        text: this.translate.instant('CASE.DELETE_ATTACHMENT_MESSAGE', attachment),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.case && this.case._id && attachment._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.caseService.deleteAttachment(this.case?._id, attachment._id);
        await this.retrieveAttachments();
        this.toastr.success(this.translate.instant('CASE.ATTACHMENT_DELETE_SUCCESS', { name: attachment.name }));
        this.refresh.emit();
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  async download(attachment: CaseAttachment) {
    let toast;
    try {
      if (this.case && this.case._id && attachment._id) {
        const extension = this.documentService.extension(attachment.type) || 'unknown';
        const base_name = attachment.name.replace(`.${extension.toLowerCase()}`, '').replace(`.${extension}`, '');
        toast = this.toastr.info(this.translate.instant('CASE.DOWNLOAD_ATTACHMENT_MESSAGE', attachment), "CASE.DOWNLOAD_ATTACHMENT_TITLE", {
          progressBar: true,
          disableTimeOut: true
        })
        const blob = await this.caseService.downloadAttachment(this.case._id, attachment._id);

        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(blob)
        a.href = objectUrl
        a.download = `${base_name}.${extension}`;
        a.click();
        this.toastr.clear(toast.toastId);
        URL.revokeObjectURL(objectUrl);
      }
    } catch (err) {
      if (toast) {
        this.toastr.clear(toast.toastId);
      }
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async openLinkNonUseDialog() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '640px',
      data: {
        case: this.case
      }
    }
    const dialog = this.dialog.open(NonUseAttachmentDialogComponent, config);
    const result: { linked: boolean, attachment?: UploadAttachment } = await firstValueFrom(dialog.afterClosed());
    if (result && result.linked && result.attachment && this.case && this.case._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.caseService.createNonUseAttachment(this.case?._id, result.attachment);
        await this.retrieveAttachments();
        this.toastr.success(this.translate.instant('CASE.ATTACHMENT_NON_USE_LINK_SUCCESS', { name: result.attachment.name }));
        this.refresh.emit();
        this._message.emitChange("LOADING", "END");
      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  viewNonUseDocument(attachment: CaseAttachment) {
    if (attachment && attachment.nonUse && attachment.document) {
      this.router.navigate(['documents', attachment.document])
    }
  }

}
