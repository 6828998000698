<!-- <div class="p-16">
  <span class="page-subtitle main bold">{{documentService.pager.totalDocs}} {{'ENTITIES.DOCUMENTS' | translate}}</span>
</div> -->
<table mat-table [dataSource]="filteredDocuments" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
  <ng-container matColumnDef="select" *ngIf="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary"
        [checked]="hasPageSelection() && isAllSelected()" [indeterminate]="hasPageSelection() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row" [matTooltipClass]="{'uzai-tooltip':true}"
      [matTooltip]="restrictions.tooltip ? (restrictions.tooltip | translate) : null"
      [matTooltipDisabled]="!isDisabled(row) || !restrictions.tooltip">
      <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(row) : null"
        [checked]="isSelected(row) || isSelectedAndDisabled(row)" [disabled]="isDisabled(row)">
      </mat-checkbox>
    </td>
  </ng-container>
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'DOCUMENT.NAME' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-row align-items-center justify-content-start">
        <img [src]="documentIcon(document)" [height]="35" class="mr-10" />
        <div class="fx-column ellipsis-container">
          <span class="text bold ellipsis">{{document?.name || '-'}}</span>
          <div class="fx-row align-items-center justify-content-start">
            <div class="fx-row align-items-center justify-content-start mr-5" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'DOCUMENT.STATUS_'+document.status+'_TOOLTIP' | translate">
              <mat-icon class="light" fontSet="material-icons-outlined" *ngIf="document.status === 'PENDING'" inset
                inline>pending</mat-icon>
              <mat-icon class=" main spin" fontSet="material-icons-outlined" *ngIf="document.status === 'PROCESSING'"
                inset inline>sync</mat-icon>
              <mat-icon class=" green" fontSet="material-icons-outlined" *ngIf="document.status === 'PROCESSED'" inset
                inline>check_circle</mat-icon>
              <mat-icon class=" red" fontSet="material-icons-outlined" *ngIf="document.status === 'ERROR'" inset
                inline>error_outline</mat-icon>
            </div>
            <mat-icon class="mr-5 main" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'CONFIDENTIALITY.CONFIDENTIAL' | translate" inset inline
              *ngIf="document.confidential">lock</mat-icon>
            <mat-icon class="mr-5 main" fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'DOCUMENT.NON_USE' | translate" inset inline
              *ngIf="document.nonUse">assignment_late</mat-icon>
            <span class="small light">{{'DOCUMENT_CLASSES.'+document.class.toUpperCase() | translate}}</span>
          </div>
        </div>
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="trademark">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'ENTITIES.TRADEMARKS' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <div class="fx-column ellipsis-container" *ngIf="document?.trademarks && document?.trademarks.length === 1">
        <a (click)="toTrademark(document.trademarks[0])" class="ellipsis">{{document.trademarks[0].name ||
          document.trademarks[0].identifierNumber}}</a>
      </div>
      <div class="fx-column ellipsis-container" *ngIf="document?.trademarks && document?.trademarks.length > 1">
        <a (click)="toTrademark(document.trademarks[0])" class="ellipsis">{{document.trademarks[0].name ||
          document.trademarks[0].identifierNumber}}</a>
        <span class="text">{{'UTILS.AND_X_MORE' |
          translate:{x:document?.trademarks.length-1} }}</span>
      </div>
      <app-metadata-missing [document]="document"
        *ngIf="!document?.trademarks || document?.trademarks?.length === 0"></app-metadata-missing>
    </td>
  </ng-container>
  <ng-container matColumnDef="nice">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'DOCUMENT.NICE_CLASSIFICATION' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <app-trademark-nice-classification [niceClassification]="document?.niceClassification"
        *ngIf="document?.niceClassification && document?.niceClassification.length > 0"
        class="mt-5"></app-trademark-nice-classification>
      <app-metadata-missing [document]="document"
        *ngIf="!document?.niceClassification || document?.niceClassification?.length === 0"></app-metadata-missing>
    </td>
  </ng-container>
  <ng-container matColumnDef="territories">
    <th mat-header-cell *matHeaderCellDef>
      <span class=" small light bold">
        {{'DOCUMENT.TERRITORIES' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <app-document-countries [territories]="document.territories"
        *ngIf="document.territories && document.territories.length > 0"></app-document-countries>
      <app-metadata-missing [document]="document"
        *ngIf="!document.territories || document.territories.length === 0"></app-metadata-missing>
    </td>
  </ng-container>
  <ng-container matColumnDef="period">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class=" small light bold">
        {{'DOCUMENT.PERIOD' | translate}}
      </span>
    </th>
    <td mat-cell *matCellDef="let document">
      <span class="text"
        *ngIf="document?.period && document.period.start && document.period.end">{{formatPeriod(document)}}</span>
      <app-metadata-missing [document]="document"
        *ngIf="!document?.period || !document.period.start || !document.period.end"></app-metadata-missing>
    </td>
  </ng-container>
  <ng-container matColumnDef="actions" *ngIf="clickable">
    <th mat-header-cell *matHeaderCellDef>
    </th>
    <td mat-cell *matCellDef="let document">
      <mat-icon class="main" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let document; columns: displayedColumns;" [class.clickable]="clickable"
    (click)="clickable ? toDocument(document) : null">
  </tr>
</table>
<div class="p-16" *ngIf="documentService.pager.totalDocs === 0">
  <span class="text light">{{'DOCUMENTS_LIST.SEARCH_NO_DOCUMENTS' | translate}}</span>
</div>
<mat-paginator [length]="documentService.pager.totalDocs || 0" [pageSize]="documentService.pager.limit || 0"
  [pageIndex]="documentService.pager.page ? (documentService.pager.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]"
  (page)="handlePage($event)">
</mat-paginator>