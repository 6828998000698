import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { Case } from 'src/app/models/case';
import { Paginator } from 'src/app/models/paginator';
import { Role, User } from 'src/app/models/user';
import { AclService } from 'src/app/services/acl.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-invite-case-operator-dialog',
  templateUrl: './invite-case-operator-dialog.component.html',
  styleUrls: ['./invite-case-operator-dialog.component.scss']
})
export class InviteCaseOperatorDialogComponent {
  case?: Case;
  user?: User;
  existing?: User;
  userOptions: { value: any, label: string, disabled: boolean, selected: boolean }[] = [];

  users: Paginator<User> = {
    page: 1,
    limit: 999,
    offset: 0,
    sort: 'lastname',
    docs: [],
    totalDocs: 0
  }

  userToInvite: {
    firstName?: string,
    lastName?: string,
    email?: string,
    role?: string
  } = {
      firstName: undefined,
      lastName: undefined,
      email: undefined,
      role: 'case_operator'
    };

  errors: {
    [key: string]: {
      value: boolean,
      message?: string
    }
  } = {
      firstName: {
        value: false,
        message: undefined
      },
      lastName: {
        value: false,
        message: undefined
      },
      email: {
        value: false,
        message: undefined
      },
      roles: {
        value: false,
        message: undefined
      }
    }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { case: Case },
    private aclService: AclService,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<InviteCaseOperatorDialogComponent>,
    private organizationService: OrganizationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private utils: UtilsService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.retrieveUsers();
    this.case = this.data.case;
  }

  ngOnDestroy(): void {
  }

  async retrieveUsers() {
    const paginator = await this.organizationService.retrieveAllUsers(this.users.page, this.users.limit, this.users.offset, this.users.sort, undefined);
    const docs = paginator.docs.filter(user => {
      if (user.role?.name.toLowerCase() === Role.CASE_OPERATOR.toLowerCase() && user.acls && this.case && this.case._id) {
        const cases = this.aclService.getCasesConstraints(user.acls);
        return !cases.includes(this.case?._id)
      }
      return false;
    })
    this.users.docs = docs;
    this.userOptions = this.users.docs.map(user => ({ value: user, selected: false, disabled: false, label: `${user.lastName} ${user.firstName}` }));
  }

  onExistingUserChange(event: any) {
    if (event) {
      if (event.value === undefined) {
        this.existing = undefined;
      } else {
        this.existing = event.value.value;
      }
    }
  }

  isExistingDisabled() {
    return (this.userToInvite.firstName !== undefined && this.userToInvite.firstName.length > 0)
      || (this.userToInvite.lastName !== undefined && this.userToInvite.lastName.length > 0)
      || (this.userToInvite.email !== undefined && this.userToInvite.email.length > 0);
  }


  isDisabled() {
    if (this.isExistingDisabled()) {
      return !this.userToInvite.firstName || !this.userToInvite.lastName || !this.userToInvite.email || !this.userToInvite.role;
    } else {
      return !this.existing
    }
  }

  _hasErrors() {
    this.errors['firstName'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.firstName) {
      this.errors['firstName'].value = true;
      this.errors['firstName'].message = this.translate.instant('ERRORS.USER_FIRSTNAME_REQUIRED');
    }

    this.errors['lastName'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.lastName) {
      this.errors['lastName'].value = true;
      this.errors['lastName'].message = this.translate.instant('ERRORS.USER_LASTNAME_REQUIRED');
    }

    this.errors['email'] = {
      value: false,
      message: undefined
    }
    if (!this.userToInvite.email) {
      this.errors['email'].value = true;
      this.errors['email'].message = this.translate.instant('ERRORS.USER_EMAIL_REQUIRED');
    } else {
      if (!this.utils.validateEmailPattern(this.userToInvite.email)) {
        this.errors['email'].value = true;
        this.errors['email'].message = this.translate.instant('ERRORS.EMAIL_FORMAT');
      }
    }
    return this.errors['firstName'].value || this.errors['lastName'].value || this.errors['email'].value;
  }

  onCancel(): void {
    this.dialogRef.close({
      invited: false
    });
  }

  async onInvite() {
    try {
      if (this.isExistingDisabled()) {
        if (this._hasErrors()) {
          return
        } else {
          const user = await this.organizationService.invite(this.userToInvite);
          if (user && user._id) {
            await firstValueFrom(this.userService.addOperatorConstraint(user._id, `${this.case?._id}:CASE`));
            this.toastr.success(this.translate.instant('CASE.CASE_OPERATOR_INVITED', this.userToInvite));
            this.dialogRef.close({
              invited: true,
              user
            });
          } else {
            throw Error()
          }

        }
      } else if (this.existing && this.existing._id) {
        await firstValueFrom(this.userService.addOperatorConstraint(this.existing._id, `${this.case?._id}:CASE`));
        this.toastr.success(this.translate.instant('CASE.CASE_OPERATOR_GRANTED', this.existing));
        this.dialogRef.close({
          invited: true,
          user: this.existing
        });
      }


    } catch (err: any) {
      if (err && err.error && err.error.message && this.utils.hasTranslation(`ERRORS.${err.error.message}`)) {
        this.toastr.error(`ERRORS.${err.error.message}`)
      } else {
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }
}
