import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() mode: string = "determinate";
  @Input() value: number = 25;
  @Input() class?: string;
  @Input() loading: boolean = false;
  @Input() showDivider: boolean = false;

}
