import { CdkMenu, CdkMenuBase, CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { PropertyOffice } from 'src/app/models/propertyOffice';
import { MessageService } from 'src/app/services/message.service';
import { PropertyOfficeService } from 'src/app/services/property-office.service';

type PropertyOfficeOption = PropertyOffice & { selected: boolean, display?: boolean }

@Component({
  selector: 'app-property-office-select',
  templateUrl: './property-office-select.component.html',
  styleUrls: ['./property-office-select.component.scss']
})
export class PropertyOfficeSelectComponent {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() initial?: string = 'all';
  @Input() class?: string;
  @Input() hint?: string;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;
  @Input() emitOnInit: boolean = true;

  loading: boolean = false;
  propertyOffices: PropertyOfficeOption[] = [];
  selected: PropertyOfficeOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: PropertyOffice | PropertyOffice[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private propertyOfficeService: PropertyOfficeService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit() {
    this.setInitialState();
  }

  _isSelected(option: PropertyOffice) {
    return this.selected.map((d) => d._id).includes(option._id);
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  populate() {
    if (this.multiple && this.value && (this.value as PropertyOffice[]).length > 0) {
      const values = (this.value as PropertyOffice[]);
      this.selected = values.map((t) => ({ ...t, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as PropertyOffice);
      this.selected = [{ ...value, selected: true, display: true }];
    }
  }

  async setInitialState() {
    await this.retrievePropertyOffices();
    if (this.multiple && this.value && (this.value as PropertyOffice[]).length > 0) {
      const values = (this.value as PropertyOffice[]);
      this.selected = values.map((t) => ({ ...t, selected: true, disabled: false }));
      this.propertyOffices.forEach(propertyOffice => {
        const found = values.find(t => t._id === propertyOffice._id);
        if (found) {
          propertyOffice.selected = true;
        } else {
          propertyOffice.selected = false;
        }
      });
      const value = _.unionWith([...this.propertyOffices.filter(c => c.selected), ...this.selected], this._propertyOfficeComparator);
      if (value.length > 0) {
        this.selection = value.length === 1 ? value[0].name : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (!this.multiple && this.value) {
      const value = (this.value as PropertyOffice);
      this.selected = [{ ...value, selected: true }];
      const option = this.propertyOffices.find(p => p._id === value._id);
      if (option) {
        option.selected = true;
      }
    }
    if (this.emitOnInit) {
      this.onChange();
    }
  }

  removeFromPreselection(option: any) {
    this.selected.splice(this.selected.findIndex((o) => option._id === o._id), 1);
    const doc = this.propertyOffices.find((t) => t._id === option._id);
    if (doc) {
      doc.selected = false;
    }
    this.onChange()
  }

  toggleFromSelection(option: PropertyOfficeOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option._id === o._id), 1);
    } else if (!this._isSelected(option)) {
      this.selected.push({ ...option, display: false });
    }
    this.selected.sort((a, b) => a.name!.localeCompare(b.name!))
  }

  _propertyOfficeComparator(poA: PropertyOffice, poB: PropertyOffice) {
    return poA._id === poB._id;
  }

  async retrievePropertyOffices() {
    this.loading = true;
    this.propertyOffices = (await this.propertyOfficeService.retrieve()).map(propertyOffice => ({ ...propertyOffice, selected: false }));
    this.propertyOffices.sort((a, b) => a.name.localeCompare(b.name));
    this.loading = false;
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.propertyOffices.forEach(p => {
        if (p.code !== option.code) {
          p.selected = false;
        }
      })
    }
    this.onChange();
  }

  async onChange() {
    if (!this.multiple) {
      const value = this.propertyOffices.find(c => c.selected) ? this.propertyOffices.find(c => c.selected) : (this.selected.length === 1 ? this.selected[0] : undefined);
      this.selection = value ? value.name : undefined;
      this.valueChange.emit(value);
      this.change.emit({ value });
      this.value = value;
      this.trigger?.close();

    } else {
      const values = _.unionWith([...this.propertyOffices.filter(c => c.selected), ...this.selected], this._propertyOfficeComparator);
      this.value = values;

      if (values.length > 0) {
        this.selection = values.length === 1 ? values[0].name : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values);
      this.change.emit({ value: values });
    }

  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return (this._filter && this._filter.length > 0) ? this.propertyOffices.filter(propertyOffice => {
      return propertyOffice.name.toLowerCase().includes(this._filter!.toLowerCase())
    }) : this.propertyOffices;
  }


  onClear() {
    this.setInitialState();
    this._filter = undefined;
    this.propertyOffices.forEach(propertyOffice => {
      propertyOffice.selected = false;
    });
    this.selected = [];
    this.onChange();
    this.trigger?.close();
  }

}
