import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  email?: string;
  errors: any = {
    email: {
      value: false,
      message: undefined
    }
  }

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {

  }

  _hasErrors() {
    this.errors.email = {
      value: false,
      message: undefined
    }
    if (!this.email) {
      this.errors.email.value = true;
      this.errors.email.message = this.translate.instant('ERRORS.EMAIL_REQUIRED');
    } else {
      if (!this.utils.validateEmailPattern(this.email)) {
        this.errors.email.value = true;
        this.errors.email.message = this.translate.instant('ERRORS.EMAIL_FORMAT');
      }
    }
    return this.errors.email.value;
  }

  async send() {
    if (this._hasErrors()) {
      return;
    } else {
      try {
        const res = await this.authService.sendResetPassword(this.email as string);
        this.toastr.info(this.translate.instant("FORGOT_PASSWORD.EMAIL_SENT", { email: this.email }));
      } catch (err: any) {
        if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
          this.toastr.error(`ERRORS.${err.message}`)
        } else {
          this.toastr.error(`ERRORS.GENERIC`)
        }
      }
    }
  }
}
