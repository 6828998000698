<span class="text" *ngIf="['PROCESSED','ERROR'].includes(status)">Score: <span
    [class]="globalScore().color+' bold'">{{globalScore().value}}</span></span>
<span class="text" *ngIf="!['PROCESSED','ERROR'].includes(status)">{{'BATCH.STATUS_'+status | translate}}</span>
<div class="score-container fx-row align-items-center justify-content-start fx-flex-100"
  *ngIf="!['PENDING','OPENED'].includes(status)">
  <div *ngIf="score.ok" class="score ok" [style.flex-basis.%]="100*score.ok/score.total"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="tooltip('ok')">
  </div>
  <div *ngIf="score.warning" class="score warning" [style.flex-basis.%]="100*score.warning/score.total"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="tooltip('warning')">
  </div>
  <div *ngIf="score.error" class="score error" [style.flex-basis.%]="100*score.error/score.total"
    [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="tooltip('error')">
  </div>
</div>