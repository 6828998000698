<mat-card class="p-0" *ngIf="case && case.documents && case.documents.length > 0">
  <div class="p-16 fx-row align-items-center justify-content-between">
    <div class="fx-column">
      <div class="fx-row align-items-center justify-content-start">
        <span class="text bold">{{'CASE.LINKED_PROOFS' | translate}}</span>
        <div class="chip main ml-10">
          {{case.documents.length}}
        </div>
      </div>
      <span class="text" *ngIf="readonly && case.phase">{{'CASE.WRITE_EVIDENCES_DISABLED' |
        translate:phase}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button class="danger-button" (click)="unlink()" *ngIf="selection.selected.length > 0">
        {{ 'CASE.UNLINK' | translate}} {{selection.selected.length}} {{((selection.selected.length > 1 ?
        'ENTITIES.PROOFS' :
        'ENTITIES.PROOF') | translate).toLowerCase()}}
      </button>
      <button class="main-button ml-10" [cdkMenuTriggerFor]="addMenu" [disabled]="readonly" [class.disabled]="readonly">
        {{ 'CASE.ADD_PROOFS' | translate}}
      </button>
      <ng-template #addMenu>
        <mat-card class="menu-container p-0 elevation" style="max-width:180px;" cdkMenu>
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="openLinkDialog()" cdkMenuItem>
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">add_link</mat-icon>
              <span class="text fx-flex">{{'CASE.LINK_PROOFS' | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
              (click)="openUploadDialog()" cdkMenuItem>
              <mat-icon class="dark mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
              <span class="text fx-flex">{{'CASE.UPLOAD_PROOFS' | translate}}</span>
            </div>
          </div>
        </mat-card>
      </ng-template>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-documents-case [documents]="documents" [filteredDocuments]="filteredDocuments" (sort)="onSortChange($event)"
    (page)="handlePage($event)" [(selection)]="selection" [readonly]="readonly" #caseDocuments></app-documents-case>
</mat-card>
<mat-card *ngIf="case && case.documents && case.documents.length == 0" class="placeholder">
  <div class="fx-flex-100 fx-row align-items-center justify-content-start">
    <div class="watermark-container">
      <img src="/assets/img/icon_lighter.svg" class="watermark" />
    </div>
    <div class="fx-row fx-wrap instructions-container">
      <span class="title dark fx-flex-100">{{'CASE.NO_LINKED_PROOFS' | translate}}</span>
      <span class="text dark fx-flex-100">{{'CASE.NO_LINKED_PROOFS_TEXT' | translate:case.trademark}}</span>
      <div class="fx-row fx-flex-100 mt-10">
        <button class="main-button" (click)="openLinkDialog()">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">add_link</mat-icon>
            {{ 'CASE.LINK_PROOFS' | translate}}
          </div>
        </button>
        <button class="main-button ml-10" (click)="openUploadDialog()">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">cloud_upload</mat-icon>
            {{ 'CASE.UPLOAD_PROOFS' | translate}}
          </div>
        </button>
      </div>
    </div>
  </div>
</mat-card>