import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Document } from 'src/app/models/document';
import { Paginator } from 'src/app/models/paginator';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit {

  @Input() from?: string;
  @Input() select?: boolean = false;
  @Input() clickable?: boolean = true;
  @Input() filteredDocuments: Document[] = [];
  @Input() restrictions: {
    selectedAndDisabled: string[],
    disabled: string[],
    tooltip?: string
  } = {
      selectedAndDisabled: [],
      disabled: [],
      tooltip: undefined
    };
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<Document> = new MatTableDataSource<Document>([])
  @Input() selection = new SelectionModel<Document>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<Document>>();

  @Output() sort = new EventEmitter<any>();
  @Output() page = new EventEmitter<PageEvent>();
  @Output() reload = new EventEmitter<any>();


  constructor(
    private dialog: MatDialog,
    public documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.displayedColumns = (this.select ? ['select', 'name', 'trademark', 'nice', 'territories', 'period'] : ['name', 'trademark', 'nice', 'territories', 'period']);
    if (this.clickable) {
      this.displayedColumns.push('actions')
    }
  }

  documentIcon(document: Document) {
    return this.documentService.icon(document);
  }

  formatSize(document: Document) {
    return this.documentService.size(document);
  }

  formatPeriod(document: Document) {
    return this.documentService.period(document);
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  hasPageSelection() {
    return this.filteredDocuments.some(row => this.isSelected(row))
  }

  isSelected(row: Document) {
    return this.selection.selected.map(doc => doc._id).includes(row._id)
  }

  isSelectedAndDisabled(row: Document) {
    return this.restrictions.selectedAndDisabled.includes(row._id!)
  }

  isDisabled(row: Document) {
    return this.restrictions.selectedAndDisabled.includes(row._id!) || this.restrictions.disabled.includes(row._id!)
  }

  isAllSelected() {
    return this.filteredDocuments.filter((row) => !this.isDisabled(row)).every(row => this.isSelected(row));
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      const difference = _.differenceBy(this.selection.selected, this.filteredDocuments.filter((row) => !this.isDisabled(row)), '_id')
      this.selection.setSelection(...difference)
      this.selectionChange.emit(this.selection);
      return;
    }
    this.selection.setSelection(...[...this.selection.selected, ...this.filteredDocuments.filter(row => !this.isSelected(row) && !this.isDisabled(row))])
    this.selectionChange.emit(this.selection);
  }

  toggle(row: Document) {
    if (this.isSelected(row)) {
      this.selection.setSelection(...this.selection.selected.filter(doc => doc._id !== row._id))
    } else {
      this.selection.setSelection(...[...this.selection.selected, row])
    }
    this.selectionChange.emit(this.selection);
  }

  onSortChange(event: any) {
    if (event.active === 'period') {
      if (event.direction === 'asc') {
        event.active = 'period.start'
      } else {
        event.active = 'period.end'
      }
    } else if (event.active === 'trademark') {
      event.active = 'trademark.name'
    }
    this.sort.emit(event)
  }

  async handlePage(event: PageEvent) {
    this.page.emit(event);
  }


  toDocument(document: Document) {
    this.router.navigate([document._id], {
      relativeTo: this.route,
      state: {
        from: "DOCUMENTS"
      }
    })
  }

  toTrademark(trademark: any) {
    if (trademark && trademark.ref) {
      this.router.navigate(['trademarks', trademark.ref], {
        state: {
          from: "DOCUMENTS"
        }
      })
    }
  }

}
