<mat-drawer-container class="drawer-container">
  <mat-drawer-content class="drawer-content">
    <div class="fx-column">
      <span class="page-title">{{'SUPPORT.TITLE' | translate}}</span>
      <span class="page-subtitle">{{'SUPPORT.SUBTITLE' | translate}}</span>
    </div>
    <mat-divider class="mv-20"></mat-divider>
    <div class="fx-row fx-wrap align-items-stretch justify-content-center">
      <div class="fx-flex-50 pr-8 border-box">
        <mat-card class="clickable" (click)="downloadUserGuide()">
          <div class="fx-row align-items-center justify-content-between">
            <div class="fx-row align-items-center justify-content-start">
              <div class="main-light-icon-container">
                <mat-icon class="main" fontSet="material-icons-outlined">description</mat-icon>
              </div>
              <div class="fx-column ml-20">
                <span class="title">{{'SUPPORT.USER_GUIDE' | translate}}</span>
                <span class="text">{{'SUPPORT.USER_GUIDE_HINT' | translate}}</span>
              </div>
            </div>
            <mat-icon class="main ml-20" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
          </div>
        </mat-card>
      </div>
      <div class="fx-flex-50 pl-8 border-box">
        <mat-card class="clickable" (click)="drawer.toggle()">
          <div class="fx-row align-items-center justify-content-between">
            <div class="fx-row align-items-center justify-content-start">
              <div class="main-light-icon-container">
                <mat-icon class="main" fontSet="material-icons-outlined">format_list_bulleted</mat-icon>
              </div>
              <div class="fx-column ml-20">
                <span class="title">{{'SUPPORT.FAQ' | translate}}</span>
                <span class="text">{{'SUPPORT.FAQ_HINT' | translate}}</span>
              </div>
            </div>
            <mat-icon class="main ml-20" fontSet="material-icons-outlined">keyboard_arrow_right</mat-icon>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="fx-row fx-wrap align-items-stretch justify-content-center mt-16">
      <div class="fx-flex-50 pr-8 border-box">
        <mat-card class="h-100">
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-between">
              <div class="fx-row align-items-center justify-content-start">
                <div class="main-light-icon-container">
                  <mat-icon class="main" fontSet="material-icons-outlined">help_outline</mat-icon>
                </div>
                <div class="fx-column ml-20">
                  <span class="title">{{'SUPPORT.ASK_QUESTION' | translate}}</span>
                  <span class="text">{{'SUPPORT.ASK_QUESTION_HINT' | translate:user}}</span>
                </div>
              </div>
            </div>
            <mat-divider class="mv-16"></mat-divider>
            <div class="fx-row align-items-start justify-content-start">
              <app-input placeholder="{{'SUPPORT.QUESTION' | translate}}..." type="text" [(value)]="question"
                class="fx-flex" [error]="errors.question" [bottomWrapper]="true"></app-input>
              <button class="main-button ml-10" (click)="askQuestion()">
                <div class="fx-row align-items-center justify-content-center">
                  {{'SUPPORT.ASK_QUESTION' | translate}}
                </div>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="fx-flex-50 pl-8 border-box">
        <mat-card class="h-100">
          <div class="fx-column">
            <div class="fx-row align-items-center justify-content-between">
              <div class="fx-row align-items-center justify-content-start">
                <div class="main-light-icon-container">
                  <mat-icon class="main" fontSet="material-icons-outlined">support_agent</mat-icon>
                </div>
                <div class="fx-column ml-20">
                  <span class="title">{{'SUPPORT.BE_CALLED_BACK' | translate}}</span>
                  <span class="text">{{'SUPPORT.BE_CALLED_BACK_HINT' | translate}}</span>
                </div>
              </div>
            </div>
            <mat-divider class="mv-16"></mat-divider>
            <div class="fx-row align-items-start justify-content-start">
              <app-input placeholder="{{'SUPPORT.PHONE_NUMBER' | translate}}..." type="text" [(value)]="phoneNumber"
                class="fx-flex" [error]="errors.phoneNumber" [bottomWrapper]="true"></app-input>
              <button class="main-button ml-10" (click)="beCalledBack()">
                <div class="fx-row align-items-center justify-content-center">
                  {{'SUPPORT.BE_CALLED_BACK' | translate}}
                </div>
              </button>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-drawer-content>
  <mat-drawer mode="over" position="end" class="drawer" #drawer>
    <div class="pl-16 pt-16 pb-16 fx-column">
      <span class="page-title">{{'SUPPORT.FAQ' | translate}}</span>
      <span class="text">{{'SUPPORT.FAQ_HINT' | translate}}</span>
      <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="faqQuery"
        (change)="onFAQQueryChange($event)" class="mt-20 fx-flex"></app-input>
      <mat-accordion class="mt-20">
        <div *ngFor="let category of categories" class="fx-column">
          <div class="fx-row align-items-center justify-content-start mv-10"
            *ngIf="filterByCategory(category).length > 0">
            <span class="title main" *ngIf="filterByCategory(category).length > 0">{{category | translate}}</span>
            <mat-divider class="ml-10 fx-flex-100"></mat-divider>
          </div>
          <mat-expansion-panel *ngFor="let entry of filterByCategory(category)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="text bold">{{entry.question}}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="fx-row align-items-center justify-content-start">
              <div class="chip main">{{entry.scope | translate}}</div>
              <div class="chip main outline ml-10">{{entry.action}}</div>
            </div>
            <p class="text mt-10">{{entry.answer}}</p>
          </mat-expansion-panel>
        </div>
      </mat-accordion>
    </div>
  </mat-drawer>
</mat-drawer-container>