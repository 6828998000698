import { Injectable } from '@angular/core';
import { MessageService } from './message.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Organization } from '../models/organization';
import { Paginator } from '../models/paginator';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private _message: MessageService,
    private http: HttpClient
  ) {

  }

  retrieveOrganizations(pageNumber: number, limit: number, offset: number, sort?: string, query?: string): Promise<Paginator<Organization>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }
    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Organization>>(`${environment.backend}/admin/organizations`, { params }));
  }

  retrieveOrganization(id: string) {
    return firstValueFrom(this.http
      .get<Organization>(`${environment.backend}/admin/organizations/${id}`,));
  }

  retrieveOrganizationUsers(id: string, pageNumber: number, limit: number, offset: number, sort?: string, query?: string) {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }
    if (query) {
      params = params.set('q', query);
    }
    return firstValueFrom(this.http
      .get<Organization>(`${environment.backend}/admin/organizations/${id}/users`, { params }));
  }

  retrieveOrganizatioUsersStats(id: string) {
    return firstValueFrom(this.http
      .get<any>(`${environment.backend}/admin/organizations/${id}/users/stats`,));
  }

  retrieveOrganizationDocumentsStats(id: string) {
    return firstValueFrom(this.http
      .get<any>(`${environment.backend}/admin/organizations/${id}/documents/stats`,));
  }

  retrieveOrganizationDocumentsGraph(id: string, start?: Moment, end?: Moment, period?: "hour" | "day" | "week" | "month" | "year") {
    let params = new HttpParams();
    if (start) {
      params = params.set('start', start.format());
    }
    if (end) {
      params = params.set('end', end.format());
    }
    if (period) {
      params = params.set('period', period);
    }
    return firstValueFrom(this.http
      .get<any>(`${environment.backend}/admin/organizations/${id}/documents/graph`, { params }));
  }

  retrieveOrganizationTrademarksStats(id: string) {
    return firstValueFrom(this.http
      .get<any>(`${environment.backend}/admin/organizations/${id}/trademarks/stats`,));
  }

}
