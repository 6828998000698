<div class="panel fx-column">
  <div class="fx-row align-items-center justify-content-start">
    <img src="/assets/img/logo.svg" height="60">
  </div>
  <div class="fx-column fx-flex-100 align-items-center justify-content-center">
    <div class="fx-row fx-wrap justify-content-center align-items-center fx-flex-50" style="max-width:50% !important;"
      *ngIf="code">
      <div class="fx-column fx-flex-100 align-items-center justify-content-center">
        <span class="title main bold center">{{'RESET_PASSWORD.TITLE' | translate}}</span>
        <span class="text center">{{'RESET_PASSWORD.INSTRUCTIONS' | translate}}</span>
      </div>
      <div class="fx-column mv-30 fx-flex-100">
        <app-input [placeholder]="('RESET_PASSWORD.PASSWORD' | translate) + '...'" type="password" prefix="lock"
          [(value)]="password" [error]="errors.password" [bottomWrapper]="true"></app-input>
        <div class="fx-row fx-wrap align-items-center justify-content-start fx-flex mb-20">
          <div class="fx-row align-items-center justify-content-start fx-flex-100"
            *ngFor="let attr of _passwordStrength()">
            <mat-icon [class]="attr.valid ? 'green mr-5' : 'red mr-5'" fontSet="material-icons-outlined" inset
              inline>{{attr.valid ?
              'done' : 'close'}}</mat-icon>
            <span [class]="attr.valid ? 'small green mr-5' : 'small red mr-5'">{{'RESET_PASSWORD.'+attr.label |
              translate}}</span>
          </div>
        </div>
        <app-input [placeholder]="('RESET_PASSWORD.CONFIRMATION' | translate) + '...'" type="password" prefix="lock"
          [(value)]="confirmation" [error]="errors.confirmation" [bottomWrapper]="true"></app-input>
        <div class="fx-row align-items-center justify-content-center mt-10 fx-flex">
          <button class="main-button fx-flex" (click)="resetPassword()">
            {{'ACTIONS.RESET_PASSWORD' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="fx-column justify-content-center align-items-center" *ngIf="!code">
      <div class="error-icon-container">
        <mat-icon class="red" fontSet="material-icons-outlined">error_outline</mat-icon>
      </div>
      <div class="mt-10 fx-column fx-flex-100 ">
        <span class="text text-center">{{"RESET_PASSWORD.NO_CODE" | translate}}</span>
      </div>
    </div>
  </div>
  <div class="fx-column align-items-center justify-content-center">
    <img src="/assets/icons/icon.svg" height="50" class="mb-10">
    <a class="main">uzai.tech</a>
  </div>
</div>