import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExternalCreateTrademarkResult } from 'src/app/services/trademark.service';

@Component({
  selector: 'app-trademark-external-create-dialog',
  templateUrl: './trademark-external-create-dialog.component.html',
  styleUrls: ['./trademark-external-create-dialog.component.scss']
})
export class TrademarkExternalCreateDialogComponent {
  trademarks: ExternalCreateTrademarkResult[] = [];
  displayedColumns: string[] = ['identifierNumber', 'country', 'status'];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { results: { declared: ExternalCreateTrademarkResult[], errors: ExternalCreateTrademarkResult[] } },
    private dialogRef: MatDialogRef<TrademarkExternalCreateDialogComponent>
  ) {

  }

  ngOnInit(): void {
    this.trademarks = [...this.data.results.declared, ...this.data.results.errors];
  }

  onCancel() {
    this.dialogRef.close()
  }
}
