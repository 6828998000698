import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Integration, IntegrationRecurrence, IntegrationRecurrenceValues } from 'src/app/models/integration';
import { Trademark } from 'src/app/models/trademark';
import { IntegrationService } from 'src/app/services/integration.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-integration-dialog',
  templateUrl: './integration-dialog.component.html',
  styleUrls: ['./integration-dialog.component.scss']
})
export class IntegrationDialogComponent {
  mode: 'create' | 'edit' = 'create';
  type?: {
    key: string,
    icon: string,
    title: string,
    description: string
  };
  integration?: Integration;

  name?: string;
  url?: string;
  recurrence?: IntegrationRecurrence;
  trademarks?: Trademark[] = [];

  recurrences: {
    value: IntegrationRecurrence,
    label: string,
    selected: boolean,
    disabled: boolean
  }[] = [
      {
        value: IntegrationRecurrenceValues[2],
        label: "DATES.ONCE",
        selected: false,
        disabled: false
      },
      {
        value: IntegrationRecurrenceValues[1],
        label: "DATES.WEEKLY",
        selected: false,
        disabled: false
      },
      {
        value: IntegrationRecurrenceValues[0],
        label: "DATES.MONTHLY",
        selected: false,
        disabled: false
      }
    ];

  errors: {
    [key: string]: {
      value: boolean,
      message?: string
    }
  } = {
      name: {
        value: false,
        message: undefined
      },
      reccurence: {
        value: false,
        message: undefined
      },
      url: {
        value: false,
        message: undefined
      }
    }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type?: {
        key: string,
        icon: string,
        title: string,
        description: string
      },
      integration?: Integration,
      mode: 'create' | 'edit'
    },
    private dialogRef: MatDialogRef<IntegrationDialogComponent>,
    private integrationService: IntegrationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {
    this.type = this.data.type;
    this.mode = this.data.mode;
    if (this.mode === 'edit') {
      this.integration = this.data.integration;
      this.name = this.integration?.name;
      this.url = this.integration?.url;
      this.recurrence = this.integration?.recurrence;
      this.trademarks = this.integration?.trademarks
    }
  }


  _hasErrors() {
    this.errors['name'] = {
      value: false,
      message: undefined
    }
    if (!this.name) {
      this.errors['name'].value = true;
      this.errors['name'].message = this.translate.instant('INTEGRATIONS.WEBSITE.NAME_REQUIRED');
    }

    this.errors['recurrence'] = {
      value: false,
      message: undefined
    }
    if (!this.recurrence) {
      this.errors['recurrence'].value = true;
      this.errors['recurrence'].message = this.translate.instant('INTEGRATIONS.WEBSITE.RECURRENCE_REQUIRED');
    }

    this.errors['url'] = {
      value: false,
      message: undefined
    }
    if (!this.url) {
      this.errors['url'].value = true;
      this.errors['url'].message = this.translate.instant('INTEGRATIONS.WEBSITE.URL_REQUIRED');
    } else {
      if (!this.utils.validateUrlPattern(this.url)) {
        this.errors['url'].value = true;
        this.errors['url'].message = this.translate.instant('INTEGRATIONS.WEBSITE.URL_FORMAT');
      }
    }
    return this.errors['name'].value || this.errors['recurrence'].value || this.errors['url'].value;
  }

  onCancel(): void {
    this.dialogRef.close({
      created: false,
      edited: false
    });
  }

  async onCreate() {
    try {
      if (this._hasErrors()) {
        return
      } else {
        if (this.name && this.type && this.recurrence && this.url && this.trademarks) {
          await this.integrationService.create(this.name, this.type.key, this.recurrence, this.url, this.trademarks);
          this.toastr.success(this.translate.instant(`INTEGRATIONS.INTEGRATION_CREATED`, { name: this.name }));
          this.dialogRef.close({
            created: true,
            edited: false
          });
        }
      }
    } catch (err) {
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async onEdit() {
    if (this._hasErrors()) {
      return
    } else {
      if (this.integration && this.integration._id && this.name && this.type && this.recurrence && this.url && this.trademarks) {
        await this.integrationService.update(this.integration?._id, this.name, this.type.key, this.recurrence, this.url, this.trademarks);
        this.toastr.success(this.translate.instant(`INTEGRATIONS.INTEGRATION_UPDATED`, { name: this.name }));
        this.dialogRef.close({
          created: false,
          edited: true
        });
      }
    }
  }
}
