import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BatchStatus } from 'src/app/models/batch';

interface BatchScore {
  ok: number;
  warning: number;
  error: number;
  total: number;
}

@Component({
  selector: 'app-batch-score',
  templateUrl: './batch-score.component.html',
  styleUrls: ['./batch-score.component.scss']
})
export class BatchSCoreComponent implements OnInit {
  @Input() score: BatchScore = {
    ok: 0,
    warning: 0,
    error: 0,
    total: 1
  }
  @Input() status: BatchStatus = BatchStatus.OPENED;

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  globalScore() {
    const score = Math.round(100 * this.score.ok / this.score.total);
    return {
      value: `${score}%`,
      color: score < 50 ? 'red' : (score < 75 ? 'orange' : 'green')
    }
  }

  tooltip(key: string) {
    return `${this.score[key as keyof BatchScore]} ${this.translate.instant('BATCH.DOCUMENTS_' + key.toUpperCase()).toLowerCase()}`
  }
}
