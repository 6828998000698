<div class="p-20 fx-row fx-wrap align-items-center justify-content-center text-center">
  <div class="fx-flex-100 align-items-center justify-content-start text-center">
    <span class="title mb-10 text-center" *ngIf="action">{{(action.method === 'next' ?
      'CASE.TIMELINE_ACTION_NEXT_'+action.status.toUpperCase() :
      'CASE.TIMELINE_ACTION_'+action.method.toUpperCase())
      | translate}}</span>
  </div>
  <ng-container *ngIf="action" [ngSwitch]="action.method">
    <div *ngSwitchCase="'close'" class="mt-20 fx-row fx-wrap fx-flex-100">
      <span class="text fx-flex-100" [innerHTML]="'CASE.TIMELINE_ACTION_CLOSE_DESCRIPTION' | translate"></span>
      <div class="fx-row align-items-center justify-content-start fx-flex-100 mt-10">
        <div class="fx-flex-50 pr-5">
          <mat-checkbox color="primary" [(ngModel)]="withdrawal">
          </mat-checkbox>
          <span class="text">{{'CASE.TIMELINE_ACTION_CLOSE_WITHDRAWN' | translate}}</span>
        </div>
        <div class="fx-flex-50 pl-5">
          <mat-checkbox color="primary" [(ngModel)]="definitive" [disabled]="withdrawal">
          </mat-checkbox>
          <span class="text">{{'CASE.TIMELINE_ACTION_CLOSE_DEFINITIVE' | translate}}</span>
        </div>
      </div>
      <div class="fx-vclumn align-items-center justify-content-center fx-flex-100 mt-10">
        <span class="text" *ngIf="case && case.trademark">{{'CASE.TIMELINE_ACTION_CLOSE_SUCESS_RATE' |
          translate:case.trademark}}</span>
        <div class="fx-row fx-wrap align-items-center justify-content-center mt-10">
          <div class="chip light clickable" (click)="!withdrawal ?decision = 'WON' : null"
            [class.green]="decision === 'WON' && !withdrawal" [class.outline]="decision !== 'WON' || withdrawal"
            [class.disabled]="withdrawal">
            {{'CASE.CLOSED_WON' | translate}}
          </div>
          <div class="chip light ml-10 clickable" (click)="!withdrawal ? decision = 'MITIGATED' : null"
            [class.orange]="decision === 'MITIGATED' && !withdrawal"
            [class.outline]="decision !== 'MITIGATED' || withdrawal" [class.disabled]="withdrawal">
            {{'CASE.CLOSED_MITIGATED' | translate}}
          </div>
          <div class="chip light ml-10 clickable" (click)="!withdrawal ? decision = 'LOST' : null"
            [class.red]="decision === 'LOST' && !withdrawal" [class.outline]="decision !== 'LOST' || withdrawal"
            [class.disabled]="withdrawal">
            {{'CASE.CLOSED_LOST' | translate}}
          </div>
        </div>
      </div>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_CLOSE_DATE' | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.CLOSING_DATE' | translate}}" [(value)]="date" [max]="date"
          [min]="case && case.phase ? case.phase.start : undefined"></app-datepicker>
      </div>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_CLOSE_CONFIRM' |
        translate:caseIdentifier}}</span>
    </div>
    <div *ngSwitchCase="'suspend'" class="mt-20 fx-row fx-wrap">
      <span class="text fx-flex-100" [innerHTML]="'CASE.TIMELINE_ACTION_SUSPEND_DESCRIPTION' | translate"></span>
      <span class="text mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUSPEND_DEFAULT' |
        translate}}<b *ngIf="defaultDuration">{{defaultDuration.value}} {{('DATES.'+defaultDuration.unit.toUpperCase() |
          translate).toLowerCase()}}</b></span>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUSPEND_DATE' | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.PHASE_START_DATE' | translate}}" [(value)]="start"
          [min]="case && case.phase ? case.phase.start : undefined"></app-datepicker>
        <mat-icon class="ml-10 mr-10" fontSet="material-icons-outlined" *ngIf="end">arrow_right_alt</mat-icon>
        <app-datepicker placeholder="{{'CASE.PHASE_END_DATE' | translate}}" [(value)]="end"
          *ngIf="end"></app-datepicker>
      </div>
      <span class="small light fx-flex-100 mt-10">{{suspensionPeriod()}}</span>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUSPEND_CONFIRM' |
        translate:caseIdentifier}}</span>
    </div>
    <div *ngSwitchCase="'unsuspend'" class="mt-20 fx-row fx-wrap">
      <span class="text fx-flex-100" [innerHTML]="'CASE.TIMELINE_ACTION_UNSUSPEND_DESCRIPTION' | translate"></span>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_UNSUSPEND_DATE' | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.PHASE_START_DATE' | translate}}" [(value)]="date"
          [min]="suspensionStart || undefined"></app-datepicker>
      </div>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_UNSUSPEND_CONFIRM' |
        translate:caseIdentifier}}</span>
    </div>
    <div *ngSwitchCase="'next'" class="mt-20 fx-row fx-wrap">
      <span class="text fx-flex-100"
        [innerHTML]="'CASE.TIMELINE_ACTION_NEXT_'+action.status.toUpperCase()+'_DESCRIPTION' | translate"></span>
      <span class="text mv-10 fx-flex-100">{{(end ? 'CASE.TIMELINE_ACTION_NEXT_DATES' :
        'CASE.TIMELINE_ACTION_NEXT_START') | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.PHASE_START_DATE' | translate}}" [(value)]="start"
          [min]="case && case.phase ? case.phase.start : undefined"></app-datepicker>
        <mat-icon class="ml-10 mr-10" fontSet="material-icons-outlined" *ngIf="end">arrow_right_alt</mat-icon>
        <app-datepicker placeholder="{{'CASE.PHASE_END_DATE' | translate}}" [(value)]="end"
          *ngIf="end"></app-datepicker>
      </div>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_NEXT_'+action.status.toUpperCase()+'_CONFIRM'
        |
        translate:caseIdentifier}}</span>
    </div>
    <div *ngSwitchCase="'subphase'" class="mt-20 fx-row fx-wrap">
      <span class="text fx-flex-100" [innerHTML]="'CASE.TIMELINE_ACTION_SUBPHASE_DESCRIPTION' | translate"></span>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUBPHASE_LABEL' | translate}}</span>
      <app-input placeholder="{{'CASE.SUBPHASE_LABEL' | translate}}..." class="fx-flex-100" type="text"
        [(value)]="label" [error]="errors.label" [bottomWrapper]="true"></app-input>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUBPHASE_DATE' | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.PHASE_START_DATE' | translate}}" [(value)]="start"
          [min]="minSubphase"></app-datepicker>
        <mat-icon class="ml-10 mr-10" fontSet="material-icons-outlined">arrow_right_alt</mat-icon>
        <app-datepicker placeholder="{{'CASE.PHASE_END_DATE' | translate}}" [(value)]="end" [min]="minSubphase"
          [max]="maxSubphase"></app-datepicker>
      </div>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_SUBPHASE_CONFIRM' |
        translate:caseIdentifier}}</span>
    </div>
    <div *ngSwitchCase="'editsubphase'" class="mt-20 fx-row fx-wrap">
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_EDITSUBPHASE_LABEL' | translate}}</span>
      <app-input placeholder="{{'CASE.SUBPHASE_LABEL' | translate}}..." class="fx-flex-100" type="text"
        [(value)]="label" [error]="errors.label" [bottomWrapper]="true"></app-input>
      <span class="text mv-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_EDITSUBPHASE_DATE' | translate}}</span>
      <div class="fx-flex-100 fx-row align-items-center justify-content-center">
        <app-datepicker placeholder="{{'CASE.PHASE_START_DATE' | translate}}" [(value)]="start"
          [min]="minSubphase"></app-datepicker>
        <mat-icon class="ml-10 mr-10" fontSet="material-icons-outlined">arrow_right_alt</mat-icon>
        <app-datepicker placeholder="{{'CASE.PHASE_END_DATE' | translate}}" [(value)]="end" [min]="minSubphase"
          [max]="maxSubphase"></app-datepicker>
      </div>
      <span class="text bold mt-10 fx-flex-100">{{'CASE.TIMELINE_ACTION_EDITSUBPHASE_CONFIRM' |
        translate:caseIdentifier}}</span>
    </div>
  </ng-container>
  <mat-divider class="fx-flex-100 mv-20"></mat-divider>
  <div class="fx-row align-items-center justify-content-center fx-flex-100">
    <button class="button fx-flex mr-10" (click)="onCancel()">{{'ACTIONS.CANCEL' | translate}}</button>
    <button class="main-button fx-flex ml-10" (click)="onConfirm()" [disabled]="!canConfirm()"
      [class.disabled]="!canConfirm()">{{'ACTIONS.CONFIRM'
      | translate}}</button>
  </div>
</div>