import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-langs',
  templateUrl: './document-langs.component.html',
  styleUrls: ['./document-langs.component.scss']
})
export class DocumentLangsComponent implements OnInit, OnChanges {
  @Input() metadata?: DocumentMetadata[] = [];
  @Input() langs?: string[] = [];
  confidences: { [lang: string]: number } = {};


  constructor(
  ) { }

  ngOnInit(): void {
    this.populateConfidence()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateConfidence()
  }

  populateConfidence() {
    this.langs?.forEach((lang: string) => {
      this.confidences[lang] = this.getConfidence(lang);
    })

  }

  getConfidence(lang: string) {
    return (this.metadata?.find(datum => datum.type === MetadataType.LANG && datum.value === lang)?.confidence ?? 0) * 100
  }
}
