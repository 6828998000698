import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss']
})
export class OtpDialogComponent implements OnInit, OnDestroy {

  user?: User;

  otp?: string;

  requestDisabled: boolean = true;

  requestInterval: { value: number, id?: any } = {
    value: 60,
    id: undefined
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<OtpDialogComponent>,
    private toastr: ToastrService,
    private utils: UtilsService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    this.requestOTP();
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  clearInterval() {
    if (this.requestInterval.id) {
      clearInterval(this.requestInterval.id);
    }
  }

  requestOTP() {
    this.authService.requestOtp().subscribe(res => {
      this.requestDisabled = true;
      this.requestInterval.id = setInterval(() => {
        this.requestInterval.value--;
        this.requestInterval = {
          value: this.requestInterval.value--,
          id: this.requestInterval.id
        }
        if (this.requestInterval.value === 0) {
          this.requestDisabled = false;
          this.requestInterval = {
            value: 60,
            id: undefined
          }
          clearInterval(this.requestInterval.id)
        }
      }, 1000)
    })
  }

  isDisabled() {
    return !this.otp || this.otp.length < 4;
  }

  onCancel(): void {
    this.clearInterval();
    this.dialogRef.close({
      success: false,
      canceled: true
    });
  }

  async onValidate() {
    try {
      const res = await this.authService.validateOtp(this.otp as string);
      this.clearInterval();
      this.dialogRef.close({
        success: true,
        canceled: false
      });
    } catch (err: any) {
      if (err && err.error && err.error.message && this.utils.hasTranslation(`ERRORS.${err.error.message}`)) {
        this.toastr.error(`ERRORS.${err.error.message}`)
      } else {
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }

  }
}
