<div class="fx-column">
  <span class="label" *ngIf="label">{{label}}</span>
  <div class="fx-row justify-content-start align-items-center input-wrapper"
    [class]="{'focus':focus,'error':error.value}">
    <input [type]="type" [placeholder]="placeholder" [(ngModel)]="selection" (ngModelChange)="valueUpdate.next($event)"
      (focus)="focus = true;picker.open();" (blur)="focus = false;picker.close();" [disabled]="disabled" #input
      class="fx-flex" />
    <mat-date-range-input [rangePicker]=" picker" [disabled]="disabled" (focus)="focus = true" (blur)="focus = false"
      style="width: 0 !important;height:0 !important;" separator="">
      <input matStartDate style="width: 0 !important;height:0 !important;" [value]="value.start"
        (dateChange)="onStartDateChange($event)">
      <input matEndDate style="width: 0 !important;height:0 !important;" [value]="value.end"
        (dateChange)="onEndDateChange($event)">
    </mat-date-range-input>
    <mat-icon class="suffix light mb-5 clickable" [class.focus]="focus" fontSet="material-icons-outlined"
      (click)="picker.open()">event</mat-icon>
    <mat-date-range-picker #picker (closed)="input.blur()" [xPosition]="'end'"></mat-date-range-picker>
  </div>
</div>