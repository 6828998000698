import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Case, CaseAttachment, CaseDocument, CasePossiblePhase, CaseStatus, CaseTimelinePhase, CaseType, ClosedPhaseMeta, InstructionPhaseMeta, Note, SubPhase, SuspendedLabel } from '../models/case';
import { environment } from 'src/environments/environment';
import { Paginator } from '../models/paginator';
import { Document } from '../models/document';
import { EvidenceRecord } from '../models/evidenceRecord';
import { UploadAttachment } from '../models/uploadFile';
import { Data } from './document.service';
import { User } from '../models/user';
import * as moment from 'moment';
import { Trademark } from '../models/trademark';
import { at } from 'lodash';

export interface CaseCreationOrUpdate {
  trademark: string;
  type: CaseType;
  lang: string;
  niceClassification: number[];
  timePeriod: {
    notificationDate: string,
    endInstructionDate: string,
    start: string,
    end: string
  };
  propertyOffice?: string;
  identifier?: string;
}

export interface CasesByPhase {
  count: number;
  _id: { name: CaseStatus }
}


@Injectable({
  providedIn: 'root'
})
export class CaseService {

  constructor(
    private http: HttpClient
  ) { }


  create(caseToCreate: CaseCreationOrUpdate): Promise<Case> {
    return firstValueFrom(this.http
      .post<Case>(`${environment.backend}/cases`, caseToCreate));
  }


  retrieve(id: string): Promise<Case> {
    return firstValueFrom(this.http
      .get<Case>(`${environment.backend}/cases/${id}`));
  }

  retrieveElligibleDocuments(id: string, pageNumber: number, limit: number, offset: number, sort?: string): Promise<Paginator<CaseDocument>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    return firstValueFrom(this.http
      .get<Paginator<CaseDocument>>(`${environment.backend}/cases/${id}/documents/elligible`, { params }));
  }

  retrieveDocuments(id: string, pageNumber: number, limit: number, offset: number, sort?: string): Promise<Paginator<CaseDocument>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    return firstValueFrom(this.http
      .get<Paginator<CaseDocument>>(`${environment.backend}/cases/${id}/documents`, { params }));
  }

  aggregateDocuments(id: string, facet: string): Promise<Data[]> {
    return firstValueFrom(this.http
      .get<Data[]>(`${environment.backend}/cases/${id}/documents/aggregate/${facet}`));
  }

  link(caseId: string, documents: string[]): Promise<Case> {
    return firstValueFrom(this.http
      .put<Case>(`${environment.backend}/cases/${caseId}/link`, { documents }));
  }

  unlink(caseId: string, documents: string[]): Promise<Case> {
    return firstValueFrom(this.http
      .put<Case>(`${environment.backend}/cases/${caseId}/unlink`, { documents }));
  }

  countAll(): Promise<{ count: number }> {
    return firstValueFrom(this.http
      .get<{ count: number }>(`${environment.backend}/cases/count`));
  }

  graphAll(): Promise<CasesByPhase[]> {
    return firstValueFrom(this.http
      .get<CasesByPhase[]>(`${environment.backend}/cases/graph?date=${moment().format("YYYY-MM-DD")}`));
  }

  retrieveAll(pageNumber: number, limit: number, offset: number, sort: string, trademarks?: Trademark[], phases?: CaseStatus[], date?: Moment, query?: string): Promise<Paginator<Case>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)
      .set('sort', sort);


    if (trademarks) {
      trademarks.forEach((trademark) => {
        if (trademark._id)
          params = params.append('trademark[]', trademark._id);
      })
    }

    if (phases && date) {
      phases.forEach((phase) => {
        params = params.append('phase[]', phase);
      })
      params = params.append('date', date.format("YYYY-MM-DD"))
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<Case>>(`${environment.backend}/cases/search`, { params }));
  }

  update(id: string, updatedCase: CaseCreationOrUpdate): Promise<Case> {
    return firstValueFrom(this.http
      .put<Case>(`${environment.backend}/cases/${id}`, updatedCase));
  }

  addNote(id: string, comment: string): Promise<Note> {
    return firstValueFrom(this.http
      .post<Note>(`${environment.backend}/cases/${id}/notes`, { comment }));
  }

  deleteNote(id: string, noteId: string): Promise<void> {
    return firstValueFrom(this.http
      .delete<void>(`${environment.backend}/cases/${id}/notes/${noteId}`));
  }

  delete(id: string): Promise<any> {
    return firstValueFrom(this.http
      .delete<any>(`${environment.backend}/cases/${id} `));
  }

  retrieveEvidenceRecords(id: string, pageNumber: number, limit: number, offset: number, sort: string): Promise<Paginator<EvidenceRecord>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)
      .set('sort', sort);


    return firstValueFrom(this.http
      .get<Paginator<EvidenceRecord>>(`${environment.backend}/cases/${id}/evidence`, { params }));
  }

  retrieveEvidenceRecord(id: string, evidenceRecordId: string) {
    return firstValueFrom(this.http
      .get<EvidenceRecord>(`${environment.backend}/cases/${id}/evidence/${evidenceRecordId}`, {}));
  }

  generateEvidenceRecord(id: string) {
    return firstValueFrom(this.http
      .post<EvidenceRecord>(`${environment.backend}/cases/${id}/evidence/generate`, {}));
  }

  downloadEvidenceRecord(id: string, evidenceRecordId: string) {
    return firstValueFrom(this.http
      .get(`${environment.backend}/cases/${id}/evidence/${evidenceRecordId}/download`, {
        responseType: 'blob'
      }));
  }

  deleteEvidenceRecord(id: string, evidenceRecordId: string) {
    return firstValueFrom(this.http
      .delete<void>(`${environment.backend}/cases/${id}/evidence/${evidenceRecordId}`));
  }

  createAttachment(id: string, file: UploadAttachment) {
    const formData = new FormData();
    formData.append("attachment", file);
    formData.append("name", file.name);
    formData.append("appendix", file.appendix ? 'true' : 'false');
    formData.append("nonUse", file.nonUse ? 'true' : 'false');
    if (file.description)
      formData.append("description", file.description);

    return lastValueFrom(this.http.post<CaseAttachment>(`${environment.backend}/cases/${id}/attachments`, formData));
  }

  createNonUseAttachment(id: string, file: UploadAttachment) {
    const attachment = {
      name: file.name,
      description: file.description,
      appendix: true,
      document: file.document,
      nonUse: true
    };

    return lastValueFrom(this.http.post<CaseAttachment>(`${environment.backend}/cases/${id}/attachments/non-use`, attachment));
  }

  updateAttachment(id: string, attachmentId: string, attachment: UploadAttachment) {
    return lastValueFrom(this.http.put<CaseAttachment>(`${environment.backend}/cases/${id}/attachments/${attachmentId}`, attachment));
  }

  countAttachments(id: string) {
    return lastValueFrom(this.http.get<number>(`${environment.backend}/cases/${id}/attachments/count`));
  }

  retrieveAttachments(id: string) {
    return lastValueFrom(this.http.get<CaseAttachment[]>(`${environment.backend}/cases/${id}/attachments`));
  }

  downloadAttachment(id: string, attachmentId: string) {
    return firstValueFrom(this.http
      .get(`${environment.backend}/cases/${id}/attachments/${attachmentId}/download`, {
        responseType: 'blob'
      }));
  }

  deleteAttachment(id: string, attachmentId: string) {
    return firstValueFrom(this.http
      .delete(`${environment.backend}/cases/${id}/attachments/${attachmentId}`, {
        responseType: 'blob'
      }));
  }

  retrieveUsers(id: string, pageNumber: number, limit: number, offset: number, sort: string): Promise<Paginator<User>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)
      .set('sort', sort);

    return firstValueFrom(this.http
      .get<Paginator<User>>(`${environment.backend}/cases/${id}/users`, { params }));
  }

  getPossiblePhases(status: CaseStatus, type: CaseType, propertyOffice: string) {
    return firstValueFrom(this.http
      .get<CasePossiblePhase[]>(`${environment.backend}/cases/timeline/phases?caseStatus=${status}&caseType=${type}&propertyOffice=${propertyOffice}`));
  }

  nextPhase(id: string, status: CaseStatus, start: Moment, end?: Moment) {
    return firstValueFrom(this.http
      .post<Case>(`${environment.backend}/cases/${id}/timeline/next`, {
        nextCaseStatus: status,
        start: start.format("YYYY-MM-DD"),
        end: end ? end.format("YYYY-MM-DD") : undefined
      }));
  }

  closeTimeline(id: string, date: Moment, meta: {
    withdrawal?: boolean,
    definitive?: boolean,
    decision?: string
  }) {
    return firstValueFrom(this.http
      .post<Case>(`${environment.backend}/cases/${id}/timeline/close`, {
        date: date.format("YYYY-MM-DD"),
        meta
      }));
  }

  suspendTimeline(id: string, start: Moment, end?: Moment) {
    return firstValueFrom(this.http
      .post<Case>(`${environment.backend}/cases/${id}/timeline/suspend`, {
        start: start.format("YYYY-MM-DD"),
        end: end ? end.format("YYYY-MM-DD") : undefined
      }));
  }

  unsuspendTimeline(id: string, date: Moment) {
    return firstValueFrom(this.http
      .post<Case>(`${environment.backend}/cases/${id}/timeline/unsuspend`, {
        end: date.format("YYYY-MM-DD")
      }));
  }

  defineSubphases(id: string, subPhases: SubPhase[]) {
    return firstValueFrom(this.http
      .put<Case>(`${environment.backend}/cases/${id}/timeline/subPhases`, {
        subPhases
      }));
  }

  _phaseProcessor(phase: CaseTimelinePhase) {
    phase.start = moment(phase.start);
    phase.end = phase.end ? moment(phase.end) : undefined;
    if (!phase.end) {
      phase.duration = 100;
      phase.infinite = true;
    } else {
      phase.duration = moment(phase.end).diff(moment(phase.start), 'days', true);
      phase.infinite = false;
    }
    if (phase && phase.meta) {
      const meta = phase.meta as InstructionPhaseMeta;
      if (meta.subPhases && meta.subPhases.length > 0) {
        phase.subPhases = meta.subPhases;
        phase.subPhases?.forEach((subphase, index) => {
          subphase.start = moment(subphase.start);
          subphase.end = subphase.end ? moment(subphase.end) : undefined;
          if (!subphase.end) {
            subphase.duration = 100;
            subphase.infinite = true;
          } else {
            subphase.duration = moment(subphase.end).diff(moment(subphase.start), 'days', true);
            subphase.infinite = false;
          }
          if (index === 0) {
            subphase.offset = moment(subphase.start).diff(moment(phase.start), 'days', true);
          } else if (phase && phase.subPhases && phase.subPhases[index - 1]) {
            subphase.offset = moment(subphase.start).diff(moment(phase.subPhases[index - 1].end), 'days', true);
          }
        })
      }
    }
    return phase;
  }

  _currentPhase(caseObject: Case) {
    const phase = caseObject?.timeline?.find((phase: CaseTimelinePhase) => {
      if (!phase.end) {
        return moment(phase.start).isSameOrBefore(moment(), 'days')
      } else {
        return moment(phase.start).isSameOrBefore(moment(), 'days') && moment(phase.end).isAfter(moment(), 'days')
      }
    });
    if (phase) {
      return ({ ...phase, endsIn: phase.end ? moment(phase.end).diff(moment(), 'days') : undefined })
    }
    if (caseObject.timeline) {
      const lastPhase = caseObject.timeline[caseObject.timeline.length - 1];
      if (lastPhase && lastPhase.name === CaseStatus.DECISION && lastPhase.end) {
        return {
          name: CaseStatus.CLOSED,
          start: lastPhase.end,
          suspended: false,
          infinite: true,
          canAddEvidence: false,
          endsIn: undefined
        }
      }
    }

    return undefined;
    // return phase ? ({ ...phase, endsIn: phase.end ? moment(phase.end).diff(moment(), 'days') : undefined }) : undefined;
  }

  _isSuspended(caseObject: Case) {
    if (caseObject.phase) {
      if (caseObject.phase && caseObject.phase.meta && (caseObject.phase.meta as InstructionPhaseMeta).subPhases) {
        const subPhases = (caseObject.phase.meta as InstructionPhaseMeta).subPhases;
        const suspensions = subPhases?.filter(sp => sp.label === SuspendedLabel && moment(sp.start).isSameOrBefore(moment(), 'days') && moment(sp.end).isAfter(moment(), 'days'));
        return suspensions && suspensions.length > 0 ? true : false;
      }
    }
    return false;
  }

  _closedMeta(caseObject: Case): ClosedPhaseMeta | undefined {
    if (caseObject && caseObject.phase && caseObject.phase.name === CaseStatus.CLOSED && caseObject.phase.meta) {
      const meta = caseObject.phase.meta as ClosedPhaseMeta;
      return meta;
    }
    return undefined
  }

}
