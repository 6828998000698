export enum DocumentClass {
  AUTOMATIC = 'AUTOMATIC',
  INVOICE = 'INVOICE',
  CERTIFICATION = 'CERTIFICATION',
  CONTRACT = 'CONTRACT',
  PRESS_EXTRACT = 'PRESS_EXTRACT',
  BOOKLET = 'BOOKLET',
  CATALOG = 'CATALOG',
  PICTURE = 'PICTURE',
  ADVERTISING = 'ADVERTISING',
  WEBSITE_SCREENSHOT = 'WEBSITE_SCREENSHOT',
  ACCOUNTING_DOCUMENT = 'ACCOUNTING_DOCUMENT',
  EMAIL = 'EMAIL',
  OTHER = 'OTHER'
}