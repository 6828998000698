<div class='fx-column align-items-center justify-content-center onboarding-container'
  *ngIf="!loading && done && (counts.trademarks === 0 || counts.documents === 0)">
  <mat-card class="p-40" style="max-width:800px;">
    <div class="fx-column align-items-center justify-content-center">
      <div class="logo-container">
        <img src="/assets/icons/icon.svg" />
      </div>
      <span class="title main bold mt-20">{{'HOME.WELCOME_UZAI' | translate}}</span>
      <span class="text">{{'HOME.ONBOARDING_TEXT' | translate}}</span>
      <div class="fx-row fx-wrap align-items-start justify-content-start mt-20">
        <div class="fx-row p-16 align-items-center justify-content-between fx-flex-100">
          <div class="fx-row align-items-center justify-content-start">
            <div class="main-light-icon-container" *ngIf="counts.trademarks === 0">
              <div class="number-container">
                <span class="text bold main">1</span>
              </div>
            </div>
            <div class="main-icon-container" *ngIf="counts.trademarks > 0">
              <mat-icon class="white" fontSet="material-icons-outlined">check</mat-icon>
            </div>
            <div class="fx-column ml-10">
              <span class="page-subtitle main">{{'ENTITIES.TRADEMARKS' | translate}}</span>
              <span class="text">{{'HOME.TRADEMARKS_HINT' | translate}}</span>
            </div>
          </div>
          <button class="main-button" style="width:180px;" (click)="toTrademarks()" *ngIf="counts.trademarks === 0">
            {{'TRADEMARKS_LIST.NEW' | translate}}
          </button>
          <span class="text bold main" *ngIf="counts.trademarks > 0">{{counts.trademarks}}
            {{((counts.trademarks > 1 ?
            'ENTITIES.TRADEMARKS' : 'ENTITIES.TRADEMARK') | translate).toLowerCase()}}</span>
        </div>
        <div class="fx-row p-16 align-items-center justify-content-between  fx-flex-100">
          <div class="fx-row align-items-center justify-content-start">
            <div class="main-light-icon-container" *ngIf="counts.documents === 0">
              <div class="number-container">
                <span class="text bold main">2</span>
              </div>
            </div>
            <div class="main-icon-container" *ngIf="counts.documents > 0">
              <mat-icon class="white" fontSet="material-icons-outlined">check</mat-icon>
            </div>
            <div class="fx-column ml-10">
              <span class="page-subtitle main">{{'ENTITIES.DOCUMENTS' | translate}}</span>
              <span class="text">{{'HOME.DOCUMENTS_HINT' | translate}}</span>
            </div>
          </div>
          <button class="main-button" style="width:180px;" (click)="openUploadDialog()" *ngIf="counts.documents === 0">
            {{'ACTIONS.UPLOAD' | translate}}
          </button>
          <span class="text bold main" *ngIf="counts.documents > 0">{{counts.documents}}
            {{((counts.documents > 1 ?
            'ENTITIES.DOCUMENTS' : 'ENTITIES.DOCUMENT') | translate).toLowerCase()}}</span>
        </div>
      </div>
    </div>
  </mat-card>
</div>
<div class='fx-row fx-wrap align-items-start justify-content-start'
  *ngIf="!loading && done && counts.trademarks > 0 && counts.documents > 0">
  <div class='fx-row fx-wrap align-items-stretch justify-content-start fx-flex-100'>
    <div class="pr-8 fx-flex-18 border-box">
      <app-counter-card label="ENTITIES.TRADEMARKS" [value]="counts.trademarks"
        [link]="{href:'/trademarks',label:'ACTIONS.VIEW_ALL'}"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-18 border-box">
      <app-counter-card label="ENTITIES.DOCUMENTS" [value]="counts.documents"
        [link]="{href:'/documents',label:'ACTIONS.VIEW_ALL'}"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-18 border-box">
      <app-counter-card label="ENTITIES.CASES" [value]="counts.cases"
        [link]="{href:'/cases',label:'ACTIONS.VIEW_ALL'}"></app-counter-card>
    </div>
    <div class="pl-8 pr-8 fx-flex-18 border-box">
      <app-counter-card label="ENTITIES.USERS" [value]="counts.users"
        [link]="{href:'/organization',label:'ACTIONS.VIEW_ALL'}"></app-counter-card>
    </div>
    <div class="pl-8 fx-flex-28 border-box">
      <app-upload-documents-box></app-upload-documents-box>
    </div>
  </div>
  <div class="pt-16 fx-flex-100 fx-row fx-wrap align-items-stretch justify-content-start">
    <div class="fx-flex-60 border-box pr-8">
      <mat-card>
        <div class="fx-row align-items-center justify-content-between mb-16">
          <span class="title">{{'HOME.CASES_BY_PHASE' | translate}}</span>
          <a routerLink="/cases" class="small">
            {{'ACTIONS.VIEW_ALL' | translate}}
          </a>
        </div>
        <app-cases-chart [data]="casesByPhases"></app-cases-chart>
      </mat-card>
    </div>
    <div class="fx-flex-40 border-box pl-8">
      <mat-card>
        <div class="fx-row align-items-center justify-content-between mb-16">
          <span class="title">{{'HOME.TRADEMARKS_USAGE' | translate}}</span>
          <a routerLink="/trademarks" class="small">
            {{'ACTIONS.VIEW_ALL' | translate}}
          </a>
        </div>
        <app-trademarks-pie [data]="trademarksObligation"></app-trademarks-pie>
      </mat-card>
    </div>
  </div>
  <div class="pt-16 fx-flex-100 fx-row fx-wrap align-items-stretch justify-content-start"
    *ngIf="openCases && openCases.docs && openCases.docs.length > 0">
    <div class="fx-row align-items-center justify-content-between fx-flex-100">
      <span class="title">{{'HOME.OPEN_CASES' | translate}}</span>
      <a routerLink="/cases" class="small">
        {{'ACTIONS.VIEW_ALL' | translate}}
      </a>
    </div>
    <div class="fx-flex-25 fx-column border-box pb-8 pt-8" *ngFor="let case of openCases.docs; let index = index"
      [class.pr-8]="index % 4 !== 2" [class.pl-8]="index % 4 !== 0">
      <app-case-card [case]="case" class="h-100"></app-case-card>
    </div>
  </div>
</div>