<div class="placeholder-container fx-row align-items-center justify-content-center"
  [style.height.px]="height() - offset">
  <mat-card class="placeholder">
    <div class="fx-flex-100 fx-row align-items-center justify-content-start">
      <div class="watermark-container">
        <img src="/assets/img/icon_lighter.svg" class="watermark" />
      </div>
      <div class="fx-column instructions-container align-items-start justify-content-start">
        <span class="title dark">{{title | translate}}</span>
        <span class="text dark">{{message | translate}}</span>
        <button [class]="button.class+' mt-20'" (click)="button.action()" *ngIf="button">
          <div class="fx-row align-items-center justify-content-center">
            <mat-icon class="white mr-5" fontSet="material-icons-outlined">{{button.icon}}</mat-icon>
            {{button.label | translate}}
          </div>
        </button>
      </div>
    </div>
  </mat-card>
</div>