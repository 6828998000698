<div class="fx-row align-items-center justify-content-between">
  <span class="text light">{{'ENTITIES.TRADEMARKS' | translate}}</span>
  <div class="fx-row align-items-center justify-content-end">
    <mat-icon [class.shown]="shown" class="visibility-icon ml-10" fontSet="material-icons-outlined">{{shown ?
      'visibility' :
      'visibility_off'}}</mat-icon>
  </div>
</div>
<div class="fx-row fx-wrap mb-10 w-100 align-items-center justify-content-between mt-8"
  *ngFor="let trademark of trademarks.slice(0,limit)">
  <div class="fx-column fx-flex ellipsis-container" *ngIf="trademark.name">
    <a (click)="toTrademark(trademark.ref)" class="fx-flex-100 ellipsis">{{trademark.name}}</a>
    <span class="small light  fx-flex-100">{{trademark.countryOfDesignation}} -
      {{trademark.identifierNumber}}</span>
  </div>
  <div class="fx-column fx-flex ellipsis-container" *ngIf="!trademark.name">
    <a (click)="toTrademark(trademark.ref)" class="fx-flex-100 ellipsis">{{trademark.identifierNumber}}</a>
    <span class="small light  fx-flex-100">{{trademark.countryOfDesignation}}</span>
  </div>
  <app-metadata-confidence *ngIf="trademark" class="ml-20"
    [value]="trademark.confidence || 0"></app-metadata-confidence>
</div>
<span class="text" *ngIf="!trademarks || trademarks.length === 0">-</span>
<div class="fx-row w-100 align-items-center justify-content-end mt-10 clickable" *ngIf="trademarks.length > 3">
  <span class="text main" (click)="toggle()">{{(limit === 3 ? 'UTILS.SEE_MORE' : 'UTILS.SEE_LESS') |
    translate}}</span>
  <mat-icon class="main ml-5" fontSet="material-icons-outlined">{{limit === 3 ? 'keyboard_arrow_down' :
    'keyboard_arrow_up'}}</mat-icon>
</div>