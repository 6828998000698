<div class="fx-row fx-wrap align-items-center justify-content-center">
  <div class="fx-flex-50 fx-column main-panel">
    <app-progress-bar [loading]="loading" mode="indeterminate"></app-progress-bar>
    <div class="p-20">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="fx-flex-50 p-20 fx-row fx-wrap align-items-end justify-content-end deco-panel">
    <img src="/assets/img/logo_letters_white.svg" height="60">
  </div>
</div>