import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Case, CaseScore, CaseStatus, ClosedPhaseMeta } from 'src/app/models/case';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-case-card',
  templateUrl: './case-card.component.html',
  styleUrls: ['./case-card.component.scss']
})
export class CaseCardComponent implements OnInit {
  @Input() case?: Case;
  @Input() class?: string;
  @Input() withUpload: boolean = false;
  isSuspended: boolean = true;
  closedMeta?: ClosedPhaseMeta;

  constructor(
    private caseService: CaseService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    if (this.case) {
      this.isSuspended = this.caseService._isSuspended(this.case);
      this.closedMeta = this.caseService._closedMeta(this.case);
    }

  }

  formatDate(date: string | Moment) {
    return moment(date).format("ll");
  }

}
