import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, firstValueFrom, map, tap } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'src/app/services/message.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loading: boolean = false;
  email?: string;
  password?: string;
  errors: any = {
    email: {
      value: false,
      message: undefined
    },
    password: {
      value: false,
      message: undefined
    }
  }

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private utils: UtilsService
  ) {

  }

  _hasErrors() {
    this.errors.email = {
      value: false,
      message: undefined
    }
    if (!this.email) {
      this.errors.email.value = true;
      this.errors.email.message = this.translate.instant('ERRORS.EMAIL_REQUIRED');
    } else {
      if (!this.utils.validateEmailPattern(this.email)) {
        this.errors.email.value = true;
        this.errors.email.message = this.translate.instant('ERRORS.EMAIL_FORMAT');
      }
    }
    this.errors.password = {
      value: false,
      message: undefined
    }
    if (!this.password) {
      this.errors.password.value = true;
      this.errors.password.message = this.translate.instant('ERRORS.PASSWORD_REQUIRED');
    }
    return this.errors.email.value || this.errors.password.value;
  }

  async login() {
    if (this._hasErrors()) {
      return;
    } else {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        const res = await firstValueFrom(this.authService.login(this.email as string, this.password as string));
        this.translate.use(res.lang);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.router.navigate([window.history.state.requested || '/']);
        this.toastr.info(this.translate.instant("LOGIN.GOOD_TO_SEE_YOU_BACK", res), this.translate.instant("LOGIN.TITLE"));

      } catch (err: any) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        if (err.message === "USER_NOT_FOUND") {
          this.errors.email.value = true;
          this.errors.email.message = this.translate.instant(`ERRORS.${err.message}`);
        } else if (err.message === "WRONG_PASSWORD") {
          this.errors.password.value = true;
          this.errors.password.message = this.translate.instant(`ERRORS.${err.message}`);
        } else {
          if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
            this.toastr.error(`ERRORS.${err.message}`)
          } else {
            this.toastr.error(`ERRORS.GENERIC`)
          }
        }
      }
    }


  }

  isDisabled() {

  }
}
