import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom, switchMap } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { CreateTrademarkFamilyDialogComponent } from 'src/app/dialogs/create-trademark-family-dialog/create-trademark-family-dialog.component';
import { NiceClass } from 'src/app/models/niceClass';
import { Paginator } from 'src/app/models/paginator';
import { Territory } from 'src/app/models/territory';
import { Trademark } from 'src/app/models/trademark';
import { TrademarkFamily } from 'src/app/models/trademarkFamily';
import { MessageService } from 'src/app/services/message.service';
import { TrademarkFamilyCreate, TrademarkFamilyService } from 'src/app/services/trademark-family.service';
import { TrademarkService } from 'src/app/services/trademark.service';

interface TrademarkFilters {
  query?: string,
  classNumber: number[],
  countryOfDesignation: string[],
  families?: string[]
}

interface TrademarkFamilyFilters {
  query?: string
}

@Component({
  selector: 'app-trademarks-list',
  templateUrl: './trademarks-list.component.html',
  styleUrls: ['./trademarks-list.component.scss']
})
export class TrademarksListComponent implements OnInit, OnDestroy {

  displayMode: string = "trademarks";

  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  trademarks: Paginator<Trademark> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  displayedColumns: string[] = ['name', 'identifier', 'countryOfDesignation', 'niceClassification', 'status', 'representation', 'actions'];
  dataSource: MatTableDataSource<Trademark> = new MatTableDataSource<Trademark>([])

  filters: TrademarkFilters = {
    query: undefined,
    classNumber: [],
    countryOfDesignation: []
  }

  private readonly filtersSubject = new Subject<TrademarkFilters>();
  private filtersSubscription?: Subscription;

  loadingFamilies: boolean = false;
  doneFamilies: boolean = false;
  noDocsFamilies: boolean = false;
  trademarkFamilies: Paginator<TrademarkFamily> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 10,
    totalDocs: 0,
    sort: 'name'
  };
  dataSourceFamilies: MatTableDataSource<TrademarkFamily> = new MatTableDataSource<TrademarkFamily>([])

  filtersFamily: TrademarkFamilyFilters = {
    query: undefined
  }

  private readonly filtersFamilySubject = new Subject<TrademarkFamilyFilters>();
  private filtersFamilySubscription?: Subscription;

  noTrademarkButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'TRADEMARKS_LIST.NEW',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.router.navigate(['new'], { relativeTo: this.route }) }
    }


  noTrademarkFamiliesButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'TRADEMARKS_LIST.NEW_FAMILY',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.newFamily() }
    }


  private readonly pagerSubject = new Subject<PageEvent>();
  private pagerSubscription?: Subscription;

  private readonly pagerFamilySubject = new Subject<PageEvent>();
  private pagerFamilySubscription?: Subscription;


  constructor(
    private _message: MessageService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private trademarkService: TrademarkService,
    private trademarkFamilyService: TrademarkFamilyService,
    private translate: TranslateService
  ) {

  }

  ngOnInit() {
    this.loading = true;
    this.loadingFamilies = true;
    this.done = false;
    this.doneFamilies = false;
    this.retrieveTrademarks(true);
    this.retrieveTrademarkFamilies(true);
    this.loading = false;
    this.loadingFamilies = false;
    this.done = true;
    this.doneFamilies = true;

    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveTrademarks(false);
      });
    this.filtersFamilySubscription = this.filtersFamilySubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetFamilyPage();
        await this.retrieveTrademarkFamilies(false);
      });

    this.pagerSubscription = this.pagerSubject
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(async () => {
        await this.retrieveTrademarks(false);
      });

    this.pagerFamilySubscription = this.pagerFamilySubject
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      ).subscribe(async () => {
        await this.retrieveTrademarkFamilies(false);
      });
  }

  public ngOnDestroy(): void {
    this.filtersSubscription?.unsubscribe();
  }

  async onTabChange(event: any) {
    if (event.index === 0) {
      this.displayMode = "trademarks";
      await this.retrieveTrademarks(false);
    } else if (event.index === 1) {
      this.displayMode = "families";
      await this.retrieveTrademarkFamilies(false);
    }
  }

  async retrieveTrademarks(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.trademarks = { ...this.trademarks, ...await this.trademarkService.retrieveAll(this.trademarks.page, this.trademarks.limit, this.trademarks.offset, this.trademarks.sort, this.filters.countryOfDesignation, this.filters.classNumber, this.filters.query, this.filters.families) };

      this.dataSource = new MatTableDataSource<Trademark>(this.trademarks.docs);

      if (init && this.trademarks.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.trademarks.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async retrieveTrademarkFamilies(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.trademarkFamilies = { ...this.trademarkFamilies, ...await this.trademarkFamilyService.retrieveAll(this.trademarkFamilies.page, this.trademarkFamilies.limit, this.trademarkFamilies.offset, this.trademarkFamilies.sort, this.filtersFamily.query) };

      this.dataSourceFamilies = new MatTableDataSource<TrademarkFamily>(this.trademarkFamilies.docs);

      if (init && this.trademarkFamilies.totalDocs === 0) {
        this.noDocsFamilies = true;
      } else if (init && this.trademarkFamilies.totalDocs > 0) {
        this.noDocsFamilies = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }

  }

  resetPage() {
    this.trademarks.limit = 10;
    this.trademarks.page = 1;
    this.trademarks.offset = 0;
  }

  resetFamilyPage() {
    this.trademarkFamilies.limit = 10;
    this.trademarkFamilies.page = 1;
    this.trademarkFamilies.offset = 0;
  }

  async handlePage(event: PageEvent) {
    this.trademarks.limit = event.pageSize;
    this.trademarks.page = event.pageIndex + 1;
    this.trademarks.offset = event.pageIndex * this.trademarks.limit;
    this.pagerSubject.next(event);
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.trademarks.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveTrademarks(false);
    }
  }

  async handleFamilyPage(event: PageEvent) {
    this.trademarkFamilies.limit = event.pageSize;
    this.trademarkFamilies.page = event.pageIndex + 1;
    this.trademarkFamilies.offset = event.pageIndex * this.trademarks.limit;
    this.pagerFamilySubject.next(event);
  }

  onSortFamilyChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.trademarkFamilies.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveTrademarkFamilies(false);
    }
  }

  onTrademarkFamilyChange(event: any) {
    this.filters.families = event.value.map((family: TrademarkFamily) => family._id);
    this.filtersSubject.next({ ...this.filters });
  }

  onNiceClassificationChange(event: any) {
    this.filters.classNumber = event.value.map((niceClass: NiceClass) => niceClass.classNumber);
    this.filtersSubject.next({ ...this.filters });
  }

  onCountryOfDesignationChange(event: any) {
    this.filters.countryOfDesignation = event.value.map((territory: Territory) => territory.alternateCodes ? [territory.id, ...territory.alternateCodes] : [territory.id]).flat();
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    if (typeof query == 'string') {
      this.filtersSubject.next({ ...this.filters });
    }
  }

  onQueryFamilyChange(query: any) {
    if (typeof query == 'string') {
      this.filtersFamilySubject.next({ ...this.filtersFamily });
    }
  }

  toTrademark(trademark: Trademark) {
    this.router.navigate([trademark._id], {
      relativeTo: this.route,
      state: {
        from: "TRADEMARKS"
      }
    })
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  async newFamily() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
      }
    }
    const dialog = this.dialog.open(CreateTrademarkFamilyDialogComponent, config);
    const result: { created: boolean, family?: Partial<TrademarkFamily> } = await firstValueFrom(dialog.afterClosed());
    if (result && result.created && result.family) {
      try {
        const toCreate: TrademarkFamilyCreate = {
          name: result.family.name,
          label: result.family.label,
          notes: result.family.notes,
          type: result.family.type,
          trademarks: []
        }
        await this.trademarkFamilyService.create(toCreate);
        this.toastr.success(this.translate.instant(`TRADEMARK_FAMILY.FAMILY_CREATED`, { name: toCreate.label }));
        await this.retrieveTrademarkFamilies(true);
      } catch (err) {
        this.toastr.error(`ERRORS.GENERIC`)
      }

    }
  }

}
