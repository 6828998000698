<div class="fx-row align-items-center justify-content-start" *ngIf="batch">
  <div [matTooltipClass]="{'uzai-tooltip':true}"
    [matTooltip]="'BATCH.STATUS_'+(batch.mode === 'NON_USE' ? 'DONE' : batch.status)+'_TOOLTIP' | translate"
    class="fx-row align-items-center justify-content-start mr-5">
    <mat-icon class="light" fontSet="material-icons-outlined" *ngIf="batch.status === 'OPENED'">cloud_upload</mat-icon>
    <mat-icon class="light" fontSet="material-icons-outlined" *ngIf="batch.status === 'PENDING'">pending</mat-icon>
    <mat-icon class="main spin" fontSet="material-icons-outlined" *ngIf="batch.status === 'PROCESSING'">sync</mat-icon>
    <mat-icon class="green " fontSet="material-icons-outlined"
      *ngIf="batch.status === 'PROCESSED'">check_circle</mat-icon>
    <mat-icon class="red" fontSet="material-icons-outlined" *ngIf="batch.status === 'ERROR'">error_outline</mat-icon>
  </div>
  <span class="text" *ngIf="batch.status === 'PROCESSING'">{{progress}}%</span>
  <span class="text" *ngIf="batch.status !== 'PROCESSING'">{{(batch.mode === 'NON_USE' ? 'BATCH.STATUS_DONE' :
    'BATCH.STATUS_'+batch.status) |
    translate}}</span>
</div>
<div class="progress-container fx-row align-items-center justify-content-start fx-flex-100"
  [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="progress + '%'">
  <div class="progress" [style.flex-basis.%]="progress">
  </div>
</div>