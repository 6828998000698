import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'src/app/services/message.service';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  user?: User;

  entries: {
    question: string,
    answer: string,
    scope: string,
    action: string
  }[] = [];
  filteredEntries: {
    question: string,
    answer: string,
    scope: string,
    action: string
  }[] = [];

  categories: string[] = [];

  faqQuery?: string;
  subscription: Subscription;
  private readonly subject = new Subject<string | undefined>();

  question?: string;
  phoneNumber?: string;


  errors: any = {
    question: {
      value: false,
      message: undefined
    },
    phoneNumber: {
      value: false,
      message: undefined
    }
  }


  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private http: HttpClient,
    private supportService: SupportService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
    });
    this.subscription = this.subject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.filterEntries();
      });
  }

  ngOnInit(): void {
    this._message.emitChange("NAV", "SECTION", {
      path: "support"
    });
    this.loadFAQ();
  }

  async loadFAQ() {
    const faq = await firstValueFrom(this.http.get("/assets/faq/faq.json")) as { action: string, scope: string, question: string, answer: string, lang: string }[];
    const hasLanguage = faq.some(entry => this.user && entry.lang === this.user.lang);
    if (hasLanguage) {
      this.entries = faq.filter(entry => this.user && entry.lang === this.user.lang)
    } else {
      this.entries = faq.filter(entry => entry.lang === "en")
    }
    this.categories = Object.keys(_.groupBy(this.entries, 'scope'));
    this.filteredEntries = this.entries;

  }

  filterEntries() {
    if (this.faqQuery !== undefined && this.faqQuery.length >= 3) {
      this.filteredEntries = this.entries.filter(entry => this.faqQuery &&
        (entry.action.toLowerCase().includes(this.faqQuery.toLowerCase())
          || entry.scope.toLowerCase().includes(this.faqQuery.toLowerCase())
          || entry.question.toLowerCase().includes(this.faqQuery.toLowerCase())
          || entry.answer.toLowerCase().includes(this.faqQuery.toLowerCase())))
    } else {
      this.filteredEntries = this.entries;
    }
  }

  onFAQQueryChange(event: any) {
    this.subject.next(this.faqQuery)
  }

  filterByCategory(category: string) {
    return this.filteredEntries.filter(entry => entry.scope === category)
  }

  _hasQuestionErrors() {
    this.errors.question = {
      value: false,
      message: undefined
    }
    if (!this.question) {
      this.errors.question.value = true;
      this.errors.question.message = this.translate.instant('ERRORS.QUESTION_REQUIRED');
    }

    return this.errors.question.value;
  }

  _hasPhoneNumberErrors() {

    this.errors.phoneNumber = {
      value: false,
      message: undefined
    }
    if (!this.phoneNumber) {
      this.errors.phoneNumber.value = true;
      this.errors.phoneNumber.message = this.translate.instant('ERRORS.PHONE_NUMBER_REQUIRED');
    }
    return this.errors.phoneNumber.value;
  }

  async askQuestion() {
    if (this._hasQuestionErrors()) {
      return;
    } else {
      try {
        if (this.question) {
          await this.supportService.askQuestion(this.question);
          this.question = undefined;
          this.toastr.success(this.translate.instant('SUPPORT.QUESTION_ASKED', { email: this.user?.email }));
        }

      } catch (err) {
        console.log(err)
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  async beCalledBack() {
    if (this._hasPhoneNumberErrors()) {
      return;
    } else {
      try {
        if (this.phoneNumber) {
          await this.supportService.phoneBack(this.phoneNumber);
          this.phoneNumber = undefined;
          this.toastr.success(this.translate.instant('SUPPORT.PHONE_BACK_REQUESTED', { phoneNumber: this.phoneNumber }));
        }

      } catch (err) {
        console.log(err)
        this.toastr.error(`ERRORS.GENERIC`)
      }
    }
  }

  downloadUserGuide() {
    const lang = this.user && this.user.lang === "fr" ? "fr" : "en";
    const toast = this.toastr.info('SUPPORT.DOWNLOAD_USER_GUIDE', undefined, {
      progressBar: true,
      disableTimeOut: true
    })
    const a = document.createElement('a')
    const objectUrl = lang === "fr" ? "https://uzaiassetsstaging.blob.core.windows.net/assets/UZAI GUIDE UTILISATION.pdf" : "https://uzaiassetsstaging.blob.core.windows.net/assets/UZAI USER GUIDE.pdf";
    a.href = objectUrl;
    a.target = "_blank";
    a.download = lang === "fr" ? "UZAI_GUIDE_UTILISATEUR.pdf" : "UZAI_USER_GUIDE.pdf";
    a.click();
    this.toastr.clear(toast.toastId);
    URL.revokeObjectURL(objectUrl);
  }
} 
