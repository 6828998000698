import { Injectable } from '@angular/core';
import { DataStore, TimeToLive } from './data-store.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Territory } from '../models/territory';

function store(ttlMinutes = TimeToLive.oneHour, forceRefresh = false) {
  function storeDecorator(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return target.store("Territory", propertyKey, descriptor, ttlMinutes, forceRefresh);
  }
  return storeDecorator;
}

@Injectable({
  providedIn: 'root'
})
export class TerritoryService extends DataStore {
  territories: Territory[] = [];

  constructor(
    private http: HttpClient
  ) {
    super();
    this.retrieve();
  }

  @store(TimeToLive.oneDay)
  async retrieve() {
    this.territories = await firstValueFrom(this.http
      .get<Territory[]>(`${environment.backend}/territories`));
    return this.territories;
  }

  retrieveOne(value: string): Territory | undefined {
    return this.territories.find((t) => (t.id === value || (t.alternateCodes && t.alternateCodes.includes(value))));
  }

}
