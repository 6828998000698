import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { DocumentClass } from 'src/app/models/documentClass';
import { MessageService } from 'src/app/services/message.service';

type DocumentClassOption = { selected: boolean, disabled?: boolean, display?: boolean, value: DocumentClass }

@Component({
  selector: 'app-document-class-select',
  templateUrl: './document-class-select.component.html',
  styleUrls: ['./document-class-select.component.scss']
})
export class DocumentClassSelectComponent implements OnInit, OnChanges {
  private id: string;
  @Input() label?: string;
  @Input() labelOutside: boolean = false;
  @Input() search: boolean = false;
  @Input() multiple: boolean = false;

  @Input() disabled: boolean = false;
  @Input() disabledValues: DocumentClass[] = [];
  @Input() emitOnInit: boolean = true;
  @Input() restrict: DocumentClass[] = [];
  @Input() noAutomatic: boolean = false;

  classes: DocumentClassOption[] = [];

  selected: DocumentClassOption[] = [];

  _filter?: string;

  @Output() change = new EventEmitter<any>();
  @Input() value?: DocumentClass | DocumentClass[];
  @Output() valueChange = new EventEmitter<any>();

  show: boolean = false;

  selection?: string;

  subscription: Subscription;

  @ViewChild('trigger') trigger?: CdkMenuTrigger;

  constructor(
    private _message: MessageService,
    private translate: TranslateService
  ) {
    this.id = _.uniqueId("SELECT-");
    this.subscription = this._message.changeEmitted$.subscribe(change => {
      if (change && change.key == "LAYOUT.SELECT") {
        (change.data.id !== this.id) && (this.show = false);
      }
    });
  }

  ngOnInit(): void {
    this.setInitialState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['restrict'] && !_.isEqual(changes['restrict']['currentValue'], changes['restrict']['previousValue'])) {
      this.retrieveClasses();
    }
  }

  _isDisabled(option: DocumentClass) {
    return this.disabledValues.includes(option);
  }

  _isSelected(option: DocumentClass) {
    return this.selected.map((d) => d.value).includes(option);
  }

  _documentClassOptionComparator(optionA: DocumentClassOption, optionB: DocumentClassOption) {
    return optionA.value === optionB.value;
  }

  populate() {
    if (this.multiple && this.value && (this.value as DocumentClass[]).length > 0) {
      const values = (this.value as DocumentClass[]);
      this.selected = values.map((value) => ({ value, selected: true, disabled: false, display: true }));
    } else if (!this.multiple && this.value) {
      const value = (this.value as DocumentClass);
      this.selected = [{ value, selected: true, disabled: false, display: true }];
    }
  }

  selectedToDisplay() {
    return this.selected.filter((o) => o.display);
  }

  isAllSelected() {
    return this.classes.every((docClass) => docClass.selected);
  }

  toggleAllSelection() {
    if (!this.isAllSelected()) {
      this.classes.forEach((docClass) => {
        docClass.selected = true;
      })
    } else {
      this.classes.forEach((docClass) => {
        docClass.selected = false;
      })
    }
    this.onChange();
  }

  async setInitialState() {
    await this.retrieveClasses();
    if (this.multiple && this.value && (this.value as DocumentClass[]).length > 0) {
      const values = (this.value as DocumentClass[]);
      this.selected = values.map((value) => ({ value, selected: true, disabled: false }));
      this.classes.forEach(docClass => {
        if (values.includes(docClass.value)) {
          docClass.selected = true;
        } else {
          docClass.selected = false;
        }
      });
      const value = _.unionWith([...this.classes.filter(c => c.selected), ...this.selected], this._documentClassOptionComparator);
      if (value.length > 0) {
        this.selection = value.length === 1 ? this.translate.instant(`DOCUMENT_CLASSES.${value[0].value.toUpperCase()}`) : `${this.label} (${value.length})`;
      } else {
        this.selection = undefined;
      }
    } else if (this.value) {
      const value = this.classes.find(c => c.value === this.value);
      if (value) {
        value.selected = true;
      }
      this.selection = value ? this.translate.instant(`DOCUMENT_CLASSES.${value.value.toUpperCase()}`) : undefined;
    } else {
      this.classes.forEach(docClass => {
        docClass.selected = false;
      });
    }
    if (this.emitOnInit) {
      this.onChange();
    }

  }

  removeFromPreselection(option: DocumentClassOption) {
    this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    const doc = this.classes.find((t) => t.value === option.value);
    if (doc) {
      doc.selected = false;
    }
    this.onChange();
  }

  toggleFromSelection(option: DocumentClassOption) {
    if (!option.selected) {
      this.selected.splice(this.selected.findIndex((o) => option.value === o.value), 1);
    } else if (!this._isSelected(option.value)) {
      this.selected.push({ ...option, display: false });
    }
  }

  async retrieveClasses() {
    const classes = Object.keys(DocumentClass).filter(key => (this.noAutomatic ? key !== DocumentClass.AUTOMATIC : true)).map(key => {
      const value = DocumentClass[key as keyof typeof DocumentClass];
      return {
        value: DocumentClass[key as keyof typeof DocumentClass],
        selected: this._isSelected(value),
        disabled: this._isDisabled(value)
      }
    })
    if (this.restrict && this.restrict.length > 0) {
      this.classes = classes.filter((docClass) => this.restrict.includes(docClass.value))
    } else {
      this.classes = classes;
    }
  }

  select(option: any) {
    option.selected = option.selected !== null ? !option.selected : true;
    this.toggleFromSelection(option)
    if (!this.multiple) {
      this.classes.forEach(c => {
        if (c.value !== option.value) {
          c.selected = false;
        }
      })
    }
    this.onChange();
  }

  onChange() {
    if (!this.multiple) {
      const value = this.classes.find(c => c.selected) !== undefined ? this.classes.find(c => c.selected) : (this.selected.length === 1 ? this.selected[0] : undefined);
      this.selection = value ? this.translate.instant(`DOCUMENT_CLASSES.${value.value.toUpperCase()}`) : undefined;
      this.valueChange.emit(value?.value as DocumentClass);
      this.change.emit({ value });
      this.trigger?.close();
      this.value = value?.value;

    } else {
      const values = _.unionWith([...this.classes.filter(c => c.selected), ...this.selected], this._documentClassOptionComparator);
      this.value = values.map(c => c.value) as DocumentClass[];
      if (values.length > 0) {
        this.selection = values.length === 1 ? this.translate.instant(`DOCUMENT_CLASSES.${values[0].value.toUpperCase()}`) : `${this.label} (${values.length})`;
      } else {
        this.selection = undefined;
      }
      this.valueChange.emit(values.map(c => c.value) as DocumentClass[]);
      this.change.emit({ value: values });
    }

  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this._message.emitChange("LAYOUT", "SELECT", {
        id: this.id
      });
    }
  }

  filtered() {
    return (this._filter && this._filter.length > 0) ? this.classes.filter(docClass => {
      return docClass.value.toString().includes(this._filter as string) || this.translate.instant(`DOCUMENT_CLASSES.${docClass.value.toUpperCase()}`).toLowerCase().includes(this._filter?.toLowerCase())
    }) : this.classes;
  }

  onSearch(event: any) {
    if (event && event.hasOwnProperty('value')) {
      this._filter = event.value;
    }
  }

  onClear() {
    if (this.multiple) {
      this.value = [];
    } else {
      this.value = undefined;
    }
    this.classes.forEach(docClass => {
      docClass.selected = false;
    });
    this.selected = [];
    this.selection = undefined
    this._filter = undefined;
    this.onChange();
    this.trigger?.close();
  }
}
