import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DocumentMetadata, MetadataType } from 'src/app/models/documentMetadata';

@Component({
  selector: 'app-document-users',
  templateUrl: './document-users.component.html',
  styleUrls: ['./document-users.component.scss']
})
export class DocumentUsersComponent {
  @Input() metadata?: DocumentMetadata[] = [];
  @Input() users: string[] = [];
  @Input() shown: boolean = false;
  limit: number = 3;

  focused: boolean = false;
  @Output() focus = new EventEmitter<boolean>();


  constructor(
  ) { }

  ngOnInit(): void {
  }


  getConfidence(user: string) {
    return (this.metadata?.find(datum => datum.type === MetadataType.USER && datum.value === user.replace(' ', '_'))?.confidence ?? 0) * 100
  }

  toggle() {
    if (this.limit === 3) {
      this.limit = this.users.length;
    } else {
      this.limit = 3;
    }
  }

  enterFocus() {
    this.focused = true;
    this.focus.emit(true);
  }

  leaveFocus() {
    this.focused = false;
    this.focus.emit(false);
  }
}
