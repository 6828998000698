<div class="fx-row fx-wrap align-items-center justify-content-start  fx-flex-100">
  <div class="fx-row fx-wrap align-items-start justify-content-between fx-flex-100" *ngIf="organization">
    <div class="fx-column align-items-start justify-content-start">
      <span class="title bold">{{'ORGANIZATION.IAM_TAB' | translate}}</span>
      <span class="text light">{{'ORGANIZATION.IAM_HINT' | translate}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-end">
      <button class="main-button ml-10" (click)="invite()" aclBasedElement resource="USER" [scopes]="['C']">
        <div class="fx-row align-items-center justify-content-center">
          <mat-icon class="white mr-5" fontSet="material-icons-outlined">person_add</mat-icon>
          {{'ORGANIZATION.INVITE_USER' | translate}}
        </div>
      </button>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <table mat-table [dataSource]="users.docs" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="avatar">
      <th mat-header-cell *matHeaderCellDef>
        <span class="small light bold">
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="fx-row align-items-center justify-content-start">
          <app-avatar [user]="user" [size]="30"></app-avatar>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'USER.LASTNAME' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <span class="text">{{user.lastName}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'USER.FIRSTNAME' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <span class="text">{{user.firstName}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'USER.EMAIL' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="fx-row align-items-center justify-content-start">
          <a [href]="'mailto:'+user.email">{{user.email}}</a>
          <mat-icon class="dark ml-5 clickable" inline fontSet="material-icons-outlined" [cdkMenuTriggerFor]="menu"
            *ngIf="user.invitedBy && user.invitedBy.firstName && user.invitedBy.lastName">info</mat-icon>
          <ng-template #menu>
            <mat-card class="menu-container p-8 elevation" cdkMenu
              *ngIf="user.invitedBy && user.invitedBy.firstName && user.invitedBy.lastName">
              <span class="small dark">{{'USER.INVITED_BY' | translate}}</span>
              <div class="fx-row justify-content-start align-items-center">
                <app-avatar [user]="user.invitedBy" [size]="20"></app-avatar>
                <span class="small ml-5">{{user.invitedBy.firstName}} {{user.invitedBy.lastName}}</span>
              </div>
            </mat-card>
          </ng-template>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="profile">
      <th mat-header-cell *matHeaderCellDef>
        <span class=" small light bold">
          {{'USER.PROFILE' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <app-user-profile [role]="user.role"></app-user-profile>
      </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <span class=" small light bold">
          {{'USER.REGISTRATION' | translate}}
        </span>
      </th>
      <td mat-cell *matCellDef="let user">
        <span class="text light" *ngIf="user.passwordVerified">{{formatDate(user.createdAt)}}</span>
        <div *ngIf="!user.passwordVerified" class="fx-row fx-wrap align-items-center justify-content-start">
          <mat-icon class="light mr-5" fontSet="material-icons-outlined" inline>email</mat-icon>
          <span class="small light bold">{{'USER.PENDING_INVITE' | translate}}</span>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="fx-row align-items-center justify-content-end">
          <button mat-icon-button (click)='edit(user)' [disabled]="!canEdit(user)"
            [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="editTooltip(user)">
            <mat-icon class="main mr-5" [class.lighter]="!canEdit(user)"
              fontSet="material-icons-outlined">edit</mat-icon>
          </button>
          <button mat-icon-button [disabled]='!canDelete(user)' (click)='delete(user)'
            [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.DELETE' | translate">
            <mat-icon [class]="canDelete(user) ? 'red' : 'lighter'" fontSet="material-icons-outlined">delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>
  <div class="p-16" *ngIf="users.totalDocs === 0">
    <span class="text light">{{'ORGANIZATION.SEARCH_NO_USERS' | translate}}</span>
  </div>
  <div class="fx-flex align-items-center justify-content-end">
    <mat-paginator [length]="users.totalDocs || 0" [pageSize]="users.limit || 0"
      [pageIndex]="users.page ? (users.page-1) : 0" [pageSizeOptions]="[10, 25, 50, 100]" (page)="handlePage($event)">
    </mat-paginator>
  </div>
</div>