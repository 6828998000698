import { Component } from '@angular/core';
import { UploadFile, UploadFileStatus } from 'src/app/models/uploadFile';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-uploading-documents-box',
  templateUrl: './uploading-documents-box.component.html',
  styleUrls: ['./uploading-documents-box.component.scss']
})
export class UploadingDocumentsBoxComponent {

  constructor(
    public documentService: DocumentService
  ) {

  }


  status() {
    const uploading = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.PENDING, UploadFileStatus.UPLOADING].includes(doc.status)).length;
    const errored = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.ERROR].includes(doc.status)).length;
    const done = this.documentService.uploadDocuments.filter((doc) => doc.status && [UploadFileStatus.DONE].includes(doc.status)).length;

    if (uploading > 0) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_IN_PROGRESS',
        loading: true,
        done: false,
        error: false
      };
    } else if (errored > 0 && uploading === 0) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_DONE_WITH_ERRORS',
        loading: false,
        done: true,
        error: true
      };
    } else if (done > 0 && uploading === 0 && done === this.documentService.uploadDocuments.length) {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_DONE',
        loading: false,
        done: true,
        error: false
      };
    } else {
      return {
        label: 'UPLOAD_DOCUMENTS.UPLOAD_UNKNOWN',
        loading: false,
        done: false,
        error: false
      };
    }
  }

  progress() {
    const total = this.documentService.uploadDocuments.length * 100;
    let progress = 0;
    this.documentService.uploadDocuments.forEach((doc: UploadFile) => {
      progress += (doc.progress ?? 0);
    })
    return Math.round(100 * progress / total);
  }

}
