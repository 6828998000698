<div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class="chip main" *ngIf="case && case.phase">{{'CASE.PHASE_'+case.phase.name | translate}}</div>
  <div class="chip light" *ngIf="case && !case.phase">{{'CASE.PHASE_UNKNOWN' | translate}}</div>
  <div class="chip light ml-10" *ngIf="isSuspended">{{'CASE.SUSPENDED' | translate}}
  </div>
  <div class="chip ml-10"
    *ngIf="case && case.phase && case.phase.name === 'CLOSED' && closedMeta && !closedMeta['withdrawal'] && mode === 'full'"
    [class.green]="closedMeta['decision'] === 'WON'" [class.orange]="closedMeta['decision'] === 'MITIGATED'"
    [class.red]="closedMeta['decision'] === 'LOST'" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CASE.CLOSED_'+closedMeta['decision'] |
    translate">
    {{'CASE.CLOSED_'+closedMeta['decision'] |
    translate}}
  </div>
  <div class="bullet ml-10"
    *ngIf="case && case.phase && case.phase.name === 'CLOSED' && closedMeta && !closedMeta['withdrawal'] && mode === 'light'"
    [class.green]="closedMeta['decision'] === 'WON'" [class.orange]="closedMeta['decision'] === 'MITIGATED'"
    [class.red]="closedMeta['decision'] === 'LOST'" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CASE.CLOSED_'+closedMeta['decision'] |
      translate">
  </div>
  <div class="fx-row align-items-center justify-content-start ml-10"
    *ngIf="case && case.phase && case.phase.name === 'CLOSED' && closedMeta && !closedMeta['withdrawal']">
    <mat-icon class="mr-5" [class.green]="closedMeta['definitive']" [class.orange]="!closedMeta['definitive']"
      fontSet="material-icons-outlined" [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="(closedMeta['definitive'] ? 'CASE.DEFINITIVE' : 'CASE.NOT_DEFINITIVE') |
    translate">{{closedMeta['definitive'] ? 'check_circle' :
      'error_outline'}}</mat-icon>
    <span class="text" *ngIf="mode === 'full'">{{(closedMeta['definitive'] ? 'CASE.DEFINITIVE' : 'CASE.NOT_DEFINITIVE')
      |
      translate}}</span>
  </div>
  <div class="chip light ml-10"
    *ngIf="mode === 'full' && case && case.phase && case.phase.name === 'CLOSED' && closedMeta && closedMeta['withdrawal']">
    {{'CASE.WITHDRAWN' | translate}}
  </div>
</div>