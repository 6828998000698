import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BatchPopulatedDocument } from 'src/app/models/batch';
import { BatchService } from 'src/app/services/batch.service';
import { DocumentService } from 'src/app/services/document.service';

@Component({
  selector: 'app-batch-documents',
  templateUrl: './batch-documents.component.html',
  styleUrls: ['./batch-documents.component.scss']
})
export class BatchDocumentsComponent implements OnInit, OnChanges {

  @Input() documents: BatchPopulatedDocument[] = [];
  @Input() selection = new SelectionModel<BatchPopulatedDocument>(true, []);
  @Output() selectionChange = new EventEmitter<SelectionModel<BatchPopulatedDocument>>();
  filteredDocuments: BatchPopulatedDocument[] = [];
  displayedColumns: string[] = ['select', 'name', 'class', 'status', 'actions'];

  sort: { active: string, direction: string } = { active: 'name', direction: 'asc' };

  constructor(
    public batchService: BatchService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.retrieveDocuments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.retrieveDocuments();
  }

  retrieveDocuments() {
    this.filteredDocuments = this.documents.sort(this.sortDocuments.bind(this)).slice(this.batchService.pager.offset, this.batchService.pager.offset + this.batchService.pager.limit);
  }

  sortDocuments(a: BatchPopulatedDocument, b: BatchPopulatedDocument) {
    if (this.sort.active === 'name') {
      return this.sort.direction === 'asc' ? (a.name.localeCompare(b.name)) : (b.name.localeCompare(a.name));
    } else if (this.sort.active === 'class') {
      return this.sort.direction === 'asc' ? (this.translate.instant(`DOCUMENT_CLASSES.${a.class.toUpperCase()}`).localeCompare(this.translate.instant(`DOCUMENT_CLASSES.${b.class.toUpperCase()}`))) : (this.translate.instant(`DOCUMENT_CLASSES.${b.class.toUpperCase()}`).localeCompare(this.translate.instant(`DOCUMENT_CLASSES.${a.class.toUpperCase()}`)));
    }
  }

  flushSelection() {
    this.selection = new SelectionModel<BatchPopulatedDocument>(true, []);
    this.selectionChange.emit(this.selection);
  }

  hasPageSelection() {
    return this.documents.some(row => this.isSelected(row))
  }

  isSelected(row: BatchPopulatedDocument) {
    return this.selection.selected.map(doc => doc._id).includes(row._id)
  }

  isAllSelected() {
    return this.documents.every(row => this.isSelected(row));
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      const difference = _.differenceBy(this.selection.selected, this.documents, '_id')
      this.selection.setSelection(...difference)
      this.selectionChange.emit(this.selection);
      return;
    }
    this.selection.setSelection(...[...this.selection.selected, ...this.documents.filter(row => !this.isSelected(row))])
    this.selectionChange.emit(this.selection);
  }

  toggle(row: BatchPopulatedDocument) {
    if (this.isSelected(row)) {
      this.selection.setSelection(...this.selection.selected.filter(doc => doc._id !== row._id))
    } else {
      this.selection.setSelection(...[...this.selection.selected, row])
    }
    this.selectionChange.emit(this.selection);
  }

  onSortChange(event: any) {
    this.sort = event;
    this.batchService.pager.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
    this.retrieveDocuments();
  }

  async handlePage(event: any) {
    this.batchService.pager.limit = event.pageSize;
    this.batchService.pager.page = event.pageIndex;
    this.batchService.pager.offset = event.pageIndex * this.batchService.pager.limit;
    this.retrieveDocuments();
  }

  documentIcon(document: Document) {
    return this.documentService.icon(document);
  }

  toDocument(document: any) {
    this.router.navigate(['documents', document._id], {
      state: {
        from: "BATCH"
      }
    })
  }
}
