<div class="fx-row fx-wrap align-items-center justify-content-start  fx-flex-100">
  <div class="fx-row fx-wrap align-items-start justify-content-between fx-flex-100" *ngIf="organization">
    <div class="fx-column align-items-start justify-content-start">
      <span class="title bold">{{'ORGANIZATION.INFORMATION_TAB' | translate}}</span>
      <span class="text light" roleBasedElement [roles]="['SUPERADMIN','ADMIN']">{{'ORGANIZATION.INFORMATION_HINT' |
        translate}}</span>
      <span class="text light" roleBasedElement
        [roles]="['CASE_OPERATOR','COUNTRY_OPERATOR','MANAGER']">{{'ORGANIZATION.INFORMATION_RO_HINT' |
        translate}}</span>
    </div>
    <div class="fx-row align-items-center justify-content-end" aclBasedElement resource="ORGANIZATION" [scopes]="['U']">
      <button class="button" (click)="cancel()">
        {{'ACTIONS.CANCEL' | translate}}
      </button>
      <button class="main-button ml-10" (click)="update()" [disabled]="isUpdateDisabled()"
        [class.disabled]="isUpdateDisabled()">
        {{'ACTIONS.SAVE_CHANGES' | translate}}
      </button>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="organization">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'ORGANIZATION.NAME' | translate}}</span>
      <span class="text light">{{'ORGANIZATION.NAME_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'ORGANIZATION.NAME' | translate" type="text" class="fx-flex-100"
        [(value)]="organization.name" roleBasedElement [roles]="['SUPERADMIN','ADMIN']"></app-input>
      <span class="text" roleBasedElement
        [roles]="['CASE_OPERATOR','COUNTRY_OPERATOR','MANAGER']">{{organization.name}}</span>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="organization">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'ORGANIZATION.HEADQUARTERS' | translate}}</span>
      <span class="text light">{{'ORGANIZATION.HEADQUARTERS_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-input [placeholder]="'ORGANIZATION.HEADQUARTERS' | translate" type="text"
        [(value)]="organization.headquartersAddress" class="fx-flex-100" roleBasedElement
        [roles]="['SUPERADMIN','ADMIN']"></app-input>
      <span class="text" roleBasedElement
        [roles]="['CASE_OPERATOR','COUNTRY_OPERATOR','MANAGER']">{{organization.headquartersAddress}}</span>
    </div>
  </div>
  <mat-divider class="mv-16 fx-flex-100"></mat-divider>
  <div class="fx-row fx-wrap align-items-start justify-content-start fx-flex-100" *ngIf="organization">
    <div class="fx-flex-40 fx-column align-items-start justify-content-start">
      <span class="text bold">{{'ORGANIZATION.LOGO' | translate}}</span>
      <span class="text light">{{'ORGANIZATION.LOGO_HINT' | translate}}</span>
    </div>
    <div class="fx-flex-60 fx-row align-items-start justify-content-start">
      <app-upload-picture [src]="organization.logo" [height]="150" class="fx-flex"
        (pictureChange)="onLogoChange($event)" roleBasedElement [roles]="['SUPERADMIN','ADMIN']"></app-upload-picture>
      <img [src]="organization.logo" style="max-height:150px;" roleBasedElement
        [roles]="['CASE_OPERATOR','COUNTRY_OPERATOR','MANAGER']" />
    </div>
  </div>
</div>