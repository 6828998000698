import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Case, CaseStatus } from 'src/app/models/case';
import { Paginator } from 'src/app/models/paginator';
import { Trademark } from 'src/app/models/trademark';
import { Scope } from 'src/app/services/acl.service';
import { CaseService } from 'src/app/services/case.service';
import { MessageService } from 'src/app/services/message.service';

interface CaseFilters {
  query?: string,
  trademark: {
    identifierNumber: string,
    countryOfDesignation: string
  }[],
  phase: CaseStatus[],
  date: Moment
}

@Component({
  selector: 'app-cases-list',
  templateUrl: './cases-list.component.html',
  styleUrls: ['./cases-list.component.scss']
})
export class CasesListComponent implements OnInit {
  loading: boolean = false;
  done: boolean = false;
  noDocs: boolean = false;
  // TODO: find a better way to retrieve cases by current phase
  cases: Paginator<Case> = {
    docs: [],
    page: 1,
    offset: 0,
    limit: 999,
    totalDocs: 0,
    sort: '-createdAt'
  };
  filteredCases: Case[] = [];
  casesByPhase: { [status: string]: Case[] } = {
    'INSTRUCTION': [],
    'DECISION': [],
    'CLOSED': []
  }
  displayedColumns: string[] = ['reference', 'status', 'trademark', 'nice', 'period', 'score', 'actions'];

  phases: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "INSTRUCTION",
        label: "CASE.PHASE_INSTRUCTION",
        selected: false
      },
      {
        value: "DECISION",
        label: "CASE.PHASE_DECISION",
        selected: false
      },
      {
        value: "CLOSED",
        label: "CASE.PHASE_CLOSED",
        selected: false
      }
    ];

  filters: CaseFilters = {
    query: undefined,
    trademark: [],
    phase: [],
    date: moment()
  }

  private readonly filtersSubject = new Subject<CaseFilters>();
  private filtersSubscription?: Subscription;

  noCaseButton: {
    label: string;
    icon: string;
    class: string;
    action: Function
  } = {
      label: 'CASES_LIST.NEW',
      icon: 'add_circle_outline',
      class: 'main-button',
      action: () => { this.router.navigate(['new'], { relativeTo: this.route }) }
    };


  constructor(
    private _message: MessageService,
    private caseService: CaseService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.filtersSubscription = this.filtersSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      ).subscribe(async () => {
        this.resetPage();
        await this.retrieveCases(false);
      });
  }

  async ngOnInit() {
    this.loading = true;
    this.done = false;
    await this.retrieveCases(true);
    this.loading = false;
    this.done = true;
  }

  async retrieveCases(init: boolean) {
    try {
      this._message.emitChange("LOADING", "START");

      this.cases = { ...this.cases, ...await this.caseService.retrieveAll(this.cases.page, this.cases.limit, this.cases.offset, this.cases.sort || '-createdAt', this.filters.trademark, this.filters.phase, this.filters.date, this.filters.query) };

      this.filteredCases = this.cases.docs.map((caseObject: Case) => ({ ...caseObject, phase: this.caseService._currentPhase(caseObject) }));

      this.casesByPhase['INSTRUCTION'] = this.filteredCases.filter((c: Case) => c.phase?.name === CaseStatus.INSTRUCTION).sort(this._caseComparator);
      this.casesByPhase['DECISION'] = this.filteredCases.filter((c: Case) => c.phase?.name === CaseStatus.DECISION).sort(this._caseComparator);
      this.casesByPhase['CLOSED'] = this.filteredCases.filter((c: Case) => c.phase?.name === CaseStatus.CLOSED).sort(this._caseComparator);



      if (init && this.cases.totalDocs === 0) {
        this.noDocs = true;
      } else if (init && this.cases.totalDocs > 0) {
        this.noDocs = false;
      }

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  _caseComparator(a: Case, b: Case) {
    return (a.phase && a.phase.endsIn ? a.phase.endsIn : -1) - (b.phase && b.phase.endsIn ? b.phase.endsIn : -1)
  }

  resetPage() {
    this.cases.limit = 10;
    this.cases.page = 1;
    this.cases.offset = 0;
  }

  async handlePage(event: any) {
    this.cases.limit = event.pageSize;
    this.cases.page = event.pageIndex + 1;
    this.cases.offset = event.pageIndex * this.cases.limit;
    this.retrieveCases(false);
  }

  onTrademarkChange(event: any) {
    this.filters.trademark = event.value;
    console.log(this.filters.trademark)
    this.filtersSubject.next({ ...this.filters });
  }

  onPhaseChange(event: any) {
    this.filters.phase = event.value;
    this.filtersSubject.next({ ...this.filters });
  }

  onQueryChange(query: any) {
    this.filtersSubject.next({ ...this.filters });
  }

  onSortChange(event: any) {
    if (['asc', 'desc'].includes(event.direction)) {
      this.cases.sort = `${event.direction === 'desc' ? '-' : ''}${event.active}`;
      this.retrieveCases(false);
    }
  }

  edit(caseObject: Case) {
    this.router.navigate([caseObject._id], {
      relativeTo: this.route,
      state: {
        from: "CASES",
        mode: "EDITION"
      }
    })
  }

  async delete(caseObject: Case) {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'CASE.DELETE_CASE_TITLE',
        text: this.translate.instant('CASE.DELETE_CASE_TEXT', { reference: caseObject.identifier || caseObject.reference }),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        },
        confirmation: {
          text: 'CASE.DELETE_CASE_CONFIRMATION',
          value: caseObject.identifier || caseObject.reference
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && caseObject) {
      try {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        await this.caseService.delete(caseObject._id!);
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.resetPage();
        this.retrieveCases(true);
        this.toastr.success(this.translate.instant('CASE.DELETE_CASE_SUCCESS', { reference: caseObject.identifier || caseObject.reference }));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  toTrademark(trademark: any) {
    if (trademark && trademark.ref) {
      this.router.navigate(['trademarks', trademark.ref], {
        state: {
          from: "CASES"
        }
      })
    }
  }

  formatYear(date: string) {
    return moment(date).format("YYYY");
  }

  formatDate(date: string | Moment) {
    return moment(date).format("ll");
  }
}
