import * as moment from "moment";
import { Moment } from "moment";

export enum TrademarkStatus {
  FILED = 'FILED',
  REGISTERED = 'REGISTERED',
  ENDED = 'ENDED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN'
}

export enum TrademarkType {
  SHAPE_3D = '3D shape',
  COLOUR = 'Colour',
  COLOURS_COMBINATION = 'Combination of colours',
  COMBINED = 'Combined',
  FIGURATIVE = 'Figurative',
  GENERAL = 'General mark',
  HOLOGRAM = 'Hologram',
  KENNFADEN = 'Kennfaden',
  MOTION = 'Motion',
  MULTIMEDIA = 'Multimedia',
  NUMBER = 'Number',
  OLFACTORY = 'Olfactory',
  OTHER = 'Other',
  PACKING = 'Packing',
  PATTERN = 'Pattern',
  POSITION = 'Position',
  SOUND = 'Sound',
  STYLIZED_CHARACTERS = 'Stylized characters',
  UNDEFINED = 'Undefined',
  WORD = 'Word'
}


export interface Trademark {
  _id?: string;
  identifierNumber: string;
  registrationNumber?: string;
  applicationNumber?: string;
  countryOfDesignation: string;
  kind?: string;
  type?: string;
  ST13?: string;
  niceClassification?: {
    classNumber: number,
    details?: string
  }[];
  applicationDate?: Moment;
  publicationDate?: Moment;
  registrationDate?: Moment;
  expirationDate?: Moment;
  reproductionURI?: string;
  name?: string;
  status?: TrademarkStatus;
  organizationId?: string;
  propertyOffice?: {
    ref: string;
    name: string;
    code: string;
    logo?: string;
  };
}

export interface TrademarkSearch {
  ST13: string,
  applicationNumber: string,
  tmOffice: string,
  tradeMarkStatus?: string,
  markImageURI?: string,
  detailImageURI?: string,
  tmName: string,
  niceClass: number[],
  isDesignation: boolean,
  tradeMarkType: string
}
