import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { NiceClass } from '../models/niceClass';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DataStore, TimeToLive } from './data-store.service';

function store(ttlMinutes = TimeToLive.oneHour, forceRefresh = false) {
  function storeDecorator(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return target.store("Nice", propertyKey, descriptor, ttlMinutes, forceRefresh);
  }
  return storeDecorator;
}

@Injectable({
  providedIn: 'root'
})
export class NiceService extends DataStore {
  classes: NiceClass[] = [];

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  @store(TimeToLive.oneDay)
  async retrieve() {
    return await firstValueFrom(this.http
      .get<NiceClass[]>(`${environment.backend}/nice/classes`));
  }

  retrieveOne(value: number) {
    return this.classes.find((c) => c.classNumber === value)
  }
}
