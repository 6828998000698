import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit, AfterViewInit {
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() type?: string;
  @Input() prefix?: string;
  @Input() suffix?: string;
  @Input() pattern?: string;
  @Input() class?: string;
  @Input() hint?: string;
  @Input() error: { value: boolean, message?: string } = {
    value: false,
    message: undefined
  };
  @Input() bottomWrapper: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoFocus: boolean = false;

  public focus: boolean = false;
  private _pattern?: RegExp;

  shown: boolean = false;

  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();
  valueUpdate: Subject<any> = new Subject<any>();

  @Output() change = new EventEmitter<any>();

  @ViewChild("input") input?: ElementRef;

  constructor() {
  }

  ngOnInit() {

    if (this.pattern) {
      this._pattern = new RegExp(this.pattern);
    }
    this.valueUpdate
      .pipe(debounceTime(100), distinctUntilChanged())
      .subscribe((value) => {
        this.valueChange.emit(this.value);
        this.change.emit(this.value);
      })
  }

  ngAfterViewInit(): void {
    if (this.input && this.autoFocus) {
      this.focus = true;
      this.input.nativeElement.focus();
    }
  }

}
