import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Organization } from '../models/organization';
import { Paginator } from '../models/paginator';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private http: HttpClient
  ) { }


  invite(user: {
    firstName?: string,
    lastName?: string,
    email?: string,
    role?: string
  }) {
    return firstValueFrom(this.http
      .post<User>(`${environment.backend}/admin/invite`, user));
  }

  removeFromOrganization(userId: string) {
    return firstValueFrom(this.http
      .delete<any>(`${environment.backend}/organizations/users/${userId}`));
  }

  mine(): Promise<Organization> {
    return firstValueFrom(this.http
      .get<Organization>(`${environment.backend}/organizations/me`));
  }

  uploadLogo(logo: File): Promise<{ url: string }> {
    const formData = new FormData();
    formData.append("image", logo);
    return firstValueFrom(this.http.post<{ url: string }>(`${environment.backend}/upload/organizationLogo`, formData));
  }

  update(organization: Organization): Promise<Organization> {
    return firstValueFrom(this.http.put<Organization>(`${environment.backend}/organizations/me`, {
      name: organization.name,
      logo: organization.logo,
      headquartersAddress: organization.headquartersAddress
    }));
  }

  countUsers(): Promise<{ count: number }> {
    return firstValueFrom(this.http
      .get<{ count: number }>(`${environment.backend}/organizations/users/count`));
  }

  retrieveAllUsers(pageNumber: number, limit: number, offset: number, sort?: string, query?: string): Promise<Paginator<User>> {
    let params = new HttpParams()
      .set('page', pageNumber)
      .set('limit', limit)
      .set('offset', offset)

    if (sort) {
      params = params.set('sort', sort);
    }

    if (query) {
      params = params.set('q', query);
    }

    return firstValueFrom(this.http
      .get<Paginator<User>>(`${environment.backend}/organizations/users`, { params }));
  }

}
