import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { DocumentType } from 'src/app/models/document';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/services/document.service';

interface TrademarkData {
  _id: {
    ref: string,
    name: string,
    identifierNumber: string,
    countryOfDesignation: string
  },
  count: number
}

@Component({
  selector: 'app-graph-documents-trademark',
  templateUrl: './graph-documents-trademark.component.html',
  styleUrls: ['./graph-documents-trademark.component.scss']
})
export class GraphDocumentsTrademarkComponent {
  @Input() data: TrademarkData[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 300;

  ready: boolean = false;

  @Input() error: boolean = false;
  @Input() noData: boolean = false;

  @ViewChild('graphtrademarkdiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.XYChart;

  constructor(
    private zone: NgZone,
    private documentService: DocumentService,
    private translate: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {

  }



  ngAfterViewInit(): void {
    setTimeout(() => {
      this.makeChart();
    }, 100)
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!this.loading) {
      setTimeout(() => {
        this.makeChart();
      }, 100)
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  _processData() {
    const data = this.data.sort((a, b) => b.count - a.count).map((datum) => ({ ref: datum._id.ref, name: datum._id.name, identifierNumber: datum._id.identifierNumber, countryOfDesignation: datum._id.countryOfDesignation, count: datum.count }));
    const tops = data.slice(0, Math.min(5, this.data.length));
    return tops;
  }

  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      if (!this.chart && this.chartElement) {

        var chart = am4core.create(this.chartElement.nativeElement, am4charts.XYChart);
        chart.logo.setVisibility(false);

        chart.data = this._processData();

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "ref";
        categoryAxis.renderer.minGridDistance = 1;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.fontSize = 8;
        // categoryAxis.renderer.labels.template.fill = am4core.color("#13022B");
        categoryAxis.renderer.labels.template.html = "<div style='display:flex;flex-direction:column;align-items:flex-end;justify-content:flex-start;'><span class='smaller dark bold'>{name}</span><span class='smaller light'>({countryOfDesignation} - {identifierNumber})</span></div>"
        // categoryAxis.renderer.labels.template.height = 30;
        // categoryAxis.renderer.labels.template.maxHeight = 30;

        var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        // valueAxis.renderer.labels.template.fill = am4core.color("#747479");
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fontWeight = 'bold';

        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryY = "ref";
        series.dataFields.valueX = "count";
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        // series.columns.template.fill = am4core.color("#8847E3");
        series.columns.template.tooltipText = `{count} ${this.translate.instant('ENTITIES.DOCUMENTS').toLowerCase()}`


        // categoryAxis.sortBySeries = series;

        this.chart = chart;
      } else if (this.chartElement && this.chart) {
        this.chart.data = this._processData();
      }

    })
  }
}
