<div class="fx-row align-items-center justify-content-start ph-16 pb-8 pt-16 " style="height:20px;">
  <a class="small" (click)="back(1)">{{getLevelLabel(first)}}</a>
  <mat-icon class="main mh-5" *ngIf="level >= 2" fontSet="material-icons-outlined" inset inline>navigate_next</mat-icon>
  <a class="small" (click)="back(2)" *ngIf="level >= 2"> {{getValueLabel('first',first)}}</a>
  <mat-icon class="main mh-5" *ngIf="level >= 3" fontSet="material-icons-outlined" inset inline>navigate_next</mat-icon>
  <a class="small" (click)="back(3)" *ngIf="level >= 3"> {{getValueLabel('second',second)}}</a>
  <mat-icon class="main mh-5" *ngIf="level >= 4" fontSet="material-icons-outlined" inset inline>navigate_next</mat-icon>
  <a class="small" *ngIf="level >= 4">{{getValueLabel('third',third)}}</a>
</div>
<div class="fx-row fx-wrap align-items-center justify-content-start">
  <div class="fx-row fx-flex-100 align-items-center justify-content-start ph-16 pt-8 pb-16 border-bottom">
    <div class="fx-row align-items-center justify-content-start">
      <span class="small bold light">{{getColumnLabel()}}</span>
      <mat-icon class="ml-5 light clickable" fontSet="material-icons-outlined" inline inset (click)="sort()">{{page.sort
        === 'data' ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
    </div>
  </div>
  <div *ngFor="let datum of data; let index = index"
    class="fx-row fx-flex-100 align-items-center justify-content-between actionable clickable p-16 border-bottom"
    (click)="open(datum)">
    <div class="fx-row align-items-center justify-content-start" *ngIf="level < 4">
      <img src="/assets/icons/folder.svg" width="30" height="30" />
      <ng-container [ngSwitch]="current">
        <div class="fx-column ml-10" *ngSwitchCase="'trademark'">
          <div class="fx-column" *ngIf="datum._id.name">
            <span class="text" *ngIf="_validateDatum(datum)">{{datum._id.name}}</span>
            <span class="small light" *ngIf="_validateDatum(datum)">{{datum._id.countryOfDesignation}} -
              {{datum._id.identifierNumber}}</span>
          </div>
          <div class="fx-column" *ngIf="!datum._id.name">
            <span class="text" *ngIf="_validateDatum(datum)">{{datum._id.identifierNumber}}</span>
            <span class="small light" *ngIf="_validateDatum(datum)">{{datum._id.countryOfDesignation}}</span>
          </div>
          <span class="text light" *ngIf="!_validateDatum(datum)">-</span>
        </div>
        <div class="ml-10 fx-row align-items-center justify-content-start" *ngSwitchCase="'territory'">
          <img *ngIf="_validateDatum(datum)" [src]="'/assets/countries/'+datum._id.toLowerCase()+'.svg'"
            class="country-flag mr-10" />
          <span class="text" *ngIf="_validateDatum(datum)">{{'TERRITORIES.'+datum._id.toUpperCase() | translate}}</span>
          <span class="text light" *ngIf="!_validateDatum(datum)">-</span>
        </div>
        <span class="text ml-10" *ngSwitchCase="'year'">{{datum._id}}</span>
        <div class="ml-10" *ngSwitchCase="'language'">
          <span class="text" *ngIf="_validateDatum(datum)">{{'LANGUAGES.'+datum._id.toLowerCase() |
            translate}}</span>
          <span class="text light" *ngIf="!_validateDatum(datum)">-</span>
        </div>
        <span class="text ml-10" *ngSwitchCase="'nice'">{{'NICE_CLASSES.CLASS_NUMBER' | translate}}{{datum._id}} -
          {{'NICE_CLASSES.'+datum._id | translate}}</span>
        <div class="ml-10" *ngSwitchCase="'class'">
          <span class="text" *ngIf="_validateDatum(datum)">{{'DOCUMENT_CLASSES.'+datum._id.toUpperCase() |
            translate}}</span>
          <span class="text light" *ngIf="!_validateDatum(datum)">-</span>
        </div>
      </ng-container>
    </div>
    <div class="chip main" *ngIf="level < 4">
      {{datum.count}} {{((datum.count > 1 ? 'ENTITIES.DOCUMENTS' :
      'ENTITIES.DOCUMENT') | translate).toLowerCase()}}
    </div>
    <div class="fx-row align-items-center justify-content-start" *ngIf="level == 4">
      <img [src]="documentIcon(datum._id.type)" [height]="30" class="mr-10" />
      <span class="text bold name">{{datum._id.name || '-'}}</span>
      <mat-icon class="ml-5 main" fontSet="material-icons-outlined" *ngIf="datum._id.confidential"
        [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'CONFIDENTIALITY.CONFIDENTIAL' | translate" inset
        inline>lock</mat-icon>
    </div>
    <mat-icon class="ml-5 main" fontSet="material-icons-outlined" *ngIf="level == 4">navigate_next</mat-icon>
  </div>
  <div class="p-16" *ngIf="total === 0 || data.length === 0">
    <span class="text light">{{'DOCUMENTS_HIERARCHY.NO_DOCUMENTS' | translate}}</span>
  </div>
  <div class="fx-row fx-flex-100 align-items-center justify-content-end p-16">
    <span class="small light">{{(page.current - 1)*page.offset+1}} - {{((page.current - 1)*page.offset+page.limit) <
        total ? ((page.current - 1)*page.offset+page.limit) : total}} of {{total}}</span>
        <mat-icon fontSet="material-icons-outlined" class="light clickable ml-20" [class.lighter]="isPreviousDisabled()"
          [class.disabled]="isPreviousDisabled()"
          (click)="isPreviousDisabled() ? null : previous()">navigate_before</mat-icon>
        <mat-icon fontSet="material-icons-outlined" class="light clickable ml-20" [class.lighter]="isNextDisabled()"
          [class.disabled]="isNextDisabled()" (click)="isNextDisabled() ? null : next()">navigate_next</mat-icon>
  </div>
</div>