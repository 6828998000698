<mat-sidenav-container>
  <mat-sidenav #sidenav class="sidenav" mode="side" [opened]="true" [disableClose]="true">
    <div class="logo-container">
      <img src="/assets/img/logo.svg">
    </div>
    <div class="mt-20 fx-flex fx-column justify-content-between">
      <div>
        <div *ngFor="let section of sections.top; let index = index"
          class="fx-row align-items-center justify-content-start section-container" [routerLink]="section.path"
          [class.active]="section.active" roleBasedElement [roles]="section.roles">
          <mat-icon [class]="section.active ? 'main' : 'dark'"
            fontSet="material-icons-outlined">{{section.icon}}</mat-icon>
          <span class="text ml-5">{{section.label | translate}}</span>
        </div>
      </div>
      <div>
        <div *ngFor="let section of sections.bottom; let index = index"
          class="fx-row align-items-center justify-content-start section-container" [routerLink]="section.path"
          [class.active]="section.active" roleBasedElement [roles]="section.roles">
          <mat-icon [class]="section.active ? 'main' : 'dark'"
            fontSet="material-icons-outlined">{{section.icon}}</mat-icon>
          <span class="text ml-5">{{section.label | translate}}</span>
        </div>
        <div *ngFor="let section of sections.admin; let index = index"
          class="fx-row align-items-center justify-content-start section-container" [routerLink]="section.path"
          [class.active]="section.active" roleBasedElement [roles]="section.roles">
          <mat-icon [class]="section.active ? 'main' : 'dark'"
            fontSet="material-icons-outlined">{{section.icon}}</mat-icon>
          <span class="text ml-5">{{section.label | translate}}</span>
        </div>
      </div>
    </div>
    <mat-divider class="mv-10"></mat-divider>
    <div class="fx-row align-items-center justify-content-start clickable mt-10 mb-10" [cdkMenuTriggerFor]="menu"
      [cdkMenuPosition]="positions">
      <app-avatar [user]="user" [size]="40"></app-avatar>
      <div class="fx-column ml-10 user-container">
        <span class="text user-attribute">{{user?.firstName}} {{user?.lastName}}</span>
        <span class="small light user-attribute">{{user?.email}}</span>
      </div>
    </div>
    <ng-template #menu>
      <mat-card class="menu-container p-0 elevation" cdkMenu>
        <div class="fx-column">
          <div class="fx-row align-items-center justify-content-start menu-item actionable clickable"
            [routerLink]="'settings'" cdkMenuItem>
            <mat-icon class="dark mr-5" fontSet="material-icons-outlined">settings</mat-icon>
            <span class="text">{{'NAV.SECTIONS.SETTINGS' | translate}}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="fx-column">
          <div class="fx-row align-items-center justify-content-start menu-item actionable clickable" (click)="logout()"
            cdkMenuItem>
            <mat-icon class="dark mr-5" fontSet="material-icons-outlined">logout</mat-icon>
            <span class="text">{{'ACTIONS.LOGOUT' | translate}}</span>
          </div>
        </div>
      </mat-card>
    </ng-template>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-progress-bar [loading]="loading" mode="indeterminate"></app-progress-bar>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-upload-panel *ngIf="uploadPanel" class="upload-panel"></app-upload-panel>