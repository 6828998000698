import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ToastrService } from 'ngx-toastr';
import { Case, CaseScore, Note } from 'src/app/models/case';
import { Role, User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CaseService } from 'src/app/services/case.service';
import { MessageService } from 'src/app/services/message.service';
import { AclService } from 'src/app/services/acl.service';

@Component({
  selector: 'app-case-notes',
  templateUrl: './case-notes.component.html',
  styleUrls: ['./case-notes.component.scss']
})
export class CaseNotesComponent {
  @Input() case?: Case;

  private user?: User;

  comment?: string;

  @ViewChild('scrollable') scrollable?: ElementRef;

  focusedNote?: Note;

  public Role = Role;
  role?: Role;

  constructor(
    private _message: MessageService,
    private aclService: AclService,
    private authService: AuthenticationService,
    private caseService: CaseService,
    private toastr: ToastrService
  ) {
    this.authService.user.subscribe((user: User | undefined) => {
      this.user = user;
      this.role = this.aclService.role()
    });
  }

  isNoteDisabled() {
    return !this.comment || this.comment.length === 0;
  }


  async addNote() {
    try {
      if (this.case && this.case._id && this.comment && this.comment.length > 0) {
        this._message.emitChange("LOADING", "START");
        const note = await this.caseService.addNote(this.case?._id, this.comment);
        if (this.user) {
          this.case.notes.push(note)
        }
        this.comment = undefined;
        this.scroll();
        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  // TODO: allow for admins too
  canDeleteNote(note: Note) {
    return note.author.ref === this.user?._id || (this.role && [Role.SUPERADMIN, Role.ADMIN, Role.MANAGER].includes(this.role));
  }

  async deleteNote(note: Note) {
    try {
      if (this.case && this.case._id && note && note._id) {
        this._message.emitChange("LOADING", "START");
        await this.caseService.deleteNote(this.case?._id, note._id);
        this.case.notes = this.case.notes.filter((caseNote) => caseNote._id !== note._id)
        this.scroll();
        this._message.emitChange("LOADING", "END");
      }
    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error('ERRORS.GENERIC');
    }
  }

  timeAgo(ts: moment.Moment | Date) {
    return moment(ts).fromNow();
  }

  scroll() {
    setTimeout(() => {
      if (this.scrollable) {
        this.scrollable.nativeElement.scroll({
          top: this.scrollable.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth'
        });
      }

    }, 1)
  }
}
