<div class="p-40">
  <div class="fx-column">
    <div class="fx-row align-items-center justify-content-between">
      <div class="fx-column">
        <span class="page-title">{{'UPLOAD_DOCUMENTS.TITLE' | translate}}</span>
        <span class="page-subtitle">{{(caseObject ? 'UPLOAD_DOCUMENTS.SUBTITLE_CASE' : 'UPLOAD_DOCUMENTS.SUBTITLE') |
          translate:caseObject}}<b *ngIf="caseObject"> {{caseObject.identifier || caseObject.reference}}</b>.</span>
      </div>
      <div class="fx-row align-items-center justify-content-end ml-20">
        <button class="button" (click)="onCancel()">
          {{'ACTIONS.CANCEL' | translate}}
        </button>
        <button class="main-button ml-10" (click)="upload()" [class.disabled]="isDisabled()" [disabled]="isDisabled()">
          {{'ACTIONS.UPLOAD' | translate}}
        </button>
      </div>
    </div>
  </div>
  <mat-divider class="mv-20"></mat-divider>
  <div class="fx-column">
    <div class="fx-row align-items-stretch justify-content-center">
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER']">
        <mat-card class="mode-card clickable h-100" roleBasedElement [class.disabled]="caseObject"
          (click)="caseObject ? null : setMode('GENERIC')" [class.selected]="mode == 'GENERIC'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_GENERIC" | translate}}</span>
            <mat-icon [class]="mode === 'GENERIC' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'GENERIC' ? 'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <span class="text">
            {{'BATCH.MODE_GENERIC_HINT' | translate}}</span>
        </mat-card>
      </div>
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER','CASE_OPERATOR']">
        <mat-card class="mode-card clickable h-100" (click)="setMode('TRADEMARKS')"
          [class.selected]="mode == 'TRADEMARKS'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_TRADEMARKS" | translate}}</span>
            <mat-icon [class]="mode === 'TRADEMARKS' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'TRADEMARKS' ?
              'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <div class="mb-8">
            <span class="text">
              {{'BATCH.MODE_TRADEMARKS_HINT' | translate}}</span>
          </div>
          <app-trademark-select label="{{'ENTITIES.TRADEMARKS' | translate}}" [multiple]="true" [search]="true"
            [(value)]="trademarks" [disabled]="mode !== 'TRADEMARKS' || caseObject !== undefined"
            #trademarkSelect></app-trademark-select>
        </mat-card>
      </div>
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER','COUNTRY_OPERATOR']">
        <mat-card class="mode-card clickable h-100" [class.disabled]="caseObject"
          (click)="caseObject ? null : setMode('COUNTRIES')" [class.selected]="mode == 'COUNTRIES'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_COUNTRIES" | translate}}</span>
            <mat-icon [class]="mode === 'COUNTRIES' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'COUNTRIES' ?
              'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <div class="mb-8">
            <span class="text">
              {{'BATCH.MODE_COUNTRIES_HINT' | translate}}</span>
          </div>
          <app-territory-select label="{{'ENTITIES.COUNTRIES' | translate}}" [multiple]="true" [search]="true"
            initial="none" [(value)]="countries" [disabled]="mode !== 'COUNTRIES'"
            #territorySelect></app-territory-select>
        </mat-card>
      </div>
      <div class="fx-flex ph-8" roleBasedElement [roles]="['SUPERADMIN','ADMIN','MANAGER','COUNTRY_OPERATOR']">
        <mat-card class="mode-card clickable h-100" [class.disabled]="caseObject"
          (click)="caseObject ? null : setMode('NON_USE')" [class.selected]="mode == 'NON_USE'">
          <div class="fx-row align-items-center justify-content-between">
            <span class="text bold dark">{{"BATCH.MODE_NON_USE" | translate}}</span>
            <mat-icon [class]="mode === 'NON_USE' ? 'main' : 'light'" fontSet="material-icons-outlined">{{mode ===
              'NON_USE' ?
              'check_circle' : 'radio_button_unchecked'}}</mat-icon>
          </div>
          <div class="mb-8 fx-column">
            <span class="text">
              {{'BATCH.MODE_NON_USE_HINT' | translate}}</span>
            <span class="text bold">
              {{'BATCH.MODE_NON_USE_WARNING' | translate}}</span>
          </div>
          <app-trademark-select label="{{'ENTITIES.TRADEMARK' | translate}}" [multiple]="false" [search]="true"
            [(value)]="trademark" (change)="onTrademarkChange($event)"
            [disabled]="mode !== 'NON_USE' || caseObject !== undefined" #trademarkSelect></app-trademark-select>
        </mat-card>
      </div>
    </div>
  </div>
  <mat-divider class=" mv-20"></mat-divider>
  <div>
    <input type="file" class="file-input" (change)="onFilesSelected($event)" #fileUpload [multiple]="mode !== 'NON_USE'"
      accept="image/png, image/jpeg, image/jpg, image/tiff, application/pdf">
    <div class="file-upload fx-column justify-content-center align-items-center" (click)="fileUpload.click()"
      [class.active]="active" *ngIf="(mode === 'NON_USE' && files.length === 0) || (mode !== 'NON_USE')">
      <div class="upload-icon-container">
        <mat-icon class="main" fontSet="material-icons-outlined">cloud_upload</mat-icon>
      </div>
      <div class="mt-10 fx-column">
        <span class="text main text-center">{{(mode !== 'NON_USE' ? "UPLOAD_DOCUMENT.INSTRUCTION" :
          "UPLOAD_DOCUMENT.INSTRUCTION_SINGLE") | translate}}</span>
        <span class="small main text-center">{{"UPLOAD_DOCUMENT.FILE_TYPES" | translate}}</span>
      </div>
    </div>
    <mat-divider class="mv-20" *ngIf="files.length > 0 && mode !== 'NON_USE'"></mat-divider>
    <div class="fx-row align-items-center justify-content-between mb-20" *ngIf="files.length > 0 && mode !== 'NON_USE'">
      <div class="fx-column">
        <span class="title">
          <b>{{files.length}}</b> {{'UPLOAD_DOCUMENTS.DOCUMENTS_COUNT' | translate}}
        </span>
        <div *ngIf="duplicates.length > 0">
          <span class="text red">
            <b>{{duplicates.length}}</b> {{(duplicates.length === 1 ? 'UPLOAD_DOCUMENTS.DUPLICATE_COUNT' :
            'UPLOAD_DOCUMENTS.DUPLICATES_COUNT') | translate}}
          </span>
        </div>
        <div *ngIf="duplicates.length > 0">
          <a class="red-important" (click)="duplicatesOnly ? showAll() : showDuplicates()">{{(duplicatesOnly ?
            'UPLOAD_DOCUMENTS.SHOW_ALL': 'UPLOAD_DOCUMENTS.SHOW_DUPLICATES') |
            translate}}</a>
          <span class="mh-5 text">|</span>
          <a class="red-important" (click)="deleteDuplicates()">{{'UPLOAD_DOCUMENTS.REMOVE_DUPLICATES' | translate}}</a>
        </div>
      </div>
      <app-input placeholder="{{'ACTIONS.SEARCH' | translate}}..." type="text" prefix="search" [(value)]="query"
        (change)="onQueryChange($event)"></app-input>
    </div>
    <div class="fx-row align-items-center justify-content-start border-main p-16"
      *ngIf="filtered.data.length > 0 && (isSomeSelected() || isAllSelected())">
      <span class="text">
        <b>{{selection()}}</b> {{'UPLOAD_DOCUMENTS.SELECTED_COUNT' | translate}}:
      </span>
      <app-document-class-select label="{{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}" [multiple]="false"
        (change)="onDocumentClassChangeForSelection($event)" [labelOutside]="false" class="ml-10"
        style="width:200px;"></app-document-class-select>
      <app-select label="{{'UPLOAD_DOCUMENT.ANALYSIS_MODE' | translate}}" [options]="analysisModes" [multiple]="false"
        (change)="onAnalysisModeChangeForSelection($event)" [labelOutside]="false" class="ml-10"
        style="width:200px;"></app-select>
      <app-checkbox [values]="confidentialitiesMultiple" (change)="onConfidentialityChangeForSelection($event)"
        class="ml-10"></app-checkbox>
    </div>
    <div *ngIf="filtered.data.length === 0 && (query || duplicatesOnly)" class="mv-10">
      <span class="light">{{'UPLOAD_DOCUMENTS.NO_DATA' | translate}}</span>
    </div>
    <div *ngIf="filtered.data.length > 0 && mode !== 'NON_USE'">
      <table mat-table [dataSource]="filtered" style="z-index: 0;" matSort (matSortChange)="onSortChange($event)">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null" color="primary" [checked]="isAllSelected()"
              [indeterminate]="isSomeSelected()">
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let file">
            <mat-checkbox (click)="$event.stopPropagation()" color="primary" (change)="$event ? toggle(file) : null"
              [checked]="file.isSelected">
            </mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span class=" small light bold">
              {{'DOCUMENT.NAME' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let file">
            <div class="fx-row align-items-center justify-content-start">
              <img [src]="icon(file)" [height]="35" class="mr-10" />
              <div class="fx-column" *ngIf="!file.edit">
                <div class="fx-row align-items-center justify-content-start">
                  <span class="text bold">{{file.filename}}</span>
                  <mat-icon *ngIf="file.isDuplicate" class="red ml-5"
                    [matTooltip]="'UPLOAD_DOCUMENTS.DUPLICATE' | translate" inset inline>dynamic_feed</mat-icon>
                </div>
                <span class="text light">{{size(file)}}</span>
              </div>
              <app-input placeholder="{{'DOCUMENT.NAME' | translate}}" type="text" [(value)]="file.filename"
                class="fx-flex" *ngIf="file.edit"></app-input>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let file">
            <div class="fx-row align-items-center justify-content-start">
              <span class="text" *ngIf="!file.edit">{{'DOCUMENT_CLASSES.'+file.class.toUpperCase() | translate}}</span>
              <app-document-class-select label="{{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}" [multiple]="false"
                [(value)]="file.class" [labelOutside]="false" *ngIf="file.edit"></app-document-class-select>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="analysis">
          <th mat-header-cell *matHeaderCellDef>
            <span class=" small light bold">
              {{'UPLOAD_DOCUMENT.ANALYSIS_MODE' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let file">
            <div class="fx-row align-items-center justify-content-start">
              <span class="text" *ngIf="!file.edit">{{'UPLOAD_DOCUMENT.ANALYSIS_MODE_'+(file.analysis.toUpperCase())
                | translate}}</span>
              <app-select label="{{'UPLOAD_DOCUMENT.ANALYSIS_MODE' | translate}}" [options]="analysisModes"
                [multiple]="false" [value]="file.analysis" (change)="onAnalysisModeChange(file,$event)"
                [labelOutside]="false" *ngIf="file.edit"
                [matTooltip]="'UPLOAD_DOCUMENT.ANALYSIS_MODE_'+file.analysis+'_HINT' | translate"></app-select>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="confidentiality">
          <th mat-header-cell *matHeaderCellDef>
            <span class="small light bold">
              {{'UPLOAD_DOCUMENT.CONFIDENTIALITY' | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let file">
            <div class="fx-row align-items-center justify-content-start" *ngIf="!file.edit">
              <mat-icon class="main mr-5" inset inline>{{file.confidential ? 'lock' : 'lock_open'}}</mat-icon>
              <span class="text">{{(file.confidential ? 'CONFIDENTIALITY.CONFIDENTIAL' :
                'CONFIDENTIALITY.NOT_CONFIDENTIAL') | translate}}</span>
            </div>
            <div class="fx-row align-items-center justify-content-start" *ngIf="file.edit">
              <app-checkbox [values]="confidentialities" [(value)]="file.confidential"></app-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let file">
            <mat-icon fontSet="material-icons-outlined" class="main clickable" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'ACTIONS.EDIT' | translate" (click)="edit(file)"
              *ngIf="mode !== 'NON_USE' && !file.edit">edit</mat-icon>
            <mat-icon fontSet="material-icons-outlined" class="main clickable" [matTooltipClass]="{'uzai-tooltip':true}"
              [matTooltip]="'ACTIONS.CLOSE_EDIT' | translate" (click)="file.edit = false"
              *ngIf="mode !== 'NON_USE' && file.edit">cancel</mat-icon>
            <mat-icon fontSet="material-icons-outlined" class="red clickable ml-20"
              [matTooltipClass]="{'uzai-tooltip':true}" [matTooltip]="'ACTIONS.DELETE' | translate"
              (click)="remove(file)">remove_circle_outline</mat-icon>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let document; columns: displayedColumns;">
        </tr>
        <!-- <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="5"><span class="light">{{'UPLOAD_DOCUMENTS.NO_DATA' | translate}}</span></td>
      </tr> -->
      </table>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]">
      </mat-paginator>
    </div>
    <div *ngIf="mode === 'NON_USE'">
      <mat-card *ngFor="let file of filtered.data" class="mv-10 document"
        [class.complete]="file.progress == 100 && file.status === 'DONE'" [class.error]="file.status === 'ERROR'">
        <div class="fx-row fx-wrap align-items-start justify-content-between" *ngIf="mode === 'NON_USE'">
          <div class="fx-row fx-wrap align-items-start justify-content-between  fx-flex-100 mt-10">
            <div class="fx-flex-30 fx-row pr-10">
              <app-input label="Name" placeholder="Name" type="text" [(value)]="file.filename"
                class="fx-flex"></app-input>
            </div>
            <div class="fx-flex-30 fx-row pr-10">
              <app-document-class-select label="{{'UPLOAD_DOCUMENT.DOCUMENT_CLASS' | translate}}" [multiple]="false"
                [(value)]="file.class" class="fx-flex-100" [labelOutside]="true"
                [noAutomatic]="true"></app-document-class-select>
            </div>
            <div class="fx-flex-30 fx-row fx-wrap pr-10">
              <span class="label fx-flex-100">{{'DOCUMENT.LANG' | translate}}</span>
              <app-select label="{{'DOCUMENT.LANG' | translate}}" [options]="langs" [multiple]="true" [search]="true"
                [emitOnInit]="false" class="fx-flex-100" [(value)]="file.langs"
                (change)="onLangsChange(file, $event)"></app-select>
            </div>
          </div>
          <div class="fx-row fx-wrap align-items-start justify-content-between mt-20 fx-flex-100">
            <div class="fx-flex-30 fx-row pr-10">
              <app-nice-select label="{{'ENTITIES.PRODUCTS_SERVICES' | translate}}" [labelOutside]="true"
                [multiple]="true" [search]="true" class="fx-flex-100" [(value)]="file.niceClassification"
                [restrict]="niceClassification" [emitOnInit]="false"></app-nice-select>
            </div>
            <div class="fx-flex-30 fx-row pr-10">
              <app-territory-select label="{{'ENTITIES.TERRITORIES' | translate}}" [labelOutside]="true"
                [multiple]="true" [search]="true" class="fx-flex-100" [(value)]="file.territories"
                [emitOnInit]="false"></app-territory-select>
            </div>
            <div class="fx-flex-30 fx-row pr-10">
              <app-daterangepicker placeholder="{{'ENTITIES.PERIOD' | translate}}"
                label="{{'ENTITIES.PERIOD' | translate}}" [value]="{start:file.period?.start,end:file.period?.end}"
                (change)="onPeriodChange(file,$event)" class="fx-flex-100"></app-daterangepicker>
            </div>
          </div>
          <div class="fx-row fx-wrap align-items-start justify-content-between mt-20 fx-flex-100">
            <div class="fx-flex-30 fx-row pr-10">
              <app-checkbox label="{{'UPLOAD_DOCUMENT.CONFIDENTIALITY' | translate}}" [values]="confidentialities"
                [(value)]="file.confidential" class="fx-flex-100"></app-checkbox>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>