import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss']
})
export class SettingsSecurityComponent {
  @Input() user?: User;
  current: string = '';
  new: string = '';
  confirmation: string = '';

  errors: any = {
    current: {
      value: false,
      message: undefined
    },
    new: {
      value: false,
      message: undefined
    },
    confirmation: {
      value: false,
      message: undefined
    }
  }

  constructor(
    private _message: MessageService,
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private utils: UtilsService
  ) {

  }

  isUpdateDisabled() {
    return false;
  }

  _passwordStrength() {
    return [
      {
        label: 'MIN_LENGTH',
        valid: this.new && this.new.length >= 8
      },
      {
        label: 'MIN_LOWERCASE',
        valid: this.new && /[a-z]+/.test(this.new)
      },
      {
        label: 'MIN_UPPERCASE',
        valid: this.new && /[A-Z]+/.test(this.new)
      },
      {
        label: 'MIN_NUMBER',
        valid: this.new && /[0-9]+/.test(this.new)
      },
      {
        label: 'MIN_SYMBOL',
        valid: this.new && /[!@#\$%\^&\*]+/.test(this.new)
      }
    ]
  }

  _hasErrors() {

    this.errors.new = {
      value: false,
      message: undefined
    }
    if (!this.new) {
      this.errors.new.value = true;
      this.errors.new.message = this.translate.instant('ERRORS.PASSWORD_REQUIRED');
    }
    if (this._passwordStrength().some(attr => !attr.valid)) {
      this.errors.new.value = true;
      this.errors.new.message = this.translate.instant('ERRORS.PASSWORD_DO_NOT_MATCH_REQUIREMENTS');
    }
    this.errors.confirmation = {
      value: false,
      message: undefined
    }
    if (!this.confirmation) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.CONFIRMATION_REQUIRED');
    }
    if (this.confirmation && this.confirmation !== this.new) {
      this.errors.confirmation.value = true;
      this.errors.confirmation.message = this.translate.instant('ERRORS.PASSWORDS_DO_NOT_MATCH');
    }
    return this.errors.new.value || this.errors.confirmation.value;
  }

  async update() {
    try {
      if (this._hasErrors()) {
        return;
      } else {

        if (this.user && this.user.email) {
          this._message.emitChange("LOADING", "START");
          const res = await firstValueFrom(this.authService.login(this.user.email, this.current));
          this.user.password = this.new;
          await firstValueFrom(this.userService.update(this.user));
          this.toastr.success('SETTINGS.SECURITY.UPDATE_SUCCESS_MESSAGE');
          this._message.emitChange("LOADING", "END");
        }
      }
    } catch (err: any) {
      this._message.emitChange("LOADING", "START");
      if (err.message === "WRONG_PASSWORD") {
        this.errors.current.value = true;
        this.errors.current.message = this.translate.instant(`ERRORS.${err.message}`);
      } else {
        if (err && err.message && this.utils.hasTranslation(`ERRORS.${err.message}`)) {
          this.toastr.error(`ERRORS.${err.message}`)
        } else {
          this.toastr.error(`ERRORS.GENERIC`)
        }
      }
    }
  }

  async cancel() {
    this.user = await this.userService.me();
  }
}
