import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { User } from 'src/app/models/user';
import { MessageService } from 'src/app/services/message.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-settings-information',
  templateUrl: './settings-information.component.html',
  styleUrls: ['./settings-information.component.scss']
})
export class SettingsInformationComponent implements OnInit {
  @Input() existingUser?: User;

  user?: User;

  avatar?: File;

  @Output() change = new EventEmitter<User>();

  loading: boolean = false;

  languages: {
    value: string,
    label: string,
    selected: boolean
  }[] = [
      {
        value: "en",
        label: "LANGUAGES.en",
        selected: false
      },
      {
        value: "fr",
        label: "LANGUAGES.fr",
        selected: false
      }
    ]


  constructor(
    private _message: MessageService,
    private toastr: ToastrService,
    private userService: UserService
  ) {

  }

  async ngOnInit() {
    await this.retrieveUser();
    this.languages.forEach((language) => {
      if (this.user?.lang === language.value) {
        language.selected = true;
      }
    })
  }

  async retrieveUser() {
    this.user = await this.userService.me();
    console.log(this.user)
  }

  isUpdateDisabled() {
    return this.loading;
  }

  onAvatarChange(logo?: any) {
    this.avatar = logo;
    if (this.avatar === undefined && this.user) {
      this.user.avatar = undefined;
    }
  }


  async update() {
    try {
      if (this.user) {
        this.loading = true;
        this._message.emitChange("LOADING", "START");
        if (this.avatar) {
          const res = await this.userService.uploadAvatar(this.avatar);
          this.user.avatar = res.url;
        } else if (!this.existingUser?.avatar) {
          this.user.avatar = undefined;
        }
        const user = await firstValueFrom(this.userService.update(this.user));
        this.onChange(user);
        this.toastr.success('SETTINGS.INFORMATION.UPDATE_SUCCESS_MESSAGE');
        this._message.emitChange("LOADING", "END");
        this.loading = false;
      }

    } catch (err) {
      this.toastr.error('ERRORS.GENERIC');
      this._message.emitChange("LOADING", "END");
      this.loading = false;
    }
  }


  async cancel() {
    this.user = this.existingUser;
  }

  onChange(user: User) {
    this.change.emit(user);
  }
} 
