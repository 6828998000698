import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as d3 from 'd3';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";

@Component({
  selector: 'app-graph-documents-timeline',
  templateUrl: './graph-documents-timeline.component.html',
  styleUrls: ['./graph-documents-timeline.component.scss']
})
export class GraphDocumentsTimelineComponent implements OnInit, OnChanges {
  @Input() data: {
    _id: string,
    count: number
  }[] = [];

  @Input() loading: boolean = true;

  @Input() title: boolean = true;

  @Input() height: number = 400;

  ready: boolean = false;


  @Input() error: boolean = false;
  @Input() noData: boolean = false;


  @ViewChild('graphperioddiv') chartElement?: ElementRef<HTMLElement>;
  private chart?: am4charts.XYChart;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private zone: NgZone
  ) {
    moment.locale(this.translateService.currentLang)
  }

  ngOnInit(): void {
    if (this.data && this.data.length > 0) {
      setTimeout(() => {
        this.makeChart();
      }, 100)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data && this.data.length > 0) {
      setTimeout(() => {
        this.makeChart();
      }, 100)
    }
  }

  ngOnDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  buildHeatmap() {
    const validData = this.data.filter((datum) => moment.utc(datum._id, "YYYY-MM").isValid());
    const start = moment.min(validData.map((datum) => moment.utc(datum._id, "YYYY-MM")));
    const end = moment.max(validData.map((datum) => moment.utc(datum._id, "YYYY-MM")));
    let date = start.clone().startOf('year');
    const heatmap: {
      month: number,
      monthLabel: string,
      year: string,
      docs: number
    }[] = [];
    while (date.isBefore(end.endOf('year'))) {
      const docs = validData.filter((datum) => moment.utc(datum._id, "YYYY-MM").isBetween(date.startOf('month'), date.endOf('month'), 'month', '[]')).reduce((accumulator, datum) => {
        return accumulator + datum.count
      }, 0);
      heatmap.push({
        month: date.month() + 1,
        monthLabel: date.format('MMM'),
        year: date.format("YYYY"),
        docs: docs
      })
      date = date.add(1, 'month');
    }
    return heatmap;
  }

  makeChart() {
    const self = this;
    this.zone.runOutsideAngular(() => {
      // Themes end

      const heatmap = this.buildHeatmap();
      const min = Math.min(...heatmap.map(h => h.docs));
      const max = Math.max(...heatmap.map(h => h.docs));

      var chart = am4core.create(this.chartElement?.nativeElement, am4charts.XYChart);
      chart.maskBullets = false;
      chart.logo.setVisibility(false);

      var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

      xAxis.dataFields.category = "monthLabel";
      yAxis.dataFields.category = "year";

      xAxis.renderer.grid.template.disabled = true;
      xAxis.renderer.minGridDistance = 40;

      yAxis.renderer.grid.template.disabled = true;
      yAxis.renderer.inversed = true;
      yAxis.renderer.minGridDistance = 30;

      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.categoryX = "monthLabel";
      series.dataFields.categoryY = "year";
      series.dataFields.value = "docs";
      series.sequencedInterpolation = true;
      series.defaultState.transitionDuration = 3000;

      var bgColor = new am4core.InterfaceColorSet().getFor("background");

      var columnTemplate = series.columns.template;
      columnTemplate.strokeWidth = 1;
      columnTemplate.strokeOpacity = 0.2;
      columnTemplate.stroke = bgColor;
      columnTemplate.tooltipText = `{monthLabel} {year}: {value.workingValue.formatNumber('#.')} ${this.translateService.instant('ENTITIES.DOCUMENTS').toLowerCase()}`;
      columnTemplate.width = am4core.percent(100);
      columnTemplate.height = am4core.percent(100);

      series.heatRules.push({
        target: columnTemplate,
        property: "fill",
        min: am4core.color("#f5f5f7"),
        max: am4core.color("#8847E3")
      });

      // heat legend
      var heatLegend = chart.createChild(am4charts.HeatLegend);
      heatLegend.series = series;
      heatLegend.align = "right";
      heatLegend.valign = "bottom";
      heatLegend.width = am4core.percent(20);
      heatLegend.marginRight = am4core.percent(2);
      heatLegend.minValue = min;
      heatLegend.maxValue = max;
      heatLegend.valueAxis.renderer.labels.template.fontSize = 10;
      heatLegend.valueAxis.renderer.labels.template.fill = am4core.color("#747479");
      heatLegend.valueAxis.renderer.labels.template.fontWeight = 'bold';
      heatLegend.valueAxis.renderer.labels.template.fontFamily = 'Source Sans 3, sans-serif';


      var minRange = heatLegend.valueAxis.axisRanges.create();
      minRange.value = heatLegend.minValue;
      minRange.label.text = min.toString();
      var maxRange = heatLegend.valueAxis.axisRanges.create();
      maxRange.value = heatLegend.maxValue;
      maxRange.label.text = max.toString();

      // Blank out internal heat legend value axis labels
      heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function (labelText) {
        return "";
      });

      series.columns.template.events.on("out", function (event) {
        heatLegend.valueAxis.hideTooltip();
      })

      chart.data = heatmap;
      this.chart = chart;

    })
  }

}
