import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, debounceTime, firstValueFrom } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/dialogs/confirmation-dialog/confirmation-dialog.component';
import { Document, DocumentType } from 'src/app/models/document';
import { Trademark } from 'src/app/models/trademark';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DocumentService } from 'src/app/services/document.service';
import { MessageService } from 'src/app/services/message.service';
import { OtpService } from 'src/app/services/otp.service';
import { environment } from 'src/environments/environment';
import { getDocument, GlobalWorkerOptions, PageViewport, PDFDocumentProxy, PDFPageProxy, version } from 'pdfjs-dist';
import { DocumentEvidence } from 'src/app/models/documentEvidence';
import { animate, group, style, transition, trigger } from '@angular/animations';
import { DocumentMetadata, DocumentMetadataAndEvidence, MetadataType } from 'src/app/models/documentMetadata';
import { BaseException } from 'pdfjs-dist/types/src/shared/util';
import { EditDocumentMetadataComponent } from 'src/app/components/edit-document-metadata/edit-document-metadata.component';
import { FadeInOut } from 'src/app/helpers/animations';
declare var GrabToPan: any;
declare var Tiff: any;

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  animations: [FadeInOut(200, 300, false)]
})
export class DocumentComponent implements OnInit, OnChanges {

  mode: "CONSULT" | "EDIT_METADATA" = "CONSULT";

  document?: Document;

  metadata: DocumentMetadata[] = [];

  evidences: DocumentEvidence[] = [];

  evidenceTypes: { [type: string]: boolean } = {
    'TRADEMARK': false,
    'DATE': false,
    'LOCATION': false,
    'NICE_CLASS': false
  }

  scrollLeftStart: number = 0;
  scrollTopStart: number = 0;
  clientXStart: number = 0;
  clientYStart: number = 0;
  startX: number = 0;
  startY: number = 0;

  isDraw: boolean = false;
  isDown: boolean = false;
  drawing?: {
    x: number,
    y: number,
    width: number,
    height: number
  };
  helpPopupTimeout: any;
  helpPopup: boolean = false;


  loading: boolean = false;

  done: boolean = false;

  src?: any;

  img: any;
  imgWidth: number = 0;
  imgHeight: number = 0;


  locked: boolean = true;

  private readonly lockedSubject = new Subject<boolean>();
  private lockedSubscription?: Subscription;

  @ViewChild('pdfViewer') pdfViewer?: ElementRef;
  @ViewChild('canvasContainer') container?: ElementRef;
  @ViewChild('annotationCanvasContainer') annotationContainer?: ElementRef;
  @ViewChild('drawingCanvasContainer') drawingContainer?: ElementRef;
  @ViewChild('editDocumentMetadata') editDocumentMetadata?: EditDocumentMetadataComponent;

  // TODO: regroup all properties in an unique object,
  // along with canvas (the doc one and the evidences one)
  // and canvas dimension in order to manager this in an easier way
  pdf?: PDFDocumentProxy;
  currentPage: number = 1;
  zoom: number = 1;
  scale: number = 1;

  displayMode: "original" | "evidence" = "original";

  _onMouseMove?: Function;
  _onMouseDown?: Function;
  _onMouseUp?: Function;
  _endPan?: Function;

  canFitWidth: boolean = false;
  canFitHeight: boolean = false;


  private readonly zoomInSubject = new Subject<void>();
  private zoomInSubscription?: Subscription;

  private readonly zoomOutSubject = new Subject<void>();
  private zoomOutSubscription?: Subscription;

  constructor(
    private _message: MessageService,
    private _location: Location,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private otpService: OtpService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this.lockedSubscription = this.lockedSubject
      .subscribe(async () => {
        await this.handleDocumentFile();
      });
    const pdfWorkerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
    GlobalWorkerOptions.workerSrc = pdfWorkerSrc;
    this._onMouseDown = this.onMouseDown.bind(this);
    this._onMouseUp = this.onMouseUp.bind(this);
    this._onMouseMove = this.onMouseMove.bind(this);
    this._endPan = this.endPan.bind(this);
    this.zoomInSubscription = this.zoomInSubject
      .pipe(
        debounceTime(300)
      ).subscribe(async () => {
        await this.zoomIn();
      });

    this.zoomOutSubscription = this.zoomOutSubject
      .pipe(
        debounceTime(300)
      ).subscribe(async () => {
        await this.zoomOut();
      });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.retrieveDocument(params["id"]);
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  async retrieveDocument(id: string) {

    try {
      this._message.emitChange("LOADING", "START");

      this.document = await this.documentService.retrieve(id);
      console.log(this.document)

      await this.retrieveMetadata();
      await this.retrieveEvidences();

      if (!this.document.confidential || this.authService.getStrongAccessToken()) {
        this.unlock();
      } else {
        this.lock();
      }
      this.done = true;

      this._message.emitChange("LOADING", "END");
    } catch (err) {
      if (err instanceof HttpErrorResponse && err.status === 404) {
        this.router.navigate(['errors', '404'])
      }
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async retrieveMetadata() {
    if (this.document && this.document._id) {
      this.metadata = await this.documentService.retrieveMetadata(this.document?._id);
    }
  }

  async retrieveEvidences() {
    if (this.document && this.document._id) {
      this.evidences = await this.documentService.retrieveEvidences(this.document?._id);
    }
  }

  async loadPDF() {
    if (this.src && this.container && this.annotationContainer && this.drawingContainer) {

      const canvas = this.renderer.createElement('canvas');
      const annotationCanvas = this.renderer.createElement('canvas');
      const drawingCanvas = this.renderer.createElement('canvas');

      this.annotationContainer.nativeElement.addEventListener("mousedown", this._onMouseDown, true);
      this.drawingContainer.nativeElement.addEventListener("mousedown", this._onMouseDown, true);

      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      this.pdf = await getDocument(this.src).promise;
      this.container.nativeElement.appendChild(canvas);
      this.annotationContainer.nativeElement.appendChild(annotationCanvas);
      this.drawingContainer.nativeElement.appendChild(drawingCanvas);
      await this.displayPage(1, true);
    }
  }

  async loadImage() {

    if (this.src && this.container && this.annotationContainer && this.drawingContainer) {

      const canvas = this.renderer.createElement('canvas');
      const annotationCanvas = this.renderer.createElement('canvas');
      const drawingCanvas = this.renderer.createElement('canvas');

      this.annotationContainer.nativeElement.addEventListener("mousedown", this._onMouseDown, true);
      this.drawingContainer.nativeElement.addEventListener("mousedown", this._onMouseDown, true);

      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      // this.pdf = await getDocument(this.src).promise;
      this.container.nativeElement.appendChild(canvas);
      this.annotationContainer.nativeElement.appendChild(annotationCanvas);
      this.drawingContainer.nativeElement.appendChild(drawingCanvas);

      this.renderImage(true);
    }
  }

  openEditMetadata() {
    this.mode = 'EDIT_METADATA';
    this.displayMode = 'evidence';
    this.showEvidences();
  }

  closeEditMetadata() {
    this.mode = 'CONSULT';
    this.isDraw = false;
    this.cancelAnnotation();
    if (this.editDocumentMetadata) {
      this.editDocumentMetadata.isDraw = false;
    }
    if (this.drawingContainer) {
      const element = this.drawingContainer.nativeElement;
      element.classList.remove("crosshair");
    }
  }

  toggleDraw() {
    this.isDraw = !this.isDraw;
    if (this.isDraw) {
      this.helpPopup = true;
      this.helpPopupTimeout = setTimeout(() => {
        this.helpPopup = false;
      }, 5000)
    }
    if (this.drawingContainer && this.isDraw) {
      const element = this.drawingContainer.nativeElement;
      element.classList.add("crosshair");
    } else if (this.drawingContainer && !this.isDraw) {
      const element = this.drawingContainer.nativeElement;
      element.classList.remove("crosshair");
    }

  }

  onMouseDown(e: any) {
    if (this.container && this.annotationContainer && this.drawingContainer) {
      if (!this.isDraw) {
        const element = this.drawingContainer.nativeElement;
        element.classList.add("grab-to-pan-grab");
        this.scrollLeftStart = element.scrollLeft;
        this.scrollTopStart = element.scrollTop;
        this.clientXStart = e.clientX;
        this.clientYStart = e.clientY;
        element.firstChild.addEventListener("mousemove", this._onMouseMove, true);
        element.addEventListener('mouseup', this._onMouseUp, true);
        element.addEventListener('mouseout', this._onMouseUp, true);
        element.addEventListener('scroll', this._endPan, true);
        e.preventDefault();
        e.stopPropagation();
      } else {
        const element = this.drawingContainer.nativeElement;
        element.firstChild.addEventListener("mousemove", this._onMouseMove, true);
        element.addEventListener('mouseup', this._onMouseUp, true);
        e.preventDefault();
        e.stopPropagation();

        this.startX = e.clientX - element.firstChild.getBoundingClientRect().left;
        this.startY = e.clientY - element.firstChild.getBoundingClientRect().top;
        this.isDown = true;
      }
    }
  }

  onMouseUp(e: any) {
    if (this.drawingContainer) {
      if (!this.isDraw) {
        const element = this.drawingContainer.nativeElement;
        element.classList.remove("grab-to-pan-grab");
        element.removeEventListener('mouseup', this._endPan, true);
        element.removeEventListener('mouseout', this._endPan, true);
        element.removeEventListener('scroll', this._endPan, true);
        element.firstChild.removeEventListener('mousemove', this._onMouseMove, true);
      } else {
        e.preventDefault();
        e.stopPropagation();

        this.isDown = false;
      }

    }
  }

  onMouseMove(e: any) {
    if (this.container && this.annotationContainer && this.drawingContainer) {
      if (!this.isDraw) {
        const drawing = this.drawingContainer.nativeElement;
        const annotation = this.annotationContainer.nativeElement;
        const render = this.container.nativeElement;
        drawing.removeEventListener('scroll', this._endPan, true);

        var xDiff = e.clientX - this.clientXStart;
        var yDiff = e.clientY - this.clientYStart;
        var scrollTop = this.scrollTopStart - yDiff;
        var scrollLeft = this.scrollLeftStart - xDiff;
        drawing.scrollTo({
          top: scrollTop,
          left: scrollLeft,
          behavior: 'instant'
        });
        annotation.scrollTo({
          top: scrollTop,
          left: scrollLeft,
          behavior: 'instant'
        });
        render.scrollTo({
          top: scrollTop,
          left: scrollLeft,
          behavior: 'instant'
        });
      } else {
        const element = this.drawingContainer.nativeElement;
        const drawingCanvas = this.drawingContainer.nativeElement.firstChild;
        const drawingctx = drawingCanvas.getContext('2d') as CanvasRenderingContext2D;
        drawingctx.beginPath();
        drawingctx.lineWidth = 2;
        drawingctx.strokeStyle = "rgb(136,71,227)";

        e.preventDefault();
        e.stopPropagation();

        if (!this.isDown) {
          return;
        }

        const mouseX = e.clientX - drawingCanvas.getBoundingClientRect().left;
        const mouseY = e.clientY - drawingCanvas.getBoundingClientRect().top;

        drawingctx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);

        var width = mouseX - this.startX;
        var height = mouseY - this.startY;

        this.drawing = {
          x: this.startX / this.scale,
          y: this.startY / this.scale,
          width: width / this.scale,
          height: height / this.scale
        }
        drawingctx.strokeRect(this.startX, this.startY, width, height);
      }

    }

  }

  endPan(e: any) {
    if (this.container && this.drawingContainer) {
      const element = this.drawingContainer.nativeElement;
      element.removeEventListener('scroll', this._endPan, true);
      element.removeEventListener('mousemove', this._onMouseMove, true);
      element.removeEventListener('mouseup', this._endPan, true);
    }
  }

  async renderImage(init: boolean = false) {

    if (this.container && this.annotationContainer && this.drawingContainer) {

      const canvas = this.container.nativeElement.firstChild;
      const annotationCanvas = this.annotationContainer.nativeElement.firstChild;
      const drawingCanvas = this.drawingContainer.nativeElement.firstChild;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;

      const width = this.imgWidth * this.zoom;
      const height = this.imgHeight * this.zoom;
      const aspect = height > width ? 'portrait' : 'landscape';
      this.scale = aspect === 'portrait' ? containerHeight / height : containerWidth / width;

      if (this.scale < 1 || init) {
        this.container.nativeElement.classList.add('flex-container');
        this.annotationContainer.nativeElement.classList.add('flex-container');
        this.drawingContainer.nativeElement.classList.add('flex-container');
      }

      if (this.imgWidth * this.scale > containerWidth) {
        this.container.nativeElement.classList.remove('flex-container');
        this.annotationContainer.nativeElement.classList.remove('flex-container');
        this.drawingContainer.nativeElement.classList.remove('flex-container');
      }
      canvas.height = this.imgHeight * this.scale;
      canvas.width = this.imgWidth * this.scale;
      annotationCanvas.height = this.imgHeight * this.scale;
      annotationCanvas.width = this.imgWidth * this.scale;
      drawingCanvas.height = this.imgHeight * this.scale;
      drawingCanvas.width = this.imgWidth * this.scale;

      ctx.drawImage(this.img, 0, 0, this.imgWidth * this.scale, this.imgHeight * this.scale)
      this.container.nativeElement.appendChild(canvas);
      this.annotationContainer.nativeElement.appendChild(annotationCanvas);
      this.drawingContainer.nativeElement.appendChild(drawingCanvas);
    }
  }

  async renderPage(page: PDFPageProxy, init: boolean = false) {

    if (this.container && this.annotationContainer && this.drawingContainer) {

      const canvas = this.container.nativeElement.firstChild;
      const annotationCanvas = this.annotationContainer.nativeElement.firstChild;
      const drawingCanvas = this.drawingContainer.nativeElement.firstChild;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;
      const viewport = page.getViewport({ scale: this.zoom });


      const aspect = viewport.height > viewport.width ? 'portrait' : 'landscape';
      this.scale = aspect === 'portrait' ? containerHeight / viewport.height : containerWidth / viewport.width;
      if (this.scale < 1 || init) {
        this.container.nativeElement.classList.add('flex-container');
        this.annotationContainer.nativeElement.classList.add('flex-container');
        this.drawingContainer.nativeElement.classList.add('flex-container');
      }
      const scaledViewport = page.getViewport({ scale: this.scale });
      if (scaledViewport.width > containerWidth) {
        this.container.nativeElement.classList.remove('flex-container');
        this.annotationContainer.nativeElement.classList.remove('flex-container');
        this.drawingContainer.nativeElement.classList.remove('flex-container');
      }
      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;
      annotationCanvas.height = scaledViewport.height;
      annotationCanvas.width = scaledViewport.width;
      drawingCanvas.height = scaledViewport.height;
      drawingCanvas.width = scaledViewport.width;


      const renderContext = {
        canvasContext: ctx,
        viewport: scaledViewport,
      };

      const renderedPage = await page.render(renderContext).promise;

      this.container.nativeElement.appendChild(canvas);
      this.annotationContainer.nativeElement.appendChild(annotationCanvas);
      this.drawingContainer.nativeElement.appendChild(drawingCanvas);
    }
  }


  async displayPage(index: number, init: boolean = false) {
    if (this.document && this.document.type === DocumentType.PDF && this.pdf) {
      const page = await this.pdf.getPage(index);
      await this.renderPage(page, init);
      if (this.displayMode === 'evidence') {
        this.showEvidences();
      }
      if (this.drawing) {
        this.showDrawing();
      }
    } else if (this.document && this.document.type !== DocumentType.PDF && this.img) {
      await this.renderImage(init);
      if (this.displayMode === 'evidence') {
        this.showEvidences();
      }
      if (this.drawing) {
        this.showDrawing();
      }
    }
    this.canFitHeight = await this._canFitHeight();
    this.canFitWidth = await this._canFitWidth();
  }


  isPreviousPageDisabled() {
    return this.currentPage === 1;
  }
  previousPage() {
    this.currentPage -= 1;
    this.resetDisplay()
  }
  isNextPageDisabled() {
    return this.pdf && this.currentPage >= this.pdf.numPages;
  }
  nextPage() {
    this.currentPage += 1;
    this.resetDisplay()
  }

  zoomInClick() {
    this.zoomInSubject.next();
  }

  async zoomIn() {
    this.zoom = 0.75 * this.zoom;
    await this.displayPage(this.currentPage);
  }

  zoomOutClick() {
    this.zoomOutSubject.next();
  }

  async zoomOut() {
    this.zoom = this.zoom / 0.75;
    await this.displayPage(this.currentPage);
  }

  async _canFitWidth() {
    if (this.container) {
      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;
      let width = 0, height = 0;
      let aspect = 'portrait';
      if (this.pdf) {
        const page = await this.pdf.getPage(this.currentPage);
        const viewport = page.getViewport({ scale: this.zoom });
        width = viewport.width;
        height = viewport.height;
        aspect = height > width ? 'portrait' : 'landscape';
      } else if (this.src && this.img) {
        width = this.imgWidth;
        height = this.imgHeight;
        aspect = height > width ? 'portrait' : 'landscape';
      } else {
        return false
      }
      const scale = aspect === 'portrait' ? containerHeight / height : containerWidth / width;
      return this.zoom != (scale * width / containerWidth);
    }
    return false;
  }

  async fitWidth() {
    if (this.container) {
      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;
      let width = 0, height = 0;
      let aspect = 'portrait';
      if (this.pdf) {
        const page = await this.pdf.getPage(this.currentPage);
        const viewport = page.getViewport({ scale: this.zoom });
        width = viewport.width;
        height = viewport.height;
        aspect = height > width ? 'portrait' : 'landscape';

      } else if (this.src && this.img) {
        width = this.imgWidth;
        height = this.imgHeight;
        aspect = height > width ? 'portrait' : 'landscape';
      }
      const scale = aspect === 'portrait' ? containerHeight / height : containerWidth / width;

      this.zoom = scale * width / containerWidth;
      await this.displayPage(this.currentPage);
    }
  }

  async _canFitHeight() {
    if (this.container) {
      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;
      let width = 0, height = 0;
      let aspect = 'portrait';
      if (this.pdf) {
        const page = await this.pdf.getPage(this.currentPage);
        const viewport = page.getViewport({ scale: this.zoom });
        width = viewport.width;
        height = viewport.height;
        aspect = height > width ? 'portrait' : 'landscape';
      } else if (this.src && this.img) {
        width = this.imgWidth;
        height = this.imgHeight;
        aspect = height > width ? 'portrait' : 'landscape';
      } else {
        return false
      }

      const scale = aspect === 'portrait' ? containerHeight / height : containerWidth / width;
      return this.zoom != (scale * height / containerHeight);
    }
    return false;
  }

  async fitHeight() {
    if (this.container) {
      const containerHeight = this.container.nativeElement.clientHeight;
      const containerWidth = this.container.nativeElement.clientWidth;
      let width = 0, height = 0;
      let aspect = 'portrait';
      if (this.pdf) {
        const page = await this.pdf.getPage(this.currentPage);
        const viewport = page.getViewport({ scale: this.zoom });
        width = viewport.width;
        height = viewport.height;
        aspect = height > width ? 'portrait' : 'landscape';
      } else if (this.src && this.img) {
        width = this.imgWidth;
        height = this.imgHeight;
        aspect = height > width ? 'portrait' : 'landscape';
      }
      const scale = aspect === 'portrait' ? containerHeight / height : containerWidth / width;
      this.zoom = scale * height / containerHeight;
      await this.displayPage(this.currentPage);
    }
  }

  resetDisplay() {
    this.zoom = 1;
    if (this.container && this.annotationContainer && this.drawingContainer) {

      const drawing = this.drawingContainer.nativeElement;
      const annotation = this.annotationContainer.nativeElement;
      const render = this.container.nativeElement;
      drawing.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      annotation.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
      render.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }
    this.displayPage(this.currentPage, true);
  }




  showDrawing() {
    if (this.drawingContainer && this.drawing) {
      const canvas = this.drawingContainer.nativeElement.firstChild;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.strokeStyle = "rgb(136,71,227)";

      ctx.rect(this.drawing.x * this.scale, this.drawing.y * this.scale, this.drawing.width * this.scale, this.drawing.height * this.scale);
      ctx.stroke();
    }
  }

  cancelAnnotation() {
    this.drawing = undefined;
    if (this.drawingContainer) {
      const drawingCanvas = this.drawingContainer.nativeElement.firstChild;
      const drawingctx = drawingCanvas.getContext('2d') as CanvasRenderingContext2D;

      drawingctx.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
    }
  }

  closeDrawingHelpPopup() {
    clearTimeout(this.helpPopupTimeout)
    this.helpPopup = false;
  }

  translateEvidenceMetadata(type: string, metadata: any) {
    switch (type) {
      case 'TRADEMARK':
        return `${metadata._id}_${metadata.name}_${metadata.identifierNumber}_${metadata.countryOfDesignation}`;
      case 'DATE':
        return moment(metadata).format("YYYY-MM-DD");
      case 'LOCATION':
        return metadata.toUpperCase();
      case 'NICE_CLASS':
      case 'PERIOD':
        return `${metadata}`;
      case 'USER':
        return metadata.replace(' ', '_');
      default:
        return undefined;
    }
  }

  async confirmAnnotation(event: { type: MetadataType, metadata: any }) {
    if (this.document && this.document._id && this.isDraw && this.drawing && event.type) {
      await this.addMetadata({
        type: event.type,
        value: this.translateEvidenceMetadata(event.type, event.metadata),
        evidence: {
          page: this.currentPage,
          start: [this.drawing?.x, this.drawing.y],
          end: [this.drawing?.x + this.drawing.width, this.drawing.y + this.drawing.height]
        }
      });
      this.cancelAnnotation();
      this.redraw();
      this.toggleDraw();
    }
  }

  async addMetadata(metadataAndEvidence: DocumentMetadataAndEvidence) {
    try {
      if (this.document && this.document._id) {
        this._message.emitChange("LOADING", "START");

        this.document = await this.documentService.addMetadata(this.document._id, metadataAndEvidence);
        await this.retrieveEvidences();
        await this.retrieveMetadata();
        this.toastr.success(`DOCUMENT.METADATA_ADDED`);
        this._message.emitChange("LOADING", "END");
      }

    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async updateMetadata(metadataAndEvidence: DocumentMetadataAndEvidence) {
    try {
      if (this.document && this.document._id) {
        this._message.emitChange("LOADING", "START");

        this.document = await this.documentService.updateMetadata(this.document._id, metadataAndEvidence);
        await this.retrieveEvidences();
        await this.retrieveMetadata();
        this.toastr.success(`DOCUMENT.METADATA_UPDATED`);
        this._message.emitChange("LOADING", "END");
      }

    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async deleteMetadata(metadata: DocumentMetadata) {
    try {
      if (this.document && this.document._id && metadata && metadata._id) {
        this._message.emitChange("LOADING", "START");
        this.document = await this.documentService.deleteMetadata(this.document._id, metadata._id);
        await this.retrieveEvidences();
        await this.retrieveMetadata();
        this.toastr.info(`DOCUMENT.METADATA_DELETED`);
        this._message.emitChange("LOADING", "END");
      }

    } catch (err) {
      this._message.emitChange("LOADING", "END");
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  redraw() {
    if (this.displayMode === 'evidence') {
      this.clearEvidences();
      this.showEvidences();
    }
  }

  showTypeEvidences(type: string) {
    const metadataType = MetadataType[type as keyof typeof MetadataType];
    if (metadataType !== undefined && !this.locked) {
      if (this.displayMode === 'evidence') {
        this.clearEvidences()
      }
      this.evidenceTypes[type] = true;
      this.evidences.filter(evidence => evidence.page === this.currentPage && evidence.type === metadataType).forEach(evidence => {
        this.showEvidence(evidence)
      })
    }

  }

  hideTypeEvidences(type: string) {
    const metadataType = MetadataType[type as keyof typeof MetadataType];
    if (metadataType !== undefined) {

      this.evidences.filter(evidence => evidence.page === this.currentPage && evidence.type === metadataType).forEach(evidence => {
        this.clearEvidence(evidence)
      })
      this.evidenceTypes[type] = false;
      if (this.displayMode === 'evidence') {
        this.showEvidences()
      }
    }
  }

  focusTypeEvidences(focused: boolean, type: MetadataType) {
    if (focused) {
      this.showTypeEvidences(type);
    } else {
      this.hideTypeEvidences(type);
    }
  }


  showPreciseEvidence(event: { type: MetadataType, value: any }) {

    if (event.type !== undefined && !this.locked) {
      this.evidenceTypes[event.type] = true;
      if (this.displayMode === 'evidence') {
        this.clearEvidences()
      }
      const metadata = this.metadata.find(datum => datum.type === event.type && datum.value === event.value);
      if (metadata) {
        this.evidences.filter(evidence => evidence.page === this.currentPage && evidence.metadata === metadata._id).forEach(evidence => {
          this.showEvidence(evidence)
        })
      }

    }
  }

  hidePreciseEvidence(event: { type: MetadataType, value: any }) {
    if (event.type !== undefined) {
      this.evidenceTypes[event.type] = false;
      const metadata = this.metadata.find(datum => datum.type === event.type && datum.value === event.value);
      if (metadata) {
        this.evidences.filter(evidence => evidence.page === this.currentPage && evidence.metadata === metadata._id).forEach(evidence => {
          this.clearEvidence(evidence)
        })
      }
      if (this.displayMode === 'evidence') {
        this.showEvidences()
      }
    }
  }

  showEvidences() {
    Object.keys(this.evidenceTypes).forEach(type => {
      this.evidenceTypes[type] = true;
    })
    this.evidences.filter(evidence => evidence.page === this.currentPage).forEach(evidence => {
      this.showEvidence(evidence)
    })
  }

  showEvidence(evidence: DocumentEvidence) {
    if (this.annotationContainer && evidence.start && evidence.end) {

      const canvas = this.annotationContainer.nativeElement.firstChild;
      if (canvas) {
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.beginPath();
        ctx.lineWidth = 2;
        ctx.strokeStyle = "rgb(136,71,227)";

        const x = Math.floor((evidence.start[0]) * this.scale);
        const y = Math.floor((evidence.start[1]) * this.scale);
        const height = Math.ceil((evidence.end[1] - evidence.start[1]) * this.scale);
        const width = Math.ceil((evidence.end[0] - evidence.start[0]) * this.scale);
        ctx.rect(x, y, width, height);
        ctx.stroke();
      }

    }

  }

  clearEvidences() {
    if (this.annotationContainer) {
      Object.keys(this.evidenceTypes).forEach(type => {
        this.evidenceTypes[type] = false;
      })
      const canvas = this.annotationContainer.nativeElement.firstChild;
      if (canvas) {
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }

    }
  }

  clearEvidence(evidence: DocumentEvidence) {
    if (this.annotationContainer && evidence.start && evidence.end) {

      const canvas = this.annotationContainer.nativeElement.firstChild;
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      const x = Math.floor((evidence.start[0]) * this.scale);
      const y = Math.floor((evidence.start[1]) * this.scale);
      const height = Math.ceil((evidence.end[1] - evidence.start[1]) * this.scale);
      const width = Math.ceil((evidence.end[0] - evidence.start[0]) * this.scale);

      ctx.clearRect(x - 1, y - 1, width + 2, height + 2);
    }

  }



  documentIcon() {
    return this.documentService.icon(this.document);
  }

  formatSize() {
    return this.documentService.size(this.document);
  }

  formatPeriod() {
    return this.document ? this.documentService.period(this.document) : '-';
  }

  formatDate(date: Moment) {
    return moment(date).format('ll');
  }

  formatDatetime(date: Moment) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  confidence(metadata: MetadataType, ref: string) {

    return 0;
  }

  authenticate() {
    this.otpService.check(() => this.unlock(), () => this.lock());
  }

  async handleDocumentFile() {
    if (this.document) {
      if (!this.locked) {
        try {
          this.loading = true;

          if (this.document.type === 'application/pdf') {
            this.src = await (await this.documentService.download(this.document, this.document.confidential)).arrayBuffer();
            this.loading = false;
            setTimeout(() => {
              this.loadPDF();
            }, 0)
          } else if (this.document.type && ['image/jpeg', 'image/png', 'image/jpg'].includes(this.document.type)) {
            const imageBlob = await this.documentService.download(this.document, this.document.confidential);
            this.img = this.renderer.createElement('img');

            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = _event => {
              if (reader.result) {
                this.src = reader.result;
                this.img.src = reader.result;
                this.loading = false;
                // this.loadImage()
              }
            };
            this.img.onload = (_event: any) => {
              this.imgWidth = this.img.width;
              this.imgHeight = this.img.height;
              this.loadImage();
            }
          } else if (this.document.type === 'image/tiff') {
            const imageBlob = await this.documentService.download(this.document, this.document.confidential);
            this.img = this.renderer.createElement('img');

            const reader = new FileReader();
            reader.readAsDataURL(imageBlob);
            reader.onloadend = _event => {
              if (reader.result) {
                var dataUrl = reader.result as string;
                var base64 = dataUrl.split(',')[1];
                base64.replace(/^data:image\/[a-z]+;base64,/, "");
                const binaryData = Uint8Array.from(atob(base64), c => c.charCodeAt(0));
                const tiff = new Tiff({ buffer: binaryData });
                const canvas = tiff.toCanvas();
                const jpgBase64Data = canvas.toDataURL("image/jpeg").replace(/^data:image\/jpeg;base64,/, "");
                const finalBase64Data = "data:image/jpeg;base64," + jpgBase64Data;
                this.src = finalBase64Data;
                this.img.src = finalBase64Data;
                this.loading = false;
              }
            };
            this.img.onload = (_event: any) => {
              this.imgWidth = this.img.width;
              this.imgHeight = this.img.height;
              this.loadImage();
            }

          }

        } catch (err: any) {
          if (err && err.status === 403) {
            this.lock();
          } else {
            console.log(err)
          }

        }
      }
    }
  }

  lock() {
    this.locked = true;
    this.lockedSubject.next(this.locked)
  }

  unlock() {
    this.locked = false;
    this.lockedSubject.next(this.locked)
  }


  onDisplayModeChange(event: any) {
    this.displayMode = event.checked ? 'evidence' : 'original';
    if (this.displayMode === "original") {
      this.clearEvidences();
    } else {
      this.showEvidences();
    }
  }

  canDisplayOrDownload(action: 'display' | 'download'): boolean {
    if (action === 'display') {
      return this.document?.documentKey !== undefined;
    } else {
      return !this.locked && this.document?.documentKey !== undefined;
    }
  }

  async download() {
    let toast;
    try {
      if (this.document && this.document.type) {
        const extension = this.documentService.extension(this.document.type?.toString());
        if (extension) {
          toast = this.toastr.info(this.translate.instant('DOCUMENT.DOWNLOAD_MESSAGE', this.document), "DOCUMENT.DOWNLOAD_TITLE", {
            progressBar: true,
            disableTimeOut: true
          })
          const base_name = this.document.name.replace(`.${extension.toLowerCase()}`, '').replace(`.${extension}`, '');
          const blob = await this.documentService.download(this.document, this.document.confidential);
          const a = document.createElement('a')
          const objectUrl = URL.createObjectURL(blob)
          a.href = objectUrl
          a.download = `${base_name}${version === 'evidence' ? '_evidence' : ''}.${extension}`;
          a.click();
          this.toastr.clear(toast.toastId);
          URL.revokeObjectURL(objectUrl);
        } else {
          this.toastr.error(`ERRORS.GENERIC`);
        }

      }

    } catch (err) {
      if (toast) {
        this.toastr.clear(toast.toastId);
      }
      this.toastr.error(`ERRORS.GENERIC`)
    }
  }

  async delete() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'DOCUMENT.DELETE_DOCUMENT_TITLE',
        text: this.translate.instant('DOCUMENT.DELETE_DOCUMENT_TEXT', this.document),
        button: {
          text: 'ACTIONS.DELETE',
          class: 'danger-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.document && this.document._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.documentService.delete(this.document._id);
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('DOCUMENT.DELETE_DOCUMENT_SUCCESS', this.document));
        this._location.back()

      } catch (err) {
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  async reanalyze() {
    const config: MatDialogConfig = {
      panelClass: 'dialog-container',
      width: '480px',
      data: {
        title: 'DOCUMENT.REANALYZE_TITLE',
        text: this.translate.instant('DOCUMENT.REANALYZE_TEXT', this.document),
        button: {
          text: 'ACTIONS.REANALYZE',
          class: 'main-button'
        }
      }
    }
    const dialog = this.dialog.open(ConfirmationDialogComponent, config);
    const result: { confirmed: boolean } = await firstValueFrom(dialog.afterClosed());
    if (result && result.confirmed && this.document && this.document._id) {
      try {
        this._message.emitChange("LOADING", "START");
        await this.documentService.analyze(this.document._id);
        this._message.emitChange("LOADING", "END");
        this.toastr.success(this.translate.instant('DOCUMENT.REANALYZE_SUCCESS', this.document));
      } catch (err) {
        this.loading = false;
        this._message.emitChange("LOADING", "END");
        this.toastr.error('ERRORS.GENERIC');
      }
    }
  }

  toBatch(id: string | undefined) {
    if (id) {
      this.router.navigateByUrl(`documents/batches/${id}`, {
        state: {
          from: "DOCUMENT"
        }
      })
    }
  }

  back() {
    if (this.mode === 'CONSULT') {
      this._location.back();
    } else {
      this.closeEditMetadata();
    }
  }

}
